import HideFooter from "../../../HideFooter";
import HideHeader from "../../../HideHeader";
import Sidebar from "./SlideBar";

const DatagatherReportLayout = (props) => {
  return (
    <div className="reports ">
      <HideHeader/>
      <Sidebar />
      {props.children}
      <HideFooter />
    </div>
  );
};
export default DatagatherReportLayout;
