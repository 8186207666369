import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewAlternateAssetsFormView from "./NewAlternateAssetsFormView";

const NewAlternateAssetsForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewAlternateAssetsFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewAlternateAssetsForm;
