import { useState, useEffect } from "react";
import NextImg from "../../../Assets/Images/CommonDashboard/BlogView.svg";
import Trade from "../../../Assets/Images/CommonDashboard/buy_taxplan.png";
// import NextImg from "../../../Assets/Images/CommonDashboard/Next.png";

import { Link, useNavigate } from "react-router-dom";
import {
  
  BASE_API_URL,
  BLOG_URL,
} from "../../../constants";
import {
  apiCall,
  getUserId,
  loginRedirectGuest,
  successAlert,
} from "../../../common_utilities";
import CommonDashboardLayout from "../../../components/Layout/Commomdashboard";

import CardBox from "../../../components/CommonDashboard/CardBox";
import DashboardSlider from "../../../components/CommonDashboard/DashboardSlider";

import BlogBoxSection from "./BlogBoxSection";
import commonEncode from "../../../commonEncode";
import SavingAccountSection from "../../MoneyManagement/views/CommonDashboard/SavingAccountSection";

const triggerDefaulyValues = [
  {'report_frequency': 'Monthly'},
  {'portfolio_percentage': '5%', 'portfolio_movement': 'Both', 'portfolio_Mode': 'Both'},
  {'mutual_fund_percentage': '3%', 'mutual_fund_movement': 'Both', 'mutual_fund_Mode': 'Both'},
  {'stock_percent': '3%', 'stock_movement': 'Both', 'stock_Mode': 'Both'},
  {'buzz_mutual_fund_frequency': 'Monthly', 'buzz_mutual_fund_Mode': 'Both'},
  {'goal_frequency': '3 Months'},
  {'Subscription':true},
  {'Report':true},
  {'Portfolio':true},
  {'MutualFunds':true},
  {'Stocks':true},
  {'BuzzMF':true},
  {'Goals':true},
]
let url = BASE_API_URL + "managetrigger/";
let payload = {
  "user_id":getUserId(),
  "tag":"all",
  "data":triggerDefaulyValues
}
let default_data = apiCall(url, payload, false, false);
// console.log(default_data)
const progressBarValues = [
  {
    title: "Equity",
    value: 20,
    color: "#fd9745",
  },
  {
    title: "Debt",
    value: 10,
    color: "#fe5a01",
  },
  {
    title: "Real Estate",
    value: 14,
    color: "#e3e3e3",
  },
  {
    title: "Liquid",
    value: 18,
    color: "#3598db",
  },
  {
    title: "Alternate",
    value: 10,
    color: "#16a086",
  },
  {
    title: "Gold",
    value: 24,
    color: "#2dcc70",
  },
  {
    title: "Other",
    value: 4,
    color: "#ffcc00",
  },
];

const news = [
  {
    id: 1,
    title: "Which Are The Best Stocks Below Rs 20 in India?",
    img: require("../../../Assets/Images/CommonDashboard/News1.jpg"),
  },
  {
    id: 2,
    title: "SMFG said to face $670 million tax hit on Fullerton India deal",
    img: require("../../../Assets/Images/CommonDashboard/News2.jpg"),
  },
  {
    id: 3,
    title: "India is one of the greatest opportunity in the world",
    img: require("../../../Assets/Images/CommonDashboard/News3.jpg"),
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
const Dashboard = ({ lifecyclestatus, renewpopup,subscriptionenddate }) => {
  const [returnsType, setReturnsType] = useState("xirr");
  const [selectedTab, setSelectedTab] = useState(1);
  const [blogdata, setBlogData] = useState([]);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const value = params.get('rm');
  const replacedString = value?.replace(/\+/g, ' ');
  const decodedValue = decodeURIComponent(replacedString);
  const [rmname, setRmname] = useState("");
  useEffect(()=> {
    // const value = localStorage.getItem("rm_tag")
    if (value) {
      const decodedValue = value.replaceAll(' ', '+');
      const rmname = commonEncode.decrypt(decodedValue)
      successAlert("Your financial planning report has been generated and shared with your designated wealth manager. <b>" +rmname+ "</b> will get in touch with you shortly and help you understand the report.");
      // localStorage.removeItem("rm_tag")
    }
  }, [value]);

  return (
    <CommonDashboardLayout>
      
      {/* <p style={{ height: "2rem" }}></p> */}
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        {/* <div className="row">
          <NiftySensex  renewpopup={renewpopup} />
        </div> */}
        <div className="Section">
          <CardBox lifecyclestatus={lifecyclestatus} renewpopup={renewpopup} subscriptionenddate={subscriptionenddate} />
        </div>
<SavingAccountSection />
        <div className="Section">
          <DashboardSlider
            lifecyclestatus={lifecyclestatus}
            renewpopup={renewpopup} subscriptionenddate={subscriptionenddate}
          />
        </div>
        <div className="Section row">
          <div className="col-md-6 col-12">
            <div className="PlanBox NewPlanbox">
              <div className="d-md-flex justify-content-between">
                {/* <div className="TradeImg">
                  <img src={Trade} />
                </div> */}
                <div className="TradeText">
                  <div className="BigPlanText">Make Your Tax Planning Easy</div>
                  <div className="SmallPlanText">
                    Personalised Tax Planning Solutions for every Individual, <br /> Entrepreneur, Professional and Business.
                  </div>
                  <div className="ExploreherePlan">
                    <Link to={process.env.PUBLIC_URL + "/tax-planning-page"}>
                      <button>Explore Now</button>
                    </Link>
                  </div>
                </div>
                <div>
                  <div  className="d-md-block d-none"  style={{
                    float: "right",
                    // marginTop : "15rem",
                    marginLeft: "1rem",
                    paddingTop: "7rem"

                  }}>
                    <img
                      width={200}
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/Person.svg"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PlanBox col-md-6 col-12 p-3">
            <div className=" BlogSectionBox">

              <div className="d-flex">
                <p className="text-bold" style={{ color: "#47494c" }}>Blog</p>
                <div className="mb-3 ms-3">
                  <a href={BLOG_URL}>
                    {/* <img className="pointer" src={ process.env.REACT_APP_STATIC_URL + "/media/BlogView.svg"} width={20} /> */}
                  </a>
                </div>
              </div>
              <BlogBoxSection />

            </div>
          </div>
        </div>
        
        <p style={{ height: "2rem" }}></p>
      </div>
    </CommonDashboardLayout>
  );
};

export default Dashboard;
