

const GuestLayout = (props) => {
    
    return (
        <>
        {/* <MainHeader /> */}
        {props.children}
        {/* <Footer /> */}
        </>
    );
}

export default GuestLayout;
