import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import {
  apiCall,
  fetchEncryptData,
  getFpUserDetailsId,
  getParentUserId,
  getUserId
} from "../../../../common_utilities";
import {
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_GET_CRYPTO_DATA,
  ADVISORY_UPDATE_ASSETS_API,
  GET_OTHER_INVESTMENTS
} from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormSwitch from "../CommonDashboardComponents/formSwitch";
import FormRangeSlider from "../CommonDashboardComponents/FormRangeSlider";
import moment from "moment";
import FintooLoader from "../../../../components/FintooLoader";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const alternateAssetsInputs = {
  default: ["dateOfPurchase", "currentMarketPrice"],
  64: ["dateOfPurchase", "currentMarketPrice"],
  36: ["oneTimeOrRecurring", "dateOfPurchase", "numberOfUnits", "averagePurchasePrice", "investedAmount", "currentPrice", "currentValue", "SIPStartDate", "SIPAmount", "totalInvestedAmount", "SIPEndDate"],
  119: ["cryptoSelect", "numberOfUnits", "averagePurchasePrice", "investedAmount", "currentPrice", "currentValue", "expectedReturns",],
  37: ["oneTimeOrRecurring", "numberOfCoins", "averagePurchasePrice", "investedAmount", "currentPrice", "currentValue", "SIPStartDate", "SIPAmount", "totalInvestedAmount", "SIPEndDate"],
  66: ["currentMarketPrice"],
  67: ["currentMarketPrice"]
};

const options_alternate_type = [
  { value: 64, label: "Art Investment" },
  { value: 36, label: "Commodity" },
  { value: 119, label: "Crypto Currency" },
  { value: 37, label: "Currency" },
  { value: 66, label: "Vintage/Luxury Cars"}, 
  { value: 67, label: "Others"}
];

const initialValues = {
  alternateType: "",
  oneTimeOrRecurring: false,
  dateOfPurchase: "",
  numberOfUnits: "",
  averagePurchasePrice: "",
  investedAmount: "",
  currentPrice: "",
  currentValue: "",
  SIPStartDate: "",
  SIPAmount: "",
  totalInvestedAmount: "",
  SIPEndDate: "",
  expectedReturns: "",
  cryptoSelect: {},
  numberOfCoins: "",
  currentMarketPrice: "",
  asset_source: "2"
};

function isInputInPolicy(inputName, policyType) {
  const isForeign = options_alternate_type.filter(data => data.value === policyType)

  if (!policyType) {
    policyType = "default";
  }

  if (isForeign.length === 0) {
    policyType = "default";
  }

  return alternateAssetsInputs[policyType].includes(inputName);

}

function checkInputForRecurring(policyType, visibleOnRecurring, visibleOnRecurringValue) {
  if (policyType === "Crypto Currency") {
    return true
  }
  if (visibleOnRecurring) {
    return visibleOnRecurringValue
  }
  return !visibleOnRecurringValue
}

const NewAlternateAssetsFormView = () => {
  const isLoaded = useRef(false);
  const [, forceUpdate] = useState();
  const [formData, setFormData] = useState(initialValues);
  const [cryptoData, setCryptoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let { id } = useParams();

  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      SIPAmount: 'The SIP amount field is required.'
    },
  }));

  const getAlternateFromValue = (insuranceTypeData, value) => {
    return insuranceTypeData.find((data) => data.value === value);
  }

  const fetchAssetData = async () => {
    setIsLoading(true);
    var payload = {
      url: GET_OTHER_INVESTMENTS,
      data: {
          user_id: getUserId(),
          inv_type: "alternate",
      },
      method: "post",
    };
    var res = await fetchEncryptData(payload);
    setIsLoading(false);
    if (res.error_code == "100") {
      var alternateId = atob(id);
      const data = res.data.alternate_data.alternate_details.filter((v) => v.id === Number(alternateId));
      assign(data[0])
    } else {
        setFormData(initialValues)
    }
  }

  const assign = async (data) => {
    console.log("roh data ----- ", data);
    // console.log("roh data ---> ", );
    let selectedCrypto = {};
    try {
      selectedCrypto = cryptoData?.filter((value)=> value.label.toLowerCase() == data.asset_name.toLowerCase())[0]
    } catch { 
      selectedCrypto = {};
    }
    const dataCopy = {
      alternateType: getAlternateFromValue(options_alternate_type, data.asset_sub_category_id).value,
      oneTimeOrRecurring: data.asset_isRecurring == "0" ? false : true,
      dateOfPurchase: data.asset_sub_category_id == 119 ? null : moment(data.investment_date).toDate(),
      numberOfUnits: Math.round(data.asset_units),
      averagePurchasePrice: data.purchase_rate,
      investedAmount: "",
      currentPrice: data.asset_current_unit_price,
      currentValue: "",
      SIPStartDate: data.asset_isRecurring == "1" ? moment(data.investment_date) : "",
      SIPAmount: data.asset_isRecurring == "1" ? data.current_rate : "",
      totalInvestedAmount: data.asset_isRecurring == "1" ? data.purchase_rate : "",
      SIPEndDate: data.asset_isRecurring == "1" ? moment(data.asset_mf_end_date) : "",
      expectedReturns: Math.round(data.interest),
      cryptoSelect: selectedCrypto,
      numberOfCoins: Math.round(data.asset_units),
      currentMarketPrice: "",
      asset_source: "2"
    }
    setFormData({...dataCopy})
  }

  const saveAsset = async () => {
    try {
      let update = 0;
      let assetSubCategoryName = options_alternate_type.find(option => option.value === formData.alternateType);
      let resp = {};
      
      let send_payload = {
        user_id: getParentUserId(),
        asset_member_id: await getFpUserDetailsId(),
        asset_ror: formData.expectedReturns,
        fp_log_id : "",
        categorydetail: "Alternate",
        subcategorydetail: assetSubCategoryName.label,
        asset_category_id: 41,
        asset_sub_category_id: formData.alternateType,
        asset_isActive: "1",
        asset_units: formData.alternateType == 37 ? formData.numberOfCoins : formData.numberOfUnits,
        asset_name: formData.alternateType == 119 ? formData.cryptoSelect.label : assetSubCategoryName.label,
        totalpurchasevalue: formData.alternateType == 119 || formData.alternateType == 36 ? (formData.numberOfUnits * 1) * (formData.averagePurchasePrice * 1) : (formData.numberOfCoins * 1) * (formData.averagePurchasePrice * 1),
        totalinvestedvalue: formData.alternateType == 119 || formData.alternateType == 36 ? (formData.currentPrice * 1) * (formData.numberOfUnits) : (formData.currentPrice * 1) * (formData.numberOfCoins), 
        asset_purchase_amount: formData.averagePurchasePrice,
        asset_current_unit_price: formData.alternateType == 119 ? formData.cryptoSelect.value : formData.currentPrice,
        asset_purchase_date: formData.dateOfPurchase != "" ? formData.alternateType == 119 ? null : moment(formData.dateOfPurchase).format("DD/MM/YYYY") : null,
        asset_source: formData.asset_source,
        asset_frequency: 1
      }

      if (formData.alternateType == 64 || formData.alternateType == 66 || formData.alternateType == 67) {
        send_payload['asset_amount'] = formData.currentMarketPrice;
      }

      if (formData.alternateType == 36 || formData.alternateType == 37) {
        if (formData.oneTimeOrRecurring) {
          send_payload['asset_purchase_date'] = moment(formData.SIPStartDate).format("DD/MM/YYYY");
          send_payload['asset_mf_end_date'] = moment(formData.SIPEndDate).format("DD/MM/YYYY");
          send_payload['asset_amount'] = formData.SIPAmount;
          send_payload['asset_current_unit_price'] = formData.currentPrice;
          send_payload['asset_isrecurring'] = true;
          send_payload['asset_purchase_amount'] = formData.totalInvestedAmount;
        } else {
          send_payload['asset_mf_end_date'] = "";
          send_payload['asset_amount'] = formData.SIPAmount;
          send_payload['asset_isrecurring'] = false;
        }
      }
      
      if (id != undefined || id != null) {
        update = 1;
        send_payload['id'] = atob(id);
        let payload = {
          url: ADVISORY_UPDATE_ASSETS_API,
          data: send_payload,
          method: "post",
        };
        resp = await fetchEncryptData(payload);
      } else {
        let payload = {
          url: ADVISORY_ADD_ASSETS_API,
          data: send_payload,
          method: "post"
        }
        resp = await fetchEncryptData(payload);
      }
      if (resp.error_code == 100) {
        setTimeout(()=> {
          navigate(
            process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=6"
          );
        }, 2000)
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: update === 1 ? `Data updated successfully!` : `Data added successfully!`, type: "success", autoClose: 3000 },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong, try again later.", type: "error", autoClose: 3000 },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const getCryptoData = async () => {
    try {
      let url = ADVISORY_GET_CRYPTO_DATA;
      let getcryptoData = await apiCall(url, {}, false, false);
      if (getcryptoData["status"] == "success") {
        var cryptolist = [];
        var crypto_data = Object.values(getcryptoData["data"]);
        var price = 0;
        crypto_data.map((v) => {
          if (v.hasOwnProperty("INR")) {
            if (v.INR.hasOwnProperty("sellPrice")) {
              price = parseFloat(v.INR.sellPrice).toFixed(2);
            }
          }
          cryptolist.push({ label: v.name, value: price });
        });
        setCryptoData(cryptolist);
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong.", type: "error", autoClose: 3000 }
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went wrong.", type: "error", autoClose: 3000 }
      });
    }
  };

  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== '') {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    if(simpleValidator.current.allValid()) {
      saveAsset();
    };
  };

  const getRealEstateTypeData = (insuranceTypeData, label) => {
    let a = insuranceTypeData.find((data) => data.label === label);
    console.log('a==>', a);
    return a;
  };

  function resetState (type) {
    if (type != 119) {
      setFormData((prev) => ({ ...prev, dateOfPurchase: "", currentMarketPrice: "", currentPrice: "", averagePurchasePrice: "", investedAmount: "", currentValue: "", expectedReturns: "", totalInvestedAmount: "", numberOfCoins: "", numberOfUnits: "", oneTimeOrRecurring: false, SIPAmount: "", SIPEndDate: "", SIPStartDate: "", cryptoSelect: ""}))
    } else {
      setFormData((prev) => ({ ...prev, dateOfPurchase: "", currentMarketPrice: "", currentPrice: "", averagePurchasePrice: "", investedAmount: "", cryptoSelect: "", currentValue: "", expectedReturns: 20, totalInvestedAmount: "", numberOfCoins: "", numberOfUnits: "", oneTimeOrRecurring: false, SIPAmount: "", SIPEndDate: "", SIPStartDate: ""}))
    }
  }

  useEffect(()=> {
    getCryptoData();
  }, []);

  useEffect(()=> {
    if (isLoaded.current == false && id && cryptoData.length > 0) {
      isLoaded.current = true;
      fetchAssetData();
    }
  }, [id, cryptoData]);

  useEffect(() => {
    simpleValidator.current.hideMessages();
    forceUpdate(4);
  }, [formData.alternateType]);

  simpleValidator.current.purgeFields();
// Select Options Styles
const customStyles = {
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: "#ffff",
      color: isFocused ? "#000" : isSelected ? "#000" : "gray",
      cursor: "pointer",
    };
  },
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    scrollBehavior: "smooth",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#424242",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
  return (
    <>
      <FintooLoader isLoading={isLoading}/>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          {id != undefined ? (
            <div className="d-flex">
              <a href={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard?assetTabNumber=6`}>
                <img
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  width={20}
                  height={20}
                  src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
                />
              </a>
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Edit Your Alternate Assets
              </h3>
            </div>
          ) : (

            <div className="d-flex">
              <a href={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard?assetTabNumber=6`}>
                <img
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  width={20}
                  height={20}
                  src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
                />
              </a>
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Add Your Alternate Assets
              </h3>
            </div>
          )}

          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              <p className="text-center">
                Enter Your Details To Add Existing Alternate Assets
              </p>
              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div>
                    <span className="lbl-newbond">Select Alternate Type *</span>
                    <br />

                    <Select
                      className="fnto-dropdown-react"
                      classNamePrefix="sortSelect"
                      styles={customStyles}
                      isSearchable={false}
                      options={options_alternate_type}
                      value={getAlternateFromValue(
                        options_alternate_type,
                        formData?.alternateType
                      )}
                      name="alternateType"
                      onChange={(e) => {
                        onDateAndSelectInputChange("alternateType", e.value);
                        resetState(e.value);
                      }}
                    />
                    {simpleValidator.current.message(
                      "alternateType",
                      formData.alternateType,
                      "required"
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className="row"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {isInputInPolicy(
                      "oneTimeOrRecurring",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <span className="lbl-newbond insurance-switch-container" style={{ color: '#b3b3b3' }}>
                            One Time Or Recurring?*
                          </span>
                        </div>
                      )}
                    {isInputInPolicy(
                      "oneTimeOrRecurring",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <span className="lbl-newbond">
                            <div className="insurance-switch-container">
                              <span>One Time&nbsp;&nbsp;</span> <FormSwitch switchValue={formData.oneTimeOrRecurring} onSwitchToggle={() => setFormData({ ...formData, oneTimeOrRecurring: !formData.oneTimeOrRecurring, SIPEndDate: "", SIPStartDate: "", SIPAmount: "" })} /> <span>&nbsp;&nbsp;&nbsp;&nbsp;Recurring</span>
                            </div>
                          </span>
                        </div>
                      )}
                    {(isInputInPolicy(
                      "dateOfPurchase",
                      formData.alternateType
                      // ) && checkInputForRecurring(formData.alternateType, false, formData.oneTimeOrRecurring) || testfun() || (formData.alternateType === "Art Investment" || formData.alternateType === "") && (
                    ) && checkInputForRecurring(formData.alternateType, false, formData.oneTimeOrRecurring) || formData.alternateType === "Art Investment" || formData.alternateType === "") && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Date Of Purchase
                              </span>
                              <br />
                            </div>
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={formData.dateOfPurchase === "" ? "" : new Date(formData.dateOfPurchase)}
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                dropdownMode="select"
                                customClass="datePickerDMF"
                                name='dateOfPurchase'
                                onChange={(e) => onDateAndSelectInputChange('dateOfPurchase', formatDatefun(e))}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                              {simpleValidator.current.message(
                              "dateOfPurchase",
                              formData.dateOfPurchase,
                              "required", {messages: {required: "Please select a Date of Purchase."}}
                            )}
                            </div>
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "SIPStartDate",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, true, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                SIP Start Date
                              </span>
                              <br />
                            </div>
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={formData.SIPStartDate === "" ? "" : new Date(formData.SIPStartDate)}
                                autoComplete="off"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={new Date()}
                                dropdownMode="select"
                                customClass="datePickerDMF"
                                name='SIPStartDate'
                                onChange={(e) => onDateAndSelectInputChange('SIPStartDate', formatDatefun(e))}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            {formData.oneTimeOrRecurring ? simpleValidator.current.message('SIPStartDate', formData.SIPStartDate, 'required', { messages: { required: 'The SIP start date field is required.' } }) : ""}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "SIPAmount",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, true, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                SIP Amount *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter SIP Amount"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              autoComplete="off"
                              name="SIPAmount"
                              value={formData.SIPAmount}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('SIPAmount', formData.SIPAmount, 'required|numeric', { messages: { required: 'The SIP amount field is required.', numeric: 'The SIP amount must be a number.' } })}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "totalInvestedAmount",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, true, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Total Invested Amount *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Invested Amount"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              autoComplete="off"
                              name="totalInvestedAmount"
                              value={formData.totalInvestedAmount}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('totalInvestedAmount', formData.totalInvestedAmount, 'required|numeric')}
                          </div>
                        </div>
                      )}

                    {isInputInPolicy(
                      "cryptoSelect",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, false, formData.oneTimeOrRecurring) && (
                        <div className="col-md-12 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Start Typing To Search For Your Crypto *
                              </span>
                              <br />
                            </div>
                            <Select
                              className="fnto-dropdown-react"
                              classNamePrefix="sortSelect"
                              styles={customStyles}
                              isSearchable={true}
                              options={cryptoData}
                              value={formData.cryptoSelect}
                              
                              name="cryptoSelect"
                              onChange={(e) => {
                                onDateAndSelectInputChange("cryptoSelect", e)
                                setFormData((prev) => ({ ...prev, currentPrice: e.value}))
                              }}
                            />
                            {simpleValidator.current.message('cryptoSelect', formData?.cryptoSelect?.label, 'required', { messages: { required: 'Please select a crypto.' } })}
                          </div>
                        </div>
                      )}

                    {isInputInPolicy(
                      "numberOfUnits",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Number Of Units *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Number Of Units"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              autoComplete="off"
                              name="numberOfUnits"
                              value={formData.numberOfUnits}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('numberOfUnits', formData.numberOfUnits, 'required|numeric')}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "currentMarketPrice",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Current Market Price *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Current Market Price"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="currentMarketPrice"
                              autoComplete="off"
                              value={formData.currentMarketPrice}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('currentMarketPrice', formData.currentMarketPrice, 'required|numeric')}
                          </div>
                        </div>
                      )}

                    {isInputInPolicy(
                      "numberOfCoins",
                      formData.alternateType
                    ) && (
                        <div className={`${(formData.alternateType == 'Currency' && formData.oneTimeOrRecurring) ? 'col-md-6' : 'col-md-12'} 'col-12'`}>
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                No. of Units *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Number Of Coins"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="numberOfCoins"
                              autoComplete="off"
                              value={formData.numberOfCoins}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('numberOfCoins', formData.numberOfCoins, 'required|numeric')}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "averagePurchasePrice",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, false, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Average Purchase Price *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Purchase Price"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              autoComplete="off"
                              name="averagePurchasePrice"
                              value={formData.averagePurchasePrice}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('averagePurchasePrice', formData.averagePurchasePrice, 'required|numeric')}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "investedAmount",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, false, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Invested Amount
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder=""
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="investedAmount"
                              disabled
                              value={formData.alternateType == 119 || formData.alternateType == 36 ? Math.round((formData.numberOfUnits * 1) * (formData.averagePurchasePrice * 1), 2): Math.round((formData.numberOfCoins * 1) * (formData.averagePurchasePrice * 1), 2) }
                              onChange={(e) => onInputChange(e, true)}
                            />
                          </div>
                        </div>
                      )}

                    {isInputInPolicy(
                      "currentPrice",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Current Price *
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder="Enter Current Price"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="currentPrice"
                              autoComplete="off"
                              value={formData.currentPrice}
                              onChange={(e) => {onInputChange(e, true)}}
                            />
                            {simpleValidator.current.message('currentPrice', formData.currentPrice, 'required|numeric')}
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "currentValue",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Current Value
                              </span>
                              <br />
                            </div>
                            <input
                              placeholder=""
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="currentValue"
                              disabled
                              value={formData.alternateType == 119 || formData.alternateType == 36 ? Math.round((formData.currentPrice * 1) * (formData.numberOfUnits * 1), 2) : Math.round((formData.currentPrice * 1) * (formData.numberOfCoins * 1),2)}
                              onChange={(e) => onInputChange(e, true)}
                            />
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "expectedReturns",
                      formData.alternateType
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Expected Return (%)*
                              </span>
                              <br />
                            </div>
                            <FormRangeSlider
                              x={formData.expectedReturns}
                              min={-100}
                              max={100}
                              step={1}
                              onChange={(x) => {
                                setFormData({ ...formData, expectedReturns: x });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {isInputInPolicy(
                      "SIPEndDate",
                      formData.alternateType
                    ) && checkInputForRecurring(formData.alternateType, true, formData.oneTimeOrRecurring) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                SIP End Date *
                              </span>
                              <br />
                            </div>
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                selected={formData.SIPEndDate === "" ? "" : new Date(formData.SIPEndDate)}
                                minDate={formData?.SIPStartDate != "" ? moment(formData.SIPStartDate).add(1, 'years').toDate() : moment().add(1,'days').toDate()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customClass="datePickerDMF"
                                name='SIPEndDate'
                                onChange={(e) => onDateAndSelectInputChange('SIPEndDate', formatDatefun(e))}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            {formData.oneTimeOrRecurring ? simpleValidator.current.message('SIPEndDate', formData.SIPEndDate, 'required', { messages: { required: 'The SIP end date field is required.' } }) : ""}
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <br />
                <div className="my-md-4">
                  <button
                    type="submit"
                    className="d-block m-auto btn btn-primary"
                    onClick={() => validateForm()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default NewAlternateAssetsFormView;
