import { Switch } from "evergreen-ui"
import { useState } from "react"

const FormSwitch = (props) => {
    return (
        <Switch
            onChange={() =>
                props.onSwitchToggle()
            }
            checked={props.switchValue ?? false}
            className="react-switch px-2"
            // uncheckedicon={false}
            // checkedicon={false}
            height={20}
            width={40}
            onColor="#042b62"
            offColor="#d8dae5"
        />
    )
}

export default FormSwitch