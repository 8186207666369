import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewLiquidAssetFormView from "./NewLiquidAssetFormView";

const NewLiquidAssetForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewLiquidAssetFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewLiquidAssetForm;
