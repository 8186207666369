import { useEffect, useState } from "react";

import { apiCall, getItemLocal, getParentUserId, loginRedirectGuest, removeMemberId, removeUserId } from "../../common_utilities";
import { CHECK_SESSION } from "../../constants";

const MainLayout = (props) => {

  const [verified, setVerified] = useState(false);

  useEffect(()=> {
    checkIfSessionExist();
  }, []);

  const checkIfSessionExist = async () => {
    try {
      if(getParentUserId()) {
        let url = CHECK_SESSION;
        let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
        let respData = await apiCall(url, data, true, false);
        let session_data = respData["data"]
        if (respData["error_code"] != "100") {
          // loginRedirect();
          // // loginRedirectGuest();
          removeMemberId();
          removeUserId();
          localStorage.removeItem('sky');
          localStorage.setItem('redirectToThis', window.location.href);
          window.location.href = process.env.PUBLIC_URL + '/session-expired';
        } else {
          setVerified(true);
          if(session_data['fp_lifecycle_status'] == '0' && session_data['fp_plan_type'] == '8'){
            if(location.pathname != '/userflow/expert-nda/' && location.pathname != '/userflow/profile-fill-details/'){
               window.location.href = process.env.PUBLIC_URL+'/userflow/expert-nda/';
            }
          }
        }
      } else {
        loginRedirectGuest();
      }
    } catch {
      loginRedirectGuest();
    }
  }
  return <>{verified ? props.children : <div style={{minHeight: '80vh'}}></div>}</>;
};

export default MainLayout;
