import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewUsEquityView from "./NewUsEquityFormView";

const NewUsEquityForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewUsEquityView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewUsEquityForm;
