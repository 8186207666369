import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className={`${styles["section-sub-title"]}`}>
        Received An Income Tax Notice? We Will Help You Reply And Resolve It.
      </h3>
      {/* <p className={`${styles.sectionsubText}`}>
      Comprehensive income tax notice management service by an experienced team of CAs and Income Tax experts trusted by thousands of clients across India. 
      </p> */}
    </>
  );
};
export default LeftSection;
