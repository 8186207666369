import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewRealEstateFormView from "./NewRealEstateFormView";

const NewRealEstateForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewRealEstateFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewRealEstateForm;
