import React, { useEffect, useState } from "react";
import EndToEndSolutionSection from "../components/HTML/NriTaxationLandingPage/EndToEndSolutionSection";
import NeedHelpSection from "../components/HTML/NriTaxationLandingPage/NeedHelpSection";
import NriTaxationHeaderSection from "../components/HTML/NriTaxationLandingPage/NriTaxationLandingPageHeaderSection";
import WhyTrustUsSection from "../components/HTML/NriTaxationLandingPage/WhyTrustUsSection";
import Fullpage from "../components/Layout/Fullpage";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import FaqSection from "../components/HTML/NriTaxationLandingPage/FaqSection";
import AppointmentBox from "../components/Pages/Calendly";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function NriTaxitionLandingPage() {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <NriTaxationHeaderSection />
        <EndToEndSolutionSection />
        <WhyTrustUsSection />
        <ClientTestimonial />
        <NeedHelpSection />
        <section id="book-appointment">
          {utmSource && tagval ? (
            <AppointmentBox
              extraParams={{ "utm_source": utmSource, "service": 98 }}
              eventCode={tagval}
              serviceName="NRI Taxation"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-nri-taxation?hide_event_type_details=1"}
               planId = "36"
            />
          ) : (
            <AppointmentBox
              eventCode={'Callback_mintyApp_36'}
              serviceName="NRI Taxation"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-nri-taxation?hide_event_type_details=1"}
              planId = "36"
            />
          )}
          {/* <AppointmentBox eventCode={'Callback_mintyApp_36'} serviceName="NRI Taxation" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-nri-taxation?hide_event_type_details=1" /> */}
        </section>
        <FaqSection />

      </div>
    </Fullpage>
  );
}

export default NriTaxitionLandingPage;
