export const servicesTypes = {
    main: 'main',
    investmentPlanning: 'investmentPlanning',
    taxPlanning: 'taxPlanning',
    bondInvestment: 'bondInvestment',
    financialPlanning: 'financialPlanning',
    retirementPlanning: 'retirementPlanning',
    stockAdvisory: 'stockAdvisory',
    virtualItr: 'virtualItr',
    notices: 'notices',
    insurance: 'insurance',
    DMF: 'DMF',
    internationalEquity: 'internationalEquity',
    nriTaxation: 'nriTaxation',
    IPO: 'IPO'
}

export const contactFormServicesData = {
    main: {
        utm_campaign: 98,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    investmentPlanning: {
        utm_campaign: 57,
        tags: "Callback_mintyApp_8",
        tagval: "minty_financial_planning",
        servicename: "financial-planning",
        plan_name: "Financial Planning",
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    taxPlanning: {
        utm_campaign: 9,
        tags: "Callback_mintyApp_9",
        tagval: "minty_tax_planning",
        servicename: "tax-planning-strategies",
        plan_name: "Tax",
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    bondInvestment: {
        utm_campaign: 33,
        tags: "cta_cb_bond",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    financialPlanning: {
        utm_campaign: 98,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    retirementPlanning: {
        utm_campaign: 10,
        tags: "Callback_mintyApp_10",
        tagval: "minty_retirement_services",
        servicename: "retirement-services",
        plan_name: "Retirement",
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    stockAdvisory: {
        utm_campaign: 98,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    virtualItr: {
        utm_campaign: 98,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    notices: {
        utm_campaign: 35,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    insurance: {
        utm_campaign: 12,
        tags: "cta_cb_li",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    DMF: {
        utm_campaign: 25,
        tags: "cta_cb_mft",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    internationalEquity: {
        utm_campaign: 98,
        tags: "fin_ad_web_reg",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    nriTaxation: {
        utm_campaign: 36,
        tags: "cta_cb_nrit",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true, external_redirect: false,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    },
    IPO: {
        utm_campaign: 89,
        tags: "cta_cb_ipo",
        tagval: null,
        servicename: null,
        plan_name: null,
        utm_source: 26,
        external_redirect: true,
        redirect_url: process.env.PUBLIC_URL + '/pricing/'
    }
}