import React, { useState, useRef, useEffect } from "react";
import Styles from "../../Pages/DMF/Portfolio/report.module.css";
import PortfolioLayout from "../Layout/Portfolio";
import { Link } from "react-router-dom";
import Select from "react-select";
import DmfReportDate from "../HTML/FintooDatePicker/DmfReportDate";
import SimpleReactValidator from "simple-react-validator";
import { 
  CAPITALGAIN_PYTHON_REPORT_URL, 
  DMF_GETUSERDETAILS_API_URL, 
  DMF_SENDMAIL_API_URL, 
  GET_CAPITALGAIN_DATA_API_URL, 
  IS_DIRECT, 
  SUPPORT_EMAIL } from "../../constants";
import { fetchEncryptData, getUserId, loginRedirectGuest } from "../../common_utilities";
import FintooLoader from "../FintooLoader";
import { useDispatch } from "react-redux";
import moment from "moment";
import FintooDatePicker from "../HTML/FintooDatePicker";

function ReportDetails() {
  const initialValues = {
    Statement: "",
    FinancialYear: "",
    min:null,
    max:null
  };
  const [, forceUpdate] = useState();
  const [Cdate, setDate] = useState();
  const [userData,setUserData] = useState()
  const [Edate, setEDate] = useState();
  const [fileCG, setFileCG] = useState();
  const [capitalGainVal ,setCapitalGainVal] = useState({})
  const cgDownloadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [showDiv, setShowDiv] = useState(false);
  const [isValueChange, setIsValueChange] = useState(false);
  const dispatch = useDispatch();
  const StatementOptions = [
    { value: "realized", label: "Realized Gain &  Loss" },
    { value: "notional", label: "Unrealized Gain &  Loss" },
  ];
  const currentYear = new Date().getFullYear()
  const FYyear = [
    { value: `previousFyYear`, label: `${currentYear-1}-${currentYear}` },
    { value: `currentFyYear`, label: `${currentYear}-${currentYear+1}` },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      //   border: 0,
      boxShadow: "none",
      color: "#000",
    }),
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    if (value === "realized") {
      setShowDiv(true);
    } else {
      setShowDiv(false);
    }
  };

  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    if (simpleValidator.current.allValid() == true) {
      // fetchRealEstate();
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (formData.FinancialYear) {
      userCred();
      var min_date = moment(`01/04/${currentYear}`, 'DD/MM/YYYY').toDate();
      var max_date = moment().toDate();
      if (formData.FinancialYear === 'previousFyYear') {
        min_date = moment(`01/04/${currentYear - 1}`, 'DD/MM/YYYY').toDate();
        max_date = moment(`31/03/${currentYear}`, 'DD/MM/YYYY').toDate();
      }
      setFormData(prev => ({ ...prev, min: min_date, max: max_date, startDate: min_date, endDate: max_date }));
    }
  }, [formData.FinancialYear]);

  useEffect(() => {

    if (fileCG) {
      document.querySelector("#cg-download").click();
    }
  }, [fileCG]);

  const userCred = async () => {
    try {
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }
      var payload = {
        url: DMF_GETUSERDETAILS_API_URL,
        method: "post",
        data: { user_id: "" + getUserId(), is_direct: IS_DIRECT },
      };

      var res = await fetchEncryptData(payload);
      setUserData(res.data)
    } catch (e) {
      // console.log(e)
    }
  }
  // console.log('formData:::  ', formData)
  const capitalGain = async () => {
    try {
      
      if (formData.Statement === "realized") {
        var capGainReq = {
          gain_type: formData.Statement,
          from_date: moment(formData.startDate).format("YYYY-MM-DD"),
          to_date: moment(formData.endDate).format("YYYY-MM-DD"),
          user_id: "" + getUserId(),
          is_direct: IS_DIRECT,
        };
        // console.log(capGainReq, 'realized')
      } else if (formData.Statement === "notional") {
        var capGainReq = {
          gain_type: formData.Statement,
          from_date: moment(formData.startDate).format("YYYY-MM-DD"),
          to_date: moment(formData.endDate).format("YYYY-MM-DD"),
          user_id: "" + getUserId(),
          is_direct: IS_DIRECT,
        };
        // console.log(capGainReq, 'notional')
      }

      var payload = {
        url: CAPITALGAIN_PYTHON_REPORT_URL,
        method: "post",
        data: capGainReq,
      };

      var res = await fetchEncryptData(payload);
      return res
    } catch (e) {
      // console.log(e)
    }
  }

  const capitalGainPython = async () => {

    try {
      if (validateForm() == false) return;
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }

      if (fileCG) {
        document.querySelector("#cg-download").click();
        return;
      }

      setIsLoading(true);
      cgDownloadRef.current.setAttribute("disabled", true);

      var res = await capitalGain()
      setIsLoading(false);
      if (res.error_code == "100") {
        const response = await fetch(res.data);
        const blobData = await response.blob();
        // setFileCG(URL.createObjectURL(blobData));
        setFileCG(URL.createObjectURL(blobData));

        cgDownloadRef.current.removeAttribute("disabled");
      } else if (res.error_code == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "No records found!",
            type: "info",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong...",
            type: "error",
          },
        });
      }
    } catch (e) {
      // console.error(e);
      setIsLoading(false);
    }
  };

  const capitalGainMail = async () => {
    if (validateForm() == false) return;
    setIsLoading(true)
    let resp = await capitalGain();
    if (resp.data) {
      var urlmail = {
        userdata: {
          to: userData.email,
        },
        subject: "Fintoo Invest - Capital Gain Report",
        template: "transactions_dmf.html",
        contextvar: {
          name: userData.name,
          SUPPORT_EMAIL: SUPPORT_EMAIL,
          report_link: resp.data,
        },
      };

      let config = {
        method: "post",
        url: DMF_SENDMAIL_API_URL,
        data: urlmail,
      };

      var res = await fetchEncryptData(config);
      setIsLoading(false)
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: res.message, type: "success" },
      });
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Email not sent, Something went wrong...",
          type: "error",
        },
      });
    }
  };

  const capitalGainData = async () => {
    try {
      if (validateForm() == false) return;

      if (formData.Statement === "realized") {
        var capGainReq = {
          gain_type: formData.Statement,
          from_date: moment(formData.startDate).format("YYYY-MM-DD"),
          to_date: moment(formData.endDate).format("YYYY-MM-DD"),
          user_id: "" + getUserId(),
          is_direct: IS_DIRECT,
        };
      } else if (formData.Statement === "notional") {
        var capGainReq = {
          gain_type: formData.Statement,
          from_date: moment(formData.startDate).format("YYYY-MM-DD"),
          to_date: moment(formData.endDate).format("YYYY-MM-DD"),
          user_id: "" + getUserId(),
          is_direct: IS_DIRECT,
        };
      }
      // console.log(capGainReq, 'notional')

      var payload = {
        url: GET_CAPITALGAIN_DATA_API_URL,
        method: "post",
        data: capGainReq,
      };
      setIsLoading(true);
      var res = await fetchEncryptData(payload);
      setIsLoading(false);

      if (res.error_code == "100") {
        setCapitalGainVal(res)
      } else if (res.error_code == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "No records found!",
            type: "info",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong...",
            type: "error",
          }
        });
      }
    } catch (e) {
      // console.log(e)
    }
  };

  return (
    <PortfolioLayout>
      <FintooLoader isLoading={isLoading} />
      {!isLoading && (
        <div className={`${Styles.ReportDetailSection}`}>
          <div>
            <Link
              className="text-decoration-none"
              to={`${process.env.PUBLIC_URL}/commondashboard/Report`}
            >
              <img
                className="pointer"
                src={
                  process.env.REACT_APP_STATIC_URL + "media/DMF/left-arrow.svg"
                }
                width={25}
              />
            </Link>
          </div>
          <div className={`row ${Styles.PortfolioReportSection}`}>
            <div className="col-12  mt-md-5 mt-4">
              <div className={`pb-2 ${Styles.insideTabBoxd}`}>
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DMF/Report/01_capital_gains_Loss_report.svg"
                      }
                      width={50}
                    />
                  </div>
                  <div className={`pt-3  ${Styles.ReportName}`}>
                    Capital Gains & Loss Report
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 mt-md-5 mt-4">
            <div className={`${Styles.ReportDetailsOptions}`}>
              <div className="">
                <div className={`${Styles.ReportLabel}`}>Statement</div>
                <div className="mt-2">
                  <Select
                    className="box-shadow-none border-0"
                    classNamePrefix="ReportSelect"
                    isSearchable={false}
                    placeholder="Select.." // Check that this is a non-empty string
                    options={StatementOptions}
                    value={StatementOptions.filter((v) => v.value === formData.Statement)[0] ?? null} // Ensure the filter returns null when no value is selected
                    styles={customStyles}
                    onChange={(e) => {
                      onDateAndSelectInputChange("Statement", e.value);
                      setIsValueChange(true);
                    }}
                  />
                </div>
                {simpleValidator.current.message(
                  "Statement",
                  formData.Statement,
                  "required"
                )}
              </div>
              <div className="">
                <div className={`${Styles.ReportLabel}`}>Financial Year</div>
                <div className="mt-2">
                  <Select
                    className="box-shadow-none border-0"
                    classNamePrefix="ReportSelect"
                    isSearchable={false}
                    options={FYyear}
                    placeholder="Select.." // Check that this is a non-empty string
                    value={FYyear.filter((v) => v.value === formData.FinancialYear)[0] ?? null} // Ensure the filter returns null when no value is selected
                    styles={customStyles}
                    onChange={(e) => {
                      onDateAndSelectInputChange("FinancialYear", e.value);
                      setIsValueChange(true);
                    }}
                  />
                </div>
                {simpleValidator.current.message(
                  "Financial Year",
                  formData.FinancialYear,
                  "required"
                )}
              </div>
              <div className="">
                <div className={`${Styles.ReportLabel}`}>From Date</div>
                <div className="mt-2">
                  <FintooDatePicker
                    style={{
                      color: "#000 !important",
                    }}
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    Placeholder="Select Date"
                    showYearDropdown
                    dropdownMode="select"
                    name="dateOfPurchase"
                    customClass="datePickerDMFReport"
                    disabled={formData.FinancialYear === ""}
                    selected={formData.startDate}
                    minDate={formData.min}
                    maxDate={formData.max}
                    onChange={(date) => {
                      setFormData(prev => ({ ...prev, startDate: moment(date).toDate() }));
                      setIsValueChange(true);
                    }}
                  />
                </div>
                {simpleValidator.current.message("date", formData.startDate, "required")}
              </div>
              <div className="">
                <div className={`${Styles.ReportLabel}`}>To Date</div>
                <div className="mt-2">
                  <FintooDatePicker
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    name="dateOfPurchase"
                    customClass="datePickerDMFReport"
                    // disabled = {!Cdate}
                    Placeholder="Select Date"
                    selected={formData.endDate}
                    minDate={formData.min}
                    maxDate={formData.max}
                    onChange={(date) => {
                      setFormData(prev => ({ ...prev, endDate: moment(date).toDate() }));
                      setIsValueChange(true);
                    }}
                  />
                </div>
                {simpleValidator.current.message("date", formData.endDate, "required")}
              </div>
              <div className={`${Styles.ReportGenOption}`}>
                <div
                  className="d-md-block d-none"
                  style={{
                    height: "2.3rem",
                  }}
                ></div>
                <button
                  onClick={() => {
                    capitalGainData();
                    setIsValueChange(false);
                  }}
                  className={`${Styles.ReportButton}`}
                  ref={cgDownloadRef}
                >
                  Generate
                </button>
                <div style={{ display: "none" }}>
                  <a
                    id="cg-download"
                    href={fileCG}
                    style={{
                      textDecoration: "none",
                      pointerEvents: fileCG ? "auto" : "none",
                    }}
                    download={"Capital_Gain_Report_" + getUserId()}
                  ></a>
                </div>
              </div>
            </div>
          </div>

          {!isValueChange && <div
            style={{
              display:
                (formData.Statement === "realized" ||
                  formData.Statement === "notional") && capitalGainVal.error_code === '100'
                  ? "block"
                  : "none",
            }}
          >
            <div className={` mt-md-5 ${Styles.rpdetailbox}`}>
              <div className={`${Styles.ReportLabel}`}>Mutual Fund</div>
              <div
                className={`col-md-10 mt-3 ${showDiv ? Styles.DmfReportView : Styles.DmfReportView
                  }`}
              >
                {formData.Statement === "realized" ? (
                  <>
                    <div className="text-center">
                      <div className={`pt-0 ${Styles.LabelText}`}>
                        Short term Realized Gains (Equity)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.equity_short_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.equity_short_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                    <div
                      className={`d-md-block d-none ${Styles.DmfReportSeprateline}`}
                    ></div>
                    <div
                      className={`d-md-none d-block ${Styles.DmfReportSepratelinevr}`}
                    ></div>
                    <div className="text-center">
                      <div className={`${Styles.LabelText}`}>
                        Long term Realized Gains (Equity)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.equity_long_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.equity_long_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                    <div
                      className={`d-md-block d-none ${Styles.DmfReportSeprateline}`}
                    ></div>
                    <div
                      className={`d-md-none d-block ${Styles.DmfReportSepratelinevr}`}
                    ></div>
                    <div className="text-center">
                      <div className={`${Styles.LabelText}`}>
                        Short term Realized Gains (Debt)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.debt_short_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.debt_short_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                    <div
                      className={`d-md-block d-none ${Styles.DmfReportSeprateline}`}
                    ></div>
                    <div
                      className={`d-md-none d-block ${Styles.DmfReportSepratelinevr}`}
                    ></div>
                    <div className="text-center">
                      <div className={`${Styles.LabelText}`}>
                        Long term Realized Gains (Debt)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.debt_long_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.debt_long_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                  </>
                ) : formData.Statement === "notional" ? (
                  <>
                    <div className="text-center">
                      <div className={`pt-0 ${Styles.LabelText}`}>
                        Unrealized Gains and Loss (Equity)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.equity_long_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.equity_long_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                    <div
                      className={`d-md-block d-none ${Styles.DmfReportSeprateline}`}
                    ></div>
                    <div
                      className={`d-md-none d-block ${Styles.DmfReportSepratelinevr}`}
                    ></div>
                    <div className="text-center">
                      <div className={`${Styles.LabelText}`}>
                        Unrealized Gains and Loss (Debt)
                      </div>
                      <div className={`${Styles.Repotval}`}>₹ {!capitalGainVal?.data?.total_data?.debt_long_array?.totalactual_gain_loss ? '00.00' : (capitalGainVal.data.total_data.debt_long_array.totalactual_gain_loss).toFixed('2')}</div>
                    </div>
                    <div
                      className={`d-md-block d-none ${Styles.DmfReportSeprateline}`}
                    ></div>
                    <div
                      className={`d-md-none d-block ${Styles.DmfReportSepratelinevr}`}
                    ></div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-md-10 d-md-flex justify-content-between">
              <div className={`pt-3 ${Styles.ReportOptionsDate}`}>
                Report Generated from Date{" "}
                <b>{moment(formData.startDate).format("DD/MM/YYYY")}</b> to <b>{moment(formData.endDate).format("DD/MM/YYYY")}</b>
              </div>
              <div
                className={`d-flex align-items-center    pt-3 ${Styles.ReportOptions}`}
              >
                <div className="pointer">
                  <div>
                    Share via Mail{" "}
                    <span>
                      <img
                        onClick={capitalGainMail}
                        className="ms-2"
                        width={20}
                        height={20}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/DMF/Report/email.svg"
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className={`px-3 ${Styles.seprateLine}`}></div>
                <div className="ps-4 pointer">
                  Download Capital Gains & Loss Report{" "}
                  <img
                    onClick={capitalGainPython}
                    className="ms-2"
                    width={20}
                    height={20}
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/DMF/Report/download.svg"
                    }
                  />
                </div>
              </div>
            </div>
          </div>}
        </div>)}
    </PortfolioLayout>
  );
}

export default ReportDetails;
