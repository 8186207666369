import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Profile_1 from "../../../Assets/06_banking_app.svg";
import { Container, Row, Col, Modal } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Fatca/style.css";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import FintooButton from "../../../HTML/FintooButton";
import axios from "axios";
import {
  DMF_UPDATEBANK_API_URL,
  DMF_DELETEBANK_API_URL,
  DMF_GETBANK_BSECODE_API_URL
} from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import * as constClass from "../../../../constants";
import {
  apiCall,
  CheckSession,
  fetchEncryptData,
  getMinorUserId,
  getPublicMediaURL,
  memberId,
} from "../../../../common_utilities";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function ConfirmBank(props) {
  const [validated, setValidated] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [bankBseCode, getBankBseCode] = useState({});
  const [bank, setBank] = useState({});
  const dispatch = useDispatch();
  const showBack = useSelector((state) => state.isBackVisible);
  const user_id = props.value == "minor" ? getMinorUserId() : memberId();
  const [isFatcaFailed, setIsFatcaFailed] = useState(false);

  var retrievedData = localStorage.getItem("Bank_DETAILS");
  var retrievedObject = JSON.parse(retrievedData);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getuserbankdetails();
  }, []);

  const getuserbankdetails = async () => {
    try {
      let bankFromStorage = JSON.parse(localStorage.getItem("bankDetails"));
      setBank({ ...bankFromStorage });
    } catch {
      setBank({});
    }
  };

  const clientRegistration = async () => {
    let url = constClass.DMF_CLIENT_REGISTRATION_API_URL;
    let data_sent = JSON.stringify({
      user_id: user_id,
      is_direct: constClass.IS_DIRECT,
    });

    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100" || (response_obj["message"].includes("modification") && response_obj["message"].includes("failed"))) {
      FATCAUpload();
    } else {
      setIsFatcaFailed(true);
    }
  };

  const FATCAUpload = async () => {
    var config = {
      method: "post",
      url: constClass.DMF_FATCA_UPLOAD_API_URL,
      data: {
        user_id: user_id,
        is_direct: constClass.IS_DIRECT,
      },
    };
    var res = await fetchEncryptData(config);
    console.log("res34--->", res);
    if (res.error_code == "100") {
      toast.success("User details updated successfully.", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      if(props.mainData.residential_status == 2 || props.mainData.residential_status == 3) {
        props.setShowPanel("BankCheque");
      } else {
        if(props.statusData.kyc_verified == "No") {
          props.setShowPanel("UploadPan");
        } else {
          props.setShowPanel("UploadSignature");
        }
      }
      // setTimeout(() => {
      //   props.onNext();
      // }, 1000);
      
    } else {
      // alert(86);
      setIsFatcaFailed(true);
    }
  };

  const handleSubmit = async () => {
    try {
      const config2 = {
        method: "post",
        url: constClass.DMF_ADDBANK_API_URL,
        data: bank,
      };
      const r3 = await fetchEncryptData(config2);
      console.log("r3--", r3);
      // localStorage.setItem("DGBankId", r3.data);
      if (r3.error_code == 100 || (r3.message.includes("already") && r3.message.includes("bank"))) {
        clientRegistration();
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Bank details have been saved.",
            type: "success",
            autoClose: 3000,
          },
        });
      } else {
        if(r3.message.toLowerCase().includes("incorrect value for bank_acc_no")) {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Bank account number must be 7 to 18 digits",
              type: "error",
              autoClose: 3000,
            },
          });
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: r3.message ?? "Error while adding bank.",
              type: "error",
              autoClose: 3000,
            },
          });
        }
        
      }
    } catch (err) {
      console.log("er4------>", err);
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message:
            "Unable to save bank details, please contact customer support.",
          type: "error",
          autoClose: 3000,
        },
      });
    }
  };

  return (
    <>
      <ToastContainer limit={1} />
      <Row className="reverse">
        <Modal
          backdrop="static"
          size="md"
          centered
          show={isFatcaFailed}
          className="profile-popups sign-popup"
          onHide={() => {
            setIsFatcaFailed(false);
          }}
        >
          <Modal.Body>
            <div className="modal-body-box">
              {/* <center><h5><b>{erroronproceed}</b></h5></center> */}
              <center>
                <center>
                  <h5>
                    <b>Verification in Progress</b>
                  </h5>
                </center>
                &nbsp; &nbsp; &nbsp;
                {/* <div></div> */}
                <p className="">
                  Thank you for your patience. Your account is currently
                  undergoing verification, which may take some time. Please
                  allow us a bit more time to complete this process. If you have
                  any questions or need assistance, feel free to reach out to
                  our support@fintoo.in.
                </p>
              </center>

              {/* <center><p><h3> We regret to inform you that your bank verification has encountered errors in the provided data. To successfully complete the verification process, please add your bank details again. If you have any questions or need assistance, please don't hesitate to reach out to us at support@fintoo.in.</h3></p></center> */}
              <div>
                <div className="pt-3 pb-3 ">
                  {/* <div className="img-box9 pt-4 inv-sign-border text-center">
                        <img
                          className="img-fluid inv-img-86"
                          // src={require("../../../../Assets/Images/temp_img_8865.jpg")}
                        />
                          </div> */}
                </div>
                <div className="pb-3 pt-3">
                  <FintooButton
                    onClick={() => {
                      setIsFatcaFailed(false);
                    }}
                    title={"Close"}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img src={Profile_1} alt="" />
          </div>
        </div>
        <div className="RightPanel col-12 col-md-6">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="Bank Account Details"
                onClick={() => props.onPrevious()}
              />
            )}
            <p className="">Confirm Your Bank Details</p>

            <div className="ConfBank">
              {Object.keys(bank).length ? (
                <div>
                  <div className="BankConfrmDetails">
                    <div>
                      <img
                        src={getPublicMediaURL(
                          `/static/media/bank_logo/${bank.bank_bse_code}.png`
                        )}
                      />
                    </div>

                    <div style={{ marginLeft: "10px" }}>
                      <span className="BankCnfmName">{bank.bank_name}</span>
                      <p>
                        <small>{bank.bank_address}</small>
                      </p>
                      <table className="w-100 bank-tbl">
                        <tr>
                          <td>
                            <span className="CofmAccountNM">Account No. </span>
                          </td>
                          <td>
                            <span>{bank.bank_acc_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="CofmIfscCode">IFSC </span>
                          </td>
                          <td>
                            <span>{bank.bank_ifsc_code}</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div
                    className="Nominee_Identity_Last"
                    style={{ float: "right" }}
                  >
                    <FintooButton
                      onClick={() => {
                        handleSubmit();
                      }}
                      title="Next"
                    />
                  </div>
                </div>
              ) : (
                <p className="d-flex text-center">
                  <strong>No results found</strong>
                </p>               
              )}
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

export default ConfirmBank;
