import { useDispatch, useSelector } from "react-redux";
import { fetchEncryptData } from "../common_utilities";
import { DMF_CHECK_ECAS_S3, DMF_GET_SC_DATA_API_URL, DMF_SENDWPSMS_API_URL, IS_DIRECT, RMF_GET_SC_DATA_API_URL } from "../constants";
import { useEffect } from "react";


const DownloadECAS = () => {
  
  const dispatch = useDispatch();

  const  downloadLinkHolding = useSelector((state) => state. downloadLinkHolding);

  const getSmallCaseData = async () => {
    console.log('hello00======>', downloadLinkHolding);
    try {
      let reqData = {
        method: "post",
        data: {
          pan: downloadLinkHolding?.pan,
          user_id: downloadLinkHolding?.id,
        },
        url: DMF_CHECK_ECAS_S3,
      };

      let checkS3Data = await fetchEncryptData(reqData);
      if (checkS3Data.error_code == "100") {
        let reqData = {
          method: "post",
          data: {
            pan: downloadLinkHolding?.pan,
            user_id: downloadLinkHolding?.id,
            fp_user_id: downloadLinkHolding?.fp_user_details_id,
            dmf: ""
          },
          url: DMF_GET_SC_DATA_API_URL,
          // fp_user_id: selectedMember.fp_user_details_id,
        };

        let getScData = await fetchEncryptData(reqData);
        if (getScData.error_code == "100") {
          let msg =
            "Hello! Your holdings have been successfully linked with Fintoo! Regards. -Team Fintoo";
          let whatsapptext =
            "Hello! Your holdings have been successfully linked with Fintoo!\nRegards,\nTeam Fintoo";
          var urlsms_success = {
            mobile: downloadLinkHolding?.mobile,
            whatsappmsg: whatsapptext,
          };
          var config1 = {
            method: "post",
            url: DMF_SENDWPSMS_API_URL,
            data: urlsms_success,
          };

          var resp_success = await fetchEncryptData(config1);
        } else {
          let msg =
            "Hello! Something went wrong while gathering your holding data. Please try again.\nRegards,\nTeam Fintoo";
          let whatsapptext_fail =
            "Hello! Something went wrong while gathering your holding data. Please try again.\nRegards,\nTeam Fintoo";
          var urlsms_fail = {
            mobile: downloadLinkHolding?.mobile,
            whatsappmsg: whatsapptext_fail,
          };
          var config2 = {
            method: "post",
            url: DMF_SENDWPSMS_API_URL,
            data: urlsms_fail,
          };

          var resp_fail = await fetchEncryptData(config2);
        }
        dispatch({ type: "LINK_HOLDING_EVENT", payload: {} });
      } else {
        setTimeout(()=> {
          getSmallCaseData();
        }, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    if(downloadLinkHolding){
      if(Object.keys(downloadLinkHolding).length > 0) {
        getSmallCaseData();
    }}
  }, [downloadLinkHolding]);

  console.log('hello0066666666======>', downloadLinkHolding);

  return <></>;
}
export default DownloadECAS;