import React, { useState, useEffect, useRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select, { components } from "react-select";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import FintooRadio2 from "../../../components/FintooRadio2";
import Switch from "react-switch";
import moment from "moment";
import Slider from "../../../components/HTML/Slider";
import DgRoundedButton from "../../../components/HTML/DgRoundedButton";
import DgDragDrop from "../../../components/HTML/DgDragDrop";
import DgDragDrop2 from "../../../components/HTML/DgDragDrop/DgDragDrop2";
import commonEncode from "../../../commonEncode";
import {
  ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
  ADVISORY_UPLOAD_ECASH_DOCUMENT,
  ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
  ADVISORY_GET_UNASSIGNED_ASSET,
  BASE_API_URL,
  ADVISORY_GET_FP_USER_DATA,
} from "../../../constants";
import { Buffer } from "buffer";
import {
  apiCall,
  fetchData,
  fetchEncryptData,
  fv,
} from "../../../common_utilities";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "react-bootstrap";
import LinkYourHoldingsDG from "./LinkYourHoldingsDG";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { useDispatch } from "react-redux";
import GoalsDropdown from "../../../components/GoalsDropdown/GoalDropdown";
import LinkEPF from "./LinkEPF";
import customStyles from "../../../components/CustomStyles";
import FintooLoader from "../../../components/FintooLoader";
import Uniquepannotfoundmodal from "./Uniquepannotfoundmodal";

const Purchaseoptions = [
  { value: 1, label: "Cumulative" },
  { value: 2, label: "Non-Cumulative" },
];
const Frequencyoptions = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Quaterly" },
  { value: 3, label: "Half Yearly" },
  { value: 4, label: "Yearly" },
];
function AssetDebt(props) {
  const [selectedButton, setSelectedButton] = useState("");
  const [selectedExtraOption, setSelectedExtraOption] = useState("");
  const [selectDebtFunds, setSelectedDebtFunds] = useState("");
  const [retirementDate, setRetirementDate] = useState("");
  const [lifeExpectancyDate, setLifeExpectancyDate] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [pannumbers, setPanNumbers] = useState([]);
  const [familyecas, setFamilyEcas] = useState([]);
  const [selfData, setSelfData] = useState({});
  const [memberdataid,setMemberDataId] = useState({})
  const setAssetsDetails = props.setAssetsDetails;
  const assetsDetails = props.assetsDetails;
  const familyData = props.familyData;
  const goalData = props.goalData;
  const setDate = props.setDate;
  const debtfunds = props.debtfunds;
  const session = props.session;
  const addForm = props.addForm;
  const updateForm = props.updateForm;
  const addAssetsSubmit = props.addAssetsSubmit;
  const cancelAssetForm = props.cancelAssetForm;
  const updateAssetsSubmit = props.updateAssetsSubmit;
  const setGoalSelected = props.setGoalSelected;
  const closeModal = props.closeModal;
  const selectGoals = props.selectGoals;
  const selectedGoals = props.selectedGoals;
  const selectedGoalIdArray = props.selectedGoalIdArray;
  const selectedGoalsId = props.selectedGoalsId;
  const setPriorityArray = props.setPriorityArray;
  const selectedPriorityArray = props.selectedPriorityArray;
  const setAutoMatedGoal = props.setAutoMatedGoal;
  const isAutoMatedGoal = props.isAutoMatedGoal;
  const setGoalLink = props.setGoalLink;
  const isGoalSelected = props.isGoalSelected;
  const setSelectedGoals = props.setSelectedGoals;
  const setSelectedGoalsId = props.setSelectedGoalsId;
  const setSelectedPriorityArray = props.setSelectedPriorityArray;
  const unchangedgoaldata = props.unchangedgoaldata;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState(0);
  const dispatch = useDispatch();
  const assetEditId = props.assetEditId;
  const skipFirstTimeLoad = useRef(null);
  const [showuniqueUANModal, setShowuniqueUANModal] = useState(false);

  const linkyourHoldings = [
    { value: "Self", label: "Self" },
    { value: "Spouse", label: "Spouse" },
  ];
  const [selectedOption, setSelectedOptionLink] = useState(null);
  const debtFundsData = debtfunds.map((index, value) => {
    return {
      label: index.fund_name,
      value: index.current_nav,
    };
  });
  const [isVisible, setIsVisible] = useState(false);
  const [isInstallmentVisible, setInstallmentIsVisible] = useState(false);

  const handleDebtFundSelection = (selectedOption) => {
    setAssetsDetails((prev) => ({
      ...prev,
      asset_name: selectedOption.label, // Set the asset_name using the selected fund name
      asset_current_unit_price: parseFloat(selectedOption.value).toFixed(2),
    }));
  };

  useEffect(() => {
    if (assetsDetails.asset_payout_type == "2") {
      setIsVisible(true);
    }
  }, [assetsDetails?.asset_payout_type]);

  const Input = (props) => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  const changeHandler = (e) => {
    if (e === 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // EPF states

  const [showEPFForm, setShowEPFForm] = useState(false);
  const [showUANModal, setShowUANModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    setShowEPFForm(false);
    setShowUANModal(false);
    setShowOTPModal(false);
  }, assetsDetails.asset_sub_category_id == 77);

  // Upload Document

  const [docPassword, setDocPassword] = useState("");
  const [dropFiles, setdropFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ecashUploadDocument = async () => {
    try {
      var form = new FormData();
      form.append("ecash_type", "CAMS");
      form.append("ecash_passkey", docPassword);
      form.append("fp_user_id", session["data"]["fp_user_id"]);
      form.append("fp_log_id", session["data"]["fp_log_id"]);
      form.append("doc_user_id", session["data"]["id"]);

      for (let i = 0; i < dropFiles.length; i++) {
        form.append(`file[${i}]`, dropFiles[i], dropFiles[i].name);
      }
      setIsLoading(true);
      var ecash_upload = await apiCall(
        ADVISORY_UPLOAD_ECASH_DOCUMENT,
        form,
        false,
        false
      );

      if (ecash_upload["error_code"] == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success("Document uploaded successfully");
        getUnassignedAsset();
        setdropFiles([]);
        setDocPassword("");
        setForceUpdate((v) => ++v);
        dispatch({ type: "RESET_DRAGZONE", payload: true });
        setIsLoading(false);
      } else if (ecash_upload["error_code"] == "102") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(ecash_upload["data"]);
        setdropFiles([]);
        setDocPassword("");
        setForceUpdate((v) => ++v);
        dispatch({ type: "RESET_DRAGZONE", payload: true });
        setIsLoading(false);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
        setdropFiles([]);
        setDocPassword("");
        setForceUpdate((v) => ++v);
        dispatch({ type: "RESET_DRAGZONE", payload: true });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUnassignedAsset = async ()=>{
    try{
      var payload ={"user_id":session["data"]["id"],"fp_log_id":session["data"]["fp_log_id"],"fp_user_id":session["data"]["fp_user_id"]}
      var unassigned_asset = await apiCall(
        ADVISORY_GET_UNASSIGNED_ASSET,
        payload,
        false,
        false
      );
      if (unassigned_asset["error_code"] == "100") {
          setPanNumbers(unassigned_asset.data)
          if (unassigned_asset.data != 'false'){
            setShowuniqueUANModal(true)
          }
          var response_pan = unassigned_asset.data
          var temp_member_id = 0
          var familyDetails = await apiCall(
            BASE_API_URL + "restapi/getfpfamilydata/" +
            "?parent_user_id=" +
            Buffer.from(commonEncode.encrypt((session["data"]["id"]).toString())).toString("base64") + '&fp_log_id='+Buffer.from(commonEncode.encrypt((session["data"]["fp_log_id"]).toString())).toString("base64")+
            "&web=1",
        )
        if (familyDetails.data != undefined) {
          setFamilyEcas(familyDetails.data)
          
          let url = ADVISORY_GET_FP_USER_DATA + '?user_id=' + btoa(commonEncode.encrypt((session["data"]["id"]).toString())) + '&fp_log_id=' + btoa(commonEncode.encrypt((session["data"]["fp_log_id"]).toString())) + '&fp_user_id=' + btoa(commonEncode.encrypt((session["data"]["fp_user_id"]).toString())) + "&web=1";

          let fpUserData = await apiCall(url, "", true, false);
          if (fpUserData.data.length > 0){
            if(familyDetails.data.length>0 && familyDetails.data != undefined){
              temp_member_id = familyDetails.data[0].id
            }
            else{
              setFamilyEcas([])
            }
          }
          else{
            setFamilyEcas([])
          }
        }
        var item = {}
        for (var pan = 0; pan < unassigned_asset.data.length; pan++) {
          item["familydata_ecas_" + response_pan[pan].asset_pan] = temp_member_id.toString();
        }
        setMemberDataId(item)
      }
    }
    catch(e){
      console.log(e)
    }
  }

  const handleFilesSelected = (files) => {
    const dropFiles = Array.from(files).slice(0, 1);
    setdropFiles(dropFiles);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      setIsLoading(true);
      ecashUploadDocument();
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
      dispatch({ type: "RESET_DRAGZONE", payload: true });
    }
  };

  useEffect(() => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.purgeFields();

    if (assetEditId) {
      if (selectedGoals == "Automated Linkage") {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      } else {
        setAutoMatedGoal(false);
      }
    } else {
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }
    if (session && !assetEditId) {
      setGoalSelected(false);
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      props.getfpgoalsdata(session.data.fp_log_id);
    }
    setForceUpdate((v) => ++v);
  }, [
    assetsDetails?.asset_sub_category_id,
    assetsDetails?.asset_isrecurring,
    selectedButton,
  ]);

  useEffect(() => {
    getRetirementData();
  }, []);

  const getRetirementData = async () => {
    try {
      // var retire_data = {
      //   fp_log_id: session["data"]["fp_log_id"],
      //   fp_user_id: session["data"]["id"],
      // };
      // var payload_retire_data = commonEncode.encrypt(
      //   JSON.stringify(retire_data)
      // );
      // var config_ret = await apiCall(
      //   ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
      //   payload_retire_data,
      //   false,
      //   false
      // );
      // var res_ret = JSON.parse(commonEncode.decrypt(config_ret));
      // if (res_ret.error_code == "100") {
      //   var retirement_date = moment(res_ret["data"][0]["dob"])
      //     .add(res_ret["data"][0]["retirement_age"], "y")
      //     .format("MM/DD/YYYY");
      //   var life_expectancy_date = moment(res_ret["data"][0]["dob"])
      //     .add(res_ret["data"][0]["life_expectancy"], "y")
      //     .format("MM/DD/YYYY");
      //   setRetirementDate(retirement_date);
      //   setLifeExpectancyDate(life_expectancy_date);
      // }
      var retirement_date = moment(session["data"]["user_details"]['dob'])
        .add(session["data"]["user_details"]['retirement_age'], "y")
        .format("MM/DD/YYYY");
      var life_expectancy_date = moment(session["data"]["user_details"]['dob'])
        .add(session["data"]["user_details"]['life_expectancy'], "y")
        .format("MM/DD/YYYY");
      
      setRetirementDate(retirement_date);
      setLifeExpectancyDate(life_expectancy_date);
      setSelfData({'retirement_date': retirement_date, 'life_expectancy_date': life_expectancy_date});

    } catch {
      (e) => { };
    }
  };

  const handleOnetimeButtonChange = (v) => {
    var member = familyData.filter((v) => v.value == assetsDetails.asset_member_id)[0];
    handleAssetMemberMaturityDate(member, "1", v);
  }

  const handleAssetMemberMaturityDate = async (member, onetimechange = "0", isrecurring = false) => {
    let retirement_date = ""
    let life_expectancy_date = ""
    if (member['isdependent'] == "0") {
      retirement_date = moment(member['dob'])
        .add(member['retirement_age'], "y")
        .format("MM/DD/YYYY");
      life_expectancy_date = moment(member['dob'])
        .add(member['life_expectancy'], "y")
        .format("MM/DD/YYYY");
    } else {
      retirement_date = selfData["retirement_date"];
      life_expectancy_date = selfData["life_expectancy_date"]
    }
    setRetirementDate(retirement_date);
    setLifeExpectancyDate(life_expectancy_date);

    if (assetsDetails.asset_sub_category_id == 77 && selectedExtraOption == "Manual Entry" && (assetsDetails.asset_isrecurring == true || isrecurring == true)) {
      if (assetsDetails.asset_isperpetual == "3") {
        if (onetimechange == "0") {
          setAssetsDetails({
            ...assetsDetails,
            asset_mf_end_date: moment(retirement_date).format("DD/MM/YYYY"),
            asset_member_id: member.value,
          });
        } else {
          setAssetsDetails({
            ...assetsDetails,
            asset_mf_end_date: moment(retirement_date).format("DD/MM/YYYY"),
            asset_isrecurring: isrecurring,
            asset_purchase_amount: " ",
          });
        }

      } else if (assetsDetails.asset_isperpetual == "2") {
        if (onetimechange == "0") {
          setAssetsDetails({
            ...assetsDetails,
            asset_mf_end_date: moment(life_expectancy_date).format("DD/MM/YYYY"),
            asset_member_id: member.value,
          });
        } else {
          setAssetsDetails({
            ...assetsDetails,
            asset_mf_end_date: moment(life_expectancy_date).format("DD/MM/YYYY"),
            asset_member_id: member.value,
            asset_isrecurring: isrecurring,
            asset_purchase_amount: " ",
          });
        }
      } else if (assetsDetails.asset_isperpetual == "1") {
        if (onetimechange == "0") {
          setAssetsDetails({
            ...assetsDetails,
            asset_member_id: member.value
          });
        } else {
          setAssetsDetails({
            ...assetsDetails,
            asset_member_id: member.value,
            asset_isrecurring: isrecurring,
            asset_purchase_amount: " ",
          });
        }

      }
    }
    // else{
    // if(onetimechange == "0"){
    //   setAssetsDetails({
    //     ...assetsDetails,
    //     asset_member_id: member.value,
    //   })
    // }else{
    //   setAssetsDetails({
    //     ...assetsDetails,
    //     asset_member_id: member.value,
    //     asset_isrecurring: isrecurring,
    //     asset_purchase_amount: " "
    //   })
    // }
    // }
    else if (assetsDetails.asset_sub_category_id == 117 && selectedExtraOption == "EPF Manual Entry") {
      if (assetsDetails.asset_isperpetual == "3") {
        setAssetsDetails({
          ...assetsDetails,
          asset_maturity_date: moment(retirement_date).format("DD/MM/YYYY"),
          asset_member_id: member.value,
        });
      }
    }
    else if (assetsDetails.asset_sub_category_id == 81 || assetsDetails.asset_sub_category_id == 85 || assetsDetails.asset_sub_category_id == 86 || assetsDetails.asset_sub_category_id == 120) {
      if (assetsDetails.asset_isperpetual == "3") {
        setAssetsDetails({
          ...assetsDetails,
          asset_maturity_date: moment(retirement_date).format("DD/MM/YYYY"),
          asset_member_id: member.value,
        });
      }
    } else {
      if (onetimechange == "0") {
        setAssetsDetails({
          ...assetsDetails,
          asset_member_id: member.value,
        })
      } else {
        setAssetsDetails({
          ...assetsDetails,
          asset_member_id: member.value,
          asset_isrecurring: isrecurring,
          asset_purchase_amount: " "
        })
      }
    }
  }

  const handleDebtSubmit = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      addAssetsSubmit(e);
      setGoalSelected(false);
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleDebtUpdate = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      updateAssetsSubmit(e);
      setGoalSelected(false);

      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleDebtCancel = async (e) => {
    e.preventDefault();
    cancelAssetForm(e);
    simpleValidator.current.hideMessages();
    setForceUpdate((v) => ++v);
  };

  const showuniqueUANModalclose =()=>{
    setShowuniqueUANModal(false);
  }

  return (
    <>
      <FintooLoader isLoading={isLoading} />
      <form noValidate="novalidate" name="goldassetform">
        {(assetsDetails.asset_sub_category_id == 75 ||
          assetsDetails.asset_sub_category_id == 76 ||
          assetsDetails.asset_sub_category_id == 78 ||
          assetsDetails.asset_sub_category_id == 79 ||
          assetsDetails.asset_sub_category_id == 80 ||
          assetsDetails.asset_sub_category_id == 87) && (
            <>
              <form noValidate="novalidate" name="goldassetform">
                <div className="row d-flex align-items-center">
                  <div className="col-md-5 custom-input">
                    <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: "18px" }}>
                      <input type="text" name="asset_name" maxLength={35}
                        value={assetsDetails.asset_name}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_name: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          simpleValidator.current.showMessageFor("Asset Name");
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Name of Asset*</label>
                    </div>
                    {simpleValidator.current.message(
                      "Asset Name",
                      assetsDetails.asset_name,
                      "required|min:3|max:60",
                      {
                        messages: {
                          required: "Please enter asset name",
                          max: "Asset name must be between 3-35 characters.",
                          min: "Asset name must be between 3-35 characters.",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5">
                    <div className="material mt-md-0 mt-3">
                      <Form.Label>Who is investment For?*</Form.Label>
                      {familyData && (
                        <Select
                          classNamePrefix="sortSelect"
                          isSearchable={false}
                          styles={customStyles}
                          options={familyData}
                          onChange={(e) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_member_id: e.value,
                            })
                          }
                          value={familyData.filter(
                            (v) => v.value == assetsDetails.asset_member_id
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row d-flex align-items-center py-md-2 ">
                  <div className="col-md-5 mt-md-0 mt-4 ">
                    <div className="material" style={{ marginTop: "5px" }}>
                      <Form.Label className="mb-0">Date of Purchase*</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          paddingTop: "0px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_purchase_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          setDate={(date) => {
                            setDate(date, "assetPurchaseDate");
                          }}
                          minDate={""}
                          maxDate={moment().toDate()}
                          className="pt-4"
                        />
                      </div>
                      {simpleValidator.current.message(
                        "Asset Purchase Date",
                        assetsDetails.asset_purchase_date,
                        "required",
                        { messages: { required: "Please select purchase date" } }
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 mt-md-0 mt-2  custom-input">
                    <div className={`${assetsDetails.asset_purchase_amount ? "inputData" : null} `} style={{ paddingTop: "19px" }}>
                      <input type="Number" name="asset_purchase_amount" value={assetsDetails.asset_purchase_amount}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            isEditable: true,
                          });
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Purchase Amount (₹)*</label>
                    </div>
                    <div
                      className="w-100"
                    >
                      {simpleValidator.current.message(
                        "Asset Purchase Amount",
                        assetsDetails.asset_purchase_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add purchase amount ",
                            min: "Please enter valid purchase amount ",
                          },
                        }
                      )}
                    </div>
                  </div>
                </div>

                <div className="row py-md-2 mt-3">
                  <div className="col-md-5 mt-4">
                    <div className="material">
                      <Form.Label>
                        Rate Of Return (%)* : {assetsDetails.asset_ror}
                      </Form.Label>
                      <div
                        className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                          } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                      >
                        <Slider
                          key={"sld-" + assetsDetails.asset_sub_category_id}
                          min={0}
                          max={20}
                          step={0.05}
                          value={assetsDetails.asset_ror}
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_ror: v,
                              isEditable: true,
                            })
                          }
                        // defaultValue={assetsDetails.asset_ror}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="material mt-md-0 mt-3">
                      <Form.Label>Payout Type*</Form.Label>

                      <Select
                        classNamePrefix="sortSelect"
                        styles={customStyles}
                        options={Purchaseoptions}
                        defaultValue={Purchaseoptions[0]}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_payout_type: e.value,
                            isEditable: true,
                          });
                          changeHandler(e.value);
                        }}
                        value={Purchaseoptions.filter(
                          (v) => v.value == assetsDetails.asset_payout_type
                        )}
                      />
                    </div>
                  </div>
                </div>
                {isVisible ? (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-5">
                        <div className="material mt-md-0 mt-4">
                          <Form.Label>Frequency*</Form.Label>
                          <Select
                            classNamePrefix="sortSelect"
                            isSearchable={false}
                            styles={customStyles}
                            defaultValue={Frequencyoptions[3]}
                            options={Frequencyoptions}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_frequency: e.value,
                                isEditable: true,
                              });
                            }}
                            value={Frequencyoptions.filter(
                              (v) => v.value == assetsDetails.asset_frequency
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="row d-flex align-items-center py-md-2 ">
                  <div className="col-md-5 " >
                    <div className="material mt-md-0 mt-4">
                      <Form.Label className="mb-0">Maturity Date*</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          // paddingTop: "0px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_maturity_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          setDate={(date) => {
                            setDate(date, "maturityDate");
                          }}
                          minDate={moment().toDate()}
                          maxDate={moment().add(100, "years").toDate()}
                        // className="pt-4"
                        />
                      </div>
                    </div>
                    {simpleValidator.current.message(
                      "Maturity Date",
                      assetsDetails.asset_maturity_date,
                      "required",
                      {
                        messages: {
                          required: "Please select maturity date ",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5 custom-input mt-md-3 mt-4" >
                    <div className={`form-group inputData`} >
                      <span>
                        <input type="Number" name="asset_purchase_amount" value={assetsDetails.asset_maturity_amt}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Maturity Amount*</label>
                      </span>
                      <span
                        className="info-hover-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Auto calculated on the basis of Purchase Amount and Rate
                          Of Return. You can also edit it and enter your desired
                          maturity amount.
                        </span>
                      </span>
                    </div>
                    <div>
                      <div>
                        {simpleValidator.current.message(
                          "Asset Maturity Amount",
                          assetsDetails.asset_maturity_amt,
                          "required|numeric|min:1,num",
                          {
                            messages: {
                              required: "Please add maturity amount ",
                              min: "Please enter valid maturity amount ",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-md-2 mt-2">
                  <div className="col-md-8">
                    <div className="d-md-flex">
                      <Form.Label className=" ">
                        Consider This Asset In Automated Linkage*
                      </Form.Label>
                      <span
                        className="ms-md-4  info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Select a goal below to map this investment with a goal of
                          your choice. Otherwise, Fintoo will link it automatically
                          with your high priority goal. In case, you do not wish to
                          utilize this investment for any goal, select "NO".
                        </span>
                      </span>
                      <div className="d-flex  ms-md-4">
                        <div>No</div>
                        <Switch
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_islinkable: v,
                            })
                          }
                          checked={assetsDetails.asset_islinkable}
                          className="react-switch px-2"
                          activeBoxShadow="0 0 2px 3px #424242"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                          onColor="#042b62"
                          offColor="#d8dae5"
                        />
                        <div>Yes</div>
                      </div>
                    </div>
                  </div>
                </div>
                {assetsDetails.asset_islinkable == true && (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-8 mt-md-2 mt-3 mt-3">
                        <div className="d-md-flex">
                          <Form.Label className="link_asset_style">
                            Link This Investment Asset to Goal
                          </Form.Label>
                          <span
                            className="ms-md-4 ms-3 info-hover-left-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              You can only assign goals which are prior to the end
                              date of the SIP, if any
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-md-0 mt-2">
                        <div className="material">
                          <div
                            className="m-0 btn-sm default-btn gradient-btn save-btn"
                            onClick={() => setGoalSelected(true)}
                          >
                            Select Goals
                          </div>
                          <br></br>
                          <br></br>

                          {selectedGoals ? (
                            <div className="d-flex" style={{ textAlign: "left!important" }}>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Selected Goals : </b>
                              </div>
                              <div className="ms-1">{selectedGoals}</div>
                            </div>
                          ) : (
                            ""
                          )}
                          {isGoalSelected ? (
                            <GoalsDropdown
                              setGoalSelected={setGoalSelected}
                              goals={goalData}
                              unchangedgoaldata={unchangedgoaldata}
                              closeModal={closeModal}
                              selectGoals={selectGoals}
                              selectedGoals={selectedGoals}
                              selectedGoalIdArray={selectedGoalIdArray}
                              selectedGoalsId={selectedGoalsId}
                              setPriorityArray={setPriorityArray}
                              selectedPriorityArray={selectedPriorityArray}
                              setAutoMatedGoal={setAutoMatedGoal}
                              isAutoMatedGoal={isAutoMatedGoal}
                              setGoalLink={setGoalLink}
                              asset_maturity_date={
                                assetsDetails.asset_maturity_date
                              }
                              type={"Asset"}
                              isGoalFilter={"1"}
                              isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                            ></GoalsDropdown>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div className="d-flex justify-content-center">
                          <a
                            href={process.env.PUBLIC_URL + "/datagathering/goals"}
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </a>

                          {addForm && (
                            <button
                              onClick={(e) => handleDebtSubmit(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Save & Add More
                            </button>
                          )}
                          {props.updateForm && (
                            <div>
                              <button
                                onClick={(e) => handleDebtCancel(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={(e) => handleDebtUpdate(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Update
                              </button>
                            </div>
                          )}

                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => props.setTab("tab2")}
                          >
                            <span className="hover-text" style={{ maxWidth: 100 }}>
                              Continue&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        {assetsDetails.asset_sub_category_id == 84 && (
          <>
            <form noValidate="novalidate" name="goldassetform">
              <div className="row d-flex align-items-center">
                <div className="col-md-5 custom-input">
                  <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: "17px" }}>
                    <input type="text" name="asset_name"
                      maxLength={35}
                      value={assetsDetails.asset_name}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_name: e.target.value,
                        }));
                      }}
                      onBlur={() => {
                        simpleValidator.current.showMessageFor("Asset Name");
                      }} required autoComplete="off" />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Name of Asset*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Asset Name",
                    assetsDetails.asset_name,
                    "required|min:3|max:60",
                    {
                      messages: {
                        required: "Please enter asset name",
                        max: "Asset name must be between 3-35 characters.",
                        min: "Asset name must be between 3-35 characters.",
                      },
                    }
                  )}
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                  <div className="material">
                    <Form.Label>Who is investment For?*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_member_id: e.value,
                          }))
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row  d-flex align-items-center py-md-2">
                <div className="col-md-5 mt-md-0 mt-4">
                  <div className="material">
                    <Form.Label>Date of Purchase*</Form.Label>
                    <div
                      className="dt-conbx"
                      style={{
                        borderBottom: "1px solid #dadada",
                        paddingTop: "0px",
                      }}
                    >
                      <ReactDatePicker
                        select_date={moment(
                          assetsDetails.asset_purchase_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        setDate={(date) => {
                          setDate(date, "assetPurchaseDate");
                        }}
                        minDate={""}
                        maxDate={moment().toDate()}
                        className="pt-4"
                      />
                    </div>
                  </div>

                  {simpleValidator.current.message(
                    "Asset Purchase Date",
                    assetsDetails.asset_purchase_date,
                    "required",
                    { messages: { required: "Please select purchase date" } }
                  )}
                </div>
                <div className="col-md-5 custom-input mt-md-0 mt-3">
                  <div className={`form-group ${assetsDetails.asset_purchase_amount ? "inputData" : null} `} style={{ paddingTop: "15px" }}>
                    <input type="Number" name="asset_purchase_amount"
                      value={assetsDetails.asset_purchase_amount}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          isEditable: true,
                        }));
                      }} required autoComplete="off" />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name"> Purchase Amount (₹)*</label>
                  </div>
                  <div
                    className="w-100"
                  >
                    {simpleValidator.current.message(
                      "Asset Purchase Amount",
                      assetsDetails.asset_purchase_amount,
                      "required|numeric|min:1,num",
                      {
                        messages: {
                          required: "Please add purchase amount ",
                          min: "Please enter valid purchase amount ",
                        },
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="row py-md-2 mt-3">
                <div className="col-md-5">
                  <div className="material">
                    <Form.Label>
                      Rate Of Return (%)* :{" "}
                      {assetsDetails.asset_ror}
                    </Form.Label>

                    {/* <div
                    className={`${
                      assetsDetails.asset_ror < 1 && "sl-hide-left"
                    } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                  > */}
                    <Slider
                      key={"sld-" + assetsDetails.asset_sub_category_id}
                      min={0}
                      max={20}
                      step={0.05}
                      value={assetsDetails.asset_ror}
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_ror: v,
                          isEditable: true,
                        })
                      }
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center py-md-2 mt-3">
                <div className="col-md-5 ">
                  <div className="material">
                    <Form.Label>Maturity Date*</Form.Label>
                    <div
                      className="dt-conbx"
                      style={{
                        borderBottom: "1px solid #dadada",
                        paddingTop: "0px",
                      }}
                    >
                      <ReactDatePicker
                        select_date={moment(
                          assetsDetails.asset_maturity_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        setDate={(date) => {
                          setDate(date, "maturityDate");
                        }}
                        minDate={moment().toDate()}
                        maxDate={moment().add(100, "years").toDate()}
                        className="pt-4"
                      />
                    </div>
                  </div>
                  <div>
                    {simpleValidator.current.message(
                      "Maturity Date",
                      assetsDetails.asset_maturity_date,
                      "required",
                      {
                        messages: {
                          required: "Please select maturity date ",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3 custom-input">
                  <div className={`form-group inputData `} style={{ paddingTop: "15px" }}>
                    <span>
                      <input type="Number" name="asset_units"
                        value={assetsDetails.asset_maturity_amt}
                        onChange={(e) => {
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          }));
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Maturity Amount (₹)*</label>
                    </span>
                    <span
                      className="info-hover-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Auto calculated on the basis of Purchase Amount and Rate
                        Of Return. You can also edit it and enter your desired
                        maturity amount.
                      </span>
                    </span>
                  </div>

                  <div>
                    {simpleValidator.current.message(
                      "Asset Maturity Amount",
                      assetsDetails.asset_maturity_amt,
                      "required",
                      {
                        messages: {
                          required: "Please enter maturity amount",
                        },
                      }
                    )}
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-8">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span
                      className="ms-md-4 info-hover-left-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex  ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_islinkable: v,
                          }))
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-md-2 mt-3">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>

                        <span
                          className="ms-md-4 ms-3 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-md-0 mt-2">
                      <div className="material">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div className="d-flex" style={{ textAlign: "left!important" }}>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            asset_maturity_date={
                              assetsDetails.asset_maturity_date
                            }
                            type={"Asset"}
                            isGoalFilter={"1"}
                            isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleDebtSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {props.updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleDebtCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleDebtUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
        {assetsDetails.asset_sub_category_id == 82 && (
          <>
            <form noValidate="novalidate" name="goldassetform">
              <div className="row d-flex align-items-center">
                <div className="col-md-5  custom-input">
                  <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: '17px' }}>
                    <input type="text" name="asset_units"
                      maxLength={35}
                      value={assetsDetails.asset_name}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_name: e.target.value,
                        }));
                      }}
                      onBlur={() => {
                        simpleValidator.current.showMessageFor("Asset Name");
                      }} required autoComplete="off" />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Name of Asset*</label>
                  </div>
                  <div>
                    {simpleValidator.current.message(
                      "Asset Name",
                      assetsDetails.asset_name,
                      "required|alpha_space|min:3|max:60",
                      {
                        messages: {
                          alpha_space: "Alphabets are allowed only.",
                          required: "Please enter asset name",
                          max: "Asset name must be between 3-35 characters.",
                          min: "Asset name must be between 3-35 characters.",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                  <div className="material">
                    <Form.Label>Who is investment For?*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_member_id: e.value,
                          }))
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-5 mt-md-0 mt-4 custom-input">
                  <div className={`form-group mt-md-2  ${assetsDetails.asset_current_unit_price ? "inputData" : null} `}>
                    <input type="Number" name="asset_current_unit_price"
                      value={assetsDetails.asset_current_unit_price}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          isEditable: true,
                        })
                      } required autoComplete="off" />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Monthly Salary (Basic Pay + D.A) (₹)*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Asset Current Price",
                    assetsDetails.asset_current_unit_price,
                    "required|numeric|min:1,num",
                    {
                      messages: {
                        required: "Please add salary ",
                        min: "Please enter valid salary ",
                      },
                    }
                  )}
                </div>
                <div className="col-md-5 mt-4">
                  <div className="material">
                    <Form.Label>
                      No of Service Years (Minimum 5 Years)*
                    </Form.Label>
                    <div
                      className={`${assetsDetails.annual_growth_rate < 7 && "sl-hide-left"
                        } ${assetsDetails.annual_growth_rate > 48 && "sl-hide-right"
                        }`}
                    >
                      <Slider
                        min={5}
                        max={50}
                        value={assetsDetails.annual_growth_rate}
                        step={1}
                        onChange={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            annual_growth_rate: v,
                            isEditable: true,
                          })
                        }
                        handleStyle={{
                          borderColor: "#424242",
                          backgroundColor: "#424242",
                        }}
                        railStyle={{
                          backgroundColor: "#ade9c0",
                        }}
                        trackStyle={{
                          backgroundColor: "#ade9c0",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-5 custom-input">
                  <div className={`form-group  ${assetsDetails.asset_current_unit_price ? "inputData" : null} `}>
                    <span>
                      <input type="Number" name="asset_current_unit_price"
                        value={
                          assetsDetails.asset_maturity_amt === 0
                            ? ""
                            : assetsDetails.asset_maturity_amt
                        }
                        onChange={(e) => {
                          if (e.target.value == 0) {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_maturity_amt: "",
                            });
                          } else {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Gratuity Accured (₹)*</label>
                    </span>
                    <span className="info-hover-box">
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Auto calculated on the basis of Monthly Salary and No of
                        Service Years. You can also edit it and enter your desired
                        Gratuity Accured.
                      </span>
                    </span>
                  </div>
                  <div style={{ top: '37pd' }}>
                    {simpleValidator.current.message(
                      "Asset Maturity Amount",
                      assetsDetails.asset_maturity_amt,
                      "required|numeric|min:1,num",
                      {
                        messages: {
                          required: "Please enter gratuity amount",
                          min: 'Please enter valid gratuity amount',

                        },
                      }
                    )}
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-8 mt-2">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span
                      className="ms-md-4 info-hover-left-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex  ms-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_islinkable: v,
                          }))
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-md-2 mt-3">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>
                        <span
                          className="ms-md-4 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mt-md-0 mt-2">
                      <div className="material">
                        {/* <div className="m-0 btn-sm default-btn gradient-btn save-btn" onClick={() => setGoalSelected(true)}>Select Goals</div> */}
                        {/* <br></br>
                    <br></br> */}

                        {selectedGoals && goalData.length > 0 ? (
                          <div style={{ textAlign: "left!important" }}>
                            <b>Selected Goal : </b> Retirement Goal - Self
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {isGoalSelected ? <GoalsDropdown setGoalSelected={setGoalSelected} goals={goalData} unchangedgoaldata={unchangedgoaldata} closeModal={closeModal} selectGoals={selectGoals} selectedGoals={selectedGoals} selectedGoalIdArray={selectedGoalIdArray} selectedGoalsId={selectedGoalsId} setPriorityArray={setPriorityArray} selectedPriorityArray={selectedPriorityArray} setAutoMatedGoal={setAutoMatedGoal} isAutoMatedGoal={isAutoMatedGoal} setGoalLink={setGoalLink} type={'Asset'} asset_maturity_date={assetsDetails.asset_maturity_date} isGratuity={true}></GoalsDropdown> : ''} */}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleDebtSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {props.updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleDebtCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleDebtUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}

                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
        {(assetsDetails.asset_sub_category_id == 118 ||
          assetsDetails.asset_sub_category_id == 85 ||
          assetsDetails.asset_sub_category_id == 81 ||
          assetsDetails.asset_sub_category_id == 86 ||
          assetsDetails.asset_sub_category_id == 120) && (
            <>
              <form noValidate="novalidate" name="goldassetform">
                <div className="row d-flex align-center">
                  <div className="col-md-5  custom-input">
                    <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: "15px" }}>
                      <input type="text" name="asset_name"
                        maxLength={35}
                        value={assetsDetails.asset_name}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_name: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          simpleValidator.current.showMessageFor("Asset Name");
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Name of Asset*</label>
                    </div>
                    {simpleValidator.current.message(
                      "Asset Name",
                      assetsDetails.asset_name,
                      "required|min:3|max:60",
                      {
                        messages: {
                          required: "Please enter asset name",
                          max: "Asset name must be between 3-35 characters.",
                          min: "Asset name must be between 3-35 characters.",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5 mt-md-0 mt-3">
                    <div className="material mt-md-3">
                      <Form.Label> Who is investment For?*</Form.Label>
                      {familyData && (
                        <Select
                          classNamePrefix="sortSelect"
                          isSearchable={false}
                          styles={customStyles}
                          options={familyData}
                          onChange={(e) =>
                            // setAssetsDetails({
                            //   ...assetsDetails,
                            //   asset_member_id: e.value,
                            // })
                            handleAssetMemberMaturityDate(e)
                          }
                          value={familyData.filter(
                            (v) => v.value == assetsDetails.asset_member_id
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row py-md-2">
                  <div className="col-md-5 mt-md-0 mt-4 custom-input">
                    <div className={`form-group mt-md-1 
                    ${assetsDetails.asset_current_unit_price == 0 ? "inputData"
                        : assetsDetails.asset_current_unit_price ? "inputData" : null} `}>
                      <input type="Number" name="asset_current_unit_price"
                        value={assetsDetails.asset_current_unit_price}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            isEditable: true,
                          });
                        }} autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Account Balance As On Today (₹)</label>
                    </div>
                  </div>
                  <div className="col-md-5 custom-input">
                    <div className={`form-group mt-1 ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                      <input type="Number" name="asset_current_unit_price"
                        value={assetsDetails.asset_purchase_amount}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            isEditable: true,
                          });
                        }} required autoComplete="off" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Investment Amount (per frequency) (₹)*</label>
                    </div>
                    <div>
                      {simpleValidator.current.message(
                        "Asset Current Price",
                        assetsDetails.asset_purchase_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please enter Investment amount ",
                            min: "Please enter valid Investment amount ",
                          },
                        }
                      )}
                    </div>
                  </div>
                </div>

                <div className="row py-md-2 align-items-center">
                  <div className="col-md-5 mt-md-0 mt-2">
                    <div className="dark-label">
                      <Form.Label>Frequency*</Form.Label>
                      <div className="d-md-flex pt-4" style={{ clear: "both" }}>
                        <FintooRadio2
                          checked={assetsDetails.asset_frequency == "1"}
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_frequency: "1",
                              isEditable: true,
                            });
                          }}
                          title="Monthly"
                        />

                        <FintooRadio2
                          checked={assetsDetails.asset_frequency == "2"}
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_frequency: "2",
                              isEditable: true,
                            });
                          }}
                          title="Quarterly"
                        />

                        <FintooRadio2
                          checked={assetsDetails.asset_frequency == "3"}
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_frequency: "3",
                              isEditable: true,
                            });
                          }}
                          title="Half Yearly"
                        />

                        <FintooRadio2
                          checked={assetsDetails.asset_frequency == "4"}
                          onClick={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_frequency: "4",
                              isEditable: true,
                            });
                          }}
                          title="Yearly"
                        />
                      </div>
                    </div>
                  </div>

                  {assetsDetails.asset_frequency == "4" ? (
                    <>
                      <div className="col-md-5 mt-md-4">
                        <div className="d-md-flex ">
                          <Form.Label
                            className=" "
                            // style={{
                            //   whiteSpace: "nowrap",
                            // }}
                          >
                            Installment for the year is Paid or not*
                          </Form.Label>
                          <div className="d-flex ms-md-5">
                            <div>No</div>
                            <Switch
                              onChange={(v) =>
                                setAssetsDetails({
                                  ...assetsDetails,
                                  installment_ispaid: v,
                                })
                              }
                              checked={assetsDetails.installment_ispaid}
                              className="react-switch px-2"
                              activeBoxShadow="0 0 2px 3px #424242"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={40}
                              onColor="#042b62"
                              offColor="#d8dae5"
                            />
                            <div>Yes</div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row py-md-2 mt-md-2 mt-4">
                  <div className="col-md-5">
                    {assetsDetails.asset_sub_category_id == "81" && (
                      <div className="material">
                        <Form.Label>
                          Rate Of Return (%)* : {assetsDetails.asset_ror}
                        </Form.Label>
                        <div
                          className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                            } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                        >
                          <Slider
                            key={"sld-" + assetsDetails.asset_sub_category_id}
                            min={0}
                            max={20}
                            step={0.05}
                            value={assetsDetails.asset_ror}
                            onChange={(v) =>
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_ror: v,
                                isEditable: true,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    {assetsDetails.asset_sub_category_id == "118" && (
                      <div className="material">
                        <Form.Label>
                          Rate Of Return (%)* : {assetsDetails.asset_ror}
                        </Form.Label>
                        <div
                          className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                            } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                        >
                          <Slider
                            key={"sld-" + assetsDetails.asset_sub_category_id}
                            min={0}
                            max={20}
                            step={0.05}
                            value={
                              assetsDetails.asset_ror == 0
                                ? 10
                                : assetsDetails.asset_ror
                            }
                            onChange={(v) =>
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_ror: v,
                                isEditable: true,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    {(assetsDetails.asset_sub_category_id == "85" ||
                      assetsDetails.asset_sub_category_id == "86" ||
                      assetsDetails.asset_sub_category_id == "120") && (
                        <div className="material">
                          <Form.Label>
                            Rate Of Return (%)* : {assetsDetails.asset_ror}
                          </Form.Label>
                          <div
                            className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                              } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                          >
                            <Slider
                              key={"sld-" + assetsDetails.asset_sub_category_id}
                              min={0}
                              max={20}
                              step={0.05}
                              // defaultValue={assetsDetails.asset_ror}
                              value={assetsDetails.asset_ror == 0
                                ? 6.8
                                : assetsDetails.asset_ror}
                              onChange={(v) => {
                                if (v != 0) {
                                  setAssetsDetails({
                                    ...assetsDetails,
                                    asset_ror: v,
                                    isEditable: true,
                                  });

                                }
                              }
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {assetsDetails.asset_sub_category_id == "118" ? null : (
                  <div className="row py-md-2">
                    <div className="col-md-5">
                      <div className="dark-label">
                        <Form.Label>Maturity Date*</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            // paddingTop: "19px",
                          }}
                        >
                          <ReactDatePicker
                            select_date={moment(
                              assetsDetails.asset_maturity_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            setDate={(date) => {
                              setDate(date, "maturityDate");
                            }}
                            minDate={moment().add(1, "days").toDate()}
                            maxDate={moment().add(100, "years").toDate()}
                            className="pt-4"
                          />
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Maturity Date",
                            assetsDetails.asset_maturity_date,
                            "required",
                            {
                              messages: {
                                required: "Please select maturity date ",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="dark-label">
                        <div className="d-flex" style={{ clear: "both" }}>
                          <FintooRadio2
                            checked={assetsDetails.asset_isperpetual == "3"}
                            onClick={() => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_isperpetual: "3",
                                asset_maturity_date: moment(retirementDate)
                                  .add(retirementDate, "y")
                                  .format("DD/MM/YYYY"),
                              });
                            }}
                            title="Upto Retirement Age"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row py-md-2">
                  <div className="col-md-5 mt-md-0 mt-3 custom-input">
                    <div className={`form-group mt-md-1 inputData`}>
                      <span>
                        <input type="Number" name="asset_units"
                          value={assetsDetails.asset_maturity_amt}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Maturity Amount (₹)*</label>
                      </span>
                      <span className="info-hover-box">
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Auto calculated on the basis of Purchase Amount and Rate
                          Of Return. You can also edit it and enter your desired
                          maturity amount.
                        </span>
                      </span>
                    </div>
                    {simpleValidator.current.message(
                      "Maturity Amount",
                      assetsDetails.asset_maturity_amt,
                      "required|numeric|min:1,num",
                      {
                        messages: {
                          required: "Please enter maturity amount ",
                          min: "Please enter valid maturity amount ",
                        },
                      }
                    )}
                  </div>
                </div>

                <div className="row py-md-2 mt-md-4">
                  <div className="col-md-8">
                    <div className="d-md-flex">
                      <Form.Label className=" ">
                        Consider This Asset In Automated Linkage*
                      </Form.Label>
                      <span className="info-hover-left-box ms-md-4">
                        <span Name="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Select a goal below to map this investment with a goal of
                          your choice. Otherwise, Fintoo will link it automatically
                          with your high priority goal. In case, you do not wish to
                          utilize this investment for any goal, select "NO".
                        </span>
                      </span>
                      <div className="d-flex ms-md-4">
                        <div>No</div>
                        <Switch
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_islinkable: v,
                            })
                          }
                          checked={assetsDetails.asset_islinkable}
                          className="react-switch px-2"
                          activeBoxShadow="0 0 2px 3px #424242"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                          onColor="#042b62"
                          offColor="#d8dae5"
                        />
                        <div>Yes</div>
                      </div>
                    </div>
                  </div>
                </div>
                {assetsDetails.asset_islinkable == true && (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-8 mt-md-2 mt-3">
                        <div className="d-md-flex">
                          <Form.Label className="link_asset_style">
                            Link This Investment Asset to Goal
                          </Form.Label>
                          <span
                            className="ms-md-4 info-hover-left-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              You can only assign goals which are prior to the end
                              date of the SIP, if any
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-md-2 mt-2">
                        <div className="material">
                          <div
                            className="m-0 btn-sm default-btn gradient-btn save-btn"
                            onClick={() => setGoalSelected(true)}
                          >
                            Select Goals
                          </div>
                          <br></br>
                          <br></br>

                          {selectedGoals ? (
                            <div className="d-flex" style={{ textAlign: "left!important" }}>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Selected Goals : </b>
                              </div>
                              <div className="ms-1">{selectedGoals}</div>
                            </div>
                          ) : (
                            ""
                          )}
                          {isGoalSelected ? (
                            <GoalsDropdown
                              setGoalSelected={setGoalSelected}
                              goals={goalData}
                              unchangedgoaldata={unchangedgoaldata}
                              closeModal={closeModal}
                              selectGoals={selectGoals}
                              selectedGoals={selectedGoals}
                              selectedGoalIdArray={selectedGoalIdArray}
                              selectedGoalsId={selectedGoalsId}
                              setPriorityArray={setPriorityArray}
                              selectedPriorityArray={selectedPriorityArray}
                              setAutoMatedGoal={setAutoMatedGoal}
                              isAutoMatedGoal={isAutoMatedGoal}
                              setGoalLink={setGoalLink}
                              type={"Asset"}
                              asset_maturity_date={
                                assetsDetails.asset_maturity_date
                              }
                              isGoalFilter={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                              isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                            ></GoalsDropdown>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div className="d-flex justify-content-center">
                          <a
                            href={process.env.PUBLIC_URL + "/datagathering/goals"}
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </a>

                          {addForm && (
                            <button
                              onClick={(e) => handleDebtSubmit(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Save & Add More
                            </button>
                          )}
                          {props.updateForm && (
                            <div>
                              <button
                                onClick={(e) => handleDebtCancel(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={(e) => handleDebtUpdate(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Update
                              </button>
                            </div>
                          )}

                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => props.setTab("tab2")}
                          >
                            <span className="hover-text" style={{ maxWidth: 100 }}>
                              Continue&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}

        {(!props.assetEditId || props.assetEditId == "") &&
          assetsDetails.asset_sub_category_id == 77 && (
            <div>
              {assetsDetails.asset_sub_category_id == 77 && (
                <div>
                  <div className="d-md-flex justify-content-center">
                    <DgRoundedButton
                      active={selectedButton == "Upload Statement"}
                      onClick={() => {
                        setSelectedButton("Upload Statement");
                        setSelectedExtraOption("Upload Statement");
                      }}
                      title="Upload Statement"
                    />
                    <DgRoundedButton
                      active={selectedButton == "Manual Entry"}
                      onClick={() => {
                        setSelectedButton("Manual Entry");
                        setSelectedExtraOption("Manual Entry");
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_name: " ",
                        }));
                      }}
                      title="Manual Entry"
                    />
                    <DgRoundedButton
                      active={selectedButton == "Link your Holdings"}
                      onClick={() => {
                        setSelectedButton("Link your Holdings");
                        setSelectedExtraOption("Link your Holdings");
                      }}
                      title="Link your Holdings"
                    />
                  </div>
                </div>
              )}
              {assetsDetails.asset_sub_category_id == 77 &&
                selectedExtraOption == "Upload Statement" && (
                  <form
                    encType="multipart/form-data"
                    method="post"
                    action="http://localhost/fileupload.php"
                    onSubmit={handleUploadSubmit}
                  >
                    <div className="col-md-10" value="CAMS">
                      <h4>Cams / Karvy</h4>
                      <ol>
                        <li>
                          Visit{" "}
                          <a
                            style={{ color: "green" }}
                            target="_new"
                            href="https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement"
                          >
                            <u>CAMS</u>
                          </a>{" "}
                          website.
                        </li>
                        <li>
                          Go to Statements &gt;&gt; View More then select{" "}
                          <b>CAS - CAMS+KFintech</b>
                        </li>
                        <li>
                          Choose 'Statement Type' as Detailed{" "}
                          <b>(Includes transaction listing)</b>
                        </li>
                        <li>
                          Choose 'Period' as <b>Specific Period</b> of your choice
                        </li>
                        <li>
                          Select <b>“01-01-1990” in “From Date”</b>
                        </li>
                        <li>
                          Choose 'Folio Listing' as{" "}
                          <b>without Zero Balance Folios</b>
                        </li>
                        <li>
                          Enter your <b>Email and choose password</b>/key for your
                          CAMS statement
                        </li>
                        <li>
                          You will get your <b>CAMS statement</b> on your email
                          within 10 minutes
                        </li>
                      </ol>
                    </div>
                    <div className="col-md-10">
                      <p>
                        <b>Note</b>: CAMS PDF can be uploaded one time a day ( Per
                        PAN )
                      </p>
                      <p>
                        The statement uploaded i.e. CAMS doesn't fetch the
                        existing SIP's, Post statement upload do edit the existing
                        SIP's in the funds fetched in the system.
                      </p>
                    </div>
                    <div>
                      <DgDragDrop2
                        className="iconupload"
                        value={dropFiles}
                        onFilesSelected={handleFilesSelected}
                      />
                      {simpleValidator.current.message(
                        "Password",
                        dropFiles,
                        "required",
                        {
                          messages: {
                            required:
                              "Please select atleast one document to upload",
                          },
                        }
                      )}
                    </div>
                    <div className="col-md-12 custom-input mt-5">
                      <div className={`form-group ${docPassword ? "inputData" : null} `}>
                        <input type="text" name="Password" value={docPassword}
                          onChange={(e) => {
                            setDocPassword(e.target.value);
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Password*</label>
                      </div>
                      {simpleValidator.current.message(
                        "Password",
                        docPassword,
                        "required",
                        { messages: { required: "Please enter the password" } }
                      )}
                    </div>
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container">
                            <div className="d-flex justify-content-center">
                              <a
                                href={process.env.PUBLIC_URL + "/datagathering/goals"}
                              >
                                <div className="previous-btn form-arrow d-flex align-items-center">
                                  <FaArrowLeft />
                                  <span className="hover-text">
                                    &nbsp;Previous
                                  </span>
                                </div>
                              </a>

                              {props.addForm && (
                                <button
                                  onClick={(e) => handleUploadSubmit(e)}
                                  className="default-btn gradient-btn save-btn"
                                >
                                  Save & Add More
                                </button>
                              )}

                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => props.setTab("tab2")}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Continue&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              {assetsDetails.asset_sub_category_id == 77 &&
                selectedExtraOption == "Manual Entry" && (
                  <form noValidate="novalidate" name="goldassetform" className="mt-2">
                    <div className="row d-flex align-items-center py-md-2">
                      <div className="col-md-5 ">
                        <div className="material">
                          <Form.Label

                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Start Typing To Search For Your Debt Mutual Funds*{" "}
                          </Form.Label>
                          {props.debtfunds && props.debtfunds.length > 0 && (
                            <Select
                              isDisabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                              classNamePrefix="sortSelect"
                              components={{ Input }}
                              autoComplete="new-password"
                              isSearchable={true}
                              styles={customStyles}
                              options={debtFundsData}
                              onChange={handleDebtFundSelection}
                              value={debtFundsData
                                .filter(
                                  (v) => v.label == assetsDetails.asset_name
                                )}
                            />
                          )}
                        </div>
                        {simpleValidator.current.message(
                          "Asset Name",
                          assetsDetails.asset_name,
                          "required",
                          { messages: { required: "Please select fund name" } }
                        )}
                      </div>
                      <div
                        className={`col-md-5 ${assetsDetails.asset_sub_category_id == 77
                          ? "mt-md-1"
                          : ""
                          }`}
                      >
                        <div className="material mt-md-0 mt-5">
                          <Form.Label>Who Is This Investment For*</Form.Label>

                          {familyData && (
                            <Select
                              disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                              classNamePrefix="sortSelect"
                              isSearchable={false}
                              styles={customStyles}
                              options={familyData}
                              onChange={(e) =>
                                // setAssetsDetails((prev) => ({
                                //   ...prev,
                                //   asset_member_id: e.value,
                                // }))
                                handleAssetMemberMaturityDate(e)
                              }
                              value={familyData.filter(
                                (v) => v.value == assetsDetails.asset_member_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-8 mt-3">
                        <div className="d-md-flex">
                          <Form.Label className=" ">
                            Is The Equity One Time Or Recurring?*
                          </Form.Label>
                          <div className="d-flex ms-md-4">
                            <div>One Time</div>
                            <Switch
                              disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                              onChange={(v) => {
                                // setAssetsDetails((prev) => ({
                                //   ...prev,
                                //   asset_isrecurring: v,
                                //   asset_purchase_amount: " ",
                                // }));
                                handleOnetimeButtonChange(v)
                              }}
                              checked={assetsDetails.asset_isrecurring}
                              className="react-switch px-2"
                              activeBoxShadow="0 0 2px 3px #424242"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={40}
                              onColor="#042b62"
                              offColor="#d8dae5"
                            />
                            <div>Recurring</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {assetsDetails.asset_isrecurring == true && (
                      <>
                        <div className="row d-flex align-items-center py-md-2">
                          <div className="col-md-5 mt-md-0 mt-4">
                            <div className="material  " >
                              <Form.Label>SIP Start Date</Form.Label>
                              <div
                                className="dt-conbx"
                                style={{
                                  borderBottom: "1px solid #dadada",
                                  paddingTop: "0px",
                                }}
                              >
                                <ReactDatePicker
                                  disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                  select_date={moment(
                                    assetsDetails.asset_purchase_date,
                                    "DD/MM/YYYY"
                                  ).toDate()}
                                  setDate={(date) => {
                                    setDate(date, "assetPurchaseDate");
                                  }}
                                  minDate={""}
                                  maxDate={moment().toDate()}
                                  className="pt-4"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 custom-input">
                            <div className={`form-group mt-md-0 mt-2 ${assetsDetails.asset_amount ? "inputData" : null} `} style={{ paddingTop: "15px" }}>
                              <input type="text" name="asset_name" maxLength={35}
                                value={assetsDetails.asset_amount === 0 ? "" : assetsDetails.asset_amount}
                                readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                  }));
                                }} required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">SIP Amount (₹)*</label>
                            </div>
                            {simpleValidator.current.message(
                              "SIP Amount",
                              assetsDetails.asset_amount,
                              "required|numeric|min:0,num",
                              {
                                messages: {
                                  required: "Please enter SIP amount",
                                  min: "Please enter valid SIP amount",
                                },
                              }
                            )}
                          </div>
                        </div>
                        <div className="row py-md-2">

                          <div className="col-md-5 custom-input">
                            <div className={`form-group mt-md-0 mt-3 ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                              <input type="Number" name="asset_units_2"
                                placeholder=""
                                className="shadow-none"
                                value={assetsDetails.asset_purchase_amount}
                                readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                    isEditable: true,
                                  }));
                                }} required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">Total Invested Amount (₹)*</label>
                            </div>

                            {simpleValidator.current.message(
                              "Asset Purchase Amount",
                              assetsDetails.asset_purchase_amount,
                              "required|numeric|min:1,num",
                              {
                                messages: {
                                  required: "Please add Invested amount ",
                                  min: "Please enter valid Invested amount ",
                                },
                              }
                            )}
                          </div>
                          <div className="col-md-5 custom-input">
                            <div className={`form-group mt-md-0 mt-3 ${assetsDetails.asset_units ? "inputData" : null} `}>
                              <input type="Number" name="asset_units"
                                value={assetsDetails.asset_units}
                                readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                  }));
                                }} required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">No. Of Units*</label>
                            </div>

                            {simpleValidator.current.message(
                              "Asset Units",
                              assetsDetails.asset_units,
                              "required|numeric|min:1,num",
                              {
                                messages: {
                                  required: "Please add units ",
                                  min: "Please enter valid units ",
                                },
                              }
                            )}
                          </div>
                        </div>
                        <div className="row py-md-2">
                          <div className="col-md-5 custom-input">
                            <div className={`form-group mt-md-0 mt-3 ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                              <input type="text" name="asset_purchase_amount"
                                placeholder=""
                                className="shadow-none"
                                value={assetsDetails.asset_current_unit_price}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                    isEditable: true,
                                  }));
                                }}
                                readOnly required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">Current Price (₹)*</label>
                            </div>
                            {simpleValidator.current.message(
                              "Current Price",
                              assetsDetails.asset_purchase_amount,
                              "required",
                              { messages: { required: "Please enter Current Price" } }
                            )}
                          </div>
                          <div className="col-md-5 custom-input mt-md-0 mt-3 ">
                            <div className={`form-group   ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                              <span>
                                <input type="text" name="asset_units_2"
                                  placeholder=""
                                  className="shadow-none"
                                  value={assetsDetails.totalinvestedvalue}
                                  onChange={(e) => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      totalinvestedvalue: e.target.value,
                                    }));
                                  }}
                                  readOnly autoComplete="off" />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label for="name">Current value (₹)</label>
                              </span>
                              <span
                                className="info-hover-box float-right"
                                style={{
                                  position: "relative !important",
                                }}
                              >
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  Auto calculated by SIP amount and current NAV.
                                </span>
                              </span>
                            </div>

                          </div>
                        </div>
                        <div className="row py-md-2 mt-2">
                          <div className="col-md-5 mt-md-0 mt-3 ">
                            <div className="material">
                              <Form.Label>SIP End Date*</Form.Label>
                              <div
                                className="dt-conbx"
                                style={{
                                  borderBottom: "1px solid #dadada",
                                  // paddingTop: "19px",
                                }}
                              >
                                <ReactDatePicker
                                  select_date={moment(
                                    assetsDetails.asset_mf_end_date,
                                    "DD/MM/YYYY"
                                  ).toDate()}
                                  setDate={(date) => {
                                    setDate(date, "assetendDate");
                                  }}
                                  minDate={moment().toDate()}
                                  maxDate={""}
                                  className="pt-4"
                                />
                              </div>
                              {simpleValidator.current.message(
                                "SIP End Date",
                                assetsDetails.asset_mf_end_date,
                                "required",
                                {
                                  messages: {
                                    required: "Please add SIP end date ",
                                  },
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-9">
                            <div className="">
                              <div
                                className="d-md-flex pt-2"
                                style={{ clear: "both" }}
                              >
                                <FintooRadio2
                                  checked={assetsDetails.asset_isperpetual == "3"}
                                  onClick={() => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      asset_isperpetual: "3",
                                      asset_mf_end_date: moment(retirementDate)
                                        .add(retirementDate, "y")
                                        .format("DD/MM/YYYY"),
                                    }));
                                  }}
                                  title="Upto Retirement Age"
                                />
                                <FintooRadio2
                                  checked={assetsDetails.asset_isperpetual == "2"}
                                  onClick={() => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      asset_isperpetual: "2",
                                      asset_mf_end_date: moment(
                                        lifeExpectancyDate
                                      )
                                        .add(lifeExpectancyDate, "y")
                                        .format("DD/MM/YYYY"),
                                    }));
                                  }}
                                  title="Upto Life Expectancy Age"
                                />
                                <FintooRadio2
                                  checked={assetsDetails.asset_isperpetual == "1"}
                                  onClick={() => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      asset_isperpetual: "1",
                                      asset_mf_end_date: moment(
                                        "2099-12-31",
                                        "YYYY-MM-DD"
                                      ).format("DD/MM/YYYY"),
                                    }));
                                  }}
                                  title="Perpetual"
                                />
                                <span className="info-hover-box">
                                  <span className="icon">
                                    <img
                                      alt="More information"
                                      src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                    />
                                  </span>
                                  <span className="msg">
                                    Perpetual SIPs refer to those with no tenure
                                    end date. Most fund houses assume such SIPs to
                                    continue till 2099 and it can be only linked
                                    to goals after 2099. Advice to select specific
                                    end date based on goals
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {assetsDetails.asset_isrecurring == false && (
                      <>
                        <div className="row d-flex align-items-center py-md-2 mt-2">
                          <div className="col-md-5 mt-md-0 mt-4">
                            <div className="material " >
                              <Form.Label>Date of Purchase</Form.Label>
                              <div
                                className="dt-conbx"
                                style={{
                                  borderBottom: "1px solid #dadada",
                                  paddingTop: "0px",
                                }}
                              >
                                <ReactDatePicker
                                  readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                  select_date={moment(
                                    assetsDetails.asset_purchase_date,
                                    "DD/MM/YYYY"
                                  ).toDate()}
                                  setDate={(date) => {
                                    setDate(date, "assetPurchaseDate");
                                  }}
                                  minDate={""}
                                  maxDate={moment().toDate()}
                                  className="pt-2"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-5 mt-md-0 mt-3  custom-input">
                            <div className={`form-group  ${assetsDetails.asset_units ? "inputData" : null} `} style={{ paddingTop: "15px" }}>
                              <input type="Number" name="asset_units_1"
                                value={assetsDetails.asset_units}
                                readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                  }));
                                }} required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">No. Of Units*</label>
                            </div>
                            {simpleValidator.current.message(
                              "Asset Units",
                              assetsDetails.asset_units,
                              "required|numeric|min:1,num",
                              {
                                messages: {
                                  required: "Please add units ",
                                  min: "Please enter valid units ",
                                },
                              }
                            )}
                          </div>
                        </div>

                        <div className="row py-md-2">
                          <div className="col-md-5 custom-input mt-md-0 mt-3">
                            <div className={`form-group  ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                              <input type="Number" name="asset_purchase_amount"
                                value={assetsDetails.asset_purchase_amount}
                                disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                    isEditable: true,
                                  }));
                                }} required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">Avg. buy Price (₹)*</label>
                            </div>
                            {simpleValidator.current.message(
                              "asset_purchase_amount",
                              assetsDetails.asset_purchase_amount,
                              "required|numeric|min:1,num",
                              {
                                messages: {
                                  required: "Please add buy amount ",
                                  min: "Please enter valid buy amount ",
                                },
                              }
                            )}
                          </div>
                          <div className="col-md-5 mt-md-0 mt-3  custom-input">
                            <div className={`form-group ${assetsDetails.totalpurchasevalue ? "inputData" : null} `}>
                              <span>
                                <input type="text" name="asset_units_1"
                                  value={assetsDetails.totalpurchasevalue}
                                  onChange={(e) => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      totalpurchasevalue: e.target.value,
                                    }));
                                  }}
                                  readOnly autoComplete="off" />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label for="name">Invested Amount (₹)</label>

                              </span>
                              <span className="info-hover-box">
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  Auto Calculated by No Of Units and Avg. Buy
                                  Price
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row py-md-2">
                          <div className="col-md-5 mt-md-0 mt-1 custom-input"> 
                            <div className={`form-group  ${assetsDetails.asset_current_unit_price ? "inputData" : null} `}>
                              <input type="text" name="asset_purchase_amount"
                                value={assetsDetails.asset_current_unit_price}
                                onChange={(e) => {
                                  setAssetsDetails((prev) => ({
                                    ...prev,
                                    asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                    isEditable: true,
                                  }));
                                }}
                                readOnly required autoComplete="off" />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">Current Price (₹)*</label>
                            </div>
                            {simpleValidator.current.message(
                              "Asset Current Price",
                              assetsDetails.asset_current_unit_price,
                              "required",
                              {
                                messages: {
                                  required: "Please add current price",
                                },
                              }
                            )}
                          </div>
                          <div className="col-md-5 mt-md-0 mt-3  custom-input">
                            <div className={`form-group  ${assetsDetails.totalinvestedvalue ? "inputData" : null} `}>
                              <span>
                                <input type="text" name="asset_purchase_amount"
                                  value={assetsDetails.totalinvestedvalue}
                                  onChange={(e) => {
                                    setAssetsDetails((prev) => ({
                                      ...prev,
                                      totalinvestedvalue: e.target.value,
                                    }));
                                  }}
                                  readOnly autoComplete="off" />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label for="name">Current Value (₹)</label>
                              </span>
                              <span className="info-hover-box">
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  Auto Calculated by No Of Units and Current Price
                                </span>
                              </span>
                            </div>

                          </div>
                        </div>
                      </>
                    )}

                    <div className="row py-md-2 mt-md-4">
                      <div className="col-md-8">
                        <div className="d-md-flex mt-md-0 mt-3">
                          <Form.Label className=" ">
                            Consider This Asset In Automated Linkage*
                          </Form.Label>
                          <span className="info-hover-left-box ms-md-4">
                            <span Name="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Select a goal below to map this investment with a
                              goal of your choice. Otherwise, Fintoo will link it
                              automatically with your high priority goal. In case,
                              you do not wish to utilize this investment for any
                              goal, select "NO".
                            </span>
                          </span>
                          <div className="d-flex ms-md-4 ">
                            <div>No</div>
                            <Switch
                              onChange={(v) =>
                                setAssetsDetails((prev) => ({
                                  ...prev,
                                  asset_islinkable: v,
                                }))
                              }
                              checked={assetsDetails.asset_islinkable}
                              className="react-switch px-2"
                              activeBoxShadow="0 0 2px 3px #424242"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={40}
                              onColor="#042b62"
                              offColor="#d8dae5"
                            />
                            <div>Yes</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {assetsDetails.asset_islinkable == true && (
                      <>
                        <div className="row py-md-2">
                          <div className="col-md-8 mt-md-2 mt-3">
                            <div className="d-md-flex mt-md-0 mt-3">
                              <Form.Label className="link_asset_style">
                                Link This Investment Asset to Goal

                              </Form.Label>
                              <span
                                className="ms-md-4 ms-2 info-hover-left-box float-right"
                                style={{
                                  position: "relative !important",
                                }}
                              >
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  You can only assign goals which are prior to the
                                  end date of the SIP, if any
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-md-2 mt-2">
                            <div className="material">
                              <div
                                className="m-0 btn-sm default-btn gradient-btn save-btn"
                                onClick={() => setGoalSelected(true)}
                              >
                                Select Goals
                              </div>
                              <br></br>
                              <br></br>

                              {selectedGoals ? (
                                <div className="d-flex" style={{ textAlign: "left!important" }}>
                                  <div>
                                    <b>Selected Goals : </b>
                                  </div>
                                  <div className="ms-1">{selectedGoals}</div>
                                </div>
                              ) : (
                                ""
                              )}
                              {isGoalSelected ? (
                                <GoalsDropdown
                                  setGoalSelected={setGoalSelected}
                                  goals={goalData}
                                  unchangedgoaldata={unchangedgoaldata}
                                  closeModal={closeModal}
                                  selectGoals={selectGoals}
                                  selectedGoals={selectedGoals}
                                  selectedGoalIdArray={selectedGoalIdArray}
                                  selectedGoalsId={selectedGoalsId}
                                  setPriorityArray={setPriorityArray}
                                  selectedPriorityArray={selectedPriorityArray}
                                  setAutoMatedGoal={setAutoMatedGoal}
                                  isAutoMatedGoal={isAutoMatedGoal}
                                  setGoalLink={setGoalLink}
                                  type={"Asset"}
                                  asset_maturity_date={
                                    assetsDetails.asset_mf_end_date
                                  }
                                  isGoalFilter={assetsDetails.asset_isrecurring == '1' ? '1' : '0'}
                                  isAssetRecurring={assetsDetails.asset_isrecurring == '1' ? '1' : '0'}
                                ></GoalsDropdown>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container">
                            <div className="d-flex justify-content-center">
                              <a
                                href={process.env.PUBLIC_URL + "/datagathering/goals"}
                              >
                                <div className="previous-btn form-arrow d-flex align-items-center">
                                  <FaArrowLeft />
                                  <span className="hover-text">
                                    &nbsp;Previous
                                  </span>
                                </div>
                              </a>

                              {addForm && (
                                <button
                                  onClick={(e) => handleDebtSubmit(e)}
                                  className="default-btn gradient-btn save-btn"
                                >
                                  Save & Add More
                                </button>
                              )}
                              {props.updateForm && (
                                <div>
                                  <button
                                    onClick={(e) => handleDebtCancel(e)}
                                    className="default-btn gradient-btn save-btn"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => handleDebtUpdate(e)}
                                    className="default-btn gradient-btn save-btn"
                                  >
                                    Update
                                  </button>
                                </div>
                              )}

                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => props.setTab("tab2")}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Continue&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              {assetsDetails.asset_sub_category_id == 77 &&
                selectedExtraOption == "Link your Holdings" && (
                  <LinkYourHoldingsDG
                    customStyles={customStyles}
                    session={session}
                  />
                )}
            </div>
          )}

        {props.assetEditId && assetsDetails.asset_sub_category_id == 77 && (
          <div>
            <form noValidate="novalidate" name="goldassetform">
              <div className="row py-md-2">
                <div className="col-md-5">
                  <div className="material">
                    <Form.Label
                      style={{
                        whiteSpace: "normal",
                      }}
                    >
                      {" "}
                      Start Typing To Search For Your Debt Mutual Funds*{" "}
                    </Form.Label>
                    {props.debtfunds && props.debtfunds.length > 0 && (
                      <Select
                        isDisabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                        classNamePrefix="sortSelect"
                        components={{ Input }}
                        autoComplete="new-password"
                        isSearchable={true}
                        styles={customStyles}
                        options={debtFundsData}
                        onChange={handleDebtFundSelection}
                        value={debtFundsData
                          .filter((v) => v.label == assetsDetails.asset_name)}
                      />
                    )}
                  </div>
                  {simpleValidator.current.message(
                    "Asset Name",
                    assetsDetails.asset_name,
                    "required",
                    { messages: { required: "Please select fund name" } }
                  )}
                </div>
                <div
                  className={`col-md-5 ${assetsDetails.asset_sub_category_id == 77 ? "mt-md-1" : ""
                    }`}
                >
                  <div className="material mt-md-0 mt-3">
                    <Form.Label>Who Is This Investment For*</Form.Label>
                    {familyData && (
                      <Select
                        isDisabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_member_id: e.value,
                          }))
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-8 mt-2">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Is The Equity One Time Or Recurring?*
                    </Form.Label>
                    <div className="d-flex ms-md-4">
                      <div>One Time</div>
                      <Switch
                        disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                        onChange={(v) => {
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_isrecurring: v,
                            asset_purchase_amount: " ",
                          }));
                        }}
                        checked={assetsDetails.asset_isrecurring}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Recurring</div>
                    </div>
                  </div>
                </div>
              </div>

              {assetsDetails.asset_isrecurring == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-5 mt-md-0 mt-4">
                      <div className="material ">
                        <Form.Label>SIP Start Date</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            paddingTop: "0px",
                          }}
                        >
                          <ReactDatePicker
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            select_date={moment(
                              assetsDetails.asset_purchase_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            setDate={(date) => {
                              setDate(date, "assetPurchaseDate");
                            }}
                            minDate={""}
                            maxDate={moment().toDate()}
                            className="pt-4"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 ">
                      <div className="material">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="SIP Amount (₹)*"
                          className="material"
                        >
                          <Form.Control
                            disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            type="Number"
                            placeholder=""
                            className="shadow-none"
                            value={assetsDetails.asset_amount === 0 ? "" : assetsDetails.asset_amount}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              }));
                            }}
                          />
                        </FloatingLabel>
                        {simpleValidator.current.message(
                          "SIP Amount",
                          assetsDetails.asset_amount,
                          "required|numeric|min:0,num",
                          {
                            messages: {
                              required: "Please enter SIP amount",
                              min: "Please enter valid SIP amount",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center py-md-2">
                    <div className="col-md-5 custom-input mt-md-0 mt-2">
                      <div className={`form-group ${assetsDetails.asset_purchase_amount ? "inputData" : null} `}>
                        <input type="Number" name="asset_units_2"
                          placeholder=""
                          className="shadow-none"
                          readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                          value={assetsDetails.asset_purchase_amount}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              isEditable: true,
                            }));
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Total Invested Amount (₹)*</label>
                      </div>
                      {simpleValidator.current.message(
                        "Asset Purchase Amount",
                        assetsDetails.asset_purchase_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add Invested amount ",
                            min: "Please enter valid Invested amount ",
                          },
                        }
                      )}
                    </div>
                    <div className="col-md-5 custom-input">
                      <div className={`form-group mt-md-0 mt-3 ${assetsDetails.asset_units ? "inputData" : null} `}>
                        <input type="Number" name="asset_units_2"
                          value={assetsDetails.asset_units}
                          readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            }));
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">No. Of Units*</label>
                      </div>

                      {simpleValidator.current.message(
                        "Asset Units",
                        assetsDetails.asset_units,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add units ",
                            min: "Please enter valid units ",
                          },
                        }
                      )}
                    </div>
                  </div>
                  <div className="row py-md-2">
                    <div className="col-md-5 mt-md-0 mt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Current Price (₹)*"
                        className="mb-3 material"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="shadow-none"
                          value={assetsDetails.asset_current_unit_price}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              isEditable: true,
                            }));
                          }}
                          readOnly
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-5  ">
                      <div className=" d-flex justify-content-between flex-grow-1 mt-md-0 mt-3">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Current value (₹)"
                          className="mb-3 material d-flex"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Current value (₹)"
                            value={assetsDetails.totalinvestedvalue}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                totalinvestedvalue: e.target.value,
                              }));
                            }}
                            readOnly
                          />
                          <span
                            className="info-hover-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto calculated by SIP amount and current NAV.
                            </span>
                          </span>
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row py-md-2">
                    <div className="col-md-5 ">
                      <div className="material">
                        <Form.Label>SIP End Date*</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            // paddingTop: "19px",
                          }}
                        >
                          <ReactDatePicker
                            select_date={moment(
                              assetsDetails.asset_mf_end_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            setDate={(date) => {
                              setDate(date, "assetendDate");
                            }}
                            minDate={moment().toDate()}
                            maxDate={""}
                            className="pt-4"
                          />
                        </div>
                        {simpleValidator.current.message(
                          "SIP End Date",
                          assetsDetails.asset_mf_end_date,
                          "required",
                          { messages: { required: "Please add SIP end date " } }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9 mt-2">
                      <div className="">
                        <div className="d-flex pt-2" style={{ clear: "both" }}>
                          <FintooRadio2
                            checked={assetsDetails.asset_isperpetual == "3"}
                            onClick={() => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_isperpetual: "3",
                                asset_mf_end_date: moment(retirementDate)
                                  .add(retirementDate, "y")
                                  .format("DD/MM/YYYY"),
                              }));
                            }}
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            title="Upto Retirement Age"
                          />
                          <FintooRadio2
                            checked={assetsDetails.asset_isperpetual == "2"}
                            onClick={() => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_isperpetual: "2",
                                asset_mf_end_date: moment(lifeExpectancyDate)
                                  .add(lifeExpectancyDate, "y")
                                  .format("DD/MM/YYYY"),
                              }));
                            }}
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            title="Upto Life Expectancy Age"
                          />
                          <FintooRadio2
                            checked={assetsDetails.asset_isperpetual == "1"}
                            onClick={() => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_isperpetual: "1",
                                asset_mf_end_date: moment(
                                  "2099-12-31",
                                  "YYYY-MM-DD"
                                ).format("DD/MM/YYYY"),
                              }));
                            }}
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            title="Perpetual"
                          />
                          <span className="info-hover-box">
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Perpetual SIPs refer to those with no tenure end
                              date. Most fund houses assume such SIPs to continue
                              till 2099 and it can be only linked to goals after
                              2099. Advice to select specific end date based on
                              goals
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {assetsDetails.asset_isrecurring == false && (
                <>
                  <div className="row d-flex align-items-center py-md-2">
                    <div className="col-md-5 mt-md-0 mt-4">
                      <div className="material">
                        <Form.Label>Date of Purchase</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            paddingTop: "0px",
                          }}
                        >
                          <ReactDatePicker
                            select_date={moment(
                              assetsDetails.asset_purchase_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                            setDate={(date) => {
                              setDate(date, "assetPurchaseDate");
                            }}
                            minDate={""}
                            maxDate={moment().toDate()}
                            className="pt-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 custom-input">
                      <div className={`form-group mt-2 ${assetsDetails.asset_units ? "inputData" : null} `}>
                        <input type="Number" name="asset_units_3"
                          value={assetsDetails.asset_units}
                          readOnly={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            }));
                          }} required autoComplete="off" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">No. Of Units*</label>
                      </div>
                      {simpleValidator.current.message(
                        "Asset Units",
                        assetsDetails.asset_units,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add units ",
                            min: "Please enter valid units ",
                          },
                        }
                      )}
                    </div>
                  </div>

                  <div className="row py-md-2">
                    <div className="col-md-5 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Avg. buy Price (₹)*"
                        className="mb-3 material"
                      >
                        <Form.Control
                          placeholder="Avg. buy Price (₹)"
                          className="shadow-none"
                          type="number"
                          name="asset_purchase_amount"
                          disabled={(assetsDetails?.asset_ecas_type ?? "").toLowerCase() == 'ecas'}
                          value={assetsDetails.asset_purchase_amount}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              isEditable: true,
                            }));
                          }}
                        />
                      </FloatingLabel>
                      {simpleValidator.current.message(
                        "asset_purchase_amount",
                        assetsDetails.asset_purchase_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add buy amount ",
                            min: "Please enter valid buy amount ",
                          },
                        }
                      )}
                    </div>

                    <div className="col-md-5 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Invested Amount (₹)"
                        className="mb-3 material d-flex"
                      >
                        <Form.Control
                          placeholder="Invested Amount (₹)"
                          className="shadow-none"
                          value={assetsDetails.totalpurchasevalue}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              totalpurchasevalue: e.target.value,
                            }));
                          }}
                          readOnly
                        />
                        <span className="info-hover-box">
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            Auto Calculated by No Of Units and Avg. Buy Price
                          </span>
                        </span>
                      </FloatingLabel>
                    </div>
                  </div>

                  <div className="row py-md-2">
                    <div className="col-md-5 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Current Price (₹)*"
                        className="mb-3 material"
                      >
                        <Form.Control
                          placeholder="Current Price (₹)"
                          className="shadow-none"
                          type="number"
                          value={assetsDetails.asset_current_unit_price}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              isEditable: true,
                            }));
                          }}
                          readOnly
                        />
                      </FloatingLabel>
                      {simpleValidator.current.message(
                        "Asset Current Price",
                        assetsDetails.asset_current_unit_price,
                        "required",
                        { messages: { required: "Please add current price" } }
                      )}
                    </div>
                    <div className="col-md-5 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Current Value (₹)"
                        className="mb-3 material d-flex"
                      >
                        <Form.Control
                          placeholder="Current Value (₹)"
                          className="shadow-none"
                          value={assetsDetails.totalinvestedvalue}
                          onChange={(e) => {
                            setAssetsDetails((prev) => ({
                              ...prev,
                              totalinvestedvalue: e.target.value,
                            }));
                          }}
                          readOnly
                        />
                        <span className="info-hover-box">
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            Auto Calculated by No Of Units and Current Price
                          </span>
                        </span>
                      </FloatingLabel>
                    </div>
                  </div>
                </>
              )}

              <div className="row py-md-2 mt-md-4">
                <div className="col-md-8">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span className="info-hover-left-box ms-md-4">
                      <span Name="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_islinkable: v,
                          }))
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-md-2 mt-3">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>
                        <span
                          className="ms-md-4 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-md-2 mt-2">
                      <div className="material">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div className="d-flex" style={{ textAlign: "left!important" }}>
                            <div>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            type={"Asset"}
                            asset_maturity_date={
                              assetsDetails.asset_mf_end_date
                            }
                            isGoalFilter={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                            isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleDebtSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {props.updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleDebtCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleDebtUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}

                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        {(!props.assetEditId || props.assetEditId == "") &&
          assetsDetails.asset_sub_category_id == 117 && (
            <div>
              {assetsDetails.asset_sub_category_id == 117 && (
                <div className="d-md-flex justify-content-center">
                  <DgRoundedButton
                    active={selectedButton == "EPF Manual Entry"}
                    onClick={() => {
                      setSelectedButton("EPF Manual Entry");
                      setSelectedExtraOption("EPF Manual Entry");
                    }}
                    title="Manual Entry"
                  />
                  <DgRoundedButton
                    active={selectedButton == "Link EPF"}
                    onClick={() => {
                      setSelectedButton("Link EPF");
                      setSelectedExtraOption("Link EPF");
                    }}
                    title="Link EPF"
                  />
                </div>
              )}
              {assetsDetails.asset_sub_category_id == 117 &&
                selectedExtraOption == "EPF Manual Entry" && (
                  <form noValidate="novalidate" name="goldassetform">
                    <div className="row d-flex align-items-center py-md-2">
                      <div className="col-md-5 custom-input">
                        <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: "19px" }}>
                          <input type="text" name="asset_name"
                            maxLength={35}
                            value={assetsDetails.asset_name}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_name: e.target.value,
                              }));
                            }} required autoComplete="off" />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Name of Asset*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Asset Name",
                            assetsDetails.asset_name,
                            "required|min:3|max:60",
                            {
                              messages: {
                                required: "Please enter asset name",
                                max: "Asset name must be between 3-35 characters.",
                                min: "Asset name must be between 3-35 characters.",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3">
                        <div className="material" style={{ marginTop: "2px" }}>
                          <Form.Label>Who Is This Investment For*</Form.Label>
                          {familyData && (
                            <Select
                              classNamePrefix="sortSelect"
                              isSearchable={false}
                              styles={customStyles}
                              options={familyData}
                              onChange={(e) =>
                                // setAssetsDetails((prev) => ({
                                //   ...prev,
                                //   asset_member_id: e.value,
                                // }))
                                handleAssetMemberMaturityDate(e)
                              }
                              value={familyData.filter(
                                (v) => v.value == assetsDetails.asset_member_id
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div className={`form-group  ${assetsDetails.asset_current_unit_price ? "inputData" : null} `}>
                          <input type="Number" name="asset_current_unit_price"
                            value={assetsDetails.asset_current_unit_price}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                isEditable: true,
                              }));
                            }} autoComplete="off" />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Current EPF Balance (₹)</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Current EPF Balance*",
                            assetsDetails.asset_current_unit_price,
                            "required|numeric|min:1,num",
                            {
                              messages: {
                                required: "Please add balance ",
                                min: "Please enter valid balance ",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div className={`form-group  ${assetsDetails.employee_contribution ? "inputData" : null} `}>
                          <input type="Number" name="employee_contribution"
                            value={assetsDetails.employee_contribution}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                employee_contribution: e.target.value.slice(
                                  0,
                                  11
                                ),
                                isEditable: true,
                              }));
                            }} required autoComplete="off" />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Employee's Monthly contribution to EPF (Rs.)*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Employee's Monthly contribution to EPF (Rs.)*",
                            assetsDetails.employee_contribution,
                            "required",
                            {
                              messages: {
                                required: "Please enter employee contribution",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div className={`form-group  ${assetsDetails.employer_contribution ? "inputData" : null} `}>
                          <input type="Number" name="employer_contribution"
                            value={assetsDetails.employer_contribution}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                employer_contribution: e.target.value.slice(
                                  0,
                                  11
                                ),
                                isEditable: true,
                              }));
                            }} required autoComplete="off" />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Employer's Monthly contribution to EPF (Rs.)*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Employer's Monthly contribution to EPF (Rs.)*",
                            assetsDetails.employer_contribution,
                            "required",
                            {
                              messages: {
                                required: "Please enter employer contribution",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2 mt-3">
                      <div className="col-md-5 mt-md-0 mt-3 ">
                        <div className="material">
                          <Form.Label>
                            Rate Of Return(%)* :{" "}
                            {assetsDetails.asset_ror == 0
                              ? 8.1
                              : assetsDetails.asset_ror}
                          </Form.Label>
                          <div
                            className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                              } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"
                              }`}
                          >
                            <Slider
                              min={0}
                              max={20}
                              value={
                                assetsDetails.asset_ror == 0
                                  ? 8.1
                                  : assetsDetails.asset_ror
                              }
                              step={0.05}
                              onChange={(v) =>
                                setAssetsDetails({
                                  ...assetsDetails,
                                  asset_ror: Math.round(
                                    (parseFloat(v) + Number.EPSILON) * 100
                                  ) / 100,
                                  isEditable: true,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3">
                        <div className="material">
                          <Form.Label>
                            Growth Rate In EPF Contribution (%)* :{" "}
                            {
                              assetsDetails.annual_growth_rate == 0
                                ? 10
                                : assetsDetails.annual_growth_rate
                            }
                          </Form.Label>
                          <Slider
                            min={0}
                            max={50}
                            value={
                              assetsDetails.annual_growth_rate == 0
                                ? 10
                                : assetsDetails.annual_growth_rate
                            }
                            onChange={(v) =>
                              setAssetsDetails((prev) => ({
                                ...prev,
                                annual_growth_rate: v,
                                isEditable: true,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-1">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Maturity Date*"
                          className="mb-4 material"
                          style={{
                            marginTop: "2px",
                          }}
                        >
                          <div
                            className="dt-conbx"
                            style={{
                              borderBottom: "1px solid #dadada",
                              paddingTop: "16px",
                            }}
                          >
                            <ReactDatePicker
                              select_date={moment(
                                assetsDetails.asset_maturity_date,
                                "DD/MM/YYYY"
                              ).toDate()}
                              setDate={(date) => {
                                setDate(date, "maturityDate");
                              }}
                              minDate={moment().toDate()}
                              maxDate={moment().add(100, "years").toDate()}
                              className="pt-4"
                            />
                          </div>
                        </FloatingLabel>
                        <div>
                          {simpleValidator.current.message(
                            "Maturity Date",
                            assetsDetails.asset_maturity_date,
                            "required",
                            {
                              messages: {
                                required: "Please select maturity date ",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 mt-2">
                        <div className="dark-label">
                          <div className="d-flex" style={{ clear: "both" }}>
                            <FintooRadio2
                              checked={assetsDetails.asset_isperpetual == "3"}
                              onClick={() => {
                                setAssetsDetails((prev) => ({
                                  ...prev,
                                  asset_isperpetual: "3",
                                  asset_maturity_date: moment(retirementDate)
                                    .add(retirementDate, "y")
                                    .format("DD/MM/YYYY"),
                                }));
                              }}
                              title="Upto Retirement Age"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-0 mt-4 custom-input">
                        <div className={`form-group inputData`}>
                          <span> <input type="Number" name="asset_current_unit_price"
                            value={assetsDetails.asset_maturity_amt}
                            onChange={(e) => {
                              setAssetsDetails((prev) => ({
                                ...prev,
                                asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              }));
                            }} required autoComplete="off" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="name">Maturity Amount*</label></span>
                          <span
                            className="info-hover-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto calculated on the basis of Purchase Amount and
                              Rate Of Return. You can also edit it and enter your
                              desired maturity amount.
                            </span>
                          </span>
                        </div>

                        <div>
                          {simpleValidator.current.message(
                            "Asset Maturity Amount",
                            assetsDetails.asset_maturity_amt,
                            "required",
                            {
                              messages: {
                                required: "Please enter maturity amount",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row py-md-2 mt-md-2">
                      <div className="col-md-8">
                        <div className="d-md-flex">
                          <Form.Label className=" ">
                            Consider This Asset In Automated Linkage*
                          </Form.Label>
                          <span className="info-hover-left-box ms-md-4">
                            <span Name="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Select a goal below to map this investment with a
                              goal of your choice. Otherwise, Fintoo will link it
                              automatically with your high priority goal. In case,
                              you do not wish to utilize this investment for any
                              goal, select "NO".
                            </span>
                          </span>
                          <div className="d-flex ms-md-4">
                            <div>No</div>
                            <Switch
                              onChange={(v) =>
                                setAssetsDetails((prev) => ({
                                  ...prev,
                                  asset_islinkable: v,
                                }))
                              }
                              checked={assetsDetails.asset_islinkable}
                              className="react-switch px-2"
                              activeBoxShadow="0 0 2px 3px #424242"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={40}
                              onColor="#042b62"
                              offColor="#d8dae5"
                            />
                            <div>Yes</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {assetsDetails.asset_islinkable == true && (
                      <>
                        <div className="row py-md-2">
                          <div className="col-md-8 mt-md-2 mt-3">
                            <div className="d-md-flex">
                              <Form.Label className="link_asset_style">
                                Link This Investment Asset to Goal
                              </Form.Label>{" "}
                              <span
                                className="ms-md-4 info-hover-left-box float-right"
                                style={{
                                  position: "relative !important",
                                }}
                              >
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  You can only assign goals which are prior to the
                                  end date of the SIP, if any
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-md-2 mt-2">
                            <div className="material">
                              <div
                                className="m-0 btn-sm default-btn gradient-btn save-btn"
                                onClick={() => setGoalSelected(true)}
                              >
                                Select Goals
                              </div>
                              <br></br>
                              <br></br>

                              {selectedGoals ? (
                                <div className="d-flex" style={{ textAlign: "left!important" }}>
                                  <div>
                                    <b>Selected Goals : </b>
                                  </div>
                                  <div className="ms-1">{selectedGoals}</div>
                                </div>
                              ) : (
                                ""
                              )}
                              {isGoalSelected ? (
                                <GoalsDropdown
                                  setGoalSelected={setGoalSelected}
                                  goals={props.retirementGoalID ? goalData.filter(goal => {
                                    return goal.value == props.retirementGoalID
                                  }) : goalData}
                                  unchangedgoaldata={unchangedgoaldata}
                                  closeModal={closeModal}
                                  selectGoals={selectGoals}
                                  selectedGoals={selectedGoals}
                                  selectedGoalIdArray={selectedGoalIdArray}
                                  selectedGoalsId={selectedGoalsId}
                                  setPriorityArray={setPriorityArray}
                                  selectedPriorityArray={selectedPriorityArray}
                                  setAutoMatedGoal={setAutoMatedGoal}
                                  isAutoMatedGoal={isAutoMatedGoal}
                                  setGoalLink={setGoalLink}
                                  type={"Asset"}
                                  asset_maturity_date={
                                    assetsDetails.asset_maturity_date
                                  }
                                  isGoalFilter={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                                  isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                                ></GoalsDropdown>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container">
                            <div className="d-flex justify-content-center">
                              <a
                                href={process.env.PUBLIC_URL + "/datagathering/goals"}
                              >
                                <div className="previous-btn form-arrow d-flex align-items-center">
                                  <FaArrowLeft />
                                  <span className="hover-text">
                                    &nbsp;Previous
                                  </span>
                                </div>
                              </a>

                              {addForm && (
                                <button
                                  onClick={(e) => handleDebtSubmit(e)}
                                  className="default-btn gradient-btn save-btn"
                                >
                                  Save & Add More
                                </button>
                              )}
                              {updateForm && (
                                <div>
                                  <button
                                    onClick={(e) => handleDebtCancel(e)}
                                    className="default-btn gradient-btn save-btn"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={(e) => handleDebtUpdate(e)}
                                    className="default-btn gradient-btn save-btn"
                                  >
                                    Update
                                  </button>
                                </div>
                              )}

                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => props.setTab("tab2")}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Continue&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              {assetsDetails.asset_sub_category_id == 117 &&
                selectedExtraOption == "Link EPF" && (
                  <LinkEPF
                    setShowOTPModal={setShowOTPModal}
                    customStyles={customStyles}
                    session={session}
                  />
                )}
            </div>
          )}
        {props.assetEditId && assetsDetails.asset_sub_category_id == 117 && (
          <div>
            <form noValidate="novalidate" name="goldassetform">
              <div className="row py-md-2">
                <div className="col-md-5 pt-1">
                  <FloatingLabel
                    controlId="floatingInput "
                    label="Name of Asset*"
                    className="mb-3 material"
                  >
                    <Form.Control
                      type="text"
                      className="shadow-none"
                      placeholder="First Name*"
                      maxLength={35}
                      value={assetsDetails.asset_name}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_name: e.target.value,
                        }));
                      }}
                    />
                  </FloatingLabel>
                  <div>
                    {simpleValidator.current.message(
                      "Asset Name",
                      assetsDetails.asset_name,
                      "required|min:3|max:60",
                      {
                        messages: {
                          required: "Please enter asset name",
                          max: "Asset name must be between 3-35 characters.",
                          min: "Asset name must be between 3-35 characters.",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                  <div className="material">
                    <Form.Label>Who Is This Investment For*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_member_id: e.value,
                          }))
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row py-md-2">
                <div className="col-md-5 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Current EPF Balance (₹)"
                    className="mb-3 material"
                  >
                    <Form.Control
                      placeholder="Current EPF Balance (₹)"
                      className="shadow-none"
                      value={assetsDetails.asset_current_unit_price}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                        }));
                      }}
                    />
                  </FloatingLabel>
                </div>
                {simpleValidator.current.message(
                  "Current EPF Balance*",
                  assetsDetails.asset_current_unit_price,
                  "required|numeric|min:1,num",
                  {
                    messages: {
                      required: "Please add balance ",
                      min: "Please enter valid balance ",
                    },
                  }
                )}
              </div>

              <div className="row py-md-2">
                <div className="col-md-5 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Employee's Monthly contribution to EPF (Rs.)*"
                    className="mb-3 material"
                  >
                    <Form.Control
                      placeholder="Employee's Monthly contribution to EPF (Rs.)*"
                      className="shadow-none"
                      type="number"
                      value={assetsDetails.employee_contribution}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          employee_contribution: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          isEditable: true,
                        }));
                      }}
                    />
                  </FloatingLabel>
                  <div>
                    {simpleValidator.current.message(
                      "Employee's Monthly contribution to EPF (Rs.)*",
                      assetsDetails.employee_contribution,
                      "required",
                      {
                        messages: {
                          required: "Please enter employee contribution",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-5 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Employer's Monthly contribution to EPF (Rs.)*"
                    className="mb-3 material"
                  >
                    <Form.Control
                      placeholder="Employer's Monthly contribution to EPF (Rs.)*"
                      className="shadow-none"
                      type="number"
                      value={assetsDetails.employer_contribution}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          employer_contribution: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          isEditable: true,
                        }));
                      }}
                    />
                  </FloatingLabel>
                  <div>
                    {simpleValidator.current.message(
                      "Employer's Monthly contribution to EPF (Rs.)*",
                      assetsDetails.employer_contribution,
                      "required",
                      {
                        messages: {
                          required: "Please enter employer contribution",
                        },
                      }
                    )}
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-5">
                  <div className="material">
                    <Form.Label>
                      Rate Of Return(%)* :{" "}
                      {parseFloat(assetsDetails.asset_ror)}
                    </Form.Label>
                    <div
                      className={`${assetsDetails.asset_ror < 1 && "sl-hide-left"
                        } ${assetsDetails.asset_ror > 18.5 && "sl-hide-right"}`}
                    >
                      <Slider
                        min={0}
                        max={50}
                        value={
                          assetsDetails.asset_ror
                        }
                        step={0.05}
                        onChange={(v) => {
                          if (v != 0) {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_ror: v,
                              isEditable: true,
                            })
                          }
                        }


                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                  <div className="material">
                    <Form.Label>
                      Growth Rate In EPF Contribution (%)* :{" "}
                      {parseInt(assetsDetails.annual_growth_rate)}
                    </Form.Label>
                    <Slider
                      min={0}
                      max={50}
                      value={assetsDetails.annual_growth_rate}
                      onChange={(v) => {
                        if (v != 0) {
                          setAssetsDetails((prev) => ({
                            ...prev,
                            annual_growth_rate: v,
                            isEditable: true,
                          }))
                        }
                      }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row py-md-2">
                <div className="col-md-5 mt-md-1">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Maturity Date*"
                    className="mb-4 material"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    <div
                      className="dt-conbx"
                      style={{
                        borderBottom: "1px solid #dadada",
                        paddingTop: "8px",
                      }}
                    >
                      <ReactDatePicker
                        select_date={moment(
                          assetsDetails.asset_maturity_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        setDate={(date) => {
                          setDate(date, "maturityDate");
                        }}
                        minDate={moment().toDate()}
                        maxDate={moment().add(100, "years").toDate()}
                        className="pt-4"
                      />
                    </div>
                  </FloatingLabel>
                  <div>
                    {simpleValidator.current.message(
                      "Maturity Date",
                      assetsDetails.asset_maturity_date,
                      "required",
                      {
                        messages: {
                          required: "Please select maturity date ",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-5 ">
                  <div className="dark-label">
                    <div className="d-flex" style={{ clear: "both" }}>
                      <FintooRadio2
                        checked={assetsDetails.asset_isperpetual == "3" || assetsDetails.asset_sub_category_id === 117}
                        onClick={() => {
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_isperpetual: "3",
                            asset_maturity_date: moment(retirementDate)
                              .add(retirementDate, "y")
                              .format("DD/MM/YYYY"),
                          }));
                        }}
                        title="Upto Retirement Age"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-md-2">
                <div className="col-md-5 mt-md-0 mt-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Maturity Amount*"
                    className="mb-3 material d-flex"
                  >
                    <Form.Control
                      type="Number"
                      placeholder="Maturity Amount*"
                      className="shadow-none"
                      value={assetsDetails.asset_maturity_amt}
                      onChange={(e) => {
                        setAssetsDetails((prev) => ({
                          ...prev,
                          asset_maturity_amt: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                        }));
                      }}
                    />
                    <span
                      className="info-hover-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Auto calculated on the basis of Purchase Amount and Rate
                        Of Return. You can also edit it and enter your desired
                        maturity amount.
                      </span>
                    </span>
                  </FloatingLabel>
                  <div>
                    {simpleValidator.current.message(
                      "Asset Maturity Amount",
                      assetsDetails.asset_maturity_amt,
                      "required",
                      {
                        messages: {
                          required: "Please enter maturity amount",
                        },
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="row py-md-2 mt-md-4">
                <div className="col-md-8">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span className="info-hover-left-box ms-md-4">
                      <span Name="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails((prev) => ({
                            ...prev,
                            asset_islinkable: v,
                          }))
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-md-2 mt-3">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>{" "}
                        <span
                          className="ms-md-4 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-md-2 mt-2">
                      <div className="material">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div className="d-flex" style={{ textAlign: "left!important" }}>
                            <div>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={props.retirementGoalID ? goalData.filter(goal => {
                              return goal.value == props.retirementGoalID
                            }) : goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            type={"Asset"}
                            asset_maturity_date={
                              assetsDetails.asset_maturity_date
                            }
                            isGoalFilter={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                            isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleDebtSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleDebtCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleDebtUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}

                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        <Modal
          classNames={{
            modal: "Modalpopup2",
          }}
          show={showUANModal}
          showCloseIcon={false}
          onClose={() => () => { }}
          center
          animationDuration={0}
        >
          <div className="" style={{ padding: "0 !important" }}>
            {/* <h2 className="HeaderText text-center">Link Your EPF Account</h2> */}
            <div className="Modalpopup2_heading">
              <div className="col-11 d-flex justify-content-center">
                {" "}
                <span>Link Your EPF Account</span>
              </div>
              <div
                className="col-1 text-light cursor-pointer"
                onClick={() => setShowUANModal(false)}
              >
                <i class="fa-regular fa-circle-xmark"></i>
              </div>
            </div>

            <div>
              <br />

              <div className="my-md-4">
                <div className="px-5">
                  <span className="lbl-newbond">Enter UAN *</span>
                  <br />
                  <input className={` w-100 fntoo-textbox-react`} type="text" />
                </div>
              </div>
              <br />
              <div className="ButtonBx">
                <button
                  className="ReNew"
                  onClick={() => {
                    setShowOTPModal(true);
                    setShowUANModal(false);
                    setIsManual(false);
                  }}
                >
                  Send OTP
                </button>
              </div>
              <br />
            </div>
          </div>
        </Modal>
        <Modal
        classNames={{
          modal: "RefreshModalpopup",
        }}
        show={showuniqueUANModal}
        showCloseIcon={false}
        onClose={() => () => { }}
        centered
        animationDuration={0}
      >
        <Uniquepannotfoundmodal showuniqueUANModalclose={showuniqueUANModalclose} pannumbers={pannumbers} familyecas={familyecas} familyData={familyData} memberdataid={memberdataid} session={session} />
      </Modal>
      </form>
    </>

  );
}

export default AssetDebt;