import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewInsuranceFormView from "./NewInsuranceFormView";

const NewInsuranceForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewInsuranceFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewInsuranceForm;
