import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className={`${styles["section-sub-title"]}`}>
        Don’t ReTire, <br />
        Reboot… Relive… Rejoice
      </h3>
    </>
  );
};
export default LeftSection;
