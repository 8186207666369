import React, { useEffect, useState } from "react";
import DirectMFHeaderSection from "../components/HTML/DirectMF/DirectMFHeaderSection";
import DirectMFTestimonialSection from "../components/HTML/DirectMF/DirectMFTestimonials";
import ExpertAdvisorySection from "../components/HTML/DirectMF/ExpertAdvisorySection";
import FeaturesSection from "../components/HTML/DirectMF/FeaturesSection";
import LifeChoiceSection from "../components/HTML/DirectMF/LifeChoiceSection";
import StatsSection from "../components/HTML/DirectMF/StatsSection";
import FaqSection from "../components/HTML/DirectMF/FaqSection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import Main from "./DMF/Main";
import AppointmentBox from "../components/Pages/Calendly";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function DirectMF() {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  React.useEffect(() => {
    document.body.classList.add('MainDivRemove');

  }, []);
  return (
    <Fullpage>
      <div style={{ backgroundColor: "white", overflow: "hidden" }}>
        <DirectMFHeaderSection />

        <div className="mb-md-5">
          <FeaturesSection />
        </div>

        <StatsSection />
        <ClientTestimonial />
        {utmSource && tagval ? (
            <AppointmentBox
              extraParams={{ "utm_source": utmSource, "service": 98 }}
              eventCode={tagval}
              serviceName="Mutual Fund"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-mutual-fund?hide_event_type_details=1"}
              planId = "46"
            />
          ) : (
            <AppointmentBox
              eventCode={'Callback_mintyApp_25'}
              serviceName="Mutual Fund"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-mutual-fund?hide_event_type_details=1"}
              planId = "46"
            />
          )}
        {/* <AppointmentBox eventCode={'Callback_mintyApp_25'} serviceName="Mutual Fund" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-mutual-fund?hide_event_type_details=1" /> */}

        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );
}

export default DirectMF;
