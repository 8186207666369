import React, { useEffect, useState } from "react";
import FaqSection from "../components/HTML/Stockadvisory/FaqSection";
import HeaderSection from "../components/HTML/Stockadvisory/HeaderSection";
import YoutubevideoSection from "../components/HTML/Stockadvisory/YoutubevideoSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import WidgetSection from "../components/HTML/Stockadvisory/WidgetSection";
import AdvisorycardSection from "../components/HTML/Stockadvisory/AdvisorySection";
import ReportsPage from "../components/HTML/Stockadvisory/ReportsPage";
import FeaturesSection from "../components/HTML/Stockadvisory/FeaturesSection";
import FeaturedIn from "../components/HTML/Stockadvisory/FeaturedIn";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import AppointmentBox from "../components/Pages/Calendly";
import AssistedUAESection from "../components/HTML/Stockadvisory/AssistedUAESection";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const Stockadvisory = () => {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  return (
    <Fullpage>
      <HeaderSection />
      <section style={{
        backgroundColor: "rgb(230, 234, 239)"
      }}>
        <WidgetSection />
      </section>
      <AssistedUAESection />
      <AdvisorycardSection />
      <FeaturesSection />
      <ReportsPage />
      <YoutubevideoSection />
      <SecuritySection />
      {/* <FeaturedIn /> */}
      <section style={{

      }} id="GETREPORTS">
        {utmSource && tagval ? (
          <AppointmentBox
            extraParams={{ "utm_source": utmSource, "service": 98 }}
            eventCode={tagval}
            serviceName="Direct Domestic Equity"
            eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-domestic-equity?hide_event_type_details=1"}
            planId="48"
          />
        ) : (
          <AppointmentBox
            eventCode={'Callback_mintyApp_89'}
            serviceName="Direct Domestic Equity"
            eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-domestic-equity?hide_event_type_details=1"}
            planId="48"
          />
        )}
        {/* <AppointmentBox eventCode={'Callback_mintyApp_89'} serviceName="Direct Domestic Equity" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-domestic-equity?hide_event_type_details=1" /> */}
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.stockAdvisory}/> */}
      </section>
      <FaqSection />
    </Fullpage>
  );
};
export default Stockadvisory;
