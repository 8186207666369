import { useState, useEffect } from "react";
import {
  apiCall,
  getFpLogId,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
  setFplogid,
} from "../common_utilities";
import FintooLoader from "../components/FintooLoader";
import "../checkboxstyle.css";
import {
  ADVISORY_GET_PRICINGDETAILS_API_URL,
  BASE_API_URL,
  CHECK_SESSION,
  imagePath,
} from "../constants";
import axios from "axios";
import commonEncode from "../commonEncode";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import HideHeader from "../components/HideHeader";
import HideFooter from "../components/HideFooter";
import { Link } from "react-router-dom";

const PaymentPage = () => {
  const [isCouponPopUpActive, setCouponActive] = useState(false);
  const [isBillingPopupActive, setBillingPopupActive] = useState(false);
  const [isBookingPopUpActive, setBookingPopUpActive] = useState(false);
  const [couponsList, setCouponList] = useState("");
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [totalpay, setTotalPay] = useState(0);
  const [productInfopay, setProductInfopay] = useState(0);
  const [totalGST, setTotalGST] = useState(0);
  const [totalpaywithGST, setTotalPaywithGST] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [couponInput, setCouponInput] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isHasPlan, setIsHasPlan] = useState(0);
  const [planCartPay, setPlanCartPay] = useState([]);
  const [couponApplied, setCouponApplied] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [isRecurringDisabled, setIsRecurringDisabled] = useState(false);
  const [subscriptionMonths, setSubscriptionMonths] = useState(null);
  const [grossPayable, setGrossPayable] = useState(false);
  const [planImg, setPlanImg] = useState("");
  const [plansubcat, setPlansubcat] = useState("");
  const [monthlyAmount, setMonthlyamount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [planType, setPlanType] = useState("");
  const [planFreq, setPlanFreq] = useState("");
  const [rm_id, setRMid] = useState("");
  const [rmdetailsName, setRMdetailsName] = useState("");
  const [rmdetailsPosition, setRMdetailsPosition] = useState("");
  const [rmdetailsImg, setRMdetailsImg] = useState("");
  const [forQuarter, setForQuarter] = useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [netPayable, setNetPayable] = useState("");
  const [isNDAChecked, setNDAChecked] = useState(false);
  const [NDAMsg, setNDAMsg] = useState("");
  const [isFAChecked, setFAChecked] = useState(false);
  const [isCouponInvalid, setCouponInvalid] = useState(false);
  const [isOfferInvalid, setInvalidOffer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [login_image, setLoginImage] = useState("");
  const plan_types = {
    1: "Basic",
    2: "Basic Pro",
    3: "Classic",
    4: "Classic Plus",
    5: "Premium",
    6: "Elite",
    7: "Elite Prime",
  };

  useEffect(() => {
    document.body.classList.add("main-layout");
    // var login_img = imagePath + "https://static.fintoo.in/static/userflow/img/login-illustration.svg";

    // setLoginImage(login_img);
  }, []);

  useEffect(() => {
    getCouponList();
    getSessionData();
    subscriptionMonthsCheck();
  }, []);
  const userid = getParentUserId();
  const rsFilter = (value) => {
    if (!isNaN(value)) {
      var is_negative = false;
      var result = Math.floor(value).toString();
      if (result.includes("-")) {
        result = result.replace("-", "");
        is_negative = true;
      }

      var lastThree = result.substring(result.length - 3);
      var otherNumbers = result.substring(0, result.length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var output =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (is_negative == true) {
        output = "-" + output;
      }

      return output;
    }
  };
  const openPopup = (popupName) => {
    if (popupName == "applyCoupon") {
      setCouponActive(true);
      setBillingPopupActive(false);
      setBookingPopUpActive(false);
      setCouponInvalid(false);
      setEnteredCoupon("");
    }
    if (popupName == "billingDetails") {
      setBillingPopupActive(true);
      setCouponActive(false);
      setBookingPopUpActive(false);
    }
    if (popupName == "bookAppointment") {
      setBookingPopUpActive(true);
      setBillingPopupActive(false);
      setCouponActive(false);
    }
  };
  const removePopup = () => {
    setBillingPopupActive(false);
    setBookingPopUpActive(false);
    setCouponActive(false);
  };
  const handleCouponCodeChange = (value) => {
    if (value == "") {
      setEnteredCoupon(value);
      setCouponInvalid(false);
    } else {
      setEnteredCoupon(value);
    }
  };
  const subscriptionMonthsCheck = async () => {
    setIsLoading(true);

    setSubscriptionMonths(localStorage.getItem("subscription_months"));
    if (
      localStorage.getItem("subscription_months") != "null" &&
      localStorage.getItem("subscription_months") != null
    ) {
      setSubscriptionMonths(localStorage.getItem("subscription_months"));
      handleSubscriptionMonthsChange(
        localStorage.getItem("subscription_months")
      );
      setIsLoading(false);
    } else {
      var payload = commonEncode.encrypt(JSON.stringify({ user_id: userid }));
      var res = await apiCall(
        BASE_API_URL +
        "restapi/userflow/getcartdata/?user_id=" +
        getParentUserId() +
        "&web=1" +
        "&subscription_months=" +
        subscriptionMonths,
        "",
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));

      if (decoded_res) {
        setIsLoading(false);

        var response = decoded_res.data;
        if (decoded_res.error_code == "100") {
          const frequency = response.cartdetails[0]["subscription_freq"];
          if (frequency) {
            setSubscriptionMonths(6);
            localStorage.setItem("subscription_months", frequency);
            subscriptionMonthsCheck();
          } else {
            setSubscriptionMonths(6);
            localStorage.setItem("subscription_months", 6);
            subscriptionMonthsCheck();
          }
        } else {
          setSubscriptionMonths(6);
          localStorage.setItem("subscription_months", 6);
          subscriptionMonthsCheck();
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Please add a plan to your cart.");
          setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL + "/pricing/";
          }, 1000);
        }

        setUserData(response[0]);
      }
      setIsLoading(false);
    }
  };

  const getSessionData = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
      if (session_data.error_code == "100") {
        if (session_data.user_details) {
          if (
            session_data.user_details.fp_log_id &&
            session_data.user_details.plan_payment_status != 6
          ) {
            setIsHasPlan(1);
          } else {
            setIsHasPlan(0);
          }
        }

        getUserFlowData();
      } else {
        loginRedirect();
      }
    } catch {
      setSessionData([]);
    }
  };

  const getCouponList = async () => {
    var config = {
      method: "GET",
      url: BASE_API_URL + "restapi/userflow/getcouponlist/?all=1&web=1",
    };
    var res = await axios(config);
    if (res) {
      var response = JSON.parse(commonEncode.decrypt(res.data));
      if (response.error_code == "100") {
        setCouponList(response.data);
      }
    }
  };

  const getUserFlowData = async () => {
    var payload = commonEncode.encrypt(JSON.stringify({ user_id: userid }));
    var res = await apiCall(
      BASE_API_URL + "restapi/userflow/getuserflowuserdata/",
      payload,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    if (decoded_res) {
      var response = decoded_res.data;

      setUserData(response[0]);
    }
  };

  const calculategst = (mrp, gst_percentage) => {
    let gstamt = 0;
    if (mrp > 0) {
      gstamt = (mrp * gst_percentage) / 100;
      return gstamt.toFixed(2);
    }
    return gstamt.toFixed(2);
  };

  const deleteCoupon = async (id) => {
    var payload = commonEncode.encrypt(
      JSON.stringify({ cart_coupon: 0, user_id: userid })
    );
    var res = await apiCall(
      BASE_API_URL + "restapi/userflow/addcoupontocart/",
      payload,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    setIsRecurring(true);
    if (decoded_res) {
      var response = JSON.parse(decoded_res.data);
      if (decoded_res.error_code == "100") {
        setDiscountTotal(response.discount);
        setTotalGST(response.gst);
        setTotalPaywithGST(response.totalpay);
        setCouponInput("");
        setCouponApplied("");
      } else {
        updateCartCouponValue();
      }
    }
  };
  const updateCartCouponValue = () => {
    setDiscountTotal(0);
  };
  const applyOffer = async (argument, total_pay, plan_Cart_Pay) => {
    setDiscountTotal(0);
    if (total_pay > 0 && plan_Cart_Pay.length > 0) {
      let chkplans = "0";
      if (argument.c_plans != "" && argument.c_plans != null) {
        chkplans = argument.c_plans;
      }
      if (argument.c_discount == 100) {
        chkplans = plan_Cart_Pay[0]["plan_id"].toString();
      }
      let validplans = chkplans;
      if (validplans.length > 0) {
        let vp_arr = [];
        if (validplans != 0) {
          let vp_arr = validplans.split(",");
          vp_arr.sort();
          let plan_id_arr = [];

          plan_Cart_Pay.forEach((product) => {
            plan_id_arr.push(product.plan_id.toString());
          });
          plan_id_arr.sort();

          let is_same =
            vp_arr.length == plan_id_arr.length &&
            vp_arr.every(function (element, index) {
              return element === plan_id_arr[index];
            });

          if (is_same || argument.c_status == "3") {
            setCouponApplied(argument.c_code);
            setCouponInput(argument.c_id);
            let total = total_pay;

            if (argument.c_ispercentage == "1") {
              let discprice = total * (argument.c_discount / 100);
              total = total - discprice;
              setCouponApplied(argument.c_code);
            } else {
              let discprice = argument.c_discount;
              total = total - discprice;
              setCouponApplied(argument.c_code);
            }
            if (total <= 0) {
              total = 0;
            } else {
              plan_Cart_Pay.forEach((product) => {
                let gstamt = calculategst(total, product.plan_gst_amount);
                setTotalGST(parseFloat(gstamt));
                setTotalPaywithGST(parseFloat(product.plan_gst_amount));
              });
            }
            var payload = commonEncode.encrypt(
              JSON.stringify({ cart_coupon: argument.c_id, user_id: userid })
            );
            var res = await apiCall(
              BASE_API_URL + "restapi/userflow/addcoupontocart/",
              payload,
              false,
              false
            );
            let decoded_res = JSON.parse(commonEncode.decrypt(res));
            if (decoded_res.error_code == "100") {
              setIsRecurring(false);
              setIsRecurringDisabled(true);
              var response = JSON.parse(decoded_res.data);
              if (response) {
                setDiscountTotal(response.discount);
                setTotalGST(response.gst);
                setTotalPaywithGST(response.totalpay);
              } else {
                updateCartCouponValue();
              }
            }
          } else {
            setCouponInvalid(true);
            setCouponApplied("");
          }
        }
      } else {
        setCouponInvalid(true);
      }
    } else {
      if (planCartPay.length == 0) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Add a plan to cart first");
        setInvalidOffer(true);
      } else {
        setCouponInvalid(true);
        setCouponApplied("");
      }
    }
  };
  const returnHash = () => {
    var hash_char = "abcdefghijklmnopqrstuvwxyz1234567890".split("");
    var token = "";
    for (var i = 0; i < 32; i++) {
      token += hash_char[Math.floor(Math.random() * hash_char.length)];
    }
    return token; //Will return a 32 bit "hash"
  };
  const applyOffer1 = async (argument) => {
    setCouponInput("");
    setDiscountTotal(0);
    if (totalpay > 0 && planCartPay.length > 0) {
      let chkplans = "0";
      if (argument.c_plans != "" && argument.c_plans != null) {
        chkplans = argument.c_plans;
      }
      let validplans = chkplans;
      if (validplans.length > 0) {
        var vp_arr = [];
        if (validplans != 0) {
          var vp_arr = validplans.split(",");
          vp_arr.sort();
        }
        var plan_id_arr = [];
        planCartPay.forEach((product) => {
          plan_id_arr.push(product.plan_id.toString());
        });
        plan_id_arr.sort();

        var is_same = false;
        is_same = vp_arr.includes(plan_id_arr[0]) ? true : false;
        if (is_same || argument.c_status == "3") {
          setCouponApplied(argument.c_code);
          setCouponInput(argument.c_id);
          var total = totalpay;
          if (argument.c_ispercentage == "1") {
            var discprice = total * (argument.c_discount / 100);
            total = total - discprice;
          } else {
            var discprice = argument.c_discount;
            total = total - discprice;
          }
          if (total <= 0) {
            total = 0;
          } else {
            planCartPay.forEach((product) => {
              var gstamt = calculategst(total, product.plan_gst_amount);
              setTotalGST(parseFloat(gstamt));
              setTotalPaywithGST(parseFloat(gstamt));
              setTotalPaywithGST(product.plan_amount);
            });
          }
          var payload = commonEncode.encrypt(
            JSON.stringify({ cart_coupon: argument.c_id, user_id: userid })
          );
          var res = await apiCall(
            BASE_API_URL + "restapi/userflow/addcoupontocart/",
            payload,
            false,
            false
          );
          let decoded_res = JSON.parse(commonEncode.decrypt(res));
          if (decoded_res) {
            setIsRecurring(false);
            setIsRecurringDisabled(true);
            var response = JSON.parse(decoded_res.data);
            if (response.error_code == "100") {
              setDiscountTotal(response.discount);
              setTotalGST(response.gst);
              setTotalPaywithGST(response.totalpay);
            } else if (
              response.error_code == "102" &&
              response.message == "Invalid Coupon"
            ) {
              setCouponInvalid(true);
              setCouponApplied("");
            } else {
              updateCartCouponValue();
            }
          }
        } else {
          setCouponInvalid(true);
          setCouponApplied("");
        }
      } else {
        setCouponInvalid(true);
        setCouponApplied("");
      }
    } else {
      if (planCartPay.length == 0) {
        setInvalidOffer(true);
      } else {
        setCouponInvalid(true);
      }
    }
  };
  const applyOfferManual = async () => {
    if (enteredCoupon == "") {
      setCouponError("Please enter coupon");
    } else {
      var c_flag = 0;
      var argument = [];
      couponsList.map((coupon) => {
        if (enteredCoupon == coupon.c_code) {
          if (coupon.c_expiry_date) {
            var currentDate = new Date();
            var couponExpiryDate = new Date(coupon.c_expiry_date);
            if (currentDate > couponExpiryDate) {
              c_flag = 2;
            } else {
              argument = coupon;
              c_flag = 1;
            }
          }
          argument = coupon;
          c_flag = 1;
        }
      });
      if (c_flag == 1) {
        setCouponError("");
        setDiscountTotal(0);
        if (totalpay > 0 && planCartPay != "") {
          setCouponApplied(argument.c_code);
          setCouponInput(argument.c_id);
          var total = totalpay;
          if (argument.c_ispercentage == "1") {
            var discprice = total * (argument.c_discount / 100);
            total = total - discprice;
          } else {
            var discprice = argument.c_discount;
            total = total - discprice;
          }
          if (total <= 0) {
            total = 0;
          } else {
            if (planCartPay) {
              if (Array.isArray(planCartPay)) {
                // If planCartPay is an array, iterate through its elements
                planCartPay.forEach((product) => {
                  var gstamt = calculategst(
                    product.plan_amount,
                    product.plan_gst_amount
                  );
                  setTotalGST(parseFloat(gstamt));
                  setTotalPaywithGST(parseFloat(gstamt));
                  setTotalPaywithGST(parseFloat(product.plan_amount));
                });
              } else if (typeof planCartPay === "object") {
                // If planCartPay is an object, you can access its properties directly
                var gstamt = calculategst(
                  planCartPay.amount.after_discount,
                  planCartPay.plan_gst_amount
                );
                setTotalGST(parseFloat(gstamt));
                setTotalPaywithGST(parseFloat(gstamt));
                setTotalPaywithGST(
                  parseFloat(planCartPay.amount.after_discount)
                );
              }
            }
          }

          var payload = commonEncode.encrypt(
            JSON.stringify({
              cart_coupon: argument.c_id,
              user_id: getParentUserId(),
            })
          );
          var res = await apiCall(
            BASE_API_URL + "restapi/userflow/addcoupontocart/",
            payload,
            false,
            false
          );
          let decoded_res = JSON.parse(commonEncode.decrypt(res));
          if (decoded_res) {
            setIsRecurring(false);
            setIsRecurringDisabled(true);
            try {
              var response = JSON.parse(decoded_res.data);
            } catch {
              var response = decoded_res.data;
            }

            if (decoded_res.error_code == "100") {
              setDiscountTotal(response.discount);
              setTotalGST(response.gst);
              setTotalPaywithGST(response.totalpay);
              setEnteredCoupon("");
              removePopup();
            } else if (decoded_res.error_code == "102") {
              setCouponInvalid(true);
              setCouponApplied("");
            } else {
              updateCartCouponValue();
            }
          }
        } else {
          if (planCartPay.length == 0) {
            setInvalidOffer(true);
          } else {
            setCouponInvalid(true);
            setCouponApplied("");
          }
        }
      } else if (c_flag == 2) {
      } else {
        setCouponInvalid(true);
        setCouponApplied("");
      }
      return false;
    }
  };

  const skiprazorpayment = async (subscription_freq = 1, plan_sub_cat = 1) => {
    var razorpay_payment_id = returnHash();
    var razorpay_order_id = returnHash();
    var razorpay_signature = returnHash();
    if (rm_id == null) {
      var rm_id = 96;
    } else {
      var rm_id = rm_id;
    }
    var skip_paydata = {
      razorpay_payment_id: razorpay_payment_id,
      razorpay_order_id: razorpay_order_id,
      razorpay_signature: razorpay_signature,
      user_id: getParentUserId(),
      subscription_months: subscription_freq,
      plan_sub_cat: plan_sub_cat,
      rm_id: rm_id,
    };
    if (planCartPay.length != 0) {
      var updatesession_data = {};
      var res = await apiCall(
        BASE_API_URL + "restapi/userflow/razorpayskippayment/",
        skip_paydata,
        false,
        false
      );
      try {
        var decoded_res = JSON.parse(commonEncode.decrypt(res));
      } catch {
        var decoded_res = res;
      }
      if (decoded_res) {
        try {
          let fp_log_id = await getFpLogId();
          setFplogid(fp_log_id);
        } catch {
          setFplogid("");
        }
        if (decoded_res.error_code == "100") {
          if (decoded_res.data.fp_log_ids.length > 1) {
            localStorage.setItem("showpaymentToast", "1");
            window.location = BASE_API_URL + "userflow/dashboard-ongoing-plan/";
          } else if (decoded_res.data.fp_log_ids.length == 1) {
            var log_id = decoded_res.data.fp_log_ids[0];
            var res = await apiCall(
              BASE_API_URL +
              "restapi/userflow/getongoingplan/?user_id=" +
              btoa(getParentUserId()).toString() +
              "&web=1",
              "",
              false,
              false
            );
            try {
              var response = JSON.parse(commonEncode.decrypt(res));
            } catch {
              var response = res;
            }
            if (response.error_code == "100") {
              response.data.map((value) => {
                value.fp_log_id == log_id;
                updatesession_data = value;
              });
              if (updatesession_data != undefined) {
                var res = await apiCall(
                  BASE_API_URL + "restapi/updateauthdata/",
                  updatesession_data,
                  false,
                  false
                );
                try {
                  var response = JSON.parse(commonEncode.decrypt(res));
                } catch {
                  var response = res;
                }
                if (response) {
                  localStorage.setItem("showpaymentToast", "1");
                  sessionStorage.removeItem("renewal_popup");
                  sessionStorage.setItem("showAboutYouToast", "0");
                  // setTimeout(function(){
                  window.location =
                    process.env.PUBLIC_URL + "/datagathering/about-you";
                }
              }
            }
          }
        }
        if (decoded_res.error_code != "100") {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Something Went Wrong");
        }
      }
    }
  };
  const skippayment = () => {
    if (isNDAChecked) {
      if (planCartPay.length == 0) {
        setIsLoading(false);
      } else if (validatepaymentform()) {
        var subscription_freq = planCartPay.subscription_freq;
        var plan_sub_cat = planCartPay.plan_sub_cat_id;
        if (couponInput != "") {
          setIsRecurring(false);
          setIsRecurringDisabled(true);
        }
        setIsLoading(true);
        skiprazorpayment(subscription_freq, plan_sub_cat);
      }
    } else {
      setNDAMsg("Please accept Non-Disclosure Agreement.");
    }
  };
  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const razorPay = () => {
    if (isNDAChecked) {
      if (planCartPay.length == 0) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Please add Plan in your cart.");
      } else {
        var subscription_freq = planCartPay.subscription_freq;
        var plan_sub_cat = planCartPay.plan_sub_cat_id;

        if (validatepaymentform()) {
          checkout(subscription_freq, plan_sub_cat);
        }
      }
    } else {
      setNDAMsg("Please accept Non-Disclosure Agreement.");
    }
  };
  const checkout = async (subscription_freq = 1, plan_sub_cat = 1) => {
    let totalpaywithGSTP = totalpay + totalGST - discountTotal;
    totalpaywithGSTP = Math.floor(totalpaywithGSTP);
    if (couponInput != "") {
      setIsRecurring(false);
      setIsRecurringDisabled(true);
    }
    if (typeof rzp1 === "undefined") {
      if (isRecurring == false) {
        var apiURL = "restapi/userflow/createorder/";
      } else {
        var apiURL = "restapi/userflow/createsubscription/";
      }
      let data = commonEncode.encrypt(
        JSON.stringify({
          user_id: getParentUserId(),
          payment_amount: totalpaywithGSTP,
          subscription_months: subscription_freq,
          plan_sub_cat: plan_sub_cat,
          rm_id: rm_id,
        })
      );
      let res = await apiCall(BASE_API_URL + apiURL, data, false, false);
      try {
        var decoded_res = JSON.parse(commonEncode.decrypt(res));
      } catch {
        var decoded_res = res;
      }
      if (decoded_res) {
        var response = decoded_res.data;
        if (decoded_res.error_code == "100") {
          var countryCode = "91";
          var firstName = sessionData.first_name;
          var email = sessionData.email;
          var mobile = sessionData.mobile;
          if (sessionData.user_details) {
            var countryCode = sessionData.user_details.country_code
              ? sessionData.user_details.country_code
              : "91";
            var firstName = sessionData.user_details.first_name;
            var email = sessionData.user_details.email;
            var mobile = sessionData.user_details.mobile;
          }
          var options = {
            rzp_test_jMQKjxtY0nGGqJ: "WrEvgiywMPJYbshGWAsCBlaq", // Enter the Key ID generated from the Dashboard
            amount: "10021312", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            // "recurring":true,
            currency: "INR",
            name: "Fintoo",
            description: "Financial Planning",
            image:
              "https://www.fintoo.in/static/userflow/img/fintoo_razor_pay_logo.png",
            // "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            // "redirect":true,
            callback_url: "/razor_pay_payment_success/",
            // "handler": function (response){
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature)
            // alert(response.notes)
            // },
            prefill: {
              name: firstName,
              email: email,
              contact: countryCode + mobile,
            },
            notes: {
              address:
                "Financial Hospital B-403, Kanakia Wall Street, Andheri - Kurla Rd, Hanuman Nagar, Andheri East, Mumbai, Maharashtra 400093",
            },
            theme: {
              color: "#042b62",
            },
            readonly: {
              contact: true,
              email: true,
              name: true,
            },
            config: {
              display: {
                hide: [
                  {
                    method: "paylater",
                  },
                ],
              },
            },
          };
          const loadrzrpay = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
          );
          if (isRecurring == false) {
            options.order_id = response.id;
          } else {
            options.subscription_id = response.id;
          }
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            if (isRecurring == false) {
              var failure_payload = commonEncode.encrypt(
                JSON.stringify({
                  razorpay_order_id: response.error.metadata.order_id,
                  razorpay_payment_id: response.error.metadata.payment_id,
                })
              );
            } else {
              var failure_payload = commonEncode.encrypt(
                JSON.stringify({
                  razorpay_order_id: response.error.metadata.order_id,
                  razorpay_payment_id: response.error.metadata.payment_id,
                  razorpay_subscription_id: options.subscription_id,
                })
              );
            }
            let res2 = apiCall(
              BASE_API_URL + "restapi/userflow/razorpayfailure/",
              failure_payload,
              false,
              false
            );
            let decoded_res = JSON.parse(commonEncode.decrypt(res2));
            if (decoded_res) {
              window.location.href = "/payment_failure/";
            }
          });
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.data);
        }
      }
    }

    const skip_payment = async () => {
      var txnid = returnHash();
      var d = new Date();
      var addedon = d.toISOString();
      var date_code = addedon.replace(/\D/g, "");
      txnid = txnid + date_code;

      var skip_paydata = {
        txnid: txnid,
        addedon: addedon,
        productinfo: productInfopay,
        amount: "0",
        user_id: parseInt(getParentUserId()),
        phone: sessionData.user_details.mobile,
        email: sessionData.user_details.email,
        firstname: sessionData.user_details.first_name,
        subscription_months: sessionData.user_details.subscription_months,
      };
      if (planCartPay.length != 0) {
        var res = await apiCall(
          BASE_API_URL + "restapi/userflow/skippayment/",
          skip_paydata,
          false,
          false
        );
        try {
          var decoded_res = JSON.parse(commonEncode.decrypt(res));
        } catch {
          var decoded_res = res;
        }
        if (decoded_res) {
          if (decoded_res.error_code == "100") {
            if (decoded_res.data.fp_log_ids.length > 1) {
              localStorage.setItem("showpaymentToast", "1");
              window.location =
                BASE_API_URL + "userflow/dashboard-ongoing-plan/";
            } else if (decoded_res.data.fp_log_ids.length == 1) {
              var log_id = decoded_res.data.fp_log_ids[0];
              var res = await apiCall(
                BASE_API_URL +
                "restapi/userflow/getongoingplan/?user_id=" +
                btoa(getParentUserId()).toString() +
                "&web=1",
                "",
                false,
                false
              );
              var response = JSON.parse(commonEncode.decrypt(res));
              if (response.error_code == "100") {
                response.data.forEach(key, (value) => {
                  value.fp_log_id == log_id;
                  updatesession_data = value;
                });
                if (updatesession_data != undefined) {
                  var res = await apiCall(
                    BASE_API_URL + "restapi/updateauthdata/",
                    updatesession_data,
                    false,
                    false
                  );
                  var response = JSON.parse(commonEncode.decrypt(res));
                  if (response) {
                    localStorage.setItem("showpaymentToast", "1");
                    sessionStorage.removeItem("renewal_popup");
                    window.location =
                      process.env.PUBLIC_URL + "/datagathering/about-you";
                  }
                }
              }
            }
          } else if (response.error_code != "100") {
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error("Something Went Wrong");
          }
        }
      }
    };
  };
  const handleSubscriptionMonthsChange = async (e) => {
    setPlanCartPay([]);
    setTotalPay(0);
    setProductInfopay("");
    setTotalGST(0);
    setTotalPaywithGST(0);
    setDiscountTotal(0);
    setGrossPayable(0);
    const frq_dict = {
      1: "Monthly",
      3: "Quarterly",
      6: "Half Yearly",
      12: "Yearly",
    };
    const plan_frq = "Monthly";

    var res = await apiCall(
      BASE_API_URL +
      "restapi/userflow/getcartdata/?user_id=" +
      getParentUserId() +
      "&web=1" +
      "&subscription_months=" +
      subscriptionMonths,
      "",
      false,
      false
    );
    var response = JSON.parse(commonEncode.decrypt(res));
    if (response.error_code == "100") {
      response.data.cartdetails.forEach((product) => {
        setPlanImg(
          plan_types[product.plan_sub_cat_id].replace(" ", "_").toLowerCase()
        );
        setPlansubcat(plan_types[product.plan_sub_cat_id]);
        setMonthlyamount(product.monthly_amount);
        setTotalAmount(JSON.parse(product.plan_amount_detail));
        setPlanType(product.plan_type);
        setPlanFreq(frq_dict["" + product.subscription_freq]);
        var amount = JSON.parse(product.amount);
        setRMid(amount.rm_id);
        setRMdetailsName(product.emp_name);
        setRMdetailsPosition(product.emp_position);
        setRMdetailsImg(product.imagepath);
        setForQuarter("Q" + amount.for_quarter);
        setBaseAmount(amount.plan_amount);
        setTotalGST(amount.gst);
        setTotalPaywithGST(amount.total_pay);
        setTotalPay(amount.plan_amount);
        setGrossPayable(Math.floor(amount.after_discount));
        setNetPayable(amount.total_pay);
        setPlanCartPay(product);
        if (response.data.couponData.length > 0) {
          applyOffer(response.data.couponData[0], amount.plan_amount, [product]);
        }
        setSubscriptionMonths(product.subscription_freq);
      });
    } else {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Please add a plan to your cart.");
      setTimeout(() => {
        window.location.href = process.env.PUBLIC_URL + "/pricing/";
      }, 1000);
    }
  };
  const validatepaymentform = () => {
    var nda = true;
    var faa = true;
    if (isNDAChecked == false) {
      nda = false;
    }

    // if (isFAChecked == false) {

    //     faa = false;
    // }

    if (nda) {
      return true;
    } else {
      return false;
    }
  };
  const handleNDAClick = (e) => {
    if (e.target.checked == false) {
      setNDAMsg("Please accept Non-Disclosure Agreement.");
    } else {
      setNDAMsg("");
    }
    setNDAChecked(e.target.checked);
  };
  return (
    <>
      <div className="Paymentpage">
        <FintooLoader isLoading={isLoading} />
        <HideHeader />

        <div className="login-header">
          <a
            target="_self"
            href={
              getItemLocal("fhc")
                ? `${process.env.PUBLIC_URL}/financial-health-checkup`
                : `${process.env.PUBLIC_URL}/pricing`
            }
          >
            <div className="back-arrow">
              <img
                src={process.env.REACT_APP_BASE_API_URL + "static/userflow/img/icons/back-arrow.svg"}
                alt="Back Arrow"
              />
            </div>
          </a>
        </div>
        <section className="payment-section h-100 bg-white">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-6">
                <div className="login-block">
                  <form
                    action="{{redirect_url}}"
                    onSubmit={() => validatepaymentform()}
                    className="payment-form"
                    method="POST"
                  >
                    {/* {totalpay > discountTotal ?
                              (totalpay+totalGST)-discountTotal: ''} */}

                    <input
                      type="hidden"
                      name="productinfo"
                      value={productInfopay}
                    />
                    <input type="hidden" name="coupon" value={couponInput} />
                    <input
                      type="hidden"
                      name="user_id"
                      value={getParentUserId()}
                    />

                    <input
                      type="hidden"
                      name="firstname"
                      value={sessionData != "" ? sessionData.first_name : ""}
                    />
                    <input
                      type="hidden"
                      name="email"
                      value={sessionData != "" ? sessionData.email : ""}
                    />
                    <input
                      type="hidden"
                      name="isprofile"
                      value={sessionData != "" ? sessionData.mobile : ""}
                    />

                    <div className="row form-row">
                      <div className="col-md-6 subspay">
                        <div
                          className="custom-switch"
                          style={{ marginBottom: "0px", fontWeight: "500" }}
                        >
                          <span
                            style={{ fontSize: "20px", paddingLeft: "7px" }}
                            className="switch-label"
                          >
                            Subscription
                          </span>
                        </div>
                        {/* {forQuarter!="Q0"? 
                                    <div className="card_debt">
                                      <div className="Profile_Details">
                                          <div className="Sub_Details">
                                            <div>
                                              <span>
                                                {planImg}
                                                <img src={imagePath+"https://static.fintoo.in/static/userflow/img/icons/"+planImg+".png"} />
                                              </span>
                                            </div>
                                            <div
                                              className="Plan_Details" style={{marginLeft: "1rem !important"}} >
                                              <p className="plan_type">{ plansubcat?plansubcat:'' }</p>
                                              <p style={{marginTop: "-4px"}}>
                                                <span className="Price"
                                                  >&#8377; {rsFilter(monthlyAmount)}
                                                </span>

                                                <sub className="Plan_Dur"><i style={{display: "flex"}}>Per Month</i></sub>
                                              </p>
                                            </div>
                                            <span className="vertical"></span>
                                          </div>
                                          <div className="customer_Details">
                                            <div>
                                              {rmdetailsImg!=''? <span className="imgBx" ><img src={rmdetailsImg} alt="" /></span>:<span  className="imgBx">
                                              <img src="https://static.fintoo.in/static/userflow/img/profile-picture.svg"
                                              alt="" /></span> }
                                            </div>
                                            <div
                                              className="Cust_NM"
                                              style={{marginLeft: "1rem !important"}} >
                                              <h4 className="Cust_Name">{rmdetailsName}</h4>

                                              <p className="Role">{rmdetailsPosition}</p>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="Bill_Details">
                                        <p className="Bill_Plans">Billed Quaterly</p>
                                        <div style={{borderBottom: "0.8px solid #90a4aa" }}></div>
                                        <div className="Quater_Details">
                                          <div>Q1 - &#8377; {rsFilter(totalAmount.Q1) }</div>
                                          <span style={{borderRight: "0.8px solid #90a4aa"}}></span>
                                          <div>Q2 - &#8377; {rsFilter(totalAmount.Q2) }</div>
                                          <span style={{borderRight: "0.8px solid #90a4aa"}}></span>
                                          <div>Q3 - &#8377; {rsFilter(totalAmount.Q3) }</div>
                                          <span style={{borderRight: "0.8px solid #90a4aa"}}></span>
                                          <div>Q4 - &#8377; {rsFilter(totalAmount.Q4) }</div>
                                        </div>
                                      </div>
                                    </div>:
                                    ""}   */}

                        <div
                          className="custom-switch"
                          style={{ marginBottom: "5px", marginTop: "23px" }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              paddingLeft: "7px",
                              color: "grey",
                              fontWeight: "500",
                            }}
                          >
                            Financial Planning
                          </span>
                        </div>
                        <div className="paymt">
                          <div className="row form-row text-center">
                            <div className="col-md-6">
                              <div className="material-radio">
                                <ul className="inline" style={{ padding: 0 }}>
                                  <li className="radio subs_radio custom-background-color">
                                    <div className="row">
                                      <div
                                        className="col-md-8"
                                        style={{ padding: "10px" }}
                                      >
                                        {forQuarter == "Q0" ? (
                                          <label>
                                            {planType} - ({planFreq})
                                          </label>
                                        ) : (
                                          <label>
                                            {planType} ({forQuarter}) - (
                                            {planFreq})
                                          </label>
                                        )}
                                      </div>
                                      <div
                                        className="col-md-4"
                                        style={{
                                          padding: "10px",
                                          borderLeft: "1px solid #ccc",
                                          textAlign: "right",
                                          color: "#fff"
                                        }}
                                      >
                                        <span className="subs_right_amt">
                                          ₹{" "}
                                          {baseAmount
                                            ? rsFilter(baseAmount)
                                            : "0"}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row form-row"
                            style={{ display: "none" }}
                          >
                            <div className="col-md-6">
                              <ul className="custom-radio-block payment-options checked">
                                <li className="d-block">
                                  <label className="custom-radio-container">
                                    <img
                                      src={
                                        BASE_API_URL +
                                        "userflow/img/icons/credit-card.svg"
                                      }
                                    />
                                    PayU
                                    <input
                                      type="radio"
                                      checked="checked"
                                      name="radio"
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div style={{ width: "100%", height: "10px" }}>
                            &nbsp;
                          </div>
                          {couponApplied == "" && (
                            <div
                              className="row form-row text-center"
                              id="couponapply_section"
                              style={{
                                display: getItemLocal("fhc") ? "none" : ""
                              }}
                            >
                              <div className="col-md-6">
                                <div
                                  className="coupon-block material input applyoffer_div"
                                  onClick={() => openPopup("applyCoupon")}
                                  style={{
                                    margin: "0",
                                    maxWidth: "130px",
                                    height: "29px",
                                  }}
                                >
                                  <span>
                                    <img
                                      src="https://static.fintoo.in/static/userflow/img/icons/giftbox.png"
                                      alt="Apply Offer"
                                      style={{ float: "left", width: "15px" }}
                                    />
                                    <button
                                      type="button"
                                      value="Apply coupon code"
                                      className="apply-coupon-code custom-color"
                                    >
                                      Apply Offer
                                    </button>
                                    <b className="right_arrow"></b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {couponApplied != "" && (
                            <div
                              className="row form-row text-center"
                              id="couponapplied_section"
                              style={{
                                display: getItemLocal("fhc") ? "none" : ""
                              }}
                            >
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div
                                      className="coupon-block material input applyoffer_div"
                                      onClick={() => openPopup("applyCoupon")}
                                      style={{
                                        margin: "0px",
                                        width: "fit-content",
                                      }}
                                    >
                                      <span className="offerApplied">
                                        <img
                                          src="https://static.fintoo.in/static/userflow/img/icons/giftbox.png"
                                          alt="Apply Offer"
                                          style={{
                                            float: "left",
                                            width: "15px",
                                            marginTop: "3px",
                                          }}
                                        />
                                        &nbsp;
                                        <span
                                          className="offer-applied"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {couponApplied}
                                        </span>
                                        &nbsp;
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-4 text-right"
                                    style={{ marginTop: "8px" }}
                                  >
                                    <div
                                      className="delete"
                                      onClick={() =>
                                        deleteCoupon("offerApplied")
                                      }
                                    >
                                      <img
                                        // src={BASE_API_URL+"static/userflow/img/icons/delete.svg"}
                                        src="https://static.fintoo.in/static/userflow/img/icons/delete.svg"
                                        alt="Delete"
                                        width="16"
                                        height="16"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div style={{ width: "100%", height: "10px" }}>
                            &nbsp;
                          </div>
                          <div className="row form-row text-center">
                            <div className="col-md-6">
                              <ul className="pay-block">
                                <li>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <p style={{ textAlign: "left" }}>
                                        Total Payable Amount
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="text-right">
                                        ₹{totalpay ? rsFilter(totalpay) : "0"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                {couponApplied != "" && (
                                  <li
                                    className="color-light-blue offerApplied"
                                    id="offerApplied"
                                  >
                                    <div className="row">
                                      <div className="col-md-8">
                                        {/* <p style={{color:"#042b62",fontSize:"10px !important",textAlign:"left"}}> */}
                                        <p
                                          style={{
                                            fontSize: "10px !important",
                                            textAlign: "left",
                                          }}
                                        >
                                          {/* <span style={{color: "#000"}}>Discount Applied</span> (<span className="offer-applied">{couponApplied}</span>) */}
                                          <span>Discount Applied</span>{" "}
                                          <span className="custom-color">
                                            ({couponApplied})
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <p className="text-right custom-color">
                                          -₹ {discountTotal}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                )}
                                {/* {isCouponInvalid==true && <li
                                               className="color-pink"
                                               id="couponinvalid"
                                               
                                             >
                                              <p>Coupon invalid!</p>
                                             </li>} */}
                                {couponApplied == '' &&
                                  <li>
                                    <div className="row">
                                      <div className="col-md-8">
                                        <p style={{ textAlign: "left" }}>Discount applied</p>
                                      </div>
                                      <div className="col-md-4">
                                        <p className="text-right">-₹ {discountTotal ? discountTotal : 0.00}</p>
                                      </div>
                                    </div>
                                  </li>
                                }
                                <li>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <p style={{ textAlign: "left" }}>Gross payable</p>
                                    </div>
                                    <div className="col-4">
                                      <p className="text-right">₹ {rsFilter(totalpay - discountTotal)}</p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <p style={{ textAlign: "left" }}>GST</p>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="text-right">₹{totalpay - discountTotal ? rsFilter(totalGST) : 0}</p>
                                    </div>
                                  </div>
                                </li>
                                <li className="net_payable">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <p style={{ textAlign: "left" }}>
                                        Net Payable{" "}
                                        {/* <a
                                          href="#"
                                          onClick={() =>
                                            openPopup("billingDetails")
                                          }
                                        >
                                          <img
                                            style={{
                                              width: "15px",
                                              marginTop: "-10px",
                                            }}
                                            src={
                                              imagePath +
                                              "https://static.fintoo.in/static/userflow/img/icons/pink-information.svg"
                                            }
                                          />
                                        </a> */}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <p
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ₹
                                        {rsFilter(
                                          totalpay + totalGST - discountTotal
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* <div className="row form-row">
                                          <div className="col-md-8 offset-md-2">
                                            <ul className="agreement-block" style={{marginBottom: "0px"}}>
                                              <li className="material-checkbox">
                                                {(baseAmount==99 || baseAmount==1499 )&& 
                                                <div
                                                  className="checkbox-container"
                                                  style={{margin: "0px"}}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    name="recurringcheckbox"
                                                    id="recurringcheckbox"
                                                    checked={isRecurring}
                                                    disabled={isRecurringDisabled}
                                                    className="custom-checkbox"
                                                  />
                                                  <label for="auto_renew" className="checkbox-label"
                                                    >Enable Auto Renewal
                                                    <a
                                                      href=""
                                                      className="info_tooltip"
                                                      style={{border: "none !important", marginLeft: "10px"}}
                                                    >
                                                      <img
                                                        src={BASE_API_URL+"userflow/img/icons/pink-information.svg"}
                                                        style={{width: "15px"}}
                                                      />
                                                      <span className="tooltiptext"
                                                        >Selecting auto-renewal will automatically renew
                                                        your subscription "{planFreq}".</span
                                                      >
                                                    </a>
                                                  </label>
                                                </div>}
                                                
                                              </li>
                                           
                                            </ul>
                                          </div>
                                        </div> */}
                          <div className="row form-row">
                            <div className="col-md-8 offset-md-2">
                              <ul
                                className="agreement-block"
                                style={{ marginTop: "0px" }}
                              >
                                <li className="material-checkbox">
                                  {(forQuarter == "Q1" ||
                                    forQuarter == "Q0") && (
                                      <div
                                        className="checkbox-container"
                                        style={{ margin: "0px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          name="nda"
                                          id="ndacheck"
                                          className="custom-checkbox"
                                          tabIndex="1"
                                          // checked={isNDAChecked}
                                          onChange={handleNDAClick}
                                          style={{ zIndex: "2 !important" }}
                                        />
                                        <label
                                          for="ndacheck"
                                          className="checkbox-label"
                                        >
                                          I have read and accept the
                                          <Link
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/userflow/nda/"
                                            }
                                            className="custom-color ms-2"
                                          >
                                            NDA - Non-Disclosure Agreement
                                          </Link>
                                          <br />
                                          <span className="error">{NDAMsg}</span>
                                        </label>
                                      </div>
                                    )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row form-row mt-4">
                            <div className="col">
                              <div className="btn-container text-center">
                                {totalpay + totalGST - discountTotal > 0 && (
                                  <button
                                    type="button"
                                    id="razorPay"
                                    className="default-btn"
                                    onClick={() => razorPay()}
                                    style={{ fontSize: "16px" }}
                                  >
                                    Pay
                                  </button>
                                )}
                                {totalpay + totalGST - discountTotal == 0 && (
                                  <button
                                    type="button"
                                    onClick={() => skippayment()}
                                    className="default-btn"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Pay
                                  </button>
                                )}

                                {/* <a
                                            type="hidden"
                                            id="razorpaystartprocess"
                                            onclick="loaderscreen('show');"
                                          ></a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </form>
                </div>
              </div>
              <div className="col-md-6 bg-illustration">
                <img src={process.env.PUBLIC_URL + "/static/media/login-illustration.svg"} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          className={`popup apply-popup  ${isBookingPopUpActive ? "active" : ""
            }`}
        >
          <div
            className="popup-container"
            style={{ maxHeight: "90vh", overflow: "hidden" }}
          >
            <a href="#" className="p_close js__p_close" title="Close">
              <img
                src={BASE_API_URL + "assets/userflow/img/cancel.png"}
                alt=""
              />
            </a>
            <div className="popup-wrapper">
              <div
                className="header-box d-flex popup-header text-center"
                style={{ background: "white !important" }}
              >
                <input type="hidden" name="rm_id" id="rm_id" value={rm_id} />

                <h4
                  className="page-header"
                  style={{
                    color: "#9cd155",
                    fontWeight: "bold",
                    fontSize: "24px !important",
                  }}
                >
                  Book Appointment With Expert
                </h4>
              </div>
              <div
                style={{
                  borderBottom: "0.8px solid #90a4aa",
                  marginRight: "20px",
                  marginLeft: "20px",
                }}
              ></div>
              <div className="popup-body">
                <span>
                  <div>
                    <p className="Appt_Des">
                      Intoductory call with our financial Advisor to know more
                      about our offerings and advice
                    </p>
                  </div>
                  <div id="Cal_API">
                    <div
                      className="calendly-inline-widget"
                      data-url="https://calendly.com/fintoo/15-minutes-consultation-expert?embed_domain=minty.co.in&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1&name={{calendlydata.name}}&email={{calendlydata.email}}&a3=+91{{calendlydata.mobile}}&a4={{rmdetails_name}}"
                      style={{ minWidth: "320px", height: "630px" }}
                    ></div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`popup apply-popup  ${isCouponPopUpActive ? "active" : ""
            }`}
          id="applyCoupon"
        >
          <div className="popup-container">
            <div className="popup-wrapper">
              <div className="header-box d-flex popup-header text-center">
                <h2 className="page-header ">Apply Offer</h2>
                <a
                  href="#"
                  onClick={() => removePopup()}
                  className="popup-closebtn"
                >
                  ×
                </a>
              </div>
              <div className="popup-body">
                <span>
                  <form className="ng-pristine ng-valid">
                    <ul className="coupon-list" style={{ margin: "10px 0" }}>
                      <li>
                        <div
                          className="coupon-name"
                          style={{ alignItems: "flex-start" }}
                        >
                          <div>
                            <input
                              type="text"
                              name="coupon_code"
                              id="coupon_code"
                              className="coupon"
                              value={enteredCoupon}
                              onChange={(e) =>
                                handleCouponCodeChange(e.target.value)
                              }
                              style={{
                                width: "128px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                              placeholder="Enter Coupon"
                            />
                            <br />
                            {isCouponInvalid && (
                              <p
                                id="c_error"
                                className="error text-center"
                                style={{ display: "block" }}
                              >
                                Invalid Coupon
                              </p>
                            )}
                          </div>
                          <button
                            type="button"
                            value="Apply"
                            onClick={() => applyOfferManual()}
                            className="coupon1 default-btn"
                            style={{ fontSize: "16px", padding: "8px 50px" }}
                          >
                            Apply
                          </button>
                        </div>
                      </li>
                      {couponsList.length >= 1 &&
                        couponsList.map((coupon) => {
                          <li>
                            <div class="coupon-name">
                              <p
                                class="coupon"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "128px",
                                  padding: "8px 15px",
                                }}
                              >
                                {coupon.c_code}
                              </p>
                              {couponInput == coupon.c_id && (
                                <button
                                  ng-if="couponinput==offer.c_id"
                                  disabled="disabled"
                                  type="button"
                                  value="Apply"
                                  class="coupon1 default-btn FP10120"
                                >
                                  Coupon Applied
                                </button>
                              )}
                              {couponInput != coupon.c_id && (
                                <button
                                  type="button"
                                  value="Apply"
                                  onClick={() => applyOffer1(coupon)}
                                  class="coupon1 default-btn"
                                >
                                  Apply
                                </button>
                              )}
                            </div>
                            <div class="coupon-note">
                              {coupon.c_ispercentage == 1 && (
                                <h3 style={{ fontSize: "15px !important" }}>
                                  Save {coupon.c_discount}% OFF
                                </h3>
                              )}
                              {coupon.c_ispercentage == 1 && (
                                <h3 style={{ fontSize: "15px !important" }}>
                                  Save ₹{coupon.c_discount} OFF
                                </h3>
                              )}

                              <p>{coupon.c_desc}</p>
                            </div>
                          </li>;
                        })}
                    </ul>
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`popup apply-popup bill-details-popup ${isBillingPopupActive ? "active" : ""
            }`}
          id="billingDetails"
        >
          <div className="popup-container">
            <div className="popup-wrapper">
              <div className="header-box d-flex popup-header text-center">
                <h2 className="page-header">Billing Detail</h2>
                <a
                  href="#"
                  onClick={() => removePopup()}
                  className="popup-closebtn"
                >
                  ×
                </a>
              </div>
              <div className="popup-body">
                <ul className="bill-details-list">
                  <li className="two-col-list">
                    <p>Total payable Amount</p>
                    <p>₹ {totalpay ? totalpay : ""}</p>
                  </li>

                  <li className="two-col-list color-blue f-bold">
                    <p>Discount applied</p>
                    <p>-₹ {discountTotal ? discountTotal : 0.0}</p>
                  </li>
                  <li className="two-col-list f-bold outline">
                    <p>Gross payable</p>
                    <p>₹ {rsFilter(totalpay - discountTotal)}</p>
                  </li>
                  <li className="two-col-list">
                    <p className="pb-0">GST</p>
                    <p className="pb-0">
                      ₹{totalpay - discountTotal ? rsFilter(totalGST) : 0}
                    </p>
                  </li>

                  <li className="two-col-list f-bold fill-row">
                    <p>Net payable</p>
                    <p>₹ {rsFilter(totalpay + totalGST - discountTotal)}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <HideFooter />
      </div>
    </>
  );
};
export default PaymentPage;
