import React from "react";
import CalcListView from "../components/HTML/CalcList";

function CalcList() {
    return (
        <CalcListView/>
    );
}

export default CalcList;
