import { useEffect, useRef, useState } from "react";
import Item from "../components/ReactSimpleSelect/Item";
import * as React from 'react';
import style from "./style.module.css";
import ReactSimpleSelectForTaxPlanning from "./ReactSimpleSelectForTaxPlanning";
import axios from "axios";
import { getItemLocal, getParentUserId, getUserId } from "../common_utilities";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import StepComponent from "../components/StepsComponent";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { BASE_API_URL } from "../constants";
import { openDialog } from "./DMF/CommonDashboard/CommonDashboardComponents/ConfirmationDialog/ConfirmHandler";
import MemberLayout from "../components/Layout/MemberLayout";
import MainLayout from "../components/Layout/MainLayout";
import uuid from 'react-uuid';

const UploadDocsPage = () => {

    const [trackData, SetTrackData] = useState({
        docLength: 0,
        remark: ''
    })
    const [Documents, setDocumentData] = useState([]);

    const [UploadedDocuments, setUploadedDocuments] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [exceedingSizeItemIndex, setSexceedingSizeItemIndex] = useState(null)
    const [appointmentDetails, setAppointmentDetails] = useState(null)
    const [remark, setRemark] = useState("");
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    const uploadRef = useRef();
    let navigate = useNavigate();

    const userid = getUserId();


    const member = getItemLocal('pd') ? getItemLocal('pd') : "";
    const docUserId = member.user_id

    const handleError = () => {
        dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Please Select Document Type First.", type: "error" },
        });
        setErrorMessage("Please Select Document Type First.");
    };

    const hasError = (idx) => {
        return exceedingSizeItemIndex && exceedingSizeItemIndex.idx == idx
    }

    function isCorrectExtention(ext) {
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'tiff':
            case 'zip':
            case 'pdf':
            case 'png':
                return true;
        }
        return false;
    }

    const onFileChange = (e, j, localId) => {

        const fileSize = e.target.files[0].size / Math.pow(1024, 2)
        const extentionType = e.target.files[0].type.split("/").pop()

        if (!isCorrectExtention(extentionType)) {

            setSexceedingSizeItemIndex({
                idx: localId, msg: 'allowed formats are - jpg, jpeg, png, tiff, zip and pdf.'
            })
        }
        if (fileSize > 5) {
            setSexceedingSizeItemIndex({
                idx: localId, msg: 'Max allowed file size is 5 MB.'
            })
        } else {
            setUploadedDocuments(prev => prev.map(data => {
                if (data.localId == localId) {
                    return {
                        ...data,
                        fileData: e.target.files[0],
                        doc_name: e.target.files[0].name,
                        isNewDoc: true
                    }
                }
                return data
            }));
            return setSexceedingSizeItemIndex(null)
        }
    };

    const getObjUrl = (v) => {
        if (v) {
            const url = URL.createObjectURL(v);
            return url
        } else {
            return ''
        }
    };

    const showUploadOption = (ob) => {
        return !ob.hasOwnProperty('fileData') && !ob.doc_read_link
    }

    const deleteOldPhoto = async (document_id, j, localId) => {

        // const containsId = UploadedDocuments[j].doc_id;

        if (!document_id) {
            return setUploadedDocuments(prev => prev.filter((data) => data.localId !== localId));
        } else {
            const result = await openDialog("Delete Confirmation", 'Are you sure you want to delete this document?');
            if (!result) return;
            setUploadedDocuments((x) => x.filter((xx, ii) => ii !== j));
        }

        try {
            const FintooUserAppointmentInfo = JSON.parse(localStorage.getItem('FintooUserAppointmentInfo'));

            if (!FintooUserAppointmentInfo?.appointment_id) {
                return navigate(`${process.env.PUBLIC_URL}/commondashboard`);
            }

            const res = await axios.post(`${BASE_API_URL}restapi/appointment/DeleteAppointmentDocuments/`, {
                "user_id": Number(userid),
                "document_id": document_id,
                "appointment_id": FintooUserAppointmentInfo.appointment_id
            });

            const { data } = res.data;

            dispatch({
                type: 'RENDER_TOAST',
                payload: { message: data.message, type: 'success' },
            });
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    const onFileUpload = async () => {
        if (!checkDocVerification()) {
            return;
        }
        setIsDisabled(true);

        uploadRef.current = UploadedDocuments;

        let formdata = new FormData();

        formdata.append("user_id", Number(userid));
        formdata.append("appointment_id", appointmentDetails.appointment_id);
        formdata.append("document_remarks", remark);

        const docTypeArr = []

        UploadedDocuments.forEach(fileData => {
            if (fileData.isNewDoc) {
                docTypeArr.push(fileData.doc_type)
                formdata.append("document_files", fileData.fileData);
            }
        })

        formdata.append("document_type", docTypeArr);

        if (UploadedDocuments.some(data => data.isNewDoc) || trackData.remark != remark) {
            axios.post(`${BASE_API_URL}restapi/appointment/updateappointmentdocument/`, formdata)
                .then(res => {
                    if (res.data.error_code === "100") {
                        toastr.options.positionClass = "toast-bottom-left";
                        toastr.success('Data updated successfully');

                    } else {
                        toastr.options.positionClass = "toast-bottom-left";
                        toastr.error(res.data.message || 'Something went wrong while uploading documents');
                    }
                    return navigate(`${process.env.PUBLIC_URL}/commondashboard`);
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            return navigate(`${process.env.PUBLIC_URL}/commondashboard`);
        }
    };

    useEffect(() => {
        document.body.classList.add("white-bg");
        return (() => document.body.classList.remove("white-bg"))
    }, [])

    const stepsData = [
        {
            current: false,
            stepCompleted: true,
            name: 'Select the Expert',
        },
        {
            current: false,
            stepCompleted: true,
            name: 'Pay for Consultancy',
        },
        {
            current: false,
            stepCompleted: true,
            name: 'Book an Appointment',
        },
        {
            current: true,
            stepCompleted: false,
            name: 'Upload Documents',
        }
    ]

    useEffect(() => {

        if (getItemLocal("family") == '1') {
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error('Please select member');
            return navigate(`${process.env.PUBLIC_URL + '/commondashboard'}`);
        }

        const FintooUserAppointmentInfo = JSON.parse(localStorage.getItem('FintooUserAppointmentInfo'));

        if (!FintooUserAppointmentInfo?.appointment_id) {
            return navigate(`${process.env.PUBLIC_URL}/commondashboard`);
        }

        setAppointmentDetails(FintooUserAppointmentInfo)

        axios.post(`${BASE_API_URL}restapi/getdocumentlistbycategory/`, { "doc_cat_id": 13 })
            .then(res => {
                const docList = res.data.data.map(doc => ({
                    "title": doc.dt_name,
                    "value": doc.dt_id
                }));
                setDocumentData(docList);
            })
            .catch(error => {
                console.error('Error fetching document types:', error);
            });

        axios.post(`${BASE_API_URL}restapi/appointment/fetchappointmentdocuments/`, {
            "user_id": Number(userid),
            "appointment_id": FintooUserAppointmentInfo.appointment_id
        }).then(res => {
            if (res.data.error_code == 102) {
                toastr.options.positionClass = "toast-bottom-left";
                toastr.error(res.data.message);
                return navigate(`${process.env.PUBLIC_URL}/commondashboard`);
            }
            const doc_data = res.data.data.document_details.filter((v) => v.doc_read_link != "File not found!");
            setRemark(res.data.data.appointment_remarks)
            if (doc_data.length > 0) {
                setUploadedDocuments(() => doc_data.map(data => ({ ...data, localId: uuid() })));
            } else {
                setUploadedDocuments([{ doc_type: '', localId: uuid() }]);
            }
            SetTrackData({
                docLength: doc_data.length,
                remark: res.data.data.appointment_remarks
            })
        });
    }, [])

    const getType = (val) => {
        const currDoc = Documents.filter(data => data.value == val)[0]
        return currDoc.title
    }

    const checkDocVerification = () => {
        const hasEmptyFileData = UploadedDocuments.some(data => {
            return !Boolean(data.doc_read_link) && Boolean(data.doc_type) && !Boolean(data.isNewDoc)
        })

        if (hasEmptyFileData) {
            dispatch({
                type: "RENDER_TOAST",
                payload: { message: "Please Select Document.", type: "error" },
            });

            return false;
        }

        return true;
    }

    return (
        <MainLayout>
            {/* <MemberLayout> */}
                <>
                    <div className={style.stepComponentContainer}>
                        <StepComponent stepsData={stepsData} />
                    </div>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h3 className="text-center display-6">Upload Documents</h3>
                                    <div
                                        style={{
                                            color: "#888",
                                            fontWeight: "600",
                                            textAlign: "center",
                                        }}>
                                    </div>
                                    <p className={`text-center ${style.subtitle}`}>
                                        Submit Documents to Help Our Tax Experts Better Understand Your query
                                    </p>
                                    <p className={`text-center ${style.subtitle}`} style={{ fontSize: '0.9rem' }} >
                                        ( Allowed formats are - jpg, jpeg, png, tiff, zip and pdf, and max allowed file size is 5 MB.)
                                    </p>
                                    <br />
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>File Type</th>
                                                <th>File Name</th>
                                                <th style={{ width: 40 }}>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {UploadedDocuments.map((v, j) => (
                                                <tr key={v.localId} className={style.trField} style={{ position: 'relative' }}>
                                                    <td className="col-6" style={{ position: 'relative' }}>
                                                        {
                                                            v.doc_id ? <span>{getType(v.doc_type)}</span> : <ReactSimpleSelectForTaxPlanning
                                                                options={Documents.map((w) => {
                                                                    return { title: w.title, value: w.value };
                                                                })}
                                                                value={v.doc_type}
                                                                onChange={(x) => {
                                                                    setUploadedDocuments(prev => prev.map(data => {
                                                                        if (data.localId == v.localId) {
                                                                            return {
                                                                                ...data,
                                                                                doc_type: x
                                                                            }
                                                                        }
                                                                        return data;
                                                                    }));
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                    <td className="col-6" >
                                                        <>
                                                            {(v.fileData || v.doc_read_link) && (
                                                                <div className="d-flex">
                                                                    <>
                                                                        <div>
                                                                            <p className="mb-0">
                                                                                {v.doc_name}
                                                                            </p>
                                                                            {v.doc_read_link && <a
                                                                                className={`mb-0 ${style["preview-link"]}`}
                                                                                style={{ color: '#042b62' }}
                                                                                href={v.isNewDoc ? getObjUrl(v.fileData) : v.doc_read_link}
                                                                                target="_blank"
                                                                            >
                                                                                Preview
                                                                            </a>}
                                                                        </div>
                                                                    </>
                                                                    {Boolean(v.finishedUpload) && (
                                                                        <i
                                                                            style={{
                                                                                paddingLeft: "1rem",
                                                                                color: "green",
                                                                            }}
                                                                            class="fa-solid fa-check"
                                                                        ></i>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>

                                                        {
                                                            showUploadOption(v) && (
                                                                // true && (
                                                                <>
                                                                    <div
                                                                        className={`${style.buttonChoose} d-inline-flex pointer`}
                                                                        onClick={() => {
                                                                            if (v.doc_type == "") {
                                                                                handleError();
                                                                            } else {
                                                                                document
                                                                                    .getElementById(v.localId)
                                                                                    .click();
                                                                            }
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="file"
                                                                            id={v.localId}
                                                                            onClick={function (e) {
                                                                                e.target.value = null
                                                                            }}
                                                                            onChange={(e) => {
                                                                                onFileChange(e, j, v.localId)
                                                                            }}
                                                                            style={{
                                                                                display: "none",
                                                                            }}
                                                                        />
                                                                        <div className={style.imagebox}>
                                                                            <img
                                                                                src={
                                                                                    process.env.PUBLIC_URL +
                                                                                    "/static/media/ITR/up-loading_3.png"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className={style.chooseText}>
                                                                            Choose File
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                    </td>
                                                    <td>
                                                        <div
                                                            onClick={() => {
                                                                deleteOldPhoto(v.doc_id, j, v.localId);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </div>


                                                    </td>
                                                    {hasError(v.localId) && <p style={{ position: 'absolute', color: 'red', top: '62%', bottom: '-55%', left: '0' }}>{exceedingSizeItemIndex.msg}</p>}
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                    {
                                        UploadedDocuments.length < 5 && <div className="py-3">
                                            <p
                                                className={style.addMoreBtn}
                                                onClick={() => {
                                                    setUploadedDocuments(prev => [...prev, { doc_type: "", localId: uuid() }]);
                                                }}
                                            >
                                                + Add More
                                            </p>
                                        </div>
                                    }

                                    <div className="py-3">
                                        <textarea
                                            rows="3"
                                            className={style.textarea}
                                            placeholder="If you have any comments or queries related to ITR, Please mention here!"
                                            value={remark ?? ''}
                                            onChange={(e) => {
                                                setRemark(e.target.value);
                                            }}
                                        ></textarea>
                                    </div>
                                    <div className="pt-3 d-flex gap-3 justify-content-center">
                                        <button
                                            disabled={isDisabled}
                                            style={{ margin: '0', padding: '0.5rem 1rem', width: '40%' }}
                                            type="button"
                                            className={style.btn}
                                            onClick={() => onFileUpload()}
                                        >
                                            Save & Continue
                                        </button>
                                        <Link to={`${process.env.PUBLIC_URL}/commondashboard`} style={{ textDecoration: 'none', width: '40%' }}>
                                            <button
                                                // disabled={isDisabled}
                                                style={{ margin: '0', padding: '0.5rem 1rem', width: '100%' }}
                                                type="button"
                                                className={style.btnOutline}
                                            >
                                                Skip
                                            </button>
                                        </Link>

                                    </div>

                                </div>
                                <div className="d-none d-md-block col-6">
                                    <img
                                        className="img-fluid"
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/ITR/01_personal_data.21c9b7453073cdd0b44e.png"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </>
            {/* </MemberLayout> */}
        </MainLayout>
    );
};


export default UploadDocsPage;




