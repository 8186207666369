import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewGoldFormView from "./NewGoldFormView";

const NewGoldForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewGoldFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewGoldForm;
