import { fetchData } from "../common_utilities";
import { BASE_API_URL } from "../constants";
import { apiCall } from "../common_utilities";
import { useEffect } from "react";

const CalendlyCallback = () => {

  const incomeSlabAnswers = ['$60K to $100K', '$100K to $500K', '$500K & Above'];
  const incomeSlabAnswersuae = ['0 to 45k AED', '45k to 155k AED', '155k to 222k AED', '222k to 444k AED', 'Above 444K AED'];
  const incomeSlabAnswerslandingPage = ['0 to 10 Lac', '10 Lac to 25 Lac', '25 Lac to 50 Lac', '50 Lac to 1 Crore', 'Above 1 Crore'];
  const incomeSlabAnswerswealthmanagementlandingPage = ['0 to 25K AED', '25K to 50 AED', '50K AED & Above'];
  const incomeSlabAnswerspersonalfinancepage = ['0 to 25K AED', '25K to 50K AED', '50K AED & Above'];

  const onLoad = async () => {
    try {
      const eventURL = localStorage.getItem("eventURL");
      const eventType = localStorage.getItem("eventType");
      const serviceName = localStorage.getItem("serviceName");
      const extraParams = localStorage.getItem("extraParams") == null ? {} : JSON.parse(localStorage.getItem("extraParams"));
      localStorage.removeItem("eventURL");
      localStorage.removeItem("eventType");
      localStorage.removeItem("serviceName");
      localStorage.removeItem("extraParams");

      localStorage.removeItem("email");
      localStorage.removeItem("fullname");
      localStorage.removeItem("mobile");
      localStorage.removeItem("itr-page");

      if (eventURL == null) return;

      if (eventType == null) {
        eventType = "Callback_mintyApp_8";
      }
      if (serviceName == null) {
        serviceName = "Financial Planning";
      }
      // Check if the page URL is "web/wealth-management"
      const isWealthManagementPage = window.location.pathname.indexOf("web/wealth-management") > -1;

      const incomeQuestion = isWealthManagementPage ? "Monthly Income" : "Income Slab";

      const r = await fetchData({
        url: eventURL,
        method: "GET",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjExMjQ0LCJqdGkiOiJmMmM1YWIwOC01N2ZiLTQ0YzAtODNjYy1lM2QxZWZhZGY2YzMiLCJ1c2VyX3V1aWQiOiI0ODVhZTAyZC02ZGNiLTQ1MjktODdiYi01MGY2NDE3NGI4ZWYifQ.5bIIwHH3DTn1Vp7Oj6hZlLkVIbI1q7jxqFogGaGkb1g",
        },
      });

      console.log("sdfr------->", r.resource);
      console.log("sdfr------->", r.resource['questions_and_answers'][1]['answer']);


      const mobileArr = r.resource.questions_and_answers.filter(
        (v) => v.question.toLowerCase().indexOf("mobile") > -1
      );
      if (mobileArr.length > 0) {
        console.log('mobileArr', mobileArr);
        var mobile = mobileArr[0]["answer"].replace(/[^0-9]/g, "");
        mobile = mobile.substring(mobile.length - 10, mobile.length);

        let payload = {
          method: "POST",
          url: process.env.REACT_APP_CONTACT_FORM,
          data: {
            fullname: r.resource.name,
            email: r.resource.email,
            tagval: "",
            servicename: serviceName,
            tags: eventType,
            utm_source: "26",
            mobile: "" + mobile,
            skip_sms: "",
            rm_id: "96",
            status: "Introductory meet",
            ...extraParams
          },
        };

        // add custom params if any
        const incomeSlab = r.resource.questions_and_answers.filter(
          (v) => v.question.toLowerCase().includes("monthly income") || v.question.toLowerCase().includes("income slab")
        );

        // console.log('sdf incomeSlab', incomeSlab, incomeQuestion.toLowerCase());
        if (incomeSlab.length > 0) {
          payload.data.incomeslab = incomeSlabAnswers.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
          if (payload.data.incomeslab == 0) {
            payload.data.incomeslab = incomeSlabAnswerslandingPage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;

          }
          if (payload.data.incomeslab == 0) {
            payload.data.incomeslab = incomeSlabAnswersuae.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;

          }
          if (payload.data.incomeslab == 0) {
            payload.data.incomeslab = incomeSlabAnswerswealthmanagementlandingPage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
          }
          if (payload.data.incomeslab == 0) {
            payload.data.incomeslab = incomeSlabAnswerspersonalfinancepage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
          }
        }
        console.log("sdf shubham yyyy =====>>>>> ", payload.data.incomeslab)
        // end
        console.log('nil rt', payload);
        console.log("nil m--------->123");
        const r1 = await fetchData(payload);
        console.log("nil rr----->", r1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (<></>);
};
export default CalendlyCallback;

export const calendlyCallbackFun = async (scheduleData) => {

  const incomeSlabAnswers = ['$60K to $100K', '$100K to $500K', '$500K & Above'];
  const incomeSlabAnswersuae = ['0 to 45k AED', '45k to 155k AED', '155k to 222k AED', '222k to 444k AED', 'Above 444K AED'];
  const incomeSlabAnswerslandingPage = ['0 to 10 Lac', '10 Lac to 25 Lac', '25 Lac to 50 Lac', '50 Lac to 1 Crore', 'Above 1 Crore'];
  const incomeSlabAnswerswealthmanagementlandingPage = ['0 to 25K AED', '25K to 50 AED', '50K AED & Above'];
  const incomeSlabAnswerspersonalfinancepage = ['0 to 25K AED', '25K to 50K AED', '50K AED & Above'];

  try {
    const eventURL = scheduleData.eventURL;
    const eventURL2 = scheduleData.eventURL2;
    const eventType = scheduleData.eventType;
    const serviceName = scheduleData.serviceName;
    const planId = scheduleData.planId;
    const extraParams = scheduleData.extraParams == null ? {} : scheduleData.extraParams;

    const email = scheduleData.email;
    const fullname = scheduleData.fullname;
    const mobileNumber = scheduleData.mobileNumber;

    if (eventURL == null) return;

    if (eventType == null) {
      eventType = "Callback_mintyApp_8";
    }
    if (serviceName == null) {
      serviceName = "Financial Planning";
    }
    // Check if the page URL is "web/wealth-management"
    const isWealthManagementPage = window.location.pathname.indexOf("web/wealth-management") > -1;

    const incomeQuestion = isWealthManagementPage ? "Monthly Income" : "Income Slab";

    const r = await fetchData({
      url: eventURL,
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjExMjQ0LCJqdGkiOiJmMmM1YWIwOC01N2ZiLTQ0YzAtODNjYy1lM2QxZWZhZGY2YzMiLCJ1c2VyX3V1aWQiOiI0ODVhZTAyZC02ZGNiLTQ1MjktODdiYi01MGY2NDE3NGI4ZWYifQ.5bIIwHH3DTn1Vp7Oj6hZlLkVIbI1q7jxqFogGaGkb1g",
      },
    });

    let payload = {
      method: "POST",
      url: process.env.REACT_APP_CONTACT_FORM,
      data: {
        fullname: fullname ? fullname : r.resource.name,
        email: email ? email : r.resource.email,
        tagval: "",
        servicename: serviceName,
        tags: eventType,
        utm_source: "26",
        mobile: mobileNumber ? mobileNumber : "",
        skip_sms: "",
        rm_id: "96",
        status: "Introductory meet",
        ...extraParams
      },
    };

    const incomeSlab = r.resource.questions_and_answers.filter(
      (v) => v.question.toLowerCase().indexOf(incomeQuestion.toLowerCase()) > -1
    );

    if (incomeSlab.length > 0) {
      payload.data.incomeslab = incomeSlabAnswers.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
      if (payload.data.incomeslab == 0) {
        payload.data.incomeslab = incomeSlabAnswerslandingPage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;

      }
      if (payload.data.incomeslab == 0) {
        payload.data.incomeslab = incomeSlabAnswersuae.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;

      }
      if (payload.data.incomeslab == 0) {
        payload.data.incomeslab = incomeSlabAnswerswealthmanagementlandingPage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
      }
      if (payload.data.incomeslab == 0) {
        payload.data.incomeslab = incomeSlabAnswerspersonalfinancepage.indexOf(incomeSlab[0]['answer'].split(" ").filter(v => v).join(" ")) + 1;
      }
    }

    if (!scheduleData.loggedIn) {
      const user_register_payload = {
        "fullname": fullname ? fullname : r.resource.name,
        "mobile": mobileNumber ? mobileNumber : "",
        "email": email ? email : r.resource.email,
        "country_code": "91",
        "tags": "fin_web_reg",
        "utm_source": 27,
        "service": "98",
        "skip_mail": "1",
        "rm_id": "96",
        "react": "1",
        "plan_id": "",
        "is_expert": "1"
      }

      await apiCall(
        BASE_API_URL + "restapi/UserRegisterApi/",
        user_register_payload,
        false,
        false
      );
    }

    const r1 = await fetchData(payload);

    if (r1.error_code == "0") {

      const r = await fetchData({
        url: eventURL2,
        method: "GET",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjExMjQ0LCJqdGkiOiJmMmM1YWIwOC01N2ZiLTQ0YzAtODNjYy1lM2QxZWZhZGY2YzMiLCJ1c2VyX3V1aWQiOiI0ODVhZTAyZC02ZGNiLTQ1MjktODdiYi01MGY2NDE3NGI4ZWYifQ.5bIIwHH3DTn1Vp7Oj6hZlLkVIbI1q7jxqFogGaGkb1g",
        },
      });

      const appointmentScheduledData = {
        date: (new Date(r.resource.start_time)).toLocaleDateString('en-ca'),
        time: ((new Date(r.resource.start_time)).toLocaleTimeString('en-US', { hour12: false })),
        meeting_link: r.resource.location.join_url,
        appointment_uri: r.resource.uri,
        income_slab: payload.data.incomeslab
      }

      const payload_data = {
        "user_id": Number(r1.data.u_id),
        "plan_id": Number(planId),
        "expert_id": 96,
        // "appointment_status":1,
        "date": appointmentScheduledData.date,
        "time": appointmentScheduledData.time,
        "meeting_link": appointmentScheduledData.meeting_link,
        "latest_appointment_uri": appointmentScheduledData.appointment_uri,
        "income_slab": appointmentScheduledData.income_slab
      }

      await apiCall(
        BASE_API_URL + "restapi/appointment/createappointment/",
        payload_data,
        false,
        false
      );

    }
  } catch (e) { }
};

