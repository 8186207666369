import React, { useEffect, useState } from "react";
import Styles from "./style.module.css";
import { RiFileUserLine } from "react-icons/ri";
import { FcBusinessman } from "react-icons/fc";
import MaleAvatar from "../Assets/03_02_Introduction_About_me_Male.png";
import FemaleAvatar from "../Assets/03_02_Introduction_About_me_Female.png";
import Profile from "../Assets/03_01_Introduction_banner.png";
import Name from "../Assets/03_02_01_Introduction_name.png";
import Age from "../Assets/03_04_Introduction_age.png";
import Gender from "../Assets/03_02_03_introduction_age.png";
import RetireAge from "../Assets/03_02_04_introduction_Retirement_Age.png";
import LifeExpectency from "../Assets/03_02_05_introduction_Life_Expectancy.png";
import Mail from "../Assets/03_02_05_introduction_email.png";
import MyFamily from "../Assets/03_03_Introduction_my_family.png";
import Spouse from "../Assets/03_03_01_Introduction_spouse.png";
import Son from "../Assets/03_04_Introduction_son.png";
import Daughter from "../Assets/03_05_Introduction_son.png";
import Father from "../Assets/03_09_Introduction_father.png";
import Mother from "../Assets/03_10_Introduction_mother.png";
import HUF from "../Assets/03_11_Introduction_huf.png";
import ReportHeader from "../ReportHeader";
import ReportFooter from "../ReportFooter";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
  capitalize,
} from "../../../../common_utilities";
import {
  ADVISORY_YOUR_PROFILE_API_URL,
  CHECK_SESSION,
} from "../../../../constants";

function ProfileReport() {
  const [userAvatar, setUserAvatar] = useState("");
  const [userSessionData, setUserSessionData] = useState({});
  const [userProfileData, setUserProfileData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [earningMembers, setEarningMembers] = useState([]);
  const [dependentMembers, setDependentMembers] = useState([]);
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [retirementage, setRetirementAge] = useState("");
  const [lifeexpectancyage, setLifeExpentancyAge] = useState("");
  const userid = getParentUserId();
  const userIcon = {
    Spouse: Spouse,
    Son: Son,
    Daughter: Daughter,
    Father: Father,
    Mother: Mother,
    "Hindu Undivided Family": HUF,
  };

  const getSessionData = async () => {
    let url = CHECK_SESSION;
    let data = { user_id: userid, sky: getItemLocal("sky") };
    let session_data = await apiCall(url, data, true, false);
    if (session_data["error_code"] == "100") {
      setUserSessionData(session_data["data"]);
      let firstName = session_data["data"]["user_details"]["first_name"];
      let lastName = session_data["data"]["user_details"]["last_name"];
      setFullName(firstName + " " + lastName);
      setEmail(session_data["data"]["email"]);
      setRetirementAge(session_data["data"]["user_details"]["retirement_age"]);
      setLifeExpentancyAge(
        session_data["data"]["user_details"]["life_expectancy"]
      );
    } else if (session_data["error_code"] == "102") {
      // loginRedirectGuest();
    }
    else {
      setUserSessionData({});
    }
  };

  const getRpYourProfileData = async () => {
    if (Object.keys(userSessionData).length != 0) {
      let url = ADVISORY_YOUR_PROFILE_API_URL;
      let data = {
        user_id: userSessionData["user_details"]["user_id"],
        fp_log_id: userSessionData["user_details"]["fp_log_id"],
      };
      let response = await apiCall(url, data, true, false);
      if (response["error_code"] == "100") {
        setUserProfileData(response["data"]);
        setMemberData(response["data"]["rpdata_member"]);
        setAge(response["data"]["rpdata_self"][0]["age"]);
        let userGender = response["data"]["rpdata_self"][0]["gender"];
        if (userGender == "male") {
          setUserAvatar(MaleAvatar);
        } else if (userGender == "female") {
          setUserAvatar(FemaleAvatar);
        }
        setGender(userGender);
        let membersData = response["data"]["rpdata_member"];
        let earning_members = [];
        let dependent_members = [];
        for (let i = 0; i < membersData.length; i++) {
          if (membersData[i]["isdependent"] == "1") {
            dependent_members.push(membersData[i]);
          } else {
            earning_members.push(membersData[i]);
          }
        }
        setEarningMembers(earning_members);
        setDependentMembers(dependent_members);
      }
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      // loginRedirectGuest();
    }
  }, []);

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    getRpYourProfileData();
  }, [userSessionData]);

  return (
    <>
      {/* <ReportHeader /> */}
      <div>
        <div className={`${Styles.ReportProfile}`}>
          <div style={{
            marginTop : "2rem"
          }} className={`${Styles.ReportSection}`}>
            <p>Profile</p>
          </div>
          <div
            style={{
              marginTop: "4rem",
            }}
          >
            <div className={`${Styles.RightSide}`}>
              <div>
                <img src={Profile} />
              </div>
              <div className={`${Styles.borderDv}`}></div>
            </div>
            {userProfileData ? (
              <div>
                <div className={`${Styles.profileType}`}>
                  <div className={`${Styles.TypeBox}`}>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                        }}
                        className={`${Styles.bgWHite}`}
                      >
                        <span className={`${Styles.icons}`}>
                          <img width={40} src={userAvatar} />
                        </span>
                      </span>
                      <span className={`${Styles.Heading}`}>About Me</span>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={`${Styles.ProfileSection}`}>
                  <table className="">
                    <tbody>
                      <tr className="mb-md-2">
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={Name} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Name</div>
                            <div className={`${Styles.Datalabel}`}>
                              {fullname}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={Age} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Age</div>
                            <div className={`${Styles.Datalabel}`}>{age}</div>
                          </div>
                        </td>
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={Gender} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Gender</div>
                            <div className={`${Styles.Datalabel}`}>
                              {capitalize(gender)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={RetireAge} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Retirement Age</div>
                            <div className={`${Styles.Datalabel}`}>
                              {retirementage}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={LifeExpectency} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Life Expectency</div>
                            <div className={`${Styles.Datalabel}`}>
                              {lifeexpectancyage}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className={`${Styles.DataImg}`}>
                            <img width={20} src={Mail} />{" "}
                          </div>
                          <div className={`${Styles.DataInfo}`}>
                            <div>Email</div>
                            <div className={`${Styles.Datalabel}`}>{email}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>
                <p>No Records Found</p>
              </div>
            )}
            <div className={`${Styles.MyFamily}`}>
              {memberData.length > 0 ? (
                <div>
                  <div>
                    <div>
                      <div className={`${Styles.profileType}`}>
                        <div
                          style={{
                            width: "100%",
                          }}
                          className={`${Styles.borderDv}`}
                        ></div>
                        <div className={`${Styles.TypeBox}`}>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                              }}
                              className={`${Styles.bgWHite}`}
                            >
                              <span className={`${Styles.icons}`}>
                                <img width={40} src={MyFamily} />
                              </span>
                            </span>
                            <span className={`${Styles.Heading}`}>
                              My Family
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>

                  {earningMembers.length > 0
                    ? earningMembers.map((earningMember) => (
                        <div className="" key={earningMember.user_id}>
                          <div>
                            <div>
                              <div className={`ms-1 ${Styles.profileType}`}>
                                <div className={`${Styles.TypeSubBox}`}>
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                      }}
                                      className={`${Styles.bgWHite}`}
                                    >
                                      <span className={`${Styles.icons}`}>
                                        <img
                                          width={40}
                                          src={
                                            userIcon[
                                              earningMember.relation_name
                                            ]
                                          }
                                        />
                                      </span>
                                    </span>
                                    <span className={`${Styles.Heading}`}>
                                      {earningMember.relation_name}
                                    </span>
                                  </div>
                                </div>
                                <div className={`${Styles.Datalabel}`}></div>
                              </div>
                            </div>
                          </div>
                          <div className={`${Styles.ProfileSection}`}>
                            <table className="">
                              <tbody>
                                <tr className="mb-md-2">
                                  <td>
                                    <div className={`${Styles.DataImg}`}>
                                      <img width={20} src={Name} />{" "}
                                    </div>
                                    <div className={`${Styles.DataInfo}`}>
                                      <div>Name</div>
                                      <div className={`${Styles.Datalabel}`}>
                                        {earningMember.first_name +
                                          " " +
                                          earningMember.last_name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={`${Styles.DataImg}`}>
                                      <img width={20} src={Age} />{" "}
                                    </div>
                                    <div className={`${Styles.DataInfo}`}>
                                      <div>Age</div>
                                      <div className={`${Styles.Datalabel}`}>
                                        {earningMember.age}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={`${Styles.DataImg}`}>
                                      <img width={20} src={Gender} />{" "}
                                    </div>
                                    <div className={`${Styles.DataInfo}`}>
                                      <div>Gender</div>
                                      <div className={`${Styles.Datalabel}`}>
                                        {capitalize(earningMember.gender)}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={`${Styles.DataImg}`}>
                                      <img width={20} src={RetireAge} />{" "}
                                    </div>
                                    <div className={`${Styles.DataInfo}`}>
                                      <div>Retirement Age</div>
                                      <div className={`${Styles.Datalabel}`}>
                                        {earningMember.retirement_age}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={`${Styles.DataImg}`}>
                                      <img width={20} src={LifeExpectency} />{" "}
                                    </div>
                                    <div className={`${Styles.DataInfo}`}>
                                      <div>Life Expectency</div>
                                      <div className={`${Styles.Datalabel}`}>
                                        {earningMember.life_expectancy}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))
                    : ""}
                  {dependentMembers.length > 0
                    ? dependentMembers.map((dependentMember) => (
                        <div className="" key={dependentMember.user_id}>
                          <div>
                            <div>
                              <div className={`${Styles.profileType}`}>
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                  className={`${Styles.borderDv}`}
                                ></div>
                                <div className={`${Styles.TypeSubBox}`}>
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                      }}
                                      className={`${Styles.bgWHite}`}
                                    >
                                      <span className={`${Styles.icons}`}>
                                        <img
                                          width={40}
                                          src={
                                            userIcon[
                                              dependentMember.relation_name
                                            ]
                                          }
                                        />
                                      </span>
                                    </span>
                                    <span className={`${Styles.Heading}`}>
                                      {dependentMember.relation_name}
                                    </span>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className={`${Styles.ProfileSection}`}>
                                <table className="">
                                  <tbody>
                                    <tr className="mb-md-2">
                                      <td>
                                        <div className={`${Styles.DataImg}`}>
                                          <img width={20} src={Name} />{" "}
                                        </div>
                                        <div className={`${Styles.DataInfo}`}>
                                          <div>Name</div>
                                          <div
                                            className={`${Styles.Datalabel}`}
                                          >
                                            {dependentMember.first_name +
                                              " " +
                                              dependentMember.last_name}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className={`${Styles.DataImg}`}>
                                          <img width={20} src={Age} />{" "}
                                        </div>
                                        <div className={`${Styles.DataInfo}`}>
                                          <div>Age</div>
                                          <div
                                            className={`${Styles.Datalabel}`}
                                          >
                                            {dependentMember.age}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className={`${Styles.DataImg}`}>
                                          <img width={20} src={Gender} />{" "}
                                        </div>
                                        <div className={`${Styles.DataInfo}`}>
                                          <div>Gender</div>
                                          <div
                                            className={`${Styles.Datalabel}`}
                                          >
                                            {capitalize(dependentMember.gender)}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              ) : (
                <div className={`${Styles.Norecord}`}>
                  <p>No Family Members Records found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <ReportFooter /> */}
    </>
  );
}

export default ProfileReport;
