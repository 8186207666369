
import React, { useEffect, useState } from 'react';
import TrackingStepsOverview from '../../components/BankTrackingOverview/TrackingStepsOverview';
import TrackingOption from '../../components/BankTrackingOverview/TrackingOption';
import style from '../../style.module.css';
import ConcentPopupUi from '../../components/BankTrackingOverview/ConcentPopupUi';
import { Modal } from 'react-bootstrap';
import ApplyWhiteBg from '../../../../components/ApplyWhiteBg';
import HideFooter from '../../../../components/HideFooter';
import { useNavigate } from "react-router-dom";
import MainLayout from '../../../../components/Layout/MainLayout';
import commonEncode from '../../../../commonEncode';
import { DataProvider } from '../../context/DataContext';
import { useDispatch } from "react-redux";
import { getFpUserDetailId, getMemberId, getUserId, removeMemberId, setFpUserDetailsId, setMemberId } from '../../../../common_utilities';

function BankTrackingOverView() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const dispatch = useDispatch();

    // Define state variable to store users data
    const [usersData, setUsersData] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState({
        memberId: '',
        fpUserId: ''
    });

    let navigate = useNavigate();

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const closePopupAndNavigate = () => {
        if (selectedUserId.memberId == getUserId()) {
            localStorage.removeItem("family");
            localStorage.removeItem("logged_in");
            window.location.href = `${process.env.PUBLIC_URL}/money-management/track-bank-account`
        } else {
            removeMemberId();
            setMemberId(selectedUserId.memberId);
            setFpUserDetailsId(selectedUserId.fpUserId);
            localStorage.removeItem("family");
            localStorage.removeItem("logged_in");
            window.location.href = `${process.env.PUBLIC_URL}/money-management/track-bank-account`
        }

    };



    // Fetch users data from local storage and set it to the state variable
    useEffect(() => {


        function checkUserData() {

            console.log(localStorage.getItem("member"), Boolean(localStorage.getItem("family")), getUserId(), getFpUserDetailId())

            if (localStorage.getItem("member")) {
                let users = JSON.parse(commonEncode.decrypt(localStorage.getItem("member")));

                setUsersData(users);
                dispatch({ type: "SET_USER_DETAILS", payload: users[0] });
            }

            if (!Boolean(localStorage.getItem("family"))) {
                setSelectedUserId({
                    memberId: getUserId(),
                    fpUserId: getFpUserDetailId()
                })
            }

        }

        setTimeout(() => { checkUserData() }, 1000)

    }, [])

    return (
        <MainLayout>
            <DataProvider>
                <ApplyWhiteBg />
                <div className={`${style.bankTrackingOverViewContainer}`}>
                    <div style={{ width: '100%', background: 'white' }}>
                        <TrackingOption openPopup={openPopup} usersData={usersData} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
                    </div>
                    <div style={{ width: '100%', background: '#F3F3F3' }} className={`${style.TrackingStepsOverview}`}>
                        <TrackingStepsOverview />
                    </div>
                    <Modal
                        className={`${style.moneyManagementModal}`}
                        dialogClassName={`${style.moneyManagementModalDialog}`}
                        centered
                        show={isPopupOpen}
                        size='lg'
                        onHide={closePopup}
                    >
                        <ConcentPopupUi closePopup={closePopupAndNavigate} />
                    </Modal>
                </div>
            </DataProvider>
        </MainLayout>

    );
}

export default BankTrackingOverView;
