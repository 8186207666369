import React, { useRef, useState, useEffect } from "react";
import Styles from "../moneymanagement.module.css";
import style from "../style.module.css";
import Header from "./Header";
import { Modal, Form } from "react-bootstrap";
import socket, { onMessageHandler } from "./socket";
import * as toastr from "toastr";
import { useData } from '../context/DataContext';
import { fetchData } from "../../../common_utilities";
// import BankAccountDataTransactionTableView  from "../views/BankAccountDataView/BankAccountDataTransactionTableView";
// import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import SelectBanks from "./SelectBanks";
import BankTrackingProcess from "../components/BankTrackingProcess/BankTrackingProcess";
import { FINVU_BASE_API_URL, CHATBOT_BASE_API_URL, FINVU_USER_ID, FINVU_PASSWORD, FINVU_TEMPLATE_NAME, FINVU_AAID, FINTOO_BASE_API_URL } from "../../../constants";
import {
  getMemberId,
  getUserId,
  removeMemberId,
  setFpUserDetailsId,
  setMemberId,
  setUserId
} from "../../../common_utilities";


const BankDataFetch = (props) => {
  const consentMonthData = localStorage.getItem("consentMonth");
  console.log("Props : ", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidData, setRes, setDataDict, bankIdDetails, mob_no, setaccDiscData, accDiscData, handleIdfromConsent, setHandleIdfromConsent, mergeAccDetails, setMergeAccountDetails, secondAccHandleId, jwtToken, setFinvuToken, finvuToken, setConsentId, consentId, setCustomerInfo, setStatementAccounts, customerInfo, statementAccounts, setXnsOther, xnsOther, xnsExceptOther, setXnsExceptOther } = useData();
  dispatch({ type: "SET_BANK_ID_DETAILS", payload: bankIdDetails });
  console.log("++++ bankIdDetails: ", bankIdDetails);
  const [showData, setShowData] = useState("");
  const { v4: uuidv4 } = require("uuid");
  const customerInfoData = useSelector((state) => state.customerInfoData);
  const [currentUserIds, setCurrentUserIds] = useState([]);
  // const [bankAccounts, setBankAccounts] = useState([]);
  // const [ dateRangeFrom, setDateRangeFrom ] = useState("");
  // const [ dateRangeTo, setDateRangeTo ] = useState("");
  let updatedMergeData = [];
  let consentAppAcc = [];
  let dateRangeFrom = "";
  let dateRangeTo = "";
  let finToken = "";
  let consentHandle = "";
  let consentDataForFIP = [];
  let allConsentData = [];
  let accumulatedData = [];
  let successFlag = false;
  let user_details = "";
  let bank_accounts = [];

  // const [xnsOther, setXnsOther] = useState([]);
  // const [xnsExceptOther, setXnsExceptOther] = useState([]);
  const [renderBankAccountData, setRenderBankAccountData] = useState(false);
  // let consId = "";

  const getMemberIdFn = () => {
    let isFamilySelected = Boolean(localStorage.getItem("family"));
    if (!isFamilySelected) {
      if (getMemberId()) {
        const memberId = getMemberId();
        return memberId;
      } else {
        const userId = getUserId();
        return userId;
      }
      // } else {
      //     let users = JSON.parse(commonEncode.decrypt(localStorage.getItem("member")));
      //     console.log("Users Data is here: ", users);
      //     const idsArray = users.map(item => String(item.id));
      //     console.log(idsArray);
      //     return idsArray;
    }
  };


  const handleButtonClick = () => {
    setRenderBankAccountData(true);
  };



  const consentApprovalRequestAPI = async (consentHandleId, consentData) => {
    try {
      // alert(JSON.stringify(mergeAccDetails));
      // alert(JSON.stringify(updatedMergeData));
      if (mergeAccDetails.length === 0) {
        consentAppAcc = updatedMergeData;
      } else {
        consentAppAcc = mergeAccDetails;
      }
      // alert(JSON.stringify(consentAppAcc));
      const consent_approval_request_payload = {
        "header": {
          "mid": "fcd2c56e-9414-11e8-9eb6-529269fb1459",
          "ts": (new Date().toISOString()).replace('Z', '+00:00'),
          "sid": sidData,
          "dup": "false",
          "type": "urn:finvu:in:app:req.accountConsentRequest.01"
        },
        "payload": {
          "FIPDetails": [
            {
              "FIP": {
                "id": bankIdDetails.fipId
              },
              "Accounts": [consentData]
            }
          ],
          "FIU": {
            "id": "fiulive@fintoo"
          },
          "ver": "1.1.2",
          "consentHandleId": consentHandleId,
          "handleStatus": "ACCEPT"
        }
      };
      console.log("consentApprovalRequestAPI from BankDataFetch: ", JSON.stringify(consent_approval_request_payload));
      socket.send(JSON.stringify(consent_approval_request_payload));
    } catch (error) {
      console.error(
        "An error occurred during Consent Request:",
        error.message
      );
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
    }
  };

  const consentRequestDetailsAPI = async () => {
    try {
      const consent_request_detail_payload = {
        "header": {
          "mid": "fcd2c56e-9414-11e8-9eb6-529269fb1459",
          "ts": (new Date().toISOString()).replace('Z', '+00:00'),
          "sid": sidData,
          "dup": "false",
          "type": "urn:finvu:in:app:req.consentRequestDetails.01"
        },
        "payload": {
          "consentHandleId": secondAccHandleId,
          "userId": mob_no + "@finvu"
        }
      }
      console.log("consentRequestDetailsAPI: ", consent_request_detail_payload);
      socket.send(JSON.stringify(consent_request_detail_payload));
    } catch (error) {
      console.error(
        "An error occurred during Consent Request:",
        error.message
      );
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
    }
  };

  const finvuTokenAPI = async (consId, consentHandle, consentData) => {
    const url = FINTOO_BASE_API_URL + 'money_managment/finvutoken/';
    const headers = new Headers();
    // headers.append('gatewayauthtoken', 'Token ' + jwtToken);
    // headers.append('gatewayauthname', 'localhost');
    try {

      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (response.ok) {
        const result = await response.json();
        console.log("Finvu Token: ", result);
        setFinvuToken(result.data.body.token);
        finToken = result.data.body.token;
        await fiRequestAPI(consId, consentHandle, consentData);
      } else {
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error("Bank Server Error");
        // throw new Error('Failed to fetch Finvu Token');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
    }
  };

  const consentHandleAPI = async () => {
    try {
      const rid = uuidv4();
      const ts = new Date().toISOString();

      const loginPayload = {
        header: {
          rid: rid,
          ts: ts,
          channelId: "finsense",
        },
        body: {
          userId: FINVU_USER_ID,
          password: FINVU_PASSWORD,
        },
      };
      // console.log(loginPayload);

      const url = FINVU_BASE_API_URL + "User/Login";
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginPayload),
      };

      const loginResponse = await fetch(url, options);
      // console.log(loginResponse);
      // console.log(loginResponse.status);
      if (loginResponse.status === 200) {
        const responseData = await loginResponse.json();
        const token = responseData.body.token;
        // console.log("Token", token);
        try {
          const submitConsentRequestPayload = {
            "header": {
              "ts": ts,
              "channelId": "finsense",
              "rid": rid
            },
            "body": {
              "custId": mob_no + "@finvu",
              "consentDescription": "Apply for loan",
              "templateName": FINVU_TEMPLATE_NAME,
              "aaId": FINVU_AAID
            }
          };
          // console.log(submitConsentRequestPayload);

          // const url =
          //   "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/SubmitConsentRequest";
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
            body: JSON.stringify(submitConsentRequestPayload),
          };

          const submitConsentRequestResponse = await fetch(FINVU_BASE_API_URL + "SubmitConsentRequest", options);
          // console.log(submitConsentRequestResponse);
          // console.log(submitConsentRequestResponse.status);
          if (submitConsentRequestResponse.status === 200) {
            const submitConsentRequestResponseData = await submitConsentRequestResponse.json();
            const body = submitConsentRequestResponseData.body;
            console.log("submitConsentRequestResponseData body", body);
            // setSecondAccountHandleId(submitConsentRequestResponseData.body.consentHandle);
            return { success: true, data: { consentHandle: submitConsentRequestResponseData.body.consentHandle } };
          } else {
            return { success: false };
          }
        }
        catch (error) {
          console.error("An error occurred:", error.message);
          return { success: false };

        }
      }
    }
    catch (error) {
      console.error("An error occurred:", error.message);
      return { success: false };

    }
  };

  const fiRequestAPI = async (consId, consentHandle, consentData) => {
    try {
      const url = FINVU_BASE_API_URL + "FIRequest";
      const rid = uuidv4();
      const fi_request_api_payload = {
        "header": {
          "rid": rid,
          "ts": (new Date().toISOString()).replace('Z', '+00:00'),
          "channelId": "finsense"
        },
        "body": {
          "custId": mob_no + "@finvu",
          "consentId": consId,
          "consentHandleId": consentHandle,
          "dateTimeRangeFrom": dateRangeFrom,
          "dateTimeRangeTo": dateRangeTo
        }
      }

      const requestBody = JSON.stringify(fi_request_api_payload);
      console.log("FIRquest payload: ", requestBody);

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + finToken,
          "Content-Type": "application/json",
        },
        body: requestBody,
      };

      const response = await fetch(url, requestOptions);
      const data = await response.json();

      console.log(JSON.stringify(data));

      if (response.ok) {
        const statusResult = await FIStatus(consId, data.body.sessionId, consentHandle, consentData);
        if (statusResult && !statusResult.error) {
          // FIStatus succeeded and returned a result
          // Proceed with further actions if needed
          console.log("FIStatus succeeded:", statusResult);
        } else {
          // FIStatus failed
          console.log("FIStatus failed:", statusResult.error);
          // Handle failure if needed
          // props.handleFailure();
        }
        // for (let i=0; i<=5; i++){
        //   const response = await FIStatus(consId, data.body.sessionId, consentHandle, consentData);
        //   if (response.data ==="true"){
        //     // FipFmDatatReport
        //   } 
        // }
      } else {
        console.log("FIRequest API Error:", data);
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error("Bank Server Error");
        // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
        // props.handleFailure();
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
      // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
      // props.handleFailure();
    }
  };

  //   async function FIStatus(cons_id, session_id, consent_handle_id, consentData) {
  //     const max_retries = 5;
  //     let retry = 0;
  //     // Define a function to call anotherAsyncFunction recursively until desired status is received
  //     const callAnotherAsyncFunction = async () => {
  //         if (retry < max_retries) {
  //             return new Promise((resolve) => {
  //                 setTimeout(async () => {
  //                     const response = await FIStatusAPI(cons_id, session_id, consent_handle_id, consentData);
  //                     if (response.body.fiRequestStatus === "READY") {
  //                       const apiResponse = await FIPfmDataReport(consent_handle_id, session_id, consentDataForFIP.linkRefNumber);
  //                       resolve(apiResponse);
  //                     } else if (response.body.fiRequestStatus === "FAILED") {
  //                         // Handle failed status if needed
  //                         resolve({ error: "FI Status failed" })
  //                     } else {
  //                         retry++;
  //                         resolve(await callAnotherAsyncFunction());
  //                     }
  //                 }, 5000); // Wait for 15 seconds before each recursive call
  //             });
  //         } else {
  //             return false;
  //         }
  //     };

  //     // Start the recursive call
  //     return callAnotherAsyncFunction();
  // }

  async function FIStatus(cons_id, session_id, consent_handle_id, consentData) {
    try {
      const max_retries = 5;
      let retry = 0;
      while (retry < max_retries) {
        const response = await FIStatusAPI(cons_id, session_id, consent_handle_id, consentData);
        console.log("FIStatus Res: ", JSON.stringify(response));
        if (response.body.fiRequestStatus === "READY") {
          const apiResponse = await FIPfmDataReport(consent_handle_id, session_id, consentDataForFIP.linkRefNumber);
          if (apiResponse.success && apiResponse.data) {
            console.log(" ~~~~~~~~~~~~~~~~~~~~~ ", JSON.stringify(apiResponse.data));
            console.log(apiResponse.data.statementAccounts.currentBalance);
            consentDataForFIP.totalBalance = apiResponse.data.statementAccounts[0].currentBalance;
            console.log(" $$$$ consentData: ", JSON.stringify(consentDataForFIP));
            allConsentData.push(consentDataForFIP);
            accumulatedData.push(apiResponse.data);
            bank_accounts.push(apiResponse.data.statementAccounts[0].accountNo);
            successFlag = true;
          }
          return apiResponse; // Return the result immediately when status is "READY"
        } else if (response.body.fiRequestStatus === "FAILED") {
          return { "error": "FI Status failed" };
          // throw new Error("FI Status failed"); // Throw an error if status is "FAILED"
        }
        retry++;
        await new Promise(resolve => setTimeout(resolve, 10000)); // Wait for 5 seconds before each retry
      }
      return { error: "Max retries exceeded" }; // Return an error if max retries exceeded
    } catch (error) {
      console.error('FIStatus error:', error);
      throw error; // Propagate the error
    }
  };



  const FIStatusAPI = async (cons_id, session_id, consent_handle_id, consentData, retryCount = 0) => {
    console.log("consentData in FIStatus: ", consentData);
    let custID = mob_no + "@finvu";
    let successFlag = false;
    const accumulatedData = [];

    try {
      const customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: 'Bearer ' + finToken,
      };
      const payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIStatus/${cons_id}/${session_id}/${consent_handle_id}/${custID}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);
      // const r = await fetch(url, payload);
      return r;

      if (r.body.fiRequestStatus === "READY") {
        console.log("FIStatus is Ready: ", JSON.stringify(r));
        console.log("For loop of consentAppAcc : ", consentAppAcc);

        // for (let i of consentAppAcc) {
        console.log("LinkRefNo: ", consentDataForFIP);
        const apiResponse = await FIPfmDataReport(consent_handle_id, session_id, consentDataForFIP.linkRefNumber);

        //   if (apiResponse.success && apiResponse.data) {
        //     accumulatedData.push(apiResponse.data);
        //     successFlag = true;
        //   }
        // }

        // if (successFlag) {
        //   setCustomerInfo(accumulatedData[0].customerInfo);
        //   dispatch({ type: "SET_CUSTOMER_INFO", payload: accumulatedData[0].customerInfo });
        //   const allStatementAccounts = accumulatedData.map(item => item.statementAccounts).flat();
        //   console.log("allStatementAccounts: ", allStatementAccounts);
        //   console.log("allStatementAccounts: ", JSON.stringify(allStatementAccounts));

        //   dispatch({ type: "SET_STATEMENT_ACCOUNT_DATA", payload: allStatementAccounts });

        //   setShowData("Bank details have been fetched successfully.");
        // } else {
        //   setShowData("Sorry, we weren't able to fetch your bank details.");
        //   // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
        //   // props.handleFailure();
        // }
      } else if (r.body.fiRequestStatus === "FAILED") {
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error("Bank Server Error");
        console.log("Financial Information (FI) Status is FAILED");
        // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
        // props.handleFailure();

      } else {
        if (retryCount < 5) {
          setTimeout(async () => await FIStatus(cons_id, session_id, consent_handle_id, retryCount + 1), 15000);
        } else {
          setShowData("Sorry, we weren't able to fetch your bank details.");
          // toastr.options.positionClass = "toast-bottom-left";
          // toastr.error("Bank Server Error");
          console.log("Financial Information (FI) Status is PENDING / REJECTED");
          // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
          // props.handleFailure();
        }
      }

      return accumulatedData;
    } catch (error) {
      console.error("OTP error", error);
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
      return accumulatedData;
    }
  };

  // const FIStatus = async (cons_id, session_id, consent_handle_id, consentData, retryCount = 0) => {
  //   console.log("consentData in FIStatus: ", consentData);
  //   let custID = mob_no + "@finvu";
  //   let successFlag = false;
  //   const accumulatedData = [];

  //   try {
  //     const customHeaders = {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: 'Bearer ' + finToken,
  //     };
  //     const url = FINVU_BASE_API_URL+ `FIStatus/${cons_id}/${session_id}/${consent_handle_id}/${custID}`;
  //     const payload = {
  //       headers: customHeaders,
  //       method: "get",
  //     };

  //     // const r = await fetchData(payload);
  //     const r = await fetch(url, payload);

  //     if (r.body.fiRequestStatus === "READY") {
  //       console.log("FIStatus is Ready: ", JSON.stringify(r));
  //       console.log("For loop of consentAppAcc : ", consentAppAcc);

  //       // for (let i of consentAppAcc) {
  //         console.log("LinkRefNo: ", consentDataForFIP);
  //         const apiResponse = await FIPfmDataReport(consent_handle_id, session_id, consentDataForFIP.linkRefNumber);

  //       //   if (apiResponse.success && apiResponse.data) {
  //       //     accumulatedData.push(apiResponse.data);
  //       //     successFlag = true;
  //       //   }
  //       // }

  //       // if (successFlag) {
  //       //   setCustomerInfo(accumulatedData[0].customerInfo);
  //       //   dispatch({ type: "SET_CUSTOMER_INFO", payload: accumulatedData[0].customerInfo });
  //       //   const allStatementAccounts = accumulatedData.map(item => item.statementAccounts).flat();
  //       //   console.log("allStatementAccounts: ", allStatementAccounts);
  //       //   console.log("allStatementAccounts: ", JSON.stringify(allStatementAccounts));

  //       //   dispatch({ type: "SET_STATEMENT_ACCOUNT_DATA", payload: allStatementAccounts });

  //       //   setShowData("Bank details have been fetched successfully.");
  //       // } else {
  //       //   setShowData("Sorry, we weren't able to fetch your bank details.");
  //       //   // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
  //       //   // props.handleFailure();
  //       // }
  //     } else if (r.body.fiRequestStatus === "FAILED") {
  //       toastr.options.positionClass = "toast-bottom-left";
  //       toastr.error("Bank Server Error");
  //       console.log("Financial Information (FI) Status is FAILED");
  //       // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
  //       // props.handleFailure();

  //     } else {
  //       if (retryCount < 5) {
  //         setTimeout(async() => await FIStatus(cons_id, session_id, consent_handle_id, retryCount + 1), 15000);
  //       } else {
  //         setShowData("Sorry, we weren't able to fetch your bank details.");
  //         toastr.options.positionClass = "toast-bottom-left";
  //         toastr.error("Bank Server Error");
  //         console.log("Financial Information (FI) Status is PENDING / REJECTED");
  //         // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
  //         // props.handleFailure();
  //       }
  //     }

  //     return accumulatedData;
  //   } catch (error) {
  //     console.error("OTP error", error);
  //     toastr.options.positionClass = "toast-bottom-left";
  //     toastr.error("Bank Server Error");
  //     return accumulatedData;
  //   }
  // };


  const FIPfmDataReport = async (ConsentHandle, sID, linkref) => {
    try {
      const customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: 'Bearer ' + finToken,
      };
      const payload = {
        url: FINVU_BASE_API_URL + `FIPfmDataReport/${ConsentHandle}/${sID}/${linkref}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);
      console.log("FIPfmDataReport API Response: ", r);

      if (r.body.customerInfo && r.body.statementAccounts) {
        console.log("FIPfmDataReport: ", JSON.stringify(r));
        return { success: true, data: { customerInfo: r.body.customerInfo, statementAccounts: r.body.statementAccounts } };
      } else {
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error("Bank Server Error");
        return { success: false };
      }
    } catch (error) {
      console.error("FIPfmDataReport error", error);
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Bank Server Error");
      return { success: false };
    }
  };



  const separateTransactions = (statAccounts) => {
    const newXnsOther = [];
    const newXnsExceptOther = [];

    for (const account of statAccounts) {
      for (const transaction of account.xns) {
        if (transaction.category.toLowerCase().includes('other') && transaction.type === 'DEBIT') {
          newXnsOther.push(transaction);
        } else {
          newXnsExceptOther.push(transaction);
        }
      }
    }

    setXnsOther(newXnsOther);
    setXnsExceptOther(newXnsExceptOther);
    console.log("newXnsOther: ", newXnsOther);
    console.log("newXnsOther: ", JSON.stringify(newXnsOther));
    console.log("newXnsExceptOther: ", newXnsExceptOther)
    console.log("newXnsExceptOther: ", JSON.stringify(newXnsExceptOther));
  };

  const uploadDatatoS3 = async (user_id, customerInfo, account_statement) => {
    // const call_id = await getCallbackId();

    // console.log("callBackId: ", call_id);
    var myHeaders = new Headers();
    // const tkn = await getJWTToken();
    // myHeaders.append("gatewayauthtoken", 'Token '+tkn);
    // myHeaders.append("gatewayauthname", GATEWAY_AUTH_NAME);
    // const transformedData = statementAccounts.map(account => [account]);
    const payload = {
      "customerInfo": customerInfo,
      "account_statement": [account_statement],
      "user_id": parseInt(user_id)
    };
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Cookie", "AWSALBTG=VmwGpjloOZHqm4zYwwgrhQHBFqgjHSadlnkk3pDv2VHCrOFU76lUNk3jvnw2J18jV4XbJnqGSOz80EIcr/iuY3e7RusDT2z5wK+pQ768CDlWOIIAjWkuVSatFsU8WKYhNR7V7TAJIR7Kmc2FcpsYP/iq+TP5rGPWnuCGQS5wqzXV; AWSALBTGCORS=VmwGpjloOZHqm4zYwwgrhQHBFqgjHSadlnkk3pDv2VHCrOFU76lUNk3jvnw2J18jV4XbJnqGSOz80EIcr/iuY3e7RusDT2z5wK+pQ768CDlWOIIAjWkuVSatFsU8WKYhNR7V7TAJIR7Kmc2FcpsYP/iq+TP5rGPWnuCGQS5wqzXV");
    try {
      const response = await fetch(FINTOO_BASE_API_URL + "money_managment/datas3upload/", {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("Upload Data to S3 bucket Response: ", result);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Internal Server Error");
    }
  };

  const FetchAccountTransactionsAPI = async (user_id, bank_accounts) => {
    var myHeaders = new Headers();
    const payload = {
      "user_id": parseInt(user_id),
      "bank_accounts": bank_accounts
    };
    myHeaders.append("Content-Type", "application/json");
    try {
      const response = await fetch(FINTOO_BASE_API_URL + "money_managment/fetch_account_trxns/", {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const result = await response.json();
        // setStatementAccountsData(result.data[0]); 
        // setFlag(true);
        console.log("FetchAccountTransactionsAPI Response: ", result);
        dispatch({ type: "SET_STATEMENT_ACCOUNT_DATA", payload: result.data[0] });
        dispatch({ type: "SET_MAX_AMOUNT_LIMIT", payload: result.max_amount_limit });
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("No transactions found");
    }
  };

  const GetExpenseCategoryList = async () => {
    try {
      const url = FINTOO_BASE_API_URL + "money_managment/income_expense_category_list/";
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      const result = await response.json();
      const mappedOptions = result.data;
      console.log(mappedOptions);
      // setOptions(mappedOptions);
      dispatch({ type: "SET_TRANSACTION_OPTIONS", payload: mappedOptions });
      console.log("GetExpenseCategoryList Response: ", result);
      return response;
    } catch (error) {
      console.error('Error updating tracked bank details:', error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Internal Server Error");
      // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
      // props.handleFailure();
    }
  };


  const UpdateTrackedbankDetails = async (bank_data) => {
    try {
      const url = FINTOO_BASE_API_URL + "money_managment/update_tracked_bank_details/";
      const payload = {
        "user_id": parseInt(bank_data.userId),
        "finvuuserId": bank_data.finvuUserid,
        "fipId": bank_data.fipId,
        "fipType": bank_data.FIType,
        "mobile_number": parseInt(bank_data.mobileNumber),
        "consentId": bank_data.consentId,
        "consentHandleId": bank_data.consentHandleId,
        "fipName": bank_data.fipName,
        "acc_type": bank_data.accType,
        "account_masked_id": bank_data.maskedAccNumber,
        "link_ref_no": bank_data.linkRefNumber,
        "total_balance": bank_data.totalBalance,
        "daterange_from": bank_data.dateRangeFrom,
        "daterange_to": bank_data.dateRangeTo,
      };

      const requestBody = JSON.stringify(payload);
      console.log("UpdateTrackedbankDetails payload: ", requestBody);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error('Failed to update data');
      }

      return response;
    } catch (error) {
      console.error('Error updating tracked bank details:', error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Internal Server Error");
      // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
      // props.handleFailure();
    }
  };

  // useEffect(() => {
  //     separateTransactions();
  // }, []);

  async function waitForWebSocketMessage(socket, onMessageHandler) {
    return new Promise(resolve => {
      socket.onmessage = function (event) {
        const data = onMessageHandler(event);
        resolve(data);
      };
    });
  }


  useEffect(() => {
    user_details = getMemberIdFn();
    setCurrentUserIds(user_details);
    console.log("UserDetails are: ", JSON.stringify(user_details));
    console.log("@@@@@@@@@@@@@@@@@@@@@");
    socket.onmessage = function (event) {
      const data = onMessageHandler(event);
      if (data.header.type == "urn:finvu:in:app:res.confirm-token.01") {
        // console.log("Account Confirm Linking Response: ", JSON.stringify(data));
        if (data.payload.status == "SUCCESS") {
          updatedMergeData = accDiscData.map(item => ({
            ...item,
            "linkRefNumber": data.payload.AccLinkDetails[0].linkRefNumber,
            "accRefNumber": data.payload.AccLinkDetails[0].accRefNumber,
            "fipId": bankIdDetails.fipId,
            "fipName": bankIdDetails.fipName
          }));
          consentRequestDetailsAPI();
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Bank Server Error");
        }
      } else if (data.header.type == "urn:finvu:in:app:res.consentRequestDetailsResponse.01") {
        console.log("Account Consent Request Response: ", JSON.stringify(data));
        if (data.payload.status == "SUCCESS") {
          setHandleIdfromConsent(data.payload.ConsentHandle);
          console.log("Data Start: ", data.payload.DataDateTimeRange.from);
          console.log("Data End: ", data.payload.DataDateTimeRange.to);
          const consentDate = new Date(consentMonthData);
          const formattedConsentDate = consentDate.toISOString().replace('Z', '');
          // dateRangeFrom = data.payload.DataDateTimeRange.from;
          dateRangeFrom = formattedConsentDate + "+00:00";
          // setDateRangeTo(data.payload.DataDateTimeRange.to);
          dateRangeTo = data.payload.DataDateTimeRange.to;
          // setMergeAccountDetails(updatedMergeData);
          if (mergeAccDetails.length === 0) {
            consentAppAcc = updatedMergeData;
          } else {
            consentAppAcc = mergeAccDetails;
          }
          console.log("consentAppAcc length: ", consentAppAcc.length);
          (async () => {
            let isFirstIteration = true;
            for (let consentData of consentAppAcc) {
              consentDataForFIP = consentData;
              console.log(" **** consentData: ", JSON.stringify(consentDataForFIP));
              if (isFirstIteration) {
                consentHandle = data.payload.ConsentHandle;
                isFirstIteration = false;
              } else {
                const consenthandleRespone = await consentHandleAPI();
                if (consenthandleRespone.success && consenthandleRespone.data) {
                  consentHandle = consenthandleRespone.data.consentHandle;
                }
              }
              await consentApprovalRequestAPI(consentHandle, consentData);
              const message = await waitForWebSocketMessage(socket, onMessageHandler);
              if (message.header.type == "urn:finvu:in:app:res.accountConsentRequest.01") {
                console.log("Account Consent Request Approval Response: ", JSON.stringify(message));
                if (message.payload.status == "SUCCESS") {
                  // setShowData("Bank Details has Been Fetch Successful");
                  console.log("Consent ID: ", message.payload.fipConsentInfos[0].consentId);
                  setConsentId(message.payload.fipConsentInfos[0].consentId);
                  consentDataForFIP.consentHandleId = consentHandle;
                  consentDataForFIP.consentId = message.payload.fipConsentInfos[0].consentId;
                  consentDataForFIP.finvuUserid = mob_no + "@finvu";
                  consentDataForFIP.mobileNumber = mob_no;
                  consentDataForFIP.userId = user_details;
                  consentDataForFIP.dateRangeFrom = dateRangeFrom;
                  consentDataForFIP.dateRangeTo = dateRangeTo;
                  console.log(" #### consentData: ", JSON.stringify(consentDataForFIP));
                  await finvuTokenAPI(message.payload.fipConsentInfos[0].consentId, consentHandle, consentData);
                }
                // else {
                //     props.handleFailure();
                //     toastr.options.positionClass = "toast-bottom-left";
                //     toastr.error("Bank Server Error");
                //     setShowData("Sorry, we weren't able to fetch your bank details.");
                // }
              }
            }
            if (successFlag & accumulatedData.length > 0) {
              setCustomerInfo(accumulatedData[0].customerInfo);
              dispatch({ type: "SET_CUSTOMER_INFO", payload: accumulatedData[0].customerInfo });
              const allStatementAccounts = accumulatedData.map(item => item.statementAccounts).flat();
              console.log("allStatementAccounts: ", allStatementAccounts);
              console.log("allStatementAccounts: ", JSON.stringify(allStatementAccounts));

              // dispatch({ type: "SET_STATEMENT_ACCOUNT_DATA", payload: allStatementAccounts });
              dispatch({ type: "SET_ALL_FETCHED_ACCOUNT_DETAILS", payload: allConsentData });
              console.log("IMP Data to Store in DB: ", JSON.stringify(allConsentData));
              await uploadDatatoS3(user_details, accumulatedData[0].customerInfo, allStatementAccounts);
              for (let bank_details of allConsentData) {
                const response = await UpdateTrackedbankDetails(bank_details);
                if (!response.ok) {
                  props.handleFailure();
                  toastr.options.positionClass = "toast-bottom-left";
                  toastr.error("Something Went wrong, Please try again!");
                }
              }
              dispatch({ type: "SET_FETCH_TXN_DATA_ACCOUNT", payload: bank_accounts });
              // await FetchAccountTransactionsAPI(user_details, bank_accounts);
              // await GetExpenseCategoryList();
              if (consentAppAcc.length == accumulatedData.length) {
                console.log("Bank transactions have been fetched successfully.");
                setShowData("Bank transactions have been fetched successfully.");
              } else {
                console.log("Only " + accumulatedData.length + " bank details has been fetched successfully.");
                setShowData("Only " + accumulatedData.length + " bank details has been fetched successfully.");
              }
            } else {
              console.log(successFlag);
              console.log(accumulatedData.length);
              props.handleFailure();

              // toastr.options.positionClass = "toast-bottom-left";
              // toastr.error("Bank Server Error");

              props.setErrorData({
                message: 'Experiencing temporary bank connectivity issues. Please retry later.',
                variant: 'error'
              })


              // setShowData("Sorry, we weren't able to fetch your bank details.");
              // props.handleFailure();

              // }
              // setShowData("Sorry, we weren't able to fetch your bank details.");
              // navigate(`${process.env.PUBLIC_URL}/money-management/link-bank-account`);
              // props.handleFailure();
            }
          })();


        } else {
          console.log("bank Server from Account Consent Response Error");
          // toastr.options.positionClass = "toast-bottom-left";
          // toastr.error("Bank Server Error");

          props.setErrorData({
            message: 'Experiencing temporary bank connectivity issues. Please retry later.',
            variant: 'error'
          })

        }

      }
      // else if (data.header.type == "urn:finvu:in:app:res.accountConsentRequest.01"){
      //       console.log("Account Consent Request Approval Response: ", JSON.stringify(data));
      //       if (data.payload.status=="SUCCESS") {
      //           // setShowData("Bank Details has Been Fetch Successful");
      //           console.log("Consent ID: ", data.payload.fipConsentInfos[0].consentId);
      //           setConsentId(data.payload.fipConsentInfos[0].consentId);
      //           finvuTokenAPI(data.payload.fipConsentInfos[0].consentId);
      //       }
      //       else {
      //           setShowData("Sorry, we weren't able to fetch your bank details.");
      //       }
      //       }
    };
  }, [showData]);


  return (
    <>
      <div style={{
        display: "grid",
        placeItems: "center",
        placeContent: "center"
      }}>
        <div>
          <img src={process.env.REACT_APP_STATIC_URL + "media/MoneyManagement/Successful.svg"} alt="Successful" />
        </div>
        {showData ? (
          <div className={`mt-4 ${Styles.Successfulmsg}`}>{showData}</div>
        ) : (
          <div className={`mt-4 ${Styles.Successfulmsg}`} style={{ display: 'block' }}>
            Please wait while we are fetching your transaction details.<br />
            This task may require a moment.
          </div>
        )}
        {showData ? (
          <div style={{ position: "relative" }} className={`mt-5 ${Styles.ContinueButton}`}>
            <Link
              to={process.env.PUBLIC_URL + "/money-management/map-transactions"}
            >
              <button style={{ padding: '.5rem 2rem' }}>Continue</button>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
};

export default BankDataFetch;