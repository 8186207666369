import DatePicker from 'react-datepicker';
import './style.css';

const FintooDatePicker = ({removeStyle,Placeholder,  ...options}) => {
    return (
        <div className={`${removeStyle ? 'no-style-calendar' : 'style-calendar'} fintoo-datepicker fn-date ${options.date ? 'fn-selected' : ''}`}>
            {/* {minDate={new Date()}} */}
            <DatePicker
            onKeyDown={(e)=> e.preventDefault()}
            placeholderText={Placeholder}
            dropdownMode="select"
            {...options}
            calendarIcon={<img width={'20px'} src={require('./Images/calendar73.png')} />}
            />
        </div>
    );
}
export default FintooDatePicker;