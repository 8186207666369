import { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import LifeInsurance from "../../Assets/Datagathering/Graph/LifeInsurance";
import Medicalinsurance from "../../Assets/Datagathering/Graph/Medicalinsurance";
import DatagatherReportLayout from "../../components/Layout/Datagather/Reports";
import FintooLoader from "../../components/FintooLoader";
import { ADVISORY_GET_CONTENGENCY_DATA, CHECK_SESSION, ADVISORY_GET_INSURANCE_DATA, ADVISORY_GET_LIFE_INSURANCE, ADVISORY_GET_MEDICAL_INSURANCE, ADVISORY_GET_MEMBER_COUNT, imagePath, BASE_API_URL } from "../../constants";
import {
  getParentUserId, getItemLocal, fetchEncryptData,
  loginRedirectGuest, numberFormat,
  setBackgroundDivImage
} from "../../common_utilities";
import { Link } from "react-router-dom";

const Contingencyplanning = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState("tab1");
  const [isOpen, setIsOpen] = useState(false);
  const handleTrigger = () => setIsOpen(!isOpen);
  const [memberCount, setMemberCount] = useState([]);
  const sessionData = useRef();
  const [contingencyRiskData, setContingencyRiskData] = useState({
    monthlylExpData: 0,
    spouseEarning: 0,
    totalLiquidAsset: 0,
    emergencyCorpusRecommendationText: "",
  });
  const [insuranceData, setInsuranceData] = useState({
    insuranceDataa: {},
    healthInsuranceData: {},
    totalsumassured: 0,
    totalpremiumamt: 0,
    totalhealthsumassured: 0,
    totalhealthpremiumamt: 0
  });
  const [mediaclInsuranceData, setMediaclInsuranceData] = useState({
    shortfallorsurplusamt: 0,
    existingmedicalcover: 0,
    idealmedicalcover: 0,
    topupamount:0,
    type: "",
    medicalgraphdata: {}
  });
  const [lifeInsuranceData, setLifeInsuranceData] = useState({
    lifeInsuranceDataa: {},
    totalOutstanding: 0,
    totalGoalPresentValue: 0,
    policyDetailsTotal: 0,
    idealCover: 0,
    totalExp: 0,
    lifeInsurancegraphdata: {}
  });
  const [mediclaimGraphData, setMediclaimGraphData] = useState("")

  useEffect(()=>{
    var location_url = location.href;
    var tab_name=location_url.split("?tab=")[1];
    if (tab_name == "lifeinsurance") {
      setTab("tab3");
    }
    if (tab_name == "medicalinsurance") {
      setTab("tab4");
    }
  },[])


  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.body.classList.add("rp-layout");
    document.getElementById('report-bg-risk').style.background = 'url(' + imagePath +
      'https://static.fintoo.in/static/assets/img/reports/ill-risk-management.svg)' + " no-repeat right top";
    getMemberList();
    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
      document.body.classList.remove("rp-layout");
    };
  }, []);

  const getContingencyRisk = async () => {
    try {
      let apiData = {
        user_id: sessionData.current["data"]["id"],
        fp_log_id: sessionData.current["data"]["fp_log_id"],
        fp_user_id: sessionData.current["data"]["fp_user_id"]
      };
      let config = {
        method: "POST",
        url: ADVISORY_GET_CONTENGENCY_DATA,
        data: apiData,
      };
      let decodedRes = await fetchEncryptData(config);
      if (decodedRes['error_code'] == '100') {
        setIsLoading(false);
        setContingencyRiskData({ ...contingencyRiskData, monthlylExpData: decodedRes['data']['contingency_recommendation']['expense'], spouseEarning: decodedRes['data']['contingency_recommendation']['earning_spouse'], totalLiquidAsset: decodedRes['data']['contingency_recommendation']['total_liquid_asset'], emergencyCorpusRecommendationText: decodedRes['data']['emergency_corpus_recommendation']['Emergency Corpus Recommendation Text'] })
      }
    }
    catch (e) {
      console.log(e);
    }

  }

  const getCurrentInsurance = async () => {
    try {
      let apiData = {
        user_id: sessionData.current["data"]["id"],
        fp_log_id: sessionData.current["data"]["fp_log_id"],
      };
      let config = {
        method: "POST",
        url: ADVISORY_GET_INSURANCE_DATA,
        data: apiData,
      };
      let decodedRes = await fetchEncryptData(config);
      if (decodedRes['error_code'] == '100') {
        setIsLoading(false);
        var insurancedata = decodedRes['data']
        var totalSumAssured = 0
        var totalPremiumAmount = 0
        insurancedata.forEach(insurance => {
          totalSumAssured = totalSumAssured + insurance['insurance_sum_assured']
          totalPremiumAmount = totalPremiumAmount + insurance['insurance_premium_amount']
        });

        var healthinsurancedata = decodedRes['data1']
        var totalHealthSumAssured = 0
        var totalHealthPremiumAmount = 0
        healthinsurancedata.forEach(insurance => {
          totalHealthSumAssured = totalHealthSumAssured + insurance['insurance_sum_assured']
          totalHealthPremiumAmount = totalHealthPremiumAmount + insurance['insurance_premium_amount']
        });
        var totalTopUp = 0;
        healthinsurancedata.forEach((insurance) => {
          totalTopUp =
          totalTopUp + insurance["insurance_premium_topup"];
        });

        setInsuranceData(v => {
          return {
            ...v,
            insuranceDataa: decodedRes['data'],
            healthInsuranceData: decodedRes['data1'],
            totalsumassured: totalSumAssured,
            totalpremiumamt: totalPremiumAmount,
            totalhealthsumassured: totalHealthSumAssured,
            totalhealthpremiumamt: totalHealthPremiumAmount,
            totalTopUp:totalTopUp
          }
        });
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const getMedicalInsurance = async () => {
    try {
      let apiData = {
        user_id: sessionData.current["data"]["id"],
        fp_log_id: sessionData.current["data"]["fp_log_id"],
        fp_user_id: sessionData.current["data"]["fp_user_id"]
      };
      let config = {
        method: "POST",
        url: ADVISORY_GET_MEDICAL_INSURANCE,
        data: apiData,
      };
      let decodedRes = await fetchEncryptData(config);
      if (decodedRes['error_code'] == '100') {
        setIsLoading(false);
        let type;
        if (decodedRes['data']['shortfallorsurplusamt'] < 0) {
          type = 'shortfall';
        } else {
          type = 'surplus';
        }

        var medicalGraphData = [];
        var graphData = [];
        if (mediaclInsuranceData.shortfallorsurplusamt) {
          graphData = [
            {
              name: "Ideal Health Cover",
              total: decodedRes['data']['requiredcover'],
            },
            {
              name: "Existing Personal Cover",
              total: decodedRes['data']['existingfullcover'],
            },
            {
              name: type,
              total: decodedRes['data']['shortfallorsurplusamt'],
            },
          ];
        } else {
          graphData = [
            { name: "Ideal Health Cover", total: decodedRes['data']['requiredcover'] },
            { name: "Existing Personal Cover", total: decodedRes['data']['existingfullcover'] },
            { name: type, total: decodedRes['data']['shortfallorsurplusamt'] },
          ];
        }
        graphData.map((val) => {
          if (val["total"]) {
            medicalGraphData.push({ name: val["name"], y: val["total"] });
          }
        });
        setMediclaimGraphData(graphData);
        mediaclInsuranceData.medicalgraphdata = medicalGraphData
        setMediaclInsuranceData(v => {
          return {
            ...v,
            shortfallorsurplusamt: decodedRes['data']['shortfallorsurplusamt'],
            existingmedicalcover: decodedRes['data']['existingfullcover'],
            idealmedicalcover: decodedRes['data']['requiredcover'],
            topupamount: decodedRes["data"]["topupamount"],
            type: decodedRes['data']['type']
          }
        });
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const getLifeInsurance = async () => {
    try {
      let apiData = {
        user_id: sessionData.current["data"]["id"],
        fp_log_id: sessionData.current["data"]["fp_log_id"]
      };
      let config = {
        method: "POST",
        url: ADVISORY_GET_LIFE_INSURANCE,
        data: apiData,
      };
      let decodedRes = await fetchEncryptData(config);
      if (decodedRes['error_code'] == '100') {
        setIsLoading(false);
        // var outstanding_details = decodedRes['data'][0]['life_insurance']['outstanding_details']
        // var outstandingTotal = 0
        // outstanding_details.forEach(outstanding => {
        //   outstandingTotal = outstandingTotal + outstanding['liability_outstanding_amount']
        // });
        var policyDetails = decodedRes['data'][0]['life_insurance']['policy_details']
        var policyDetailsTotal = 0
        policyDetails.forEach(policy => {
          policyDetailsTotal = policyDetailsTotal + policy['insurance_sum_assured']
        });

        var futureGoals = decodedRes['data'][0]['life_insurance']['future_goals']
        var futureGoalsTotal = 0
        futureGoals.forEach(futurevalue => {
          futureGoalsTotal = futureGoalsTotal + futurevalue['goal_present_value']
        });

        if (isNaN(lifeInsuranceData.totalExp)) {
          lifeInsuranceData.totalExp = 0
        }

        var lifeInsuranceGraphData = [];
        var graphData = [];
        if (decodedRes) {
          graphData = [
            {
              name: "Ideal Cover",
              total: decodedRes['data'][0]['our_recommendation']['ideal_cover'],
            },
            {
              name: "Existing Cover",
              total: decodedRes['data'][0]['our_recommendation']['existing_cover'],
            },
            {
              name: "Additional Insurance Required",
              total: decodedRes['data'][0]['our_recommendation']['our_recommendation'],
            },
          ];
        }
        else {
          graphData = [
            { name: "Ideal Cover", total: decodedRes['data'][0]['our_recommendation']['ideal_cover'] },
            { name: "Existing Cover", total: decodedRes['data'][0]['our_recommendation']['existing_cover'] },
            { name: "Additional Insurance Required", total: decodedRes['data'][0]['our_recommendation']['our_recommendation'] },
          ];
        }
        graphData.map((val) => {
          if (val["total"]) {
            lifeInsuranceGraphData.push({ name: val["name"], y: val["total"] });
          }
        });
        // lifeInsuranceData.lifeInsurancegraphdata = lifeInsuranceGraphData

        setLifeInsuranceData(v => {
          return {
            ...v,
            idealCover: decodedRes['data'][0]['our_recommendation']['ideal_cover'],
            totalOutstanding: decodedRes['data']['0']['life_insurance']['outstanding_debt'],
            totalGoalPresentValue: futureGoalsTotal,
            policyDetailsTotal: policyDetailsTotal,
            lifeInsuranceDataa: decodedRes['data'],
            totalExp: decodedRes['data'][0]['life_insurance']['regular_expense_till_limited'] + decodedRes['data'][0]['life_insurance']['pv_house_rent'] + decodedRes['data'][0]['life_insurance']['regular_house_expense'],
            lifeInsurancegraphdata: lifeInsuranceGraphData
          }
        });

      }
    }
    catch (e) {
      console.log(e)
    }

  }

  const getMemberCount = async () => {
    try {
      let apiData = {
        fp_log_id: sessionData.current["data"]["fp_log_id"]
      };
      let config = {
        method: "POST",
        url: ADVISORY_GET_MEMBER_COUNT,
        data: apiData,
      };
      let decodedRes = await fetchEncryptData(config);
      if (decodedRes['error_code'] == '100') {
        setMemberCount(decodedRes['member_count'])
      }
    }
    catch (e) {
      console.log(e)
    }
  }


  const getMemberList = async () => {
    try {
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let config = {
        method: "POST",
        url: CHECK_SESSION,
        data: data,
      };
      sessionData.current = await fetchEncryptData(config);
      if (sessionData.current.error_code == "102") {
        loginRedirectGuest()
      }
      else {
        getContingencyRisk()
        getCurrentInsurance()
        getMedicalInsurance()
        getLifeInsurance()
        getMemberCount()

      }

    } catch (e) {
      console.log("err", e);
    }
  };

  return (
    <DatagatherReportLayout>
      <FintooLoader isLoading={isLoading} />
      <div className="reports ">
        <div className="">
          <div className="background-div">
            <div class="bg active" id="report-bg-risk"></div>
          </div>
          <div className="white-box">
            <div className="d-flex justify-content-md-center tab-box">
              <div className="d-flex top-tab-menu noselect">
                <div className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`} onClick={() => setTab("tab1")}>
                  <div className="tab-menu-title">CONTINGENCY PLANNING</div>
                </div>
                <div className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`} onClick={() => setTab("tab2")}>
                  <div className="tab-menu-title">CURRENT INSURANCE</div>
                </div>
                {memberCount == 1 &&
                  <div className={`tab-menu-item ${tab == "tab3" ? "active" : ""}`} onClick={() => setTab("tab3")}>
                    <div className="tab-menu-title">LIFE INSURANCE</div>
                  </div>
                }
                <div className={`tab-menu-item ${tab == "tab4" ? "active" : ""}`} onClick={() => setTab("tab4")}>
                  <div className="tab-menu-title">MEDICAL INSURANCE</div>
                </div>
                <div className={`tab-menu-item ${tab == "tab5" ? "active" : ""}`} onClick={() => setTab("tab5")}>
                  <div className="tab-menu-title">WHAT IF ANALYSIS</div>
                </div>
              </div>
            </div>

            <div>
              <div className={tab == "tab1" ? "d-block" : "d-none"}>
                <div
                  className="contingencyPlanningHolder">
                  <div>
                    <div
                      className="contingencyPlanningHead text-center"
                      style={{ paddingTop: "20px !important" }}
                    >
                      <h4 className="rTitle" style={{ textAlign: "left" }}>
                        <img
                          alt=""
                          src={imagePath + "https://static.fintoo.in/static/assets/img/reports/risk-management/continegency-planning.svg"}
                        />
                        Emergency Corpus
                      </h4>

                      <div className="textDiv white">
                        <p>
                          {" "}
                          Emergencies may arise due to many reasons like health
                          issues, job loss, disability, unexpected expenses,
                          etc. These emergencies may lead to a reduction or
                          stoppage of one’s income. But, regular household
                          expenses don’t stop. So, it is important to plan out
                          an emergency corpus for such situations.
                        </p>

                      </div>

                      <div className="table-responsive rTable">
                        <table
                          id="borderRightaactive"
                          className="bgStyleTable"
                          border={1}
                        >
                          <thead>
                            <tr>
                              <th colSpan={4} className="text-center">
                                Emergency Corpus
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="outline">
                              <td>Type</td>
                              <td colSpan={2}>Amount (₹)</td>
                              <td rowSpan={4} className="text-center boxed">
                                <div>Emergency corpus(₹) (B-A)</div>
                                <div
                                  className="colorGreen1 "

                                  style={{
                                    color: "#042b62",
                                    backgroundColor: "`transparent` !important",
                                  }}
                                >
                                  <span>
                                    {(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData) === 0 && (
                                      <h2 style={{ fontWeight: 900 }}>
                                        <span>Surplus </span>
                                        {numberFormat(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData, 0)}
                                      </h2>
                                    )}
                                    {(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData) > 0 && (
                                      <h2 style={{ fontWeight: 900 }}>
                                        <span>Surplus </span>
                                        {numberFormat(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData, 0)}
                                      </h2>
                                    )}
                                    {(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData) < 0 && (
                                      <h2 style={{ color: 'red', fontWeight: 900 }}>
                                        <span>Shortfall </span>({numberFormat(Math.abs(contingencyRiskData.totalLiquidAsset - contingencyRiskData.monthlylExpData), 0)})
                                      </h2>
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                Emergency Corpus Required for {contingencyRiskData.spouseEarning == "no" ? "6" : "4"} months
                                (A)
                              </td>
                              <td>{numberFormat(Math.abs(contingencyRiskData.monthlylExpData), 0)}</td>
                            </tr>
                            <tr>
                              <td className="bold top-line total-value">
                                Assets Available ( B )
                              </td>
                              <td
                                colSpan={2}
                                className="bold top-line total-value "
                              >
                                {numberFormat(contingencyRiskData.totalLiquidAsset, 0)}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <br />
                      <br />
                      <div className="recommen_sec_div mt-5">
                        <div className="rec_head_div">
                          <i />
                          <span>Fintoo Recommends</span>
                        </div>
                        <div className="rec_text_div">

                          <p dangerouslySetInnerHTML={{ __html: contingencyRiskData.emergencyCorpusRecommendationText ? contingencyRiskData.emergencyCorpusRecommendationText : '' }}></p>
                          <p />
                        </div>
                      </div>
                    </div>
                    <div
                      className="no-data-found text-center d-none"
                    >
                      <div className="container">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-10">
                            <img
                              alt="Data not found"
                              src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                            />
                            <p>
                              Since you missed to fill in the required
                              information which is needed here, we are not able
                              to show you this section. Kindly click on below
                              button to provide all the necessary inputs.
                              Providing all the information as asked will ensure
                              more accurate financial planning report. Once you
                              fill in the data, same will be reflected here.
                            </p>
                            <a
                              href={process.env.PUBLIC_URL + "/datagathering/insurance"}
                              target="_blank"
                              className="link"
                            >
                              Complete Insurance
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn ">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/report/goal-analysis"
                            }
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center" >
                              <FaArrowLeft />
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Previous&nbsp;
                              </span>
                            </div>
                          </Link>
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => setTab("tab2")}
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab2" ? "d-block" : "d-none"}>
                <div
                  className="pageHolder currentInsurancePolicies"

                >

                  {insuranceData.insuranceDataa?.length > 0 &&
                    <>
                      <h4 className="rTitle" style={{}}>
                        <img
                          src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/current-insurance-policies.svg"}
                          className="title-icon"
                        />
                        Current Life Insurance
                      </h4>
                      <div
                        className="rContent "
                        style={{}}
                      >
                        <p>
                          If we can imagine a situation where our goals are affected
                          by acts beyond our control, we realise the importance of
                          insurance in our lives. Insurance enables us to live
                          without worrying about the impact of uncertain events that
                          could hamper our life along with the lives of our loved
                          ones.
                        </p>
                      </div>
                      <div className="table-responsive rTable" style={{}}>
                        <table className="bgStyleTable">
                          <tbody>
                            <tr>
                              <th>Policy name</th>
                              <th>Type</th>
                              <th>Name of Holder</th>
                              <th>Start Year</th>
                              <th>End Year</th>
                              <th>Sum Assured (₹)</th>
                              <th>Premium Payable (₹)</th>
                              <th>Premium Frequency</th>
                            </tr>
                            {insuranceData.insuranceDataa.map(insurance => (
                              <tr >
                                <td>{insurance.insurance_name}</td>
                                <td>{insurance.category_name}</td>
                                <td>{insurance.insurance_member_name}</td>
                                <td>{insurance.insurance_purchase_date.split('-')[0]}</td>
                                <td>{insurance.insurance_policy_enddate.split('-')[0]}</td>
                                <td>{numberFormat(insurance.insurance_sum_assured, 0)}</td>
                                <td>{numberFormat(insurance.insurance_premium_amount, 0)}</td>
                                <td>{insurance.payment_mode}</td>
                              </tr>
                            ))}
                            <tr className="bold top-line total-value">
                              <td colSpan={5}>Total</td>
                              <td>{numberFormat(insuranceData.totalsumassured, 0)}</td>
                              <td>{numberFormat(insuranceData.totalpremiumamt, 0)}</td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>

                  }



                  {insuranceData.healthInsuranceData?.length > 0 &&
                    <>
                      <h4 className="rTitle  mt-5">
                        <img
                          className="title-icon"
                          src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/current-insurance-policies.svg"}
                        />
                        Current Health Insurance
                      </h4>
                      <div className="rContent ">
                        <p>
                          If we can imagine a situation where our goals are affected
                          by acts beyond our control, we realise the importance of
                          insurance in our lives. Insurance enables us to live
                          without worrying about the impact of uncertain events that
                          could hamper our life along with the lives of our loved
                          ones.
                        </p>
                      </div>
                      <div className="table-responsive rTable">
                        <table className="bgStyleTable">
                          <tbody>
                            <tr>
                              <th>Policy name</th>
                              <th>Type</th>
                              <th>Name of Holder</th>
                              <th>Start Year</th>
                              <th>End Year</th>
                              <th>Sum Assured (₹)</th>
                              <th>Top Up (₹)</th>
                              <th>Premium Payable (₹)</th>
                              <th>Premium Frequency</th>
                            </tr>
                            {insuranceData.healthInsuranceData.map(healthinsurance => (
                              <tr>
                                <td>{healthinsurance.insurance_name}</td>
                                <td>{healthinsurance.category_name}</td>
                                <td>{healthinsurance.insurance_member_name}</td>
                                <td>{healthinsurance.insurance_purchase_date.split('-')[0]}</td>
                                <td>{healthinsurance.insurance_policy_enddate.split('-')[0]}</td>
                                <td>{numberFormat(healthinsurance.insurance_sum_assured, 0)}</td>
                                <td>{numberFormat(healthinsurance.insurance_premium_topup,0)}</td>
                                <td>{numberFormat(healthinsurance.insurance_premium_amount, 0)}</td>
                                <td>{healthinsurance.payment_mode}</td>
                              </tr>
                            ))}
                            <tr className="bold top-line total-value">
                              <td colSpan={5}>Total</td>
                              <td>{numberFormat(insuranceData.totalhealthsumassured, 0)}</td>
                              <td>{numberFormat(insuranceData.totalTopUp, 0)}</td>
                              <td>{numberFormat(insuranceData.totalhealthpremiumamt, 0)}</td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  }
                  {insuranceData.insuranceDataa?.length == 0 &&
                    insuranceData.healthInsuranceData?.length == 0 ||
                    insuranceData.insuranceDataa?.length == undefined &&
                    insuranceData.healthInsuranceData?.length == undefined &&
                    <div className="no-data-found text-center">
                      <div className="container">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-10">
                            <img
                              src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                              alt="Data not found"
                            />
                            <p>
                              Since you missed to fill in the required information
                              which is needed here, we are not able to show you
                              this section. Kindly click on below button to
                              provide all the necessary inputs. Providing all the
                              information as asked will ensure more accurate
                              financial planning report. Once you fill in the
                              data, same will be reflected here.
                            </p>
                            <a
                              href={process.env.PUBLIC_URL + "/datagathering/insurance"}
                              target="_blank"
                              className="link"
                            >
                              Complete Insurance
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div className="previous-btn form-arrow d-flex align-items-center" onClick={() => setTab("tab1")}>
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>

                          <div className="next-btn form-arrow d-flex align-items-center" onClick={() => {
                            if (memberCount === 0) {
                              setTab("tab4");
                            } else {
                              setTab("tab3");
                            }
                          }}>
                            <span className="hover-text" style={{ maxWidth: 100 }}>
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab3" ? "d-block" : "d-none"}>
                <div>
                  {lifeInsuranceData.idealCover > 0 && (
                    <div>
                      <div className="lifeInsuranceHolderBox">
                        <h4 className="rTitle">
                          <img
                            className="title-icon"
                            src={imagePath + "https://static.fintoo.in/static/assets/img/reports/risk-management/life-insurance.svg"}
                          />
                          Life insurance
                        </h4>
                        <div
                          className="rContent "
                        >
                          <p>
                            As an earning member of your family, it’s essential
                            that you buy life insurance. It provides you with a
                            layer of protection against situations that are out of
                            your control and in turn, offers peace of mind. In
                            other words, in the event of your demise, the amount
                            that your family will receive will help them to meet
                            their financial needs. Purchasing appropriate life
                            insurance will help maintain their standard of living
                            and ensure that they are able to repay outstanding
                            debts, if any. Moreover, you will be able to provide
                            for your family’s future needs, be it your children’s
                            education, their weddings, or your spouse’s
                            retirement.
                          </p>
                        </div>
                        <div className="table-responsive rTable">
                          <table className="bgStyleTable">
                            <thead>
                              <tr className="color">
                                <td>Particular</td>
                                <td className="text-center">
                                  Annual amount (₹)
                                  <br />
                                  <span
                                    style={{ fontSize: 12, paddingLeft: 10 }}

                                  >
                                    (till 31<sup>st</sup> Dec, {new Date().getFullYear()})
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bold">
                                <td>Critical Expenses</td>
                                <td />
                              </tr>
                              <tr>
                                <td>Regular expenses till lifetime (Of Self)</td>
                                <td>{numberFormat(lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['pv_house_rent'] + lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['regular_house_expense'], 0)}</td>
                              </tr>
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['regular_expense_till_limited'] > 0 && (
                                <tr>
                                  <td>
                                    Regular expenses till Limited term It includes family members and dependent members
                                  </td>
                                  <td>{numberFormat(lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['regular_expense_till_limited'], 0)}</td>
                                </tr>
                              )}
                              <tr className="bold top-line total-value">
                                <td>Total Critical Expenses (A)</td>
                                <td>
                                  {numberFormat(lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['regular_expense_till_limited'] + lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['pv_house_rent'] + lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['regular_house_expense'],0)}
                                </td>
                              </tr>

                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['outstanding_details']?.length > 0 &&
                                <tr className="bold top-line total-value" >
                                  <td>Outstanding Debt</td>
                                </tr>
                              }

                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['outstanding_details']?.length > 0 && lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['outstanding_details'].map(outstanding => (
                                <tr>
                                  <td>{outstanding.liability_name}</td>
                                  <td>{numberFormat(outstanding.liability_outstanding_amount, 0)}</td>
                                </tr>
                              ))}
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['outstanding_details']?.length > 0 &&
                                <tr className="bold top-line total-value" >
                                  <td>Total Liabilites (B)</td>
                                  <td>{numberFormat(lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['outstanding_debt'], 0)}</td>
                                </tr>
                              }
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['future_goals']?.length > 0 &&
                                <tr className="bold top-line total-value">
                                  <td>Goal Present Value</td>
                                  <td />
                                </tr>
                              }
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['future_goals']?.length > 0 && lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['future_goals'].map(goals => (
                                <tr>
                                  <td>{goals.goal_name}</td>
                                  <td>{numberFormat(goals.goal_present_value, 0)}</td>
                                </tr>
                              ))}
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['future_goals']?.length > 0 &&
                                <tr className="bold top-line total-value">
                                  <td>Total Goal Present Value (C)</td>
                                  <td>{numberFormat(lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['total_goal'], 0)}</td>
                                </tr>
                              }
                              <tr className="bold top-line total-value">
                                <td>
                                  Total Ideal Insurance(D) (
                                  {lifeInsuranceData.totalExp ? 'A' : ''}
                                  {lifeInsuranceData.totalExp && lifeInsuranceData.totalOutstanding !== 0 ? ' + ' : ''}
                                  {lifeInsuranceData.totalOutstanding !== 0 ? 'B' : ''}
                                  {(lifeInsuranceData.totalExp || lifeInsuranceData.totalOutstanding) && lifeInsuranceData.totalGoalPresentValue !== 0 ? '+' : ''}
                                  {lifeInsuranceData.totalGoalPresentValue !== 0 ? 'C' : ''}
                                  )
                                </td>
                                <td>{numberFormat(lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue, 0)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="lifeInsuranceHolderBox mt-5">
                        <h4 className="rTitle">
                          <img
                            className="title-icon"
                            alt=""
                            src={imagePath + "https://static.fintoo.in/static/assets/img/reports/risk-management/policy-details.svg"}
                          />
                          Policy details
                        </h4>
                        {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['policy_details']?.length > 0 &&
                          <div className="rContent">
                            <p>
                              In the table below you’ll find details of the existing
                              life insurance policies that you have purchased.
                            </p>
                          </div>
                        }
                        <div className="table-responsive rTable">
                          <table className="bgStyleTable">
                            <thead>
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['policy_details']?.length > 0 &&
                                <tr className="color">
                                  <td>Policy name</td>
                                  <td>Sum assured (₹)</td>
                                </tr>
                              }
                            </thead>
                            <tbody>
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['policy_details']?.length > 0 && lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['policy_details'].map(policy => (
                                <tr>
                                  <td>{policy.insurance_name}</td>
                                  <td>{numberFormat(policy.insurance_sum_assured, 0)}</td>
                                </tr>
                              ))}
                              {lifeInsuranceData.lifeInsuranceDataa[0]['life_insurance']['policy_details']?.length > 0 &&
                                <tr className="bold top-line total-value">
                                  <td>Total Existing Insurance(E)</td>
                                  <td>{numberFormat(lifeInsuranceData.policyDetailsTotal, 0)}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="lifeInsuranceHolderBox">
                        <div
                          className="rContent ">
                          <p>
                            - Ideal Insurance cover required less existing
                            insurance that you already have will give the
                            additional life insurance cover that you need to buy.
                          </p>
                        </div>
                        <div className="table-responsive rTable">
                          <table className="bgStyleTable recommendationTable1">
                            <tbody>
                              <tr>
                                <td>Ideal Insurance cover (D)</td>
                                <td>{numberFormat(lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue, 0)}</td>
                              </tr>
                              <tr>
                                <td>Existing Insurance cover (E)</td>
                                <td>{numberFormat(lifeInsuranceData.policyDetailsTotal, 0)}</td>
                              </tr>
                              <tr className="color bold top-line total-value">
                                <td>
                                  Additional Insurance Required (E-D)
                                </td>
                                {((lifeInsuranceData.policyDetailsTotal)-(lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)) === 0 && (
                                  <td>{numberFormat(((lifeInsuranceData.policyDetailsTotal) - (lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)), 0)}</td>
                                )}
                                {((lifeInsuranceData.policyDetailsTotal)-(lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)) > 0 && (
                                  <td>{numberFormat(((lifeInsuranceData.policyDetailsTotal) - (lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)), 0)}</td>
                                )}
                                {((lifeInsuranceData.policyDetailsTotal)-(lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)) < 0 && (
                                  <td style={{ color: 'red' }}>({numberFormat(Math.abs((lifeInsuranceData.policyDetailsTotal) - (lifeInsuranceData.totalExp + lifeInsuranceData.totalOutstanding + lifeInsuranceData.totalGoalPresentValue)), 0)})</td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="rGraph mt-5 ps-md-4">
                        <div className="mt-4">
                          <LifeInsurance lifeInsurancegraphdata={lifeInsuranceData.lifeInsurancegraphdata} />
                        </div>
                      </div>
                    </div>
                  )}
                  {lifeInsuranceData.idealCover === 0 && (
                    <div
                      className="no-data-found text-center"
                    >
                      <div className="container">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-10">
                            <img
                              alt="Data not found"
                              src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                            />
                            <p>
                              Since you missed to fill in the required
                              information which is needed here, we are not able
                              to show you this section. Kindly click on below
                              button to provide all the necessary inputs.
                              Providing all the information as asked will ensure
                              more accurate financial planning report. Once you
                              fill in the data, same will be reflected here.
                            </p>
                            <a
                              href={process.env.PUBLIC_URL + "/datagathering/insurance"}
                              target="_blank"
                              className="link"
                            >
                              Complete Insurance
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row py-2">
                    <div className=" text-center">
                      <div>
                        <div className="btn-container fixedBtn">
                          <div className="d-flex justify-content-center">
                            <div className="previous-btn form-arrow d-flex align-items-center" onClick={() => setTab("tab2")}>
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                            <div className="next-btn form-arrow d-flex align-items-center" onClick={() => setTab("tab4")}>
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Next&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab4" ? "d-block" : "d-none"}>
                <div
                  className="lifeInsuranceHolderBox">
                  <h4 className="rTitle">
                    <img
                      className="title-icon"
                      src={imagePath + "https://static.fintoo.in/static/assets/img/reports/risk-management/medical-insurance-coverage.svg"}
                    />
                    Medical insurance coverage
                  </h4>
                  <div>
                    <div
                      className="rContent ">
                      <p>
                        Today, when stress and lifestyle-related health issues
                        are rapidly increasing, a large number of young and
                        middle-aged individuals are being diagnosed with
                        diseases that were once considered to be rare and
                        acquired only at old ages. While health is deteriorating
                        and health care is becoming more and more expensive,
                        buying a health insurance policy for yourself and your
                        family has become extremely important.{" "}
                      </p>
                      <p>
                        Hospitalisation can burn a hole in your pocket and
                        derail your finances. It will become even more difficult
                        if the earning member needs to get hospitalised. All
                        this can be avoided by just paying a small annual
                        premium which would lessen your stress in case of
                        medical emergencies. A good health insurance policy
                        would usually cover expenses made towards doctor
                        consultation fees, costs towards medical tests,
                        ambulance charges, hospitalization costs and even
                        post-hospitalization recovery costs to a certain extent.
                      </p>
                      <p className="medicalLabel mt-3">
                        Parameters Considered To Calculate Your Ideal Medical
                        Insurance Cover:
                      </p>
                      <ol
                        className="MedicalInsurance"
                        style={{
                          lineHeight: "30px",
                        }}
                      >
                        <li>Location</li>
                        <li>Lifestyle</li>
                        <li>Current Health Status</li>
                        <li>Affordability</li>
                      </ol>
                    </div>
                    <div className="table-responsive rTable">
                      <table className="bgStyleTable">
                        <tbody>
                          <tr>
                            <th>Coverage</th>
                            <th>Sum Insured (₹)</th>
                          </tr>
                          <tr>
                            <td>Ideal Health Coverage For Family (A)</td>
                            <td>
                              {numberFormat(
                                mediaclInsuranceData.idealmedicalcover,
                                0
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Existing Personal Cover (B)</td>
                            <td>
                              {numberFormat(
                                mediaclInsuranceData.existingmedicalcover,
                                0
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Existing Top UP Family (C)</td>
                            <td>
                              {numberFormat(
                                mediaclInsuranceData.topupamount,
                                0
                              )}
                            </td>
                          </tr>
                          <tr className="bold top-line total-value">
                            <td style={{ textTransform: "capitalize" }}>
                              {mediaclInsuranceData.type} (B+C) - A
                            </td>
                            {mediaclInsuranceData.shortfallorsurplusamt ===
                              0 && (
                              <td>
                                {numberFormat(
                                  mediaclInsuranceData.shortfallorsurplusamt,
                                  0
                                )}
                              </td>
                            )}
                            {mediaclInsuranceData.shortfallorsurplusamt > 0 && (
                              <td>
                                {numberFormat(
                                  mediaclInsuranceData.shortfallorsurplusamt,
                                  0
                                )}
                              </td>
                            )}
                            {mediaclInsuranceData.shortfallorsurplusamt < 0 && (
                              <td style={{ color: "red" }}>
                                (
                                {numberFormat(
                                  Math.abs(
                                    mediaclInsuranceData.shortfallorsurplusamt
                                  ),
                                  0
                                )}
                                )
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="rGraph mt-5 ps-md-4">
                      <div className="mt-4">
                        <Medicalinsurance medicalgraphdata={mediclaimGraphData} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="no-data-found text-center d-none"

                  >
                    <div className="container">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-10">
                          <img
                            alt="Data not found"
                            src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                          />
                          <p>
                            Since you missed to fill in the required
                            information which is needed here, we are not able
                            to show you this section. Kindly click on below
                            button to provide all the necessary inputs.
                            Providing all the information as asked will ensure
                            more accurate financial planning report. Once you
                            fill in the data, same will be reflected here.
                          </p>
                          <a
                            href={process.env.PUBLIC_URL + "/datagathering/insurance"}
                            target="_blank"
                            className="link"
                          >
                            Complete Insurance
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div className="previous-btn form-arrow d-flex align-items-center" onClick={() => {
                            if (memberCount === 0) {
                              setTab("tab2");
                            } else {
                              setTab("tab3");
                            }
                          }}>
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          <div className="next-btn form-arrow d-flex align-items-center" onClick={() => setTab("tab5")}>
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab5" ? "d-block" : "d-none"} onClick={() => setTab("tab4")}>
                <div className="analysis-section text-center">
                  <div className="container">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-10">
                        {/* <img
                       src={imagePath + "/web/static/media/DMF/coming-soon.svg"}
                          alt=""
                        /> */}
                        <img src={process.env.REACT_APP_STATIC_URL + "media/DMF/coming-soon.svg"} alt="comming-soon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          <Link
                            to={process.env.PUBLIC_URL + "/report/cash-flow-management"}
                          >
                            <div className="next-btn form-arrow d-flex align-items-center">
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Next&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatagatherReportLayout>
  );
};
export default Contingencyplanning;
