import { useState, useEffect, useRef } from "react";
import PortfolioLayout from "../../../../components/Layout/Portfolio";
import Table from "react-bootstrap/Table";

import moment from "moment";
import axios from "axios";
import {
  DMF_COMPLETEDTRANSACTIONS_API_URL,
  DMF_GET_MF_TRANSACTIONS_API_URL,
  DMF_GET_PENDING_TANSACTION_API_URL,
  DMF_GET_UPCOMING_TRANSACTION_API_URL,
  IS_DIRECT,
} from "../../../../constants";
import {
  Link,
} from "react-router-dom";
import {
  getUserId,
  getItemLocal,
  fetchEncryptData,
  indianRupeeFormat,
  fetchData,
} from "../../../../common_utilities";
import FintooButton from "../../../../components/HTML/FintooButton";
import FintooLoader from "../../../../components/FintooLoader";

import Calendar from "react-calendar";
import style from "../style.module.css";
import FintooCheckbox from "../../../../components/FintooCheckbox/FintooCheckbox";
import { AiOutlineSearch } from "react-icons/ai";
import ExploreStock from "../../../../components/HTML/ExploreStock";
import SlidingPanel from "react-sliding-side-panel";
import CloseFilter from "../../../../Assets/Images/close.png";
import { HiSortAscending } from "react-icons/hi";
import FintooInlineLoader from "../../../../components/FintooInlineLoader";

const PortfolioTransaction = (props) => {
  const [tabSelection, setTabSelection] = useState("orderHistory");
  const [innerSelection, setInnerSelection] = useState("inprocess");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelecteDates] = useState([]);
  const [upcomingTransaction, setUpcomingTransaction] = useState([]);
  const [completedTransaction, setCompletedTransaction] = useState([]);
  const [diffDays, setDiffDays] = useState("");
  const [error, setError] = useState(false);
  const refCalendarBox = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePanelWidth, setSidePanelWidth] = useState(30);
  const [mfTransactions, setMfTransactions] = useState([]);

  const contentRef = useRef();
  const [inlineLoader, setInlineLoader] = useState(false);
  const unBlockScrollListen = useRef(true);

  const [filterState, setFilterState] = useState({
    orderType: [],
    orderStatus: []
  });

  const [options, setOptions] = useState({ currentPage: 1 });

  const handleOrderTypeChange = (v) => {
    let order_type = filterState.orderType;
    if (order_type.findIndex((x) => x == v) > -1) {
      order_type.splice(order_type.findIndex((x) => x == v), 1);
    } else {
      order_type.push(v);
    }
    setFilterState({ ...filterState, orderType: order_type });
  };

  const handleOrderStatusChange = (v) => {
    let order_status = filterState.orderStatus;
    if (order_status.findIndex((x) => x == v) > -1) {
      order_status.splice(order_status.findIndex((x) => x == v), 1);
    } else {
      order_status.push(v);
    }
    setFilterState({ ...filterState, orderStatus: order_status });
  }

  let userid = getUserId();

  function handleResize() {
    if (window.innerWidth < 768) {
      setSidePanelWidth(100);
    } else {
      setSidePanelWidth(20);
    }
  }

  const getMfTransactions = async (reset = false) => {
    try {
      let page = options.currentPage;
      const loaderFunction = page == 1 ? setIsLoading : setInlineLoader;
      loaderFunction(true);

      let fromDate = "";
      let toDate = "";
      if (selectedDates.length == 0) {
        fromDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        toDate = moment().format("YYYY-MM-DD");
      } else {
        fromDate = moment(selectedDates[0]).format("YYYY-MM-DD");
        toDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      }
      let payload = {
        url: DMF_GET_MF_TRANSACTIONS_API_URL,
        method: "post",
        data: {
          user_id: "" + getUserId(),
          page: "" + page,
          from_date: fromDate,
          to_date: toDate,
        },
      };

      if (options?.orderStatus ?? [].length > 0) {
        payload.data.order_status = options.orderStatus;
      }
      if (options?.orderType ?? [].length > 0) {
        payload.data.order_type = options.orderType;
      }

      let results = await fetchEncryptData(payload);
      loaderFunction(false);
      if (results.error_code == 100 && Array.isArray(results?.data?.transaction_data)) {
        if (Boolean(options.reset)) {
          console.log("reset --> ", options.reset);
          setMfTransactions(results.data.transaction_data);
        } else {
          setMfTransactions(prev => [...prev, ...results.data.transaction_data]);
        }
        unBlockScrollListen.current = true;
      } else if (Boolean(options.reset)) {
        console.log("inside reset --> ", options);
        if (results.error_code == 102) {
          setMfTransactions([]);
        }
      }
    } catch (e) {
      console.error('err77', e);
      console.error("Something went wrong");
    }
  };

  useEffect(() => {
    getMfTransactions();
  }, [options]);

  useEffect(function () {
    // CheckSession();
    // onLoadInIt();
    // getMfTransactions();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (unBlockScrollListen.current && contentRef.current.getBoundingClientRect().bottom <= window.innerHeight) {
      unBlockScrollListen.current = false;
      setOptions(prev => ({ ...prev, currentPage: prev.currentPage + 1, reset: false }));
      // window.removeEventListener("scroll", handleScroll);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refCalendarBox.current &&
        !refCalendarBox.current.contains(event.target)
      ) {
        setOpenCalendar(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const onLoadInIt = async () => {
    if (innerSelection == "inprocess") {
      getInprocessTransactions();
    } else {
      setTabSelection("upcoming");
      setInnerSelection("sip");
    }

    let userid = getUserId();
    try {
      if (getItemLocal("family")) {
        var new_array = [];
        var new_data = getItemLocal("member");
        new_data.forEach((element) => {
          new_array.push(element.id);
        });
        var stringArray = new_array.map((item) => item.toString());
      }
      setIsLoading(true);
      var upcomingData = {
        transaction_user_id: getItemLocal("family") ? stringArray : userid,
      };

      var config = {
        method: "post",
        url: DMF_GET_UPCOMING_TRANSACTION_API_URL,
        data: upcomingData,
      };

      var res = await fetchEncryptData(config);

      setUpcomingTransaction(res.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getInprocessTransactions = async () => {
    if (getItemLocal("family")) {
      var new_array = [];
      var new_data = getItemLocal("member");
      new_data.forEach((element) => {
        new_array.push(element.id);
      });
      var stringArray = new_array.map((item) => item.toString());
    }
    setIsLoading(true);
    try {
      var data = {
        user_id: getItemLocal("family") ? stringArray : getUserId(),
        is_direct: IS_DIRECT,
      };
      var config = {
        method: "post",
        url: DMF_GET_PENDING_TANSACTION_API_URL,
        data: data,
      };
      var res = await fetchEncryptData(config);
      var resp = res.data;
      const response_obj = [...resp].sort((a, b) => {
        const dateA = new Date(b.transaction_date);
        const dateB = new Date(a.transaction_date);
        return dateA - dateB;
      });
      response_obj.forEach(async (item) => {
        var trx_date = moment(item.transaction_date);
        if (moment(trx_date).hours() >= 15) {
          moment(trx_date).add(1, "day").startOf('day').add(10, "hours").format("YYYY-MM-DD");

        }

        var hd_data = {
          current_date: trx_date,
        };

        var config = {
          method: "post",
          url: process.env.REACT_APP_PYTHON_URL + "/stocksapi/checkholiday/",
          data: hd_data,
        };

        var res = await fetchData(config);
        var response_holiday = res.data;

        if (response_holiday == 1) {
          trx_date.setDate(trx_date.getDate() + 1);
        }

        let day = moment(trx_date).isoWeekday();

        if (day == 7) {
          trx_date.setDate(trx_date.getDate() + 1);
        } else if (day == 6) {
          trx_date.setDate(trx_date.getDate() + 2);
        }
        item.nav_date = trx_date;
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  };

  // useEffect(() => {
  //   getCompletedTransactions();
  // }, [selectedDates]);

  const getCompletedTransactions = async () => {
    try {
      if (getItemLocal("family")) {
        var new_array = [];
        var new_data = getItemLocal("member");
        new_data.forEach((element) => {
          new_array.push(element.id);
        });
        var stringArray = new_array.map((item) => item.toString());
      }
      setIsLoading(true);
      setCompletedTransaction(true);
      let fromDate = "";
      let toDate = "";
      if (selectedDates.length == 0) {
        fromDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        toDate = moment().format("YYYY-MM-DD");
      } else {
        fromDate = moment(selectedDates[0]).format("YYYY-MM-DD");
        toDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      }

      var completedData = {
        user_id: getItemLocal("family") ? stringArray : userid,
        from_date: fromDate,
        to_date: toDate,
        is_direct: IS_DIRECT,
      };

      setDiffDays(moment(toDate).diff(moment(fromDate), "days") + "");

      var config = {
        method: "post",
        url: DMF_COMPLETEDTRANSACTIONS_API_URL,
        data: completedData,
      };
      var res = await fetchEncryptData(config);
      setCompletedTransaction(res.data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <PortfolioLayout>
      <p style={{ height: "1rem" }}></p>
      <FintooLoader isLoading={isLoading} />
      {/* <p style={{ height: "2rem" }}></p> */}
      <div className="row">
        <div className={`col-12`}>
          <div className="insideTabBox">
            <div className="insideTab">
              <div
                onClick={() => {
                  setTabSelection("orderHistory");
                  setInnerSelection("inprocess");
                  getInprocessTransactions();
                }}
                className={`pointer ${tabSelection == "orderHistory" ? "active" : ""
                  }`}
              >
                <p>
                  <strong>Orders</strong>
                </p>
              </div>
              <div
                onClick={() => {
                  setTabSelection("upcoming");
                  setInnerSelection("sip");
                  onLoadInIt();
                }}
                className={`pointer ${tabSelection == "upcoming" ? "active" : ""
                  }`}
              >
                <p>
                  <strong>Upcoming</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="insideTabContent">
            <div className="ptTableBx p-2 p-md-4" ref={contentRef}>
              {tabSelection == "orderHistory" && (
                <div className={style.fltTrx} style={{ justifyContent: 'end' }}>
                  {/* <div
                    className=" "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "2rem",
                    }}
                  >
                    <div
                      className="search-box-container"
                      style={{ width: "fit-content" }}
                    >
                      <input
                        type="text"
                        name=""
                        id="search-text"
                        placeholder="Search Fund"
                        // value={txtvalue}
                        // onChange={filter}
                        tabIndex={0}
                        className="autocomplete-text"
                      />
                      <span
                        className="cr-pointer"
                        // onClick={() =>
                        //   document.getElementById("search-text").focus()
                        // }
                      >
                        <AiOutlineSearch width={"1.2em"} height={"1.2em"} />
                      </span>
                    </div>
                  </div> */}

                  <div className="position-relative d-flex align-items-center pointe pt-4 pt-md-0">
                    <div
                      className="resultOptionsBtn position-relative hover-dropdown pointer"
                      onClick={() => setOpenPanel((prev) => !prev)}
                    >
                      <HiSortAscending fontSize={"1.2rem"} />
                      <span>Filter</span>
                    </div>
                  </div>
                </div>
              )}

              {tabSelection == "upcoming" && (
                <div className=" d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center opt-flt-trnx">
                    <div
                      className={`pe-5 grey-500 ${innerSelection == "sip" ? "active" : ""
                        }`}
                    >
                      <span
                        className="pointer p-2"
                        onClick={() => setInnerSelection("sip")}
                      >
                        SIP
                      </span>
                    </div>
                    <div
                      className={`pe-5 grey-500 ${innerSelection == "stp" ? "active" : ""
                        }`}
                    >
                      <span
                        className="pointer p-2"
                        onClick={() => setInnerSelection("stp")}
                      >
                        STP
                      </span>
                    </div>
                    <div
                      className={` grey-500 ${innerSelection == "swp" ? "active" : ""
                        }`}
                    >
                      <span
                        className="pointer p-2"
                        onClick={() => setInnerSelection("swp")}
                      >
                        SWP
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {tabSelection == "orderHistory" &&
                innerSelection == "inprocess" && (
                  <>
                    {mfTransactions.length > 0 ? (
                      <Table responsive className="ptTable mt-4">
                        <thead className="">
                          <tr>
                            <th scope="col" className="ps-0 pt-4">
                              Fund Name
                            </th>
                            {/* <th scope="col">Order Date & Time</th> */}
                            <th scope="col">Order Date & Time</th>
                            {/* <th scope="col">NAV Date</th> */}
                            <th scope="col">Amount</th>
                            <th scope="col">Order Type</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            {/* <th scope="col">Mode</th> */}
                            {/* <th scope="col">Transaction Type</th> */}
                            {/* <th scope="col">Order Status</th> */}
                            <th>Order Summary</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mfTransactions.length > 0 &&
                            mfTransactions.map((val) => (
                              <tr key={val.transaction_id}>
                                <td
                                  scope="row"
                                  data-label="Funds"
                                  className="fundNameTd ps-0 pt-4"
                                >
                                  <div className="fundName9">
                                    <div>
                                      <img
                                        src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${val.amc_code}.png`}
                                        onError={(e) => {
                                          e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                          e.onError = null;
                                        }}
                                      />
                                    </div>
                                    <div className="fundNameCon">
                                      <div className="ProcessFundName">
                                        <strong>{val.s_name}</strong>
                                      </div>
                                      {/* <div className="d-flex fundoptiondetails">
                                        <div>
                                          {val.cart_purchase_type.toUpperCase()}
                                        </div>
                                        <div className="hrlinefund"></div>
                                        <div>
                                          Folio: {""}
                                          {val.transaction_folio_no == "new_folio"
                                            ? "New Folio"
                                            : val.transaction_folio_no}
                                        </div>
                                      </div> */}
                                      {/* <div style={{
                                        fontSize: ".8rem"
                                      }}>
                                        NAV Date : {moment(val.nav_date).format("DD-MM-YYYY")}
                                      </div> */}
                                    </div>
                                  </div>
                                </td>
                                <td scope="row" data-label="Order Date">
                                  <strong>
                                    {moment(val.transaction_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </strong>

                                  <div
                                    style={{
                                      color: "#8d8d8d",
                                    }}
                                  >
                                    {moment(val.transaction_date).format(
                                      "HH:mm:ss"
                                    )}
                                  </div>
                                </td>
                                {/* <td scope="row" data-label="NAV Date">
                                  <strong>
                                    {moment(val.nav_date).format("DD-MM-YYYY")}
                                  </strong>
                                </td> */}

                                <td scope="row" data-label="Amount">
                                  {/* <strong>{indianRupeeFormat(val.cart_amount)}</strong> */}
                                  <strong>
                                    {indianRupeeFormat(val.cart_amount * 1)}
                                  </strong>
                                </td>

                                <td scope="row" data-label="Purchase Type">
                                  <strong>{val.cartpurchasetype}</strong>

                                  {/* <strong>XXXX{val.bank_acc_no.substr(val.bank_acc_no.length - 4)}</strong> */}
                                  {/* <div
                                    style={{
                                      color: "#8d8d8d",
                                    }}
                                  >
                                    {inprocessTransaction &&
                                      renderPaymenytmode(val.payment_mode)}
                                  </div> */}
                                </td>

                                {/* <td scope="row" data-label="Mode">
                                  <strong>
                                    {inprocessTransaction &&
                                      renderPaymenytmode(val.payment_mode)}
                                  </strong>
                                </td> */}

                                {/* <td scope="row" data-label="Transaction Type">
                                  <strong>
                                    SUCCESS
                                  </strong>
                                </td> */}

                                <td scope="row" data-label="Payment Status">
                                  <div>
                                    <strong>{val.payment_status}</strong>
                                  </div>

                                  {/* <div style={{
                                    fontSize: ".8rem"
                                  }} className="d-flex">
                                    <div>NAV: 12345.6</div> <div className="hrlinefund"></div>
                                    <div>Units: 100.1                                    </div>

                                  </div> */}
                                </td>

                                <td scope="row" data-label="Order Status">
                                  <div>
                                    <strong>{val.order_status}</strong>
                                  </div>
                                </td>

                                <td scope="row">
                                  <div style={{ paddingLeft: "3rem" }}>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard/transactionInfo/${val.transaction_id}`}
                                    >
                                      <div className="pointer">
                                        <ExploreStock />
                                      </div>
                                    </Link>
                                  </div>
                                  {/* 
                                  <div style={{
                                    fontSize: ".8rem"
                                  }} className="d-flex">
                                    <div>NAV: 12345.6</div> <div className="hrlinefund"></div>
                                    <div>Units: 100.1                                    </div>

                                  </div> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <></>
                    )}
                    {!isLoading && mfTransactions.length == 0 && (
                      <>
                        <div className="text-center ps-0">
                          <div className="pt-2">
                            {diffDays == "30" ? (
                              <h2>
                                No transaction in the last {diffDays} days.
                              </h2>
                            ) : (
                              <h2>
                                No transaction in the selected date range.
                              </h2>
                            )}
                          </div>
                          <img
                            style={{ width: "400px" }}
                            src={
                              process.env.REACT_APP_STATIC_URL +
                              "/media/DMF/investment.png"
                            }
                          />
                          <div className="fin-p-txt pt-3 pb-3">
                            <p>
                              You can now manage your Mutual Funds on Fintoo
                              Dashboard
                            </p>
                          </div>
                          <div className="btn-add-fnds">
                            <Link
                              to={`${process.env.PUBLIC_URL}/direct-mutual-fund/funds/all`}
                            >
                              <FintooButton title="Add Funds" />
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

              {tabSelection == "orderHistory" &&
                innerSelection == "completed" && (
                  <>
                    <div>
                      {completedTransaction.length > 0 && (
                        <Table responsive className="ptTable mt-4">
                          <thead>
                            <tr>
                              <th scope="col" className="ps-0 pt-4">
                                Funds
                              </th>
                              <th scope="col">NAV Date</th>
                              <th scope="col">Amount</th>
                              <th scope="col">NAV</th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {completedTransaction.map((item) => (
                              <tr key={item}>
                                <td
                                  scope="row"
                                  data-label="Funds"
                                  className="fundNameTd ps-0 pt-4"
                                >
                                  <div className="fundName9">
                                    <div>
                                      <img
                                        src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${item.amc_code}.png`}
                                        onError={(e) => {
                                          e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                          e.onError = null;
                                        }}
                                      />
                                    </div>
                                    <div className="fundNameCon">
                                      <div className="CompletedFundName">
                                        <strong>{item.s_name}</strong>
                                      </div>
                                      <div className="CompletedFolioNo">
                                        Folio No.:{" "}
                                        {item.transaction_folio_no ==
                                          "new_folio"
                                          ? "New Folio"
                                          : item.transaction_folio_no}
                                        &nbsp;&nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td scope="row" data-label="NAV Date">
                                  <strong>
                                    {moment(item.transaction_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Amount">
                                  <strong>
                                    {item.amount != "-"
                                      ? indianRupeeFormat(item.amount)
                                      : "-"}
                                  </strong>
                                </td>
                                <td scope="row" data-label="NAV">
                                  <strong>
                                    {Math.round(
                                      (parseFloat(item.nav) + Number.EPSILON) *
                                      1000
                                    ) / 1000}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Transaction Type">
                                  <strong>{item.transaction_type}</strong>
                                </td>
                                <td scope="row" data-label="Status">
                                  <strong
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {item.bse_status == "PASS" ||
                                      item.bse_payment_status == "1"
                                      ? "Success"
                                      : "Failed"}
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}

                      {!isLoading && completedTransaction.length == 0 && (
                        <>
                          <div className="text-center ps-0">
                            <div className="pt-2">
                              {diffDays == "30" ? (
                                <h2>
                                  No transaction in the last {diffDays} days.
                                </h2>
                              ) : (
                                <h2>
                                  No transaction in the selected date range.
                                </h2>
                              )}
                            </div>
                            <img
                              style={{ width: "400px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/DMF/investment.png"
                              }
                            />
                            <div className="fin-p-txt pt-3 pb-3">
                              <p>
                                You can now manage your Mutual Funds on Fintoo
                                Dashboard
                              </p>
                            </div>
                            <div className="btn-add-fnds">
                              <Link
                                to={`${process.env.PUBLIC_URL}/direct-mutual-fund/funds/all`}
                              >
                                <FintooButton title="Add Funds" />
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

              {tabSelection == "upcoming" && innerSelection == "sip" && (
                <>
                  {Object.keys(upcomingTransaction).length > 0 &&
                    upcomingTransaction[innerSelection.toUpperCase()].length >
                    0 &&
                    !isLoading ? (
                    <>
                      <div
                        className={` d-flex justify-content-between grey-500 mt-4 ${style.subTiles}`}
                      >
                        <div className={`d-flex fn-tp-text-9 ps-0 pt-4 pb-3 }`}>
                          <div className="pe-4 fn-right-border">
                            Monthly payable: Total SIP{" "}
                            <span className="TotalSIP">
                              {upcomingTransaction["sip_length"]}
                            </span>{" "}
                          </div>
                          <div className="ps-4">
                            Total Amount:{" "}
                            <span className="TotalSIPAmount">
                              {indianRupeeFormat(
                                upcomingTransaction["sip_total"]
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pt-4">
                          Upcoming SIPs in next 30 Days
                        </div>
                      </div>

                      <Table responsive className="ptTable mt-4">
                        <thead>
                          <tr>
                            <th cope="col" className="ps-0 pt-4">
                              Funds
                            </th>
                            <th cope="col " className="fundDateYear">
                              SIP Date
                            </th>
                            <th cope="col " className="pFundAmount">
                              Amount
                            </th>
                            <th cope="col">Bank</th>
                            <th className="pFundOptions"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(upcomingTransaction).length > 0 &&
                            upcomingTransaction[innerSelection.toUpperCase()]
                              .length > 0 &&
                            upcomingTransaction[innerSelection.toUpperCase()]
                              .reverse()
                              .map((val) => (
                                <tr>
                                  <td
                                    scope="row"
                                    data-label="Funds"
                                    className="fundNameTd ps-0 pt-4 "
                                  >
                                    <div className="fundName9">
                                      <div>
                                        <img
                                          src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${val.amc_code}.png`}
                                          onError={(e) => {
                                            e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                            e.onError = null;
                                          }}
                                        />
                                      </div>
                                      <div className="fundNameCon">
                                        <div>
                                          <strong>{val.scheme_name}</strong>
                                        </div>
                                        <div>
                                          Folio No.:{" "}
                                          {val.transaction_folio_no ==
                                            "new_folio"
                                            ? "New Folio"
                                            : val.transaction_folio_no}
                                          &nbsp;&nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    scope="row"
                                    data-label="SIP Date"
                                    className=" "
                                  >
                                    <strong>
                                      {moment(
                                        val.upcoming_transaction_date
                                      ).format("DD-MM-YYYY")}
                                    </strong>
                                  </td>
                                  <td
                                    scope="row"
                                    data-label="Amount "
                                    className=""
                                  >
                                    <strong>
                                      {indianRupeeFormat(val.cart_amount)}
                                    </strong>
                                  </td>
                                  <td
                                    scope="row"
                                    data-label="Bank"
                                    className=""
                                  >
                                    <strong>{val.bank_name}</strong>
                                    <div>
                                      XXXX
                                      {val.bank_acc_no.substr(
                                        val.bank_acc_no.length - 4
                                      )}
                                    </div>
                                  </td>
                                  <td scope="row" data-label="" className="">
                                    <div className="d-flex justify-content-end fintoo-small-btn-bx fnOptions7">
                                      <div className="fintoo-small-btn pointer">
                                        <span>
                                          <a
                                            href={`${process.env.PUBLIC_URL}/direct-mutual-fund/MutualFund/${val.scheme_code}`}
                                          >
                                            Invest More{" "}
                                          </a>
                                        </span>
                                      </div>
                                      {/* <div
                                        className="fintoo-small-btn pointer"
                                        onClick={() => detailsPage(val)}
                                      >
                                        <span>Transaction</span>
                                      </div> */}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    !isLoading && (
                      <>
                        <>
                          <div className="text-center">
                            <div className="pb-3">
                              <h2>No Upcoming Transactions.</h2>
                            </div>
                            <img
                              style={{ width: "400px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "/media/DMF/investment.png"
                              }
                            />
                            <div className="fin-p-txt pt-3 pb-3">
                              <p>
                                You can now manage your Mutual Funds on Fintoo
                                Dashboard
                              </p>
                            </div>
                            <div className="btn-add-fnds">
                              <Link
                                to={`${process.env.PUBLIC_URL}/direct-mutual-fund/funds/all`}
                              >
                                <FintooButton title="Add Funds" />
                              </Link>
                            </div>
                          </div>
                        </>{" "}
                      </>
                    )
                  )}
                </>
              )}

              {tabSelection == "upcoming" && innerSelection == "stp" && (
                <>
                  {Object.keys(upcomingTransaction).length > 0 &&
                    upcomingTransaction[innerSelection.toUpperCase()].length >
                    0 ? (
                    <>
                      <div className=" d-flex justify-content-between grey-500 mt-4">
                        <div className="d-flex fn-tp-text-9 ps-0 pt-4 pb-3">
                          <div className="pe-4 fn-right-border">
                            Monthly payable: Total STP{" "}
                            <span className="TotalSIP">
                              {upcomingTransaction["stp_length"]}
                            </span>
                          </div>
                          <div className="ps-4">
                            Total Amount:{" "}
                            <span className="TotalSIPAmount">
                              {indianRupeeFormat(
                                upcomingTransaction["stp_total"]
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pt-4">
                          Upcoming STPs in next 30 Days
                        </div>
                      </div>
                      <Table responsive className="ptTable mt-4">
                        <thead>
                          <tr>
                            <th scope="col" className="ps-0 pt-4">
                              Funds
                            </th>
                            <th scope="col" className="fundDateYear">
                              STP Date
                            </th>
                            <th scope="col pFundAmount">Amount</th>
                            <th scope="col">Transfer to</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(upcomingTransaction).length > 0 &&
                            upcomingTransaction[innerSelection.toUpperCase()]
                              .length > 0 &&
                            upcomingTransaction[
                              innerSelection.toUpperCase()
                            ].map((val) => (
                              <tr>
                                <td
                                  scope="row"
                                  data-label="Funds"
                                  className="fundNameTd ps-0 pt-4"
                                >
                                  <div className="fundName9">
                                    <div>
                                    <img
                                          src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${val.amc_code}.png`}
                                          onError={(e) => {
                                            e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                            e.onError = null;
                                          }}
                                        />
                                    </div>
                                    <div className="fundNameCon">
                                      <div>
                                        <strong>{val.scheme_name}</strong>
                                      </div>
                                      <div>
                                        Folio No.:{" "}
                                        {val.transaction_folio_no == "new_folio"
                                          ? "New Folio"
                                          : val.transaction_folio_no}
                                        &nbsp;&nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td scope="row" data-label="STP Date">
                                  <strong>
                                    {moment(
                                      val.upcoming_transaction_date
                                    ).format("DD-MM-YYYY")}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Amount">
                                  <strong>
                                    {indianRupeeFormat(val.cart_amount)}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Transfer to">
                                  <div>
                                    <strong>{val.transfer_to_scheme}</strong>
                                  </div>
                                  <div>Folio No.: {val.transfer_folio_no}</div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <div className="pb-3">
                          <h2>No Upcoming Transactions.</h2>
                        </div>
                        <img
                          style={{ width: "400px" }}
                          src={
                            process.env.REACT_APP_STATIC_URL +
                            "/media/DMF/investment.png"
                          }
                        />
                        <div className="fin-p-txt pt-3 pb-3">
                          <p>
                            You can now manage your Mutual Funds on Fintoo
                            Dashboard
                          </p>
                        </div>
                        <div className="btn-add-fnds">
                          <Link
                            to={`${process.env.PUBLIC_URL}/direct-mutual-fund/funds/all`}
                          >
                            <FintooButton title="Add Funds" />
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {tabSelection == "upcoming" && innerSelection == "swp" && (
                <>
                  {Object.keys(upcomingTransaction).length > 0 &&
                    upcomingTransaction[innerSelection.toUpperCase()].length >
                    0 ? (
                    <>
                      <div
                        className={`d-flex justify-content-between grey-500 mt-4 ${style.subTiles}`}
                      >
                        <div className="d-flex fn-tp-text-9 ps-0 pt-4 pb-3">
                          <div className="pe-4 fn-right-border">
                            Total SWP{" "}
                            <span className="TotalSIP">
                              {upcomingTransaction["swp_length"]}
                            </span>
                          </div>
                          <div className="ps-4">
                            Total Amount:{" "}
                            <span className="TotalSIPAmount">
                              {indianRupeeFormat(
                                upcomingTransaction["swp_total"]
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pt-4">
                          Upcoming SWPs in next 30 Days
                        </div>
                      </div>
                      <Table responsive className="ptTable mt-4">
                        <thead>
                          <tr>
                            <th scope="col" className="ps-0 pt-4">
                              Funds
                            </th>
                            <th scope="col" className="fundDateYear">
                              SWP Date
                            </th>
                            <th scope="col" className="pFundAmount">
                              Amount
                            </th>
                            <th scope="col">Bank</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(upcomingTransaction).length > 0 &&
                            upcomingTransaction[innerSelection.toUpperCase()]
                              .length > 0 &&
                            upcomingTransaction[
                              innerSelection.toUpperCase()
                            ].map((val) => (
                              <tr>
                                <td
                                  scope="row"
                                  data-label="Funds"
                                  className="fundNameTd ps-0"
                                >
                                  <div className="fundName9">
                                    <div>
                                    <img
                                          src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${val.amc_code}.png`}
                                          onError={(e) => {
                                            e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                            e.onError = null;
                                          }}
                                        />
                                    </div>
                                    <div className="fundNameCon">
                                      <div>
                                        <strong>{val.scheme_name}</strong>
                                      </div>
                                      <div>
                                        Folio No.:{" "}
                                        {val.transaction_folio_no == "new_folio"
                                          ? "New Folio"
                                          : val.transaction_folio_no}
                                        &nbsp;&nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td scope="row" data-label="SWP Date">
                                  <strong>
                                    {moment(
                                      val.upcoming_transaction_date
                                    ).format("DD-MM-YYYY")}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Amount">
                                  <strong>
                                    {indianRupeeFormat(val.cart_amount)}
                                  </strong>
                                </td>
                                <td scope="row" data-label="Bank">
                                  <strong>{val.bank_name} &nbsp;</strong>
                                  <div>
                                    XXXX
                                    {val.bank_acc_no.substr(
                                      val.bank_acc_no.length - 4
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <div className="pb-3">
                          <h2>No Upcoming Transactions.</h2>
                        </div>
                        <img
                          style={{ width: "400px" }}
                          src={
                            process.env.REACT_APP_STATIC_URL +
                            "/media/DMF/investment.png"
                          }
                        />
                        <div className="fin-p-txt pt-3 pb-3">
                          <p>
                            You can now manage your Mutual Funds on Fintoo
                            Dashboard
                          </p>
                        </div>
                        <div className="btn-add-fnds">
                          <Link
                            to={`${process.env.PUBLIC_URL}/direct-mutual-fund/funds/all`}
                          >
                            <FintooButton title="Add Funds" />
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <FintooInlineLoader isLoading={inlineLoader} />
            </div>
          </div>
        </div>
      </div>

      <SlidingPanel
        className="Filter_Panel"
        type={"right"}
        isOpen={openPanel}
        size={sidePanelWidth}
        backdropClicked={() => setOpenPanel(false)}
      >
        <div id="FilterData" className="d-flex flex-column">
          <div className="ps-3 pe-3 pt-3">
            <div className="SideBar_Filter">
              <div className="filter_text">Filters</div>
              <div>
                <button type="button" onClick={() => setOpenPanel(false)}>
                  <img src={CloseFilter} alt="" srcset="" />
                </button>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }} className="Line"></div>
          </div>
          <div className="p-3" style={{ flexGrow: "1", overflow: "auto" }}>
            <div className="fltr-section" style={{ paddingTop: "0" }}>
              {/* <h4>Sort</h4> */}

              <div className="fund_Option">
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                  <div className="grey-500 pe-3" onClick={() => setOpenCalendar(true)} >
                    {selectedDates.length == 2
                      ? `${moment(selectedDates[0]).format(
                        "DD-MM-YYYY"
                      )} To ${moment(selectedDates[1]).format("DD-MM-YYYY")}`
                      : "Select Date Range"}
                  </div>
                  <div onClick={() => setOpenCalendar(true)}>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "/media/DMF/calendar-323.svg"
                      }
                      width="30px"
                      alt="calendar"
                    />
                  </div>
                  <div ref={refCalendarBox}>
                    {openCalendar && (
                      <div
                        className="range-calendar"
                        style={{ top: "105px", left: "10px", width: "85%" }}
                      >
                        <Calendar
                          maxDate={new Date()}
                          onChange={(v) => {
                            setSelecteDates(v);
                            setOpenCalendar(false);
                          }}
                          selectRange={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="fltr-section">
              <h4>Order Type</h4>
              <div className="Category_Filter">
                <ul className="fltr-items">
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      checked={filterState.orderType.includes('sip')}
                      title={"SIP"}
                      onChange={() => handleOrderTypeChange('sip')}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      checked={filterState.orderType.includes('lumpsum')}
                      title={"Lumpsum"}
                      onChange={() => handleOrderTypeChange('lumpsum')}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"Redeem"}
                      checked={filterState.orderType.includes('redeem')}
                      onChange={() => handleOrderTypeChange('redeem')}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"SWP"}
                      checked={filterState.orderType.includes('swp_in') && filterState.orderType.includes('swp_out')}
                      onChange={() => { handleOrderTypeChange('swp_in'); handleOrderTypeChange('swp_out') }}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"Switch"}
                      checked={filterState.orderType.includes('switch_in') && filterState.orderType.includes('switch_out')}
                      onChange={() => { handleOrderTypeChange('switch_in'); handleOrderTypeChange('switch_out') }}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"STP"}
                      checked={filterState.orderType.includes('stp_in') && filterState.orderType.includes('stp_out')}
                      onChange={() => { handleOrderTypeChange('stp_in'); handleOrderTypeChange('stp_out') }}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="fltr-section">
              <h4>Payment Status</h4>
              <div className="Category_Filter">
                <ul className="fltr-items">
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"Success"}
                      checked={filterState.orderStatus.includes('success')}
                      onChange={() => handleOrderStatusChange('success')}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"Pending"}
                      checked={filterState.orderStatus.includes('pending')}
                      onChange={() => handleOrderStatusChange('pending')}
                    />
                  </li>
                  <li className="fltr-items-li fltr-items-li-w50">
                    <FintooCheckbox
                      title={"Failed"}
                      checked={filterState.orderStatus.includes('failed')}
                      onChange={() => handleOrderStatusChange('failed')}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-3 Filter_Btn_panel">
            <div>
              {/* <button onClick={applyFilter}>Apply</button> */}
              <button onClick={() => {
                setOpenPanel(false);
                setOptions(prev => ({ ...prev, reset: true, currentPage: 1, ...filterState }));
                window.scrollTo(0, 0);
              }}>Apply</button>
            </div>
            <div
              onClick={() => {
                // window.location.reload();
              }}
              style={{ paddingLeft: "5%" }}
              className="Filter_Btn_panel"
            >
              <button
                className="Reset"
                type="button"
                onClick={() => {
                  setOpenPanel(false);
                  setOptions(prev => ({ ...prev, reset: true, currentPage: 1, ...filterState, orderStatus: [], orderType: [] }));
                  setFilterState({ ...filterState, orderStatus: [], orderType: [] });
                  setSelecteDates([]);
                  window.scrollTo(0, 0);
                }}
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </PortfolioLayout>
  );
};

export default PortfolioTransaction;
