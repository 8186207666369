import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import { useNavigate, useParams } from "react-router-dom";
// import { data } from "cheerio/lib/api/attributes";
import { fetchEncryptData, getFpUserDetailsId, getUserId, getParentUserId } from "../../../../common_utilities";
import axios from "axios";
import { useDispatch } from "react-redux";
import moment from "moment";
import { GET_OTHER_INVESTMENTS, IS_DIRECT, ADVISORY_ADD_ASSETS_API, ADVISORY_UPDATE_ASSETS_API } from "../../../../constants";
const numericRegex = new RegExp(/^\d*\.?\d*$/);
const liquidAssetsInputs = {
  default: ["current_balance"],
  "Bank Balance": ["current_balance"],
  "Cash Balance": ["current_balance"],
  Others: ["dateOfPurchase", "directValue"],
};

const options_liquid_type = [
  { value: "Bank Balance", label: "Bank Balance" },
  { value: "Cash Balance", label: "Cash Balance" },
  { value: "Others", label: "Others" },
];
function isInputInPolicy(inputName, assetType) {
  if (!assetType) {
    assetType = "default";
  }

  return liquidAssetsInputs[assetType].includes(inputName);
}
const initialValues = {
  LiquidType: "",
  dateOfPurchase: "",
  directValue: "",
};

const NewLiquidAssetFormView = () => {
  const [, forceUpdate] = useState();

  const [formData, setFormData] = useState(initialValues);
  const queryParameters = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [editdata, setEditData] = useState('');
  const dispatch = useDispatch();

  const simpleValidator = useRef(new SimpleReactValidator());


  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const getLiquidTypeData = (insuranceTypeData, label) => {
    return insuranceTypeData.find((data) => data.label === label);
  };

  const { id } = useParams();
  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    if (simpleValidator.current.allValid() == true) {
      addliquiddata();
    }
  };

  simpleValidator.current.purgeFields();

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#000" : isSelected ? "#000" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#424242",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  console.log(formData, "data123")

  useEffect(() => {
    if (id != undefined) {
      fetchLiquidData(atob(id));
    }
  }, [])
  
  const fetchLiquidData = async (usrparts) => {
    try {

      let payload_data =
      {
        user_id: getUserId(),
        inv_type: "liquid",
        is_direct: IS_DIRECT,
      }

      let payload = {
        url: GET_OTHER_INVESTMENTS,
        data: payload_data,
        method: "post",
      };

      console.log(payload, "200")
      let res = await fetchEncryptData(payload);


      if (res.error_code == "100") {
        var data = res.data['liquid_data']['liquid_details']
        console.log(data, "202")

        var type = ''
        var amount = ''
        if (usrparts) {
          let assetDetails = data.find(item => item.id == usrparts ?? usrparts);
          console.log('formData==>', assetDetails)
          setEditData(assetDetails)

          type = (assetDetails.asset_name !== assetDetails.asset_type && assetDetails.asset_name === 'Bank balance')
            ? 'Bank Balance'
            : assetDetails.asset_name !== assetDetails.asset_type
              ? assetDetails.asset_name
              : assetDetails.asset_type;


          if (assetDetails.asset_name === 'Others') {
            amount = assetDetails.asset_amount ?? assetDetails.asset_amount
          }
          else {
            amount = assetDetails.current_balance ?? assetDetails.current_balance
          }
          setFormData((prevFormData) => ({ ...prevFormData, AssetType: getLiquidTypeData(options_liquid_type, type).label, current_balance: assetDetails.asset_amount, directValue: amount, dateOfPurchase: assetDetails.asset_purchase_date ?? assetDetails.asset_purchase_date }))
        }
      } else {
        console.error(e);
      }
    } catch (e) {

      console.error(":::>>", e);
    }
  };

  const emptystates = () => {
    setFormData((prevformData) => ({ ...prevformData, current_balance: "", directValue: "", dateOfPurchase: "" }))
  }


  const reqdataliquid = async () => {
    try {
      var date = moment(new Date()).format('DD/MM/YYYY')
      var purchasedate = ''
      var amount = ''

      if (formData.AssetType == 'Others') {
        purchasedate = moment(formData.dateOfPurchase).format('DD/MM/YYYY')
        amount = formData.directValue
      }
      else {
        amount = formData.current_balance
      }
      var data = {
        "Created_By": 0,
        "Updated_By": 0,
        "asset_amount": amount,
        "asset_abreturn": "0",
        "annual_growth_rate": "10",
        "asset_broker_id": 0,
        "asset_category_id": 40,
        "asset_citytype": "0",
        "asset_current_unit_price": "",
        "asset_currency": false,
        "asset_ecas_type": "manual",
        "asset_epf_ismanual": "1",
        "asset_folio_number": null,
        "asset_footnote": null,
        "asset_frequency": "1",
        "asset_goal_link_id": 0,
        "asset_goalname": null,
        "asset_gold_karat": 0,
        "asset_isActive": "1",
        "asset_ismortgage": "0",
        "asset_isperpetual": "3",
        "asset_isallocation": false,
        "asset_iselss": "1",
        "asset_islinkable": true,
        "asset_isrecurring": false,
        "asset_isrented": "1",
        "asset_maturity_amt": 0,
        "asset_maturity_date": null,
        "asset_member_id": getFpUserDetailsId(),
        "asset_mf_end_date": "",
        "asset_name": formData.AssetType,
        "asset_pan": null,
        "asset_payout_type": "1",
        "asset_pin_code": "",
        "asset_purchase_amount": "",
        "asset_purchase_date": purchasedate,
        "asset_rental_amount": "",
        "asset_rental_income": null,
        "asset_ror": 0,
        "asset_sub_category_id": 61,
        "asset_unique_code": "",
        "asset_units": "",
        "categorydetail": "Liquid",
        "created_datetime": date,
        "employee_contribution": "",
        "employer_contribution": "",
        "fp_log_id": "",
        "installment_ispaid": "1",
        "membername1": "",
        "stock_mf": null,
        "stock_name": null,
        "subcategorydetail": formData.assetType,
        "totalinvestedvalue": "",
        "totalpurchasevalue": "",
        "totalmaturtiyamount": "",
        "updated_datetime": date,
        "user_id": getParentUserId(),
        "scheme_equityshare": {},
        "asset_source": "1"
      }

      if (id) {
        data.id = editdata.id;
      }
  
      return data
    }
    catch (e) {
      console.log("::--->", e)
    }
  }


  const addliquiddata = async () => {
    try {
      let req = await reqdataliquid();
    
      var config = ''

      if (id != undefined) {
        config = {
          method: 'POST',
          url: ADVISORY_UPDATE_ASSETS_API,
          data: req
        }
      }
      else {
        config = {
          method: 'POST',
          url: ADVISORY_ADD_ASSETS_API,
          data: req
        }
      }
      console.log(config, "300")

      let response = await axios(config);
      // console.log(response,"hhhh")
      // console.log(response.data, "apicall")

      // console.log(response.data.data, "44")
      if (response.data['error_code'] == '100') {
        if (id != undefined) {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Data Updated Successfully!", type: "success" },
          })
        }
        else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Data Added Successfully!", type: "success" },
          })

        }
      }
      navigate(process.env.PUBLIC_URL + '/direct-mutual-fund/portfolio/dashboard/?assetTabNumber=8');

    }
    catch (e) {
      console.log("::>>>>>", e)
    }

  }

  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <a
              href={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard?assetTabNumber=8`}
            >
              <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
              />
            </a>
            <h3
              className="text-center pb-0 mb-0 ps-2"
              style={{
                flexGrow: 1,
              }}
            >
              {id ? 'Edit Your Liquid Asset' : 'Add Your Liquid Assets'}

            </h3>
          </div>

          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              <p className="text-center">
                {id ? '' : ' Enter Your Details To Add Existing Liquid Assets'}

              </p>
              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div>
                    <span className="lbl-newbond">Select Asset Type *</span>
                    <br />

                    <Select
                      className="fnto-dropdown-react shadow-none outline-none"
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      options={options_liquid_type}
                      value={getLiquidTypeData(
                        options_liquid_type,
                        formData.AssetType
                      )}
                      name="AssetType"
                      onChange={(e) => (
                        onDateAndSelectInputChange("AssetType", e.label), emptystates())
                      }
                    />
                    {simpleValidator.current.message(
                      "AssetType",
                      formData.AssetType,
                      "required"
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className="row"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {isInputInPolicy("current_balance", formData.AssetType) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Current Balance *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Current Balance"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              name="current_balance"
                              value={formData.current_balance}
                              onChange={(e) => {
                                //onInputChange(e, true);
                                setFormData(prev => ({ ...prev, current_balance: e.target.value.replace(/[^0-9.]/, '') }));
                              }}
                            />
                          </div>
                          {simpleValidator.current.message(
                            "current_balance",
                            formData.current_balance,
                            "required|alpha_spaces"
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {isInputInPolicy("dateOfPurchase", formData.AssetType) && (
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Date Of Purchase</span>
                          <br />
                          <div className="bonds-datepicker">
                            <FintooDatePicker
                              dateFormat="yyyy/MM/dd"
                              selected={
                                formData.dateOfPurchase === ""
                                  ? ""
                                  : new Date(formData.dateOfPurchase)
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              name="dateOfPurchase"
                              customClass="datePickerDMF"
                              maxDate={new Date()}
                              onChange={(e) =>
                                onDateAndSelectInputChange(
                                  "dateOfPurchase",
                                  formatDatefun(e)
                                )
                              }
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>

                          {simpleValidator.current.message('dateOfPurchase', formData.dateOfPurchase, 'required')}
                        </div>
                      </div>
                    </div>
                  )}
                  {isInputInPolicy("directValue", formData.AssetType) && (
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Direct Value</span>
                          <br />
                          <input
                            placeholder="Enter Direct Value"
                            className={` w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                            type="text"
                            name="directValue"
                            value={formData.directValue}
                            onChange={(e) => {
                              //onInputChange(e, true);
                              setFormData(prev => ({ ...prev, directValue: e.target.value.replace(/[^0-9.]/, '') }));
                            }}
                          />
                          {simpleValidator.current.message(
                            "directValue",
                            formData.directValue,
                            "required|numeric"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <div className="my-md-4">
                  <button
                    type="submit"
                    className="d-block m-auto btn btn-primary"
                    onClick={() => validateForm()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewLiquidAssetFormView;
