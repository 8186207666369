import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewOtherAssetsFormView from "./NewOtherAssetsFormView";

const NewOtherAssetsForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewOtherAssetsFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewOtherAssetsForm;
