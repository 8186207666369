import BootStrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";
import { useState } from "react";
import { ADVISORY_GETSETREPORTPDFSTATUS_API, ADVISORY_GETSUMMARYREPORT_API, BASE_API_URL } from "../../constants";
import { apiCall, getParentUserId } from "../../common_utilities";
import commonEncode from "../../commonEncode";
import FintooLoader from "../FintooLoader";
import { Link } from "react-router-dom";

const DownloadModal = ({ show,onHide,ssessiondata }) => {
  const [reportValue,setReportValue] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const downloadReprot = async() =>{
    try{
      // summary report only
      setIsLoading(true)
      var user_id = getParentUserId()
      var d_url = ""
      var url = ADVISORY_GETSUMMARYREPORT_API +"&user_id="+user_id+"&fp_log_id="+ssessiondata['fp_log_id']+"&fp_user_id="+ssessiondata['fp_user_id']
      var report_data = await apiCall(
        url,
        "",
        false,
        false
      );      
      if(report_data.error_code = "100"){
        d_url = BASE_API_URL + 'restapi/downloadfile/' + btoa('00' + ssessiondata["id"]) + '/' + ssessiondata['fp_log_id'] + '/' + report_data['data']['filename'] + '/148/';
        setIsLoading(false)
        window.open(d_url, '_blank');
        
      }
    }
    catch{
      setIsLoading(true)
    }
  }
  const deleteParReportCookie = (name) => {
    localStorage.removeItem(name)
  };
  return (
    <>
    <FintooLoader isLoading={isLoading} />
    <BootStrapModal show={show} centered>
      <BootStrapModal.Body>
        <div className={style.row}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                {/* <div className="col-md-4">Report Type:</div> */}
                <div className="col-md-6">
                  <div className="d-flex justify-content-center">
                    <input
                      type="radio"
                      name="dwn_opt_report"
                      value={reportValue}
                      onChange={()=>{
                        setReportValue(1)
                      }}
                      checked={reportValue == 1}
                    />
                    <div className="ps-2">Download Report</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-center">
                    <input
                      type="radio"
                      name="dwn_opt_report"
                      value={reportValue}
                      onChange={()=>{
                        setReportValue(2)
                      }}
                      checked={reportValue == 2}
                    />
                    <div className="ps-2">View Report</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          { reportValue == 1 &&
            <button type="button" className={style.outlineBtn} onClick={()=> downloadReprot()}>
                    Submit
            </button>
          }
          { reportValue == 2 &&
             <Link to={`${process.env.PUBLIC_URL}/report/intro`}>
                <button type="button" className={style.outlineBtn}
                  onClick={() => {
                    onHide();
                    deleteParReportCookie('ParReportData');
                  }}
                >
              Submit
            </button>
            </Link>
          }
          <button type="button" className={style.outlineBtn} onClick={onHide}>
            Cancel
          </button>
        </div>
      </BootStrapModal.Body>
    </BootStrapModal>
    </>
  );
};
export default DownloadModal;
