import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../HTML/FintooBackButton";
import commonEncode from "../../commonEncode";
import * as constClass from "../../constants";
import axios from "axios";
import moment from "moment";
import {
  fetchEncryptData,
  apiCall,
  getUserId,
  getItemLocal,
  getCurrentUserDetails,
  setItemLocal,
} from "../../common_utilities";
import PaymentSuccess from "../Pages/ErrosPages/PaymentSuccess";
import { useNavigate } from "react-router-dom";
import SubmitButton from "./SubmitButton";
import { useDispatch } from "react-redux";

const PortfolioOtpModal = (props) => {
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [useremail, setuseremail] = useState("");
  const [usermobile, setusermobile] = useState("");
  const [generateotp, setGeneratedSmsOTP] = useState("");
  const [generatedemailotp, setGeneratedEmailOTP] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [validOtp, setValidOtp] = useState(true);
  const [primaryBankId, setPrimaryBankId] = useState("");
  const [switchfunds, setSwitchFunds] = useState(false);
  const [deletecart, setDeleteCart] = useState("");
  const [nomineeflag, setnomineeflag] = useState("");
  const [startStp, setStartStp] = useState("");
  const navigate = useNavigate();
  const [stopSIPButtonDisable, setStopSIPButtonDisable] = useState(false);
  const [sipfund, Setfundname] = useState("");
  const [sipamount, setamount] = useState("");
  const dispatch = useDispatch();
  const [fromfund, Setfromfundname] = useState("");
  const [switchto, Setswitchto] = useState("");

  // var cartId = localStorage.getItem("cartId");

  useEffect(() => {
    Setfromfundname(
      localStorage.getItem("switch_from")
        ? localStorage.getItem("switch_from")
        : ""
    );
    Setswitchto(
      localStorage.getItem("switch_to") ? localStorage.getItem("switch_to") : ""
    );
    onLoadInIt();
    fetchMail();
    fetchSms();
  }, [useremail, switchto, fromfund]);

  const randomOTP = useRef(Math.floor(Math.random() * 90000) + 10000);

  const switchFund = async () => {
    setStopSIPButtonDisable(true);
    var trans_code = "NEW";
    var trans_id = "" + props.value[1].current.transaction_id;
    var res = await apiCall(constClass.DMF_SWITCH_FUND_API_URL, {
      transaction_id: trans_id,
      trans_code: trans_code,
      is_direct: constClass.IS_DIRECT,
    });
    setSwitchFunds(res.data);
    setStopSIPButtonDisable(false);
    if (res.error_code * 1 === 100) {
      await deleteCart();
      navigate(
        process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentSucess?a=Switch"
      );
    } else {
      navigate(
        process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentFailed?a=Switch"
      );
    }
  };

  const startSwp = async () => {
    setStopSIPButtonDisable(true);
    var trans_id = props.transaction_id.transaction_id;
    var res = await apiCall(constClass.DMF_START_SWP_API_URL, {
      trxn_id: trans_id.toString(),
      is_direct: constClass.IS_DIRECT,
    });
    setStopSIPButtonDisable(false);
    if (res.error_code * 1 === 100) {
      navigate(
        process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentSucess?a=SWP"
      );
    } else {
      navigate(
        process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentFailed?a=swp"
      );
    }
  };

  const deleteCart = async () => {
    var cart_id_from = "" + props.value[1].current.cart_id_from;
    var cart_id_to = "" + props.value[1].current.cart_id_to;
    try {
      var payload = {
        method: "post",
        url: DMF_DELETE_SWP_STP_FROM_CART,
        data: {
          from_data: [
            {
              cart_id: cart_id_from,
            },
          ],
          to_data: [
            {
              cart_id: cart_id_to,
            },
          ],
        },
      };
      var res = await fetchEncryptData(payload);
      setDeleteCart(res.data);
    } catch (e) {}
  };

  const updatenominee = async () => {
    try {
      var res = await apiCall(constClass.DMF_UPDATENOMINEE_API_URL, {
        user_id: getUserId(),
        is_direct: constClass.IS_DIRECT,
        is_authenticated: "1",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOtpChange = (e) => {
    if (e.target.value.length > 5) {
      setOtpInput("");
      setValidOtp(false);
    } else {
      setOtpInput(e.target.value);
      setValidOtp(true);
    }
  };

  const submitOtp = () => {
    if (generatedemailotp != otpInput) {
      setValidOtp(false);
    } else {
      setValidOtp(true);
      localStorage.removeItem("switch_from");
      localStorage.removeItem("switch_to");
      if (props.onEditSubmit) {
        props.onEditSubmit();
      }
      if (props.label == "Redeem Fund") {
        normalOrderEntry();
      }
      if (props.label == "Switch Fund") {
        switchFund();
      }
      if (props.label == "Stop SIP") {
        cancelSip();
      }
      if (props.label == "Confirm SWP") {
        startSwp();
      }
      if (props.label == "Confirm STP") {
        startSTP();
      }
      if (nomineeflag == null || nomineeflag == "") {
        // console.log("call api")
        updatenominee();
      }
    }
  };
  const startSTP = async () => {
    setStopSIPButtonDisable(true);
    try {
      let config = {
        method: "post",
        url: constClass.DMF_START_STP_API_URL,
        data: props.value[1],
      };

      var res2 = await fetchEncryptData(config);
      setStartStp(res2.data);
      if (res2.error_code * 1 === 100) {
        await deleteCart();
        setStopSIPButtonDisable(false);
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentSucess?a=stp"
        );
      } else {
        setStopSIPButtonDisable(false);
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentFailed?a=stp"
        );
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      console.log("Start STP::: ", e);
    }
  };
  // console.log("startStp",startStp)

  const normalOrderEntry = async () => {
    try {
      setStopSIPButtonDisable(true);
      let x = props.transaction_id.current.transaction_id.toString();
      var payload = {
        method: "post",
        url: constClass.DMF_NORMAL_ORDER_ENTRY_API_URL,
        data: {
          user_id: getUserId(),
          transaction_id: x,
          trans_code: "NEW",
          is_direct: constClass.IS_DIRECT,
        },
      };
      var res = await fetchEncryptData(payload);
      await deleteCartAPI();
      setStopSIPButtonDisable(false);
      if (res.error_code == 100) {
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentSucess?a=Redeem"
        );
      } else {
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentFailed?a=Redeem"
        );
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      console.log("catch :", e);
    }
  };

  const cancelSip = async () => {
    setStopSIPButtonDisable(true);
    try {
      let transactionId = "";
      for (const x of props?.detailedMfPotfolio?.sip_stp_swp_data?.moreSIP) {
        if (Boolean(x.checked)) {
          transactionId = "" + x.transaction_id;
          break;
        }
      }
      if (!transactionId) {
        throw "Unable to get transactionId";
      }
      // return;
      var payload = {
        method: "post",
        url: constClass.DMF_XSIP_ORDER_ENTRY_API_URL,
        data: {
          user_id: getUserId(),
          trxn_id: transactionId,
          trans_code: "CXL",
          is_direct: constClass.IS_DIRECT,
        },
      };
      if (props?.reason) {
        payload.data.reason_code = "" + props?.reason?.id;
        payload.data.reason_text = props?.reason?.text;
      }
      var res = await fetchEncryptData(payload);
      setStopSIPButtonDisable(false);
      if (res.error_code * 1 === 100) {
        stopsipmail("sucess");
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentSucess?a=StopSIP"
        );
      } else {
        stopsipmail("fail");
        navigate(
          process.env.PUBLIC_URL + "/direct-mutual-fund/PaymentFailed?a=StopSIP"
        );
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      console.log("catch :", e);
    }
  };

  const deleteCartAPI = async () => {
    var cart_id = props.value[2].current.cart_id.toString();
    var payload = {
      method: "post",
      url: constClass.DMF_CART_DELETE_API_URL,
      data: {
        cart_id: cart_id.toString(),
      },
    };
    var res = await fetchEncryptData(payload);
  };

 

  const fetchSms = async () => {
    var sms_api_id = "fintoo_otp";
    var otp =  randomOTP.current
    console.log(otp,'ttt')
    setGeneratedSmsOTP(otp);
    var red_amount = "";
    var fromfund = localStorage.getItem("switch_from");
    var switchto = localStorage.getItem("switch_to");
    var amount = getItemLocal("amount");
    var red_amount = getItemLocal("red_amount");

    // console.log(fromfund,"555",amount,"to",switchto)
    var msg = "Greetings from Fintoo! Your OTP verification code is " + otp;
    var whatsapptext =
      "Greetings from Fintoo! Your OTP verification code is : " + otp;
    // console.log(setGeneratedSmsOTP(otp),'whatsapptext', whatsapptext);

    setGeneratedSmsOTP(otp);
    var keyvalue = "";

    if (props.label == "Redeem Fund") {
      keyvalue = "redeem_dmf";
    } else if (props.label == "Switch Fund") {
      keyvalue = "switch";
    } else if (props.label == "Stop SIP") {
      keyvalue = "stop_sip";
    } else if (props.label == "Confirm SWP") {
      keyvalue = "swp";
    } else if (props.label == "Confirm STP") {
      keyvalue = "stp";
    } else if (props.label == "Stop SWP") {
      keyvalue = "stop_swp";
    } else if (props.label == "Edit Sip") {
      keyvalue = "edit_sip";
    }

    // console.log(switchto,"shree",props.label,"mob",usermobile,"2222222",)
    if (keyvalue == "stop_swp") {
      var urlsms = {
        mobile: usermobile,
        msg: msg,
        sms_api_id: sms_api_id,
        whatsapptext: whatsapptext,
      };
    } else {
      let user_data = await getCurrentUserDetails();

      var from_fund = "";
      var amount_val = "";

      if (Number(amount) > 0) {
        amount_val = amount;
      }

      if (
        keyvalue == "switch" ||
        keyvalue == "swp" ||
        keyvalue == "redeem_dmf"
      ) {
        from_fund = fromfund;
      } else if (keyvalue == "stp") {
        from_fund = props.value[0].scheme;
      } else if (keyvalue == "redeem_dmf") {
        amount_val = red_amount;
      } else if (props.label == "Edit Sip") {
        keyvalue = "edit_sip";
        amount_val = amount;
      } else if ((keyvalue = "stop_sip")) {
        amount_val = red_amount;
      }

      let fund_name_value = props.value[0].scheme.replaceAll("&", "and");
      Setfundname(fund_name_value);
      let from_fund_name = from_fund.replaceAll("&", "and");
      let to_fund_name = switchto ? switchto.replaceAll("&", "and") : "";
      let from_f_name =
        from_fund_name.length <= 20
          ? from_fund_name
          : from_fund_name.slice(0, 20) + "...";
      let switch_to =
        to_fund_name.length <= 20
          ? to_fund_name
          : to_fund_name.slice(0, 20) + "...";
      fund_name_value =
        fund_name_value.length <= 20
          ? fund_name_value
          : fund_name_value.slice(0, 20) + "...";

      // console.log("300",props,">>>>")
      // console.log(props.value,"value123")
      // console.log(props.value[1].from_data.cart_amount?props.value[1].from_data.cart_amount:props,"shree")
      console.log("keyvalue", keyvalue);
      var urlsms = {
        mobile: usermobile,
        otp: otp,
        key: keyvalue,
        user_name: user_data.name,
        fund_name: fund_name_value,
        from_fund_name: from_f_name,
        to_fund_name: switch_to,
        is_direct: constClass.IS_DIRECT,
        amount: amount_val,
      };
    }

    setamount(amount_val);
    var data2 = commonEncode.encrypt(JSON.stringify(urlsms));
    var config1 = {
      method: "post",
      url: constClass.DMF_SENDSMS_API_URL,
      data: data2,
    };

    // console.log(config1,"www")
    var res = await axios(config1);
    var response = JSON.parse(commonEncode.decrypt(res.data));
   
    console.log(response.error_code, "response");
   
  };

  const fetchMail = async () => {
    setOtpInput("");
    var otp = randomOTP.current;
    let user_data = await getCurrentUserDetails();
    setGeneratedEmailOTP(otp);
    var urlmail = {
      userdata: {
        to: useremail,
      },
      subject: "Fintoo - Your one time password",
      template: "otp_message_template.html",
      contextvar: { otp: otp, user_name: user_data.name },
    };
    console.log("OTP ", otp);

    var data = commonEncode.encrypt(JSON.stringify(urlmail));
    var config = {
      method: "post",
      url: constClass.DMF_SENDMAIL_API_URL,
      data: data,
    };

    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
  };

  const onLoadInIt = async () => {
    try {
      var userid = getUserId();
      var form_data_user = { user_id: userid };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
      let user_data = responseobj.data;
      setnomineeflag(user_data.is_authenticated);
      setuseremail(user_data.email);
      setusermobile(user_data.mobile);
    } catch (e) {}
  };

  const stopsipmail = async (v) => {
    try {
      let user_data = await getCurrentUserDetails();

      console.log(sipamount);
      var subject = "";
      var template = "";

      if (v === "sucess") {
        subject = "Confirmation: Successful Termination of SIP Process";
        template = "stop_sip.html";
      }
      if (v === "fail") {
        subject =
          "Notification Regarding Failed Stop SIP Request on BSE Platform";
        template = "stop_sip_fail.html";
      }

      var urlmail = {
        userdata: {
          to: user_data.email,
        },
        subject: subject,
        template: template,
        contextvar: {
          name: user_data.name,
          platform: "FIntoo",
          amount: sipamount,
          fundname: sipfund,
          date: moment(new Date()).format("DD/MM/YYYY"),
        },
      };

      // console.log(urlmail.contextvar,"2222222")
      let config = {
        method: "post",
        url: constClass.DMF_SENDMAIL_API_URL,
        data: urlmail,
      };

      var res = await fetchEncryptData(config);
      console.log(res, "response mail");
    } catch (e) {
      console.log("------->", e);
    }
  };

  useEffect(() => {
    timer.current.counter = timer.current.default;
    startTimer();
  }, []);

  const startTimer = () => {
    timer.current.obj = setInterval(() => {
      if (timer.current.counter > 0) {
        timer.current.counter = timer.current.counter - 1;
        setCount(timer.current.counter);
      } else {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
      }
    }, 1000);
  };

  var props_data = props.value;

  return (
    <>
      <Modal.Header className="py-3">
        <FintooBackButton onClick={() => props.onBack()} />
        <div className="modal-title">Please Enter OTP</div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <div className="modal-whitepopup-box-item grey-color border-top-0 text-center">
              <p>Sent to</p>
              <p>{useremail}</p>
              <p>{usermobile}</p>
            </div>

            <div className="modal-whitepopup-box-item grey-color">
              <div className="px-md-4">
                <div className="d-flex justify-content-center flx-otp-bx">
                  <div className="flx-item">
                    <div>Folio</div>
                    <div>
                      <strong>{props_data[0].folio_no}</strong>
                    </div>
                  </div>
                  <div className="flx-item">
                    <div>{props.label}</div>
                    <div>
                      <strong>{props_data[0].scheme}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center p-4">
              <input
                type="text"
                maxLength={5}
                minLength={5}
                placeholder="Enter OTP here"
                className="bottom-border-input w-50 text-center grey-color"
                value={otpInput}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => handleOtpChange(e)}
              />
            </div>

            <div className="text-center p-4 grey-color">
              {count == 0 && (
                <p
                  className="pointer blue-color"
                  onClick={() => {
                    startTimer();
                    fetchMail();
                    fetchSms();
                    setValidOtp(true);
                  }}
                >
                  Resend OTP
                </p>
              )}
              {count > 0 && (
                <p>
                  Resend OTP in{" "}
                  <strong>
                    {moment().startOf("day").seconds(count).format("mm:ss")}
                  </strong>
                </p>
              )}
              {validOtp ? <> </> : <p className="red-color">Invalid OTP</p>}
            </div>

            {/* <div
              className="mt-3 switch-fund-btn mobile-bottom-button"
              onClick={() => {
                submitOtp();
              }}
            >
              Submit
            </div> */}
            <SubmitButton
              disabled={stopSIPButtonDisable}
              title={"Submit"}
              onClick={() => {
                submitOtp();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  );
};
export default PortfolioOtpModal;
