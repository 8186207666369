import { FINVU_WEBSOCKET_URL } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import * as toastr from "toastr";

let socket = null;

try {
  if (window.location.pathname === "/web/money-management/track-bank-account") {
    socket = new WebSocket(FINVU_WEBSOCKET_URL);
    console.log("******* WebSocket connection established *******");
    socket.onclose = function(event) {
      if (event.wasClean) {
        console.log(`Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.error('Connection abruptly closed from Money Management Module');
      }
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error('Session Expired!!!!!!');
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    };
  }
} catch (error) {
  console.error("######## WebSocket connection failed #########", error);
  if (window.location.pathname === "/web/money-management/track-bank-account") {
    toastr.options.positionClass = "toast-bottom-left";
    toastr.error('Session Expired!!!!!!');
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

}

export default socket;

export function onMessageHandler(event) {
  const data = JSON.parse(event.data);
  return data;
}