export const getSumOfDataListProp = (dataObj, key) => {
    try {
        const totalSum = dataObj.reduce((acc, data) => {
            return data[key] + acc
        }, 0).toFixed(3)

        return isNaN(totalSum) ? '' : Number(totalSum)
    } catch {
        return 0
    }


}