import styles from "./style.module.css";
import { CiDesktop } from "react-icons/ci";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";
const AdvisorycardSection = () => {
  return (
    <section className={`${styles.AdvisorycardSection}`}>
      <div className={`${styles["AdvisorycardSection-section-container"]}`}>
        <div className={`${styles.Advisorycard}`}>
         <div>
         <div className={`${styles.CardIcon}`}>
            <CiDesktop />{" "}
          </div>
          <p className={`${styles.CardTitle}`}>Automated Advisory</p>
          <p className={`${styles.CardPara}`}>
            Fintoo's Automated Financial Advisory option uses cutting-edge
            Artificial Intelligence to assess your financial situation and
            provides a customised financial planning solution is best suited for
            you.
          </p>
         </div>
         <div className={` ${styles.btnStart}`}>
            <Link to={`${process.env.PUBLIC_URL}/pricing/`}>
              <button className={`ms-3  ${styles.AdvisorycardBtn}`}>
                Start
              </button>
            </Link>
          </div>
        </div>
        <div className={` ms-md-4 mt-5 mt-md-0 ${styles.Advisorycard}`}>
          <div className={`${styles.CardIcon}`}>
            <FiUsers />{" "}
          </div>
          <p className={`${styles.CardTitle}`}>Expert Advisory</p>
          <p className={`${styles.CardPara}`}>
            Fintoo's Expert Advisory option combines cutting-edge Artificial
            Intelligence with inputs from the extremely experienced and the best
            financial advisors in India. The two come together to assess your
            financial situation and provide recommendations that are best suited
            for you.
          </p>
          <div className={` ${styles.btnStart}`}>
            <a href={process.env.REACT_APP_PYTHON_URL + "web/expert/"}>
              <button className={` ${styles.AdvisorycardBtn}`}>Start</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdvisorycardSection;
