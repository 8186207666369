import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import DOB from "../../../Assets/02_date_of_birth.svg";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Link from "../../../MainComponents/Link";
import Male from "../../../Assets/01_Fatca_male.png";
import FeMale from "../../../Assets/02_Fatca_female.png";
import Other from "../../../Assets/03_Fatca_other.png";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";

import * as constClass from "../../../../constants";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CheckSession,
  apiCall,
  successAlert,
  errorAlert,
  memberId,
  loginRedirectGuest,
  getUserId,
  getPublicMediaURL,
} from "../../../../common_utilities";
import useForm from "./useForm";
import { format } from "date-fns";
import commonEncode from "../../../../commonEncode";
import axios from "axios";
import { RiCoinsLine } from "react-icons/ri";
import { DisableIcon } from "evergreen-ui";
import { MdDisabledByDefault } from "react-icons/md";
import FintooDatePicker from "../../../HTML/FintooDatePicker";

function Dob(props) {
  const [gender, setGender] = useState("1");
  const [dob, setDob] = useState(null);
  const [errors, setErrors] = useState({});
  const userid = memberId();
  const [user_dob, setuser_dob] = useState("");
  const [user_gender, setgender] = useState("");
  const dispatch = useDispatch();
  const allUserData = localStorage.getItem("user");
  const userData = JSON.parse(allUserData);
  const showBack = useSelector((state) => state.isBackVisible);
  const [msg, setmessage] = useState("");
  const [userdob, setuserdob] = useState(false);
  const [adhardob, setAdharDob] = useState(false);
  const [disableGender, setDisableGender] = useState(false);

  useEffect(() => {
    const req_req = localStorage.getItem("req");

    if (req_req != null) {
      const response = JSON.parse(commonEncode.decrypt(req_req));

      const response_gender = response.data.gender;
      const response_dob = response.data.dob;

      if (response_dob != null && response_gender != null) {
        setAdharDob(true)

        setDob(moment(response_dob, "YYYY-MM-DD").toDate());
        console.log("nil788", moment(response_dob, "YYYY-MM-DD").format("LLL"));
        setuserdob(true);

        if (response_gender == "M") {
          setGender("male");
          setDisableGender(true);
        } else if (response_gender == "F") {
          setGender("female");
          setDisableGender(true);
        } else {
          setGender("other");
          setDisableGender(true);
        }
      } else {
      }
    } else {
      onLoadInIt();
    }

    // CheckSession();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const onLoadInIt = async () => {
    // props.dispatch({
    //   type: "SET_PROGRESS",
    //   payload: Math.round((1 / 18) * 100),
    // });

    try {
      var form_data = { user_id: getUserId() };
      var req_data = commonEncode.encrypt(JSON.stringify(form_data));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: req_data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);

      let responseobj = JSON.parse(response);
      if (responseobj.data["aadhar_verified"] == "Yes") {
        setAdharDob(true); 
      }
      if (responseobj.data["dob"] != "") {
        // let x = new Date(responseobj.data["dob"]);
        setDob(moment(responseobj.data["dob"], 'DD/MM/YYYYY').toDate());
      } else {
        setDob(null);
      }

      setGender(responseobj.data["gender"]);
      var error = responseobj["error_code"];
      var api_message = responseobj["message"];

      if (responseobj.data["aadhar_verified"] == "Yes") {
        setuserdob(true);
        setDisableGender(true);
      }
      if (dob != null && gender != null) {
      }

      if (error == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: api_message, type: "error" },
        });
      }
    } catch (e) {
      e.setErrors();
    }
  };

  function onChangeValue(event) {
    if (disableGender === true) return false;
    setGender(event.target.value);
  }
  const [isActive, setActive] = useState("true");

  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleChange = (v) => {
    setDob(v);
    setErrors({});
  };

  const handleSubmit = async () => {
    if (!dob) {
      errors.dob = "This field is required";
      setErrors({ ...errors });
      return;
    } else if (dob > moment().subtract(18, "years").toDate()) {
      errorAlert("User cannot be Minor");
      return;
    } else {
      setErrors({});
    }
    let url = constClass.DMF_UPDATEBASICDETAILS_API_URL;
    let data = {
      user_id: getUserId(),
      dob: format(new Date(dob), "yyyy-MM-dd"),
      gender: gender,
      is_direct: constClass.IS_DIRECT,
    };
    console.log("get", getUserId());
    let respData = await apiCall(url, data);

    if (respData["error_code"] == "100") {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "User Details Updated Successfully!",
          type: "success",
        },
      });

      setTimeout(() => {
        props.onNext();
      }, 4000);
    } else if (respData["error_code"] == "102") {
      if (respData["message"].includes("gender")) {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Please select gender.",
            type: "error",
          },
        });
      } else {
        errorAlert(respData["message"]);
      }
      return;
    }
    // props.dispatch({
    //   type: "SET_PROGRESS",
    //   payload: Math.round((2 / 18) * 100),
    // });
  };

  console.log(dob, "nil64");

  return (
    <>
      <ToastContainer limit={1} />
      <Row className="reverse">
        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img
              src={getPublicMediaURL("static/media/DMF/02_date_of_birth.svg")}
              alt="DOB"
            />
          </div>
        </div>
        <div className=" RightPanel col-12 col-md-6">
          <div className="rhl-inner">
            {/* title="Date of Birth" */}
              <h4>Date of Birth</h4>
                {/* <FintooProfileBack
                title="Date of Birth"
                onClick={() => props.onPrevious()}
              /> */}
            <div>
              <p>Enter your date of birth as per your Aadhar.</p>
              <Row>
                <Col className=" p-2">
                  <div
                    className={`dob8 birth-calendar ${
                      dob ? "m_selected" : "m_empty"
                    }`}
                  >
                    <FintooDatePicker
                      disabled={adhardob}
                      maxDate={moment().subtract(18, "years").toDate()}
                      selected={dob}
                      onChange={(date) => handleChange(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  {errors.dob && <p className="error">{errors.dob}</p>}
                </Col>
              </Row>
              <hr className="ProfileHr" />
              <div className="VerifyDetails">
                <h4>Gender</h4>
                <p>Select your gender</p>
                <div className="DobNext" style={{ marginTop: "0rem" }}>
                  <div className="gs-bx">
                    <div className="GenderSelect" onChange={onChangeValue}>
                      <div className="w-33 text-center" style={{ display: "" }}>
                        <input
                          style={{ display: "none" }}
                          id="Male"
                          type="radio"
                          value="male"
                          name="gender"
                          checked={gender === "male"}
                          onChange={(e) => {}}
                        />
                        <label
                          onClick={handleToggle}
                          htmlFor="Male"
                          className={
                            gender === "male"
                              ? "ColorChange active"
                              : "ColorChange"
                          }
                        >
                          <img
                            src={getPublicMediaURL(
                              "/static/media/DMF/01_Fatca_male.svg"
                            )}
                            style={{
                              fontSize: "60px",
                            }}
                            alt="Male"
                          />
                          <div className="GenderName">
                            <span>Male</span>
                          </div>
                        </label>
                      </div>
                      <div className="w-33 text-center" style={{ display: "" }}>
                        <input
                          style={{ display: "none" }}
                          id="FeMale"
                          type="radio"
                          value="female"
                          name="gender"
                          checked={gender === "female"}
                          onChange={(e) => {}}
                        />
                        <label
                          htmlFor="FeMale"
                          className={
                            gender === "female"
                              ? "ColorChange active"
                              : "ColorChange"
                          }
                        >
                          <img
                            src={getPublicMediaURL(
                              "/static/media/DMF/02_Fatca_female.svg"
                            )}
                            style={{
                              fontSize: "60px",
                            }}
                            alt="Female"
                          />
                          <div className="GenderName">
                            <span>Female</span>
                          </div>
                        </label>
                      </div>
                      <div className="w-33 text-center" style={{ display: "" }}>
                        <input
                          style={{ display: "none" }}
                          id="other"
                          type="radio"
                          value="other"
                          name="gender"
                          checked={gender === "other"}
                          onChange={(e) => {}}
                        />
                        <label
                          htmlFor="other"
                          className={
                            gender === "other"
                              ? "ColorChange active"
                              : "ColorChange"
                          }
                        >
                          <img
                            src={getPublicMediaURL(
                              "/static/media/DMF/03_Fatca_other.svg"
                            )}
                            style={{
                              fontSize: "60px",
                            }}
                            alt="other"
                          />
                          <div className="GenderName">
                            <span>Other</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <hr className="ProfileHr" /> */}
                  <div className="fintoo-top-border pt-4 mt-4 ">
                    <FintooButton
                      className="d-block me-0 ms-auto"
                      onClick={handleSubmit}
                      title="Next"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

// const mapStateToProps = (state) => ({
//   progressValue: state.progressValue,
//   progressTitle: state.progressTitle,
// });

// export default connect(mapStateToProps)(Dob);
export default Dob;
