import { React, useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import HDFC from "../../Assets/hdfc.png";
import Close from "../../Assets/cart_Close.png";
import { Form, Row, Toast } from "react-bootstrap";
import information from "../../Assets/information.png";
import Delete from "../../Assets/Dashboard/delete_Gray.png";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BiInfoCircle } from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import moment from "moment";
import NumberPicker from "../../HTML/NumberPicker";
import axios from "axios";
import { CART_ADD_URL, CART_UPDATE_URL, DMF_BASE_URL } from "../../../constants";
import commonEncode from "../../../commonEncode";
import WhiteOverlay from "../../HTML/WhiteOverlay";
import { fetchEncryptData, getUserId, indianRupeeFormat, isFamilySelected } from "../../../common_utilities";
import { useDispatch } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { memo } from "react";
import Swal from "sweetalert2";
import DmfDatePicker from "../../HTML/FintooDatePicker/DmfDatePicker";
import FintooDatePicker from "../../HTML/FintooDatePicker";

function Investmentfundbox(props) {
  const re = /^[0-9\b]+$/;
  const dispatch = useDispatch();

  const [dob, setDob] = useState(null);
  const Info = styled(ReactTooltip)`
    max-width: 278px;
    padding-top: 9px;
  `;
  const InfoMessage = styled.p`
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  `;
  const [sipAmount, setSipAmount] = useState(0);
  const [amount1, setAmount1] = useState();
  const [sipTenure, setSipTenure] = useState(0);

  const [schemeType, setSchemeType] = useState(props.schemeType);
  const [keyIds, setKeyIds] = useState([]);
  const [sipObj, setSipObj] = useState({});
  const [lumpObj, setLumpObj] = useState({});
  const [showSipBox, setShowSipBox] = useState(true);
  const [show, setShow] = useState(false);
  const [updateSipCart, setUpdateSipCart] = useState(0);
  const [updateLumpsumCart, setUpdateLumpsumCart] = useState(0);
  const [availableFunds, setAvailableFunds] = useState([]);
  const [error, setError] = useState({});
  const [, forceUpdate] = useState();
  const [, forceUpdate_] = useState();


  const SIPValidator = useRef(new SimpleReactValidator({
    validators: {
      elssAmountSIP: {
        message: 'SIP amount should be a multiple of 500.',
        rule: (val, params, validator) => {
          return val % 500 === 0;
        },
        messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        required: true
      },
    }
  }));

  const LumpValidator = useRef(new SimpleReactValidator({
    validators: {
      elssAmountLumpsum: {
        message: 'Lumpsum amount should be a multiple of 500.',
        rule: (val, params, validator) => {
          return val % 500 === 0;
        },
        messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        required: true
      }
    }
  }));

  useEffect(() => {
    setKeyIds([...schemeType.funds.map(v => v.cart_purchase_type * 1)]);
  }, []);

  useEffect(() => {

    var af = [];
    var sipObj_ = {};
    var lumpObj_ = {};
    props.schemeType.funds.forEach((v) => {
      af.push(v.cart_purchase_type * 1);
      switch (v.cart_purchase_type * 1) {
        case 2:
          sipObj_ = { ...sipObj, folios: v.folios, cart_amount: v.cart_amount, cart_tenure: v.cart_tenure, cart_sip_start_date: v.cart_sip_start_date, cart_folio_no: v.cart_folio_no || "new_folio", cart_id: v.cart_id, min_sip_investment: v.min_sip_investment, min_lumpsum_investment: v.min_lumpsum_investment, scheme_name: v.scheme_name };
          break;
        case 1:
          lumpObj_ = { ...lumpObj, folios: v.folios, cart_amount: v.cart_amount, cart_folio_no: v.cart_folio_no || "new_folio", cart_id: v.cart_id, min_sip_investment: v.min_sip_investment, min_lumpsum_investment: v.min_lumpsum_investment, scheme_name: v.scheme_name };
          break;
      }
    });
    setSipObj({ ...sipObj_ });
    setLumpObj({ ...lumpObj_ });
    setAvailableFunds([...af]);

  }, [props?.schemeType.funds]);

  useEffect(() => {
    if (updateSipCart == 0) return;
    setTimeout(() => {
      handleSipSubmit();
    }, 200);
  }, [updateSipCart]);

  useEffect(() => {

    SIPValidator.current.showMessages();
    forceUpdate(1);
  }, [sipObj]);

  useEffect(() => {

    LumpValidator.current.showMessages();
    forceUpdate_(1);
  }, [lumpObj]);

  useEffect(() => {
    if (updateLumpsumCart == 0) return;
    setTimeout(() => {
      handleLumpSubmit();
    }, 200);
  }, [updateLumpsumCart]);


  const handleChange1 = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAmount1(value);
  };

  const [isOpened, setIsOpened] = useState(true);
  const [isRemoveFund, setIsRemoveFund] = useState(true);



  useEffect(() => {
    if (Object.keys(error).length) {
      var a = error;
      var b = Object.keys(a).map((v) => a[v]);
      dispatch({ type: "RENDER_TOAST", payload: { message: <>{b.map((v => <p key={'cartmsg-' + v}>{v}</p>))}</>, type: 'error' } });
    }
  }, [error]);

  const handleSipSubmit = async () => {
    try {
      var formValid = SIPValidator.current.allValid();
      SIPValidator.current.showMessages();
      forceUpdate(1);
      if (formValid == false) return;

      setShow(true);
      var response;
      if (sipObj.cart_id) {

        var payload = {
          url: CART_UPDATE_URL,
          method: 'post',
          data: {
            "user_id": '' + getUserId(),
            "cart_id": '' + sipObj.cart_id,
            "cart_amount": '' + sipObj.cart_amount,
            "cart_tenure": '' + sipObj.cart_tenure,
            "cart_folio_no": '' + sipObj.cart_folio_no,
            "cart_sip_start_date": moment(sipObj.cart_sip_start_date).format('YYYY-MM-DD'),
          },
        };
        response = await fetchEncryptData(payload);
      } else {
        var payload = {
          url: CART_ADD_URL,
          method: 'post',
          data: {
            "cart_scheme_code": props.schemeType.cart_scheme_code,
            "cart_amount": '' + sipObj.cart_amount * 1,
            "cart_tenure": '' + sipObj.cart_tenure,
            "cart_sip_start_date": moment(sipObj.cart_sip_start_date).format('YYYY-MM-DD'),
            "user_id": getUserId(),
            "cart_purchase_type": "2",
            "dividend_option": '0',
            "cart_folio_no": '' + sipObj.cart_folio_no,
          },
        };
        response = await fetchEncryptData(payload);

      }


      if (response.error_code * 1 === 100) {
        // navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/mycart");
        dispatch({ type: "RENDER_TOAST", payload: { message: response.message, type: 'success' } });
        props.reloadPage();
      } else {
        // navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/mycart");

        dispatch({ type: "RENDER_TOAST", payload: { message: response.message, type: 'error' } });

      }
      setShow(false);
    } catch (e) {
      setShow(false);

    }
  }

  const handleLumpSubmit = async () => {
    try {

      var formValid = LumpValidator.current.allValid();
      LumpValidator.current.showMessages();
      forceUpdate_(1);
      if (formValid == false) return;

      setShow(true);
      var response;
      if (lumpObj.cart_id) {
        var payload = {
          url: CART_UPDATE_URL,
          data: {
            "user_id": '' + getUserId(),
            "cart_id": '' + lumpObj.cart_id,
            "cart_amount": '' + lumpObj.cart_amount,
            "cart_folio_no": '' + lumpObj.cart_folio_no
          },
          method: 'post'
        };
        response = await fetchEncryptData(payload);
      } else {
        var payload = {
          url: CART_ADD_URL,
          data: {
            "cart_scheme_code": props.schemeType.cart_scheme_code,
            "cart_amount": '' + lumpObj.cart_amount,
            "user_id": getUserId(),
            "cart_purchase_type": "1",
            "dividend_option": "0",
            "cart_folio_no": '' + lumpObj.cart_folio_no,
          },
          method: 'post'
        };
        response = await fetchEncryptData(payload);
      }

      if (response.error_code * 1 === 100) {
        // navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/mycart");
        dispatch({ type: "RENDER_TOAST", payload: { message: response.message, type: 'success' } });
        props.reloadPage();
      } else {
        // navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/mycart");
        dispatch({ type: "RENDER_TOAST", payload: { message: d.message, type: 'error' } });
      }
      setShow(false);
    } catch (e) {
      setShow(false);
      console.error('error', e);
    }
  }



  return (
    <>
      <div
        className="InvestFundbox"
        style={{
          height: isOpened ? "MyCartBox" : "",
        }}
      >

        <WhiteOverlay show={show} />
        <Container>


          <Row>
            <div className="d-flex">
              <div className="FundName flex-fill">
                <div>
                  <img src={`${process.env.PUBLIC_URL}/static/media/companyicons/${schemeType.funds[0].amc_code}.png`} />
                </div>
                <div className="FundHead">

                  <h4>{schemeType.funds[0].scheme_name}</h4>

                </div>
              </div>
              {isFamilySelected() && <div><span className="badge badge-secondary">{schemeType.memberName}</span></div>}
            </div>

            <>
              <>

                {availableFunds.map((v) => (<>
                  {/* SIP box */}
                  {v == 2 && Object.keys(sipObj).length > 0 && (
                    <div className="cart-details">
                      <div className="cart-data col-11">
                        <div className="cart-data-demo-mode col-2">
                          <div className="cart-title">Mode</div>
                          <div className="cart-ietm-title SIP_cart">SIP</div>
                        </div>
                        <div className="cart-data-demo">
                          <div className="cart-title">Amount</div>
                          <div className={`cart-ietm-title ${'cart_amount' in error ? 'error-b' : ''}`}>
                            <input
                              autoComplete="off"
                              className={`Rupee-icon w-100 `}
                              value={sipObj.cart_amount}
                              type="text"
                              name="Amount"
                              onChange={(e) => {
                                if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 10) {
                                  setSipObj({ ...sipObj, cart_amount: e.target.value });
                                }
                                forceUpdate(1);
                              }}
                              onBlur={() => handleSipSubmit()}
                            />
                          </div>
                          {sipObj.scheme_name.toLowerCase().indexOf('elss') > -1 ? (
                            SIPValidator.current.message(
                              'amount',
                              sipObj.cart_amount,
                              `required|min:${props?.schemeType.funds[0].min_sip_investment},num|elssAmountSIP`,
                              {
                                messages: {
                                  required: `Min Sip Investment ${props?.schemeType.funds[0].min_sip_investment}`,
                                  min: `Min Sip Investment ${props?.schemeType.funds[0].min_sip_investment}`,
                                }
                              }
                            )
                          ) : (
                            SIPValidator.current.message(
                              'amount',
                              sipObj.cart_amount,
                              `required|min:${props?.schemeType.funds[0].min_sip_investment},num`,
                              {
                                messages: {
                                  required: `Min Sip Investment ${props?.schemeType.funds[0].min_sip_investment}`,
                                  min: `Min Sip Investment ${props?.schemeType.funds[0].min_sip_investment}`,
                                }
                              }
                            )
                          )}
                        </div>
                        <div className="cart-data-demo mobile-space">
                          <div className="cart-title">Start Date </div>
                          <div className="cart-ietm-title">
                            <div
                            style={{
                              background : "none"
                            }}
                              className={`sip-datepicker sip-datepicker-0 dob8 ${dob ? "m_selected" : "m_empty"}`}
                            >
                              <FintooDatePicker
                                selected={sipObj.cart_sip_start_date ? new Date(sipObj.cart_sip_start_date) : null}
                                // selected={selectedDate}
                                showMonthDropdown={false}
                                showYearDropdown={false}
                                onChange={(date) => {
                                  setSipObj({ ...sipObj, cart_sip_start_date: moment(date).format("YYYY-MM-DD") });
                                  setUpdateSipCart((v) => ++v);
                                }}
                                minDate={Number(moment().format('H.mm')) >= 15.30 ? moment().add(11, 'day').toDate() : moment().add(10, 'day').toDate()} // Set minimum date as today
                                maxDate={Number(moment().format('H.mm')) >= 15.30 ? moment().add(11, 'day').add(2, 'months').toDate() : moment().add(10, 'day').add(2, 'months').toDate()} // Set maximum date as end of the current year
                                  
                                dateFormat="dd/MM/yyyy"
                                
                                filterDate={(date)=> {
                                  if(date.getDate() > 28) {
                                    return false;
                                  }
                                  return true;
                                }}
                              // calendarIcon={<img width={'20px'} src={require('./Images/calendar73.png')} />}
                              />

                              {/* <DatePicker
                                maxDate={new Date()}
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                dayPlaceholder="DD"
                                className={`my-dob-calendar`}
                                onChange={(v) => setDob(v)}
                                value={dob}
                                format="dd-MM-y"
                              /> */}
                            </div>

                          </div>
                          {SIPValidator.current.message(
                            'cart_sip_start_date',
                            sipObj.cart_sip_start_date,
                            `required`,
                            {
                              messages: {
                                required: 'Add SIP Date',
                              }
                            }
                          )}
                        </div>
                        <div className="cart-data-demo">
                          <div className="cart-title">
                            Tenure (in years){" "}
                            <sup style={{ cursor: "pointer" }}>
                              <BiInfoCircle
                                style={{ fontSize: "16px", outline: "none" }}
                                data-tip
                                data-for="SIPTenure"
                                data-event-off
                                data-title=""
                                src={information}
                              />
                            </sup>
                            <Info id="SIPTenure" place="top">
                              <InfoMessage>
                                Generally, an SIP carries an end date after 1 Year, 3 Years or 5 Years of investment. The investor can hence, withdraw the amount invested whenever he wishes or as per his financial goals.
                              </InfoMessage>
                            </Info>
                          </div>
                          <div className={`cart-ietm-title ${'cart_tenure' in error ? 'error-b' : ''}`}>
                            <input
                              autoComplete="off"
                              className=" w-100"
                              value={sipObj.cart_tenure}
                              maxLength={2}
                              minLength={1}
                              type="text"
                              name="tenure"
                              onChange={(e) => {
                                if ((e.target.value === '' || re.test(e.target.value)) && e.target.value * 1 <= 30) {
                                  setSipObj({ ...sipObj, cart_tenure: e.target.value });
                                }
                                forceUpdate();
                              }}
                              onBlur={() => handleSipSubmit()}
                            />
                          </div>
                          {SIPValidator.current.message(
                            'startTenure',
                            sipObj.cart_tenure,
                            'required|min:1,num',
                            { messages: { required: `Add SIP Tenure`, min: 'Min 1' } }
                          )}
                        </div>
                        <div className="cart-data-demo mobile-space">
                          <div className="cart-title">
                            {(sipObj?.folios ?? []).length > 0 ? 'Folio (Select Folio)' : 'Folio'}
                            <sup style={{ cursor: "pointer" }}>
                              <BiInfoCircle
                                style={{ fontSize: "16px", outline: "none" }}
                                data-tip
                                data-for="Folio"
                                data-event-off
                                data-title=""
                                src={information}
                              />
                            </sup>
                            <Info id="Folio" place="top">
                              <InfoMessage>
                                Folio number is a unique number
                                identifying your account with the fund. Like a
                                bank account number.
                              </InfoMessage>
                            </Info>
                          </div>
                          <div className={`cart-ietm-title ${'cart_folio_no' in error ? 'error-b' : ''}`}>
                            {console.log('4rr', typeof v.folios)}
                            <Form.Select
                              aria-label="Default select example"
                              className="shadow-none border-0"
                              onChange={(e) => {
                                setUpdateSipCart((v) => ++v);
                                setSipObj({ ...sipObj, cart_folio_no: e.target.value });
                              }}
                              value={sipObj?.cart_folio_no}
                            >
                              <option value="new_folio">New Folio</option>
                              {'folios' in sipObj && sipObj.folios.map((x) => <option value={x}>{x}</option>)}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            className="delete-icon"
                            style={{}}
                            src={Delete}
                            onClick={() => {
                              // 
                              if (sipObj.cart_id) {
                                props.onRemove({ cart_id: sipObj.cart_id });
                              } else {
                                props.reloadPage();
                              }
                            }}
                          />
                          {/* <MdDelete
                            style={{
                              marginRight: "1rem",
                            }}
                            className="delete-icon"
                            onClick={() => props.onRemove("sip")}
                          /> */}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Lumpsum box */}
                  {v == 1 && Object.keys(lumpObj).length > 0 && (
                    <div className="cart-details boxContent">
                      <div className="cart-data col-lg-7 col-12">
                        <div className="cart-data-demo-mode col-2">
                          <div className="cart-title">Mode</div>
                          <div className="cart-ietm-title SIP_cart">Lumpsum</div>
                        </div>
                        <div className="cart-data-demo">
                          <div className="cart-title">Amount</div>
                          <div className={`cart-ietm-title ${'cart_amount_l' in error ? 'error-b' : ''}`}>
                            <input
                              autoComplete="off"
                              className="Rupee-icon w-100"

                              value={lumpObj.cart_amount}

                              type="text"
                              name="Amount"
                              onChange={(e) => {
                                if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 10) {
                                  setLumpObj({ ...lumpObj, cart_amount: e.target.value });
                                }
                                forceUpdate_();
                              }}
                              onBlur={() => handleLumpSubmit()}
                            />
                          </div>
                          {lumpObj.scheme_name.toLowerCase().indexOf('elss') > -1 ? (
                            LumpValidator.current.message(
                              'amount',
                              lumpObj.cart_amount,
                              `required|min:${props?.schemeType.funds[0].min_lumpsum_investment},num|elssAmountLumpsum`,
                              {
                                messages: {
                                  required: `Min Lumpsum Investment ${props?.schemeType.funds[0].min_lumpsum_investment}`,
                                  min: `Min Lumpsum Investment ${props?.schemeType.funds[0].min_lumpsum_investment}`,
                                }
                              }
                            )
                          ) : (
                            LumpValidator.current.message(
                              'amount',
                              lumpObj.cart_amount,
                              `required|min:${props?.schemeType.funds[0].min_lumpsum_investment},num`,
                              {
                                messages: {
                                  required: `Min Lumpsum Investment ${props?.schemeType.funds[0].min_lumpsum_investment}`,
                                  min: `Min Lumpsum Investment ${props?.schemeType.funds[0].min_lumpsum_investment}`,
                                }
                              }
                            )
                          )}
                        </div>
                        <div className="cart-data-demo mobile-space">
                          <div className="cart-title">
                            {(lumpObj?.folios ?? []).length > 0 ? 'Folio (Select Folio)' : 'Folio'}
                            <sup style={{ cursor: "pointer" }}>
                              <BiInfoCircle
                                style={{ fontSize: "16px", outline: "none" }}
                                data-tip
                                data-for="Folio"
                                data-event-off
                                data-title=""
                                src={information}
                              />
                            </sup>
                            <Info id="Folio" place="top">
                              <InfoMessage>
                                Folio number is a unique number
                                identifying your account with the fund. Like a
                                bank account number.
                              </InfoMessage>
                            </Info>
                          </div>
                          <div className={`cart-ietm-title ${'cart_folio_no_l' in error ? 'error-b' : ''}`}>
                            {console.log('4rr 8y ', v, typeof v.folios)}
                            <Form.Select
                              aria-label="Default select example"
                              className="shadow-none border-0"
                              onChange={(e) => {
                                setLumpObj({ ...lumpObj, cart_folio_no: e.target.value });
                                setUpdateLumpsumCart((v) => ++v);
                              }}
                              value={lumpObj?.cart_folio_no}
                            >
                              <option value="new_folio">New Folio</option>
                              {'folios' in lumpObj && lumpObj.folios.map((x) => <option value={x}>{x}</option>)}

                            </Form.Select>
                          </div>
                        </div>
                        <div>
                          <div className="">
                            <img
                              className="delete-icon"
                              style={{
                                marginTop: "2.6rem",
                                marginLeft: "1em",
                              }}
                              src={Delete}
                              onClick={() => {
                                if (lumpObj.cart_id) {
                                  props.onRemove({ cart_id: lumpObj.cart_id })
                                } else {
                                  props.reloadPage();
                                }
                              }
                              }
                            />
                            {/* <MdDelete
                              className="delete-icon"
                              style={{
                                marginTop: "2.6rem",

                                cursor: "pointer",
                                color: "#042b62",
                                fontSize: "18px",
                              }}
                            
                            />  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>)

                )}


                <div className="AddLumsum">
                  {/* <p>{v.cart_purchase_type}</p> */}
                  {Object.keys(sipObj).length > 0 && Object.keys(lumpObj).length == 0 && (
                    <>
                      {sipObj.min_lumpsum_investment * 1 > 0 ? (
                        <p ><span onClick={() => {
                          var a = availableFunds;
                          a.push(1);
                          setAvailableFunds([...a]);
                          setLumpObj({
                            cart_amount: "",
                            min_lumpsum_investment: sipObj.min_lumpsum_investment,
                            min_sip_investment: sipObj.min_sip_investment,
                            folios: sipObj.folios,
                            cart_folio_no: sipObj.cart_folio_no,
                            scheme_name: sipObj.scheme_name
                          });
                        }}>Add Lumpsum +</span></p>
                      ) : (
                        <p ><span onClick={() => {
                          dispatch({ type: "RENDER_TOAST", payload: { message: 'The AMC has restricted investment in this fund to SIP only.', type: 'error' } });
                        }}>Add Lumpsum +</span></p>
                      )}

                    </>
                  )}
                  {Object.keys(lumpObj).length > 0 && Object.keys(sipObj).length == 0 && (
                    <>
                      {lumpObj.min_sip_investment * 1 > 0 ? (
                        <p ><span onClick={() => {
                          var a = availableFunds;
                          a.push(2);
                          setAvailableFunds([...a]);
                          setSipObj({
                            cart_amount: "",
                            min_lumpsum_investment: lumpObj.min_lumpsum_investment,
                            min_sip_investment: lumpObj.min_sip_investment,
                            folios: lumpObj.folios,
                            cart_folio_no: lumpObj.cart_folio_no,
                            scheme_name: lumpObj.scheme_name
                          });
                        }}>Add SIP +</span></p>
                      ) : (
                        <p ><span onClick={() => {
                          dispatch({ type: "RENDER_TOAST", payload: { message: 'SIP not allowed in this fund', type: 'error' } });
                        }}>Add SIP +</span></p>
                      )}

                    </>
                  )}
                </div>
              </>

              <Col className="MobileView" xs={11}>
                <div className="mb-float-button">
                  {/* <Link to={`${process.env.PUBLIC_URL}/direct-mutual-fund/MyCartSelectBank`}> */}
                  <button onClick={props.onProceed}>
                    {" "}
                    <label>Proceed to Pay</label>
                  </button>
                  {/* </Link> */}
                </div>
              </Col>
            </>

          </Row>


        </Container>

      </div>
    </>
  );
}

export default memo(Investmentfundbox);
