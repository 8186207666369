import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Performance = (props) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "spline",
      backgroundColor: "#f9fcf5",
    },
    title: {
      text: "Performance",
    },
    colors: ["#588036", "#e1b624", "#f9411f", "#f88221", "#4faa36"],
    xAxis: {
      type: "datetime",
      crosshair: true,
      labels: {
        format: "{value:%Y-%m-%d}",
        rotation: -40,
      },
      tickPixelInterval: 150,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "₹";
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
      series: {
        animation: false,
        dataLabels: {
          enabled: false,
          distance: 40,
          pointFormat: "₹{point.y}",
          style: {
            fontWeight: "bold",
            color: "black",
          },
        },
      },
    },
    series: [
      {
        name: "Growth Of 10000 INR",
        marker: {
          symbol: "square",
        },
        data: [],
      },
    ],
  });

  useEffect(() => {
    // Convert the provided data into separate arrays for dates and values
    const dates = props.growthSeriesData
      ? props.growthSeriesData.map((data) => new Date(data["@date"]).getTime())
      : [];
    const values = props.growthSeriesData
      ? props.growthSeriesData.map((data) => parseFloat(data["#text"]))
      : [];

    // Update the chartOptions with new data
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        categories: dates,
      },
      series: [
        {
          ...prevOptions.series[0],
          data: values,
        },
      ],
    }));
  }, [props.growthSeriesData]);

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default Performance;