import React, { useState, useEffect } from "react";
import styles from "./style.module.css";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo.svg";
import Confirm from "../images/Confirm.png";
import commonEncode from "../../../commonEncode";
import AppointmentBox from "../../../components/Pages/Calendly/ITRindex";
import ApplyWhiteBg from "../../../components/ApplyWhiteBg";
import HideFooter from "../../../components/HideFooter";
import HideHeader from "../../../components/HideHeader";
import {
  getUserId,
  loginRedirectGuest,
  getItemLocal,
  getCookieData,
} from "../../../common_utilities";
import { useDispatch } from "react-redux";
function Appointment() {
  const [value, setvalue] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('nil78', getUserId());
    if (getUserId() == null) {
      // loginRedirectGuest();
      return;
    }

    if (getItemLocal("sc") == 1) {
      navigate(process.env.PUBLIC_URL + "/itr-thank-you");
      return;
    }

    if (localStorage.getItem("pid") == null) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Select plan before proceed.", type: "error" },
      });
      navigate(process.env.PUBLIC_URL + "/itr-file");
      return;
    }
    document.body.classList.add("bg-color");
    setvalue(JSON.parse(commonEncode.decrypt(localStorage.getItem("pid"))));
    return () => {
      document.body.classList.remove("bg-color");
    };
  }, []);

  useEffect(() => {
    if (getUserId() == null) return;
    if(window.location.host.includes("stg.")) {
      // stage
      if (getCookieData("itr-page")) {
        setUrl(
          "https://calendly.com/d/ckdc-m44-wft/45min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-06"
        );
      } else {
        setUrl(
          "https://calendly.com/d/ckgk-cv8-ryg/60min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1%22"
        );
      }
    } else if(window.location.host.includes("fintoo.in")){
      // fintoo india
      if (getCookieData("itr-page")) {
        setUrl(
          "https://calendly.com/d/ckdc-m44-wft/45min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-06"
        );
      } else {
        setUrl(
          "https://calendly.com/d/ckgk-cv8-ryg/60min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1%22"
        );
      }
    } else {
      // fintoo dubai or localhost
      if (getCookieData("itr-page")) {
        setUrl(
          "https://calendly.com/d/ckdc-m44-wft/45min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-06"
        );
      } else {
        setUrl(
          "https://calendly.com/d/ckgk-cv8-ryg/60min-live-itr-filing?hide_event_type_details=1&hide_gdpr_banner=1%22"
        );
      }
    }
    
  }, [value]);

  // console.log(value.plan_id, "value.plan_id");

  return (
    <>
      <HideFooter />
      <HideHeader />
      <ApplyWhiteBg />

      <div className={`${styles.PlanSubscription}`}>
        <section className={`${styles.Appointment_section}`}>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-6 col-12">
                <div className={`${styles.Appointment_section_block}`}>
                  <div className="d-flex justify-content-center">
                    <img src={Logo} />
                  </div>
                  <h2 className={`text-center ${styles.page_header}`}>
                    Schedule Appointment
                  </h2>
                  <p className={`text-center ${styles.page_subTxt}`}>
                    Tax Filing | Individuals with Capital Gains
                  </p>
                </div>

                <div className={` ${styles.AppointmentFrame}`}>
                  <div className="">
                    <AppointmentBox
                      eventCode={"ITR_2023"}
                      serviceName="Income Tax Filing"
                      eventUrl={url}
                      extraParams={{
                        tagval: window.location.host.includes("fintoo.in")
                          ? "itr_filing_2023"
                          : "ITR_2023_LandingPage_UAE",
                        service: window.location.host.includes("fintoo.in")
                          ? "91"
                          : "34",
                        utm_source: "26",
                        rm_id: 96,
                        skip_mail: "1",
                        comment: window.location.host.includes("fintoo.in")
                          ? "ITR filing 2023"
                          : "UAE filing 2023",
                        tags: window.location.host.includes("fintoo.in")
                          ? "itr_filing_2023"
                          : "ITR_2023_LandingPage_UAE",
                        itr: "itr",
                        plan_name: value.plan_name,
                      }}
                    />
                  </div>
                </div>
                <div className={`d-none ${styles.AppointmentConfirmFrame}`}>
                  <div className={` ${styles.Confirmed}`}>
                    <div className={`${styles.confirmBox}`}>
                      <div className="d-flex justify-content-center">
                        <img src={Confirm} />
                      </div>
                      <div className="mt-5">
                        <p> Confirmed </p>
                      </div>
                      <div className="mt-4">You are Scheduled with fintoo.</div>
                      <div className="mt-4">Redirecting....</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-6 h100 ${styles.login_illustration}`}></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Appointment;
