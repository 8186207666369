import styles from "./style.module.css";
import {
    FaTwitter,
    FaFacebookF,
    FaInstagram,
    FaYoutube,
    FaLinkedin,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
function MFSnippetFooter() {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    const hideMainFooter = useSelector((state) => state.hideMainFooter);

    // console.log('hideMainFooter', hideMainFooter);

    return (
        <>
            <footer>
                <div className={`${styles.Footer}`}>
                    <div className={`${styles.FooterSection} ${styles.FooterSection2}`} style={{padding:'1rem 0'}}>
                        <div className={`col-md-3 col-12 ${styles.Footerwidget}`} style={{ textAlign: 'right' }}>
                            <div className={`${styles.FooterwidgetImg}`}>
                                <img
                                    width={200}
                                    src={process.env.REACT_APP_STATIC_URL + "media/wp/Fintoowhitelogo_.svg"}
                                />
                            </div>
                        </div>
                        <div className={`col-md-3 col-12 ${styles.Footerwidget}`} style={{ textAlign: 'right' }}>
                            {/* <div className={`${styles.FooterwidgetImg}`}>
                                <img
                                    width={200}
                                    src={
                                        process.env.REACT_APP_STATIC_URL +
                                        "media/footer/FintooLogo.png"
                                    }
                                />
                            </div> */}
                            <div className={`${styles.Footerwidgetcontact}`}>
                                <a href="tel:+919699800600">+91-9699 800 600</a>
                            </div>
                            <div className={`${styles.Footerwidgetcontact}`}>
                                <a href="mailto:online@fintoo.in">online@fintoo.in</a>
                            </div>
                            {/* <div className={`${styles.FooterwidgetSocial}`} style={{ justifyContent: 'flex-end' }}>
                                <a
                                    className={`${styles.FooterwidgetSocialIcons}`}
                                    href="https://x.com/FintooApp"
                                >
                                    <FaTwitter />
                                </a>

                                <a
                                    className={`${styles.FooterwidgetSocialIcons}`}
                                    href="https://www.facebook.com/fintooapp/"
                                >
                                    <FaFacebookF />
                                </a>

                                <a
                                    className={`${styles.FooterwidgetSocialIcons}`}
                                    href="https://www.linkedin.com/company/1769616/"
                                >
                                    <FaLinkedin />
                                </a>

                                <a
                                    className={`${styles.FooterwidgetSocialIcons}`}
                                    href="https://www.instagram.com/fintoo.app/"
                                >
                                    <FaInstagram />
                                </a>

                                <a
                                    className={`${styles.FooterwidgetSocialIcons}`}
                                    href="https://www.youtube.com/channel/UC00AMcwwfUKrV-XD5n6hWyQ/videos"
                                >
                                    <FaYoutube />
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <div className={`${styles.FooterSectionText}`}>
                        <div className={`${styles.FooterSectionPara}`}>
                            <p>
                                Financial Hospital Advisor LLP (Brand Name - Fintoo.in, Fintoo app, Fintoo) makes no warranties or representations, express or implied, on products and services offered through the platform. It accepts no liability for any damages or losses, however, caused in connection with the use of, or on the reliance of its advisory or related services. Past performance is not indicative of future returns. Please consider your specific investment requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns of any investment portfolio can neither be predicted nor guaranteed. Investments made on advisory are subject to market risks, read all scheme related documents carefully.
                            </p>
                            <p>
                                © FINANCIAL HOSPITAL ADVISOR LLP [SEBI RIA Registration No: INA000015756] [BASL Membership ID: 1874] [Type of Registration: Non-Individual] [Validity of registration: February 17, 2021-Perpetual] [Address: Financial Hospital B-404, Kanakia Wall Street, Andheri - Kurla Rd, Hanuman Nagar, Andheri East, Mumbai, Maharashtra 400093] [Platform Partner : BSE (Member code-10096)] [LLP - AAE-1913] [GST No : 27AAEFF2161P1Z6] [Principal Officer details : Mr. Mihir Shah (mihir.shah@fintoo.in)] [Compliance Officer details : Mrs. Nisha Harchekar (nisha.harchekar@fintoo.in)] [Corresponding SEBI regional/local office: Plot No. C 4-A , G Block, Near Bank of India, Bandra Kurla Complex,Bandra East, Mumbai, Maharashtra 400051]
                            </p>
                        </div>
                        <div
                            className={`text-center ${styles.FooterSectionCopyRightText}`}
                        >
                            Copyright © {year} Fintoo,. All rights reserved
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default MFSnippetFooter;
