import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import BootStrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";
import FintooLoader from "../FintooLoader";
import { ADVISORY_EXPERT_FP_DOC, ADVISORY_SIGNDESK_API_CALL } from "../../constants";
import { fetchData } from "../../common_utilities";
import { Link } from "react-router-dom";


const KYCPopup = (props) => {
  const [modalStep, setModalStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const proceedFp = async ()=>{
    setIsLoading(true);
    props.onHide();
    try{
      let fpBody = {
        user_id: props.sessiondata["id"].toString(),
        fp_log_id: props.sessiondata["fp_log_id"].toString(),
        flag: "fp",
      };
      let config = {
        method: "POST",
        url: ADVISORY_EXPERT_FP_DOC,
        data: fpBody,
      };
      let response = await fetchData(config);
      if (response["error_code"] == "100") {
        var b64FileString = response["data"]["b64_file_string"];
        let signDeskConfig = {
          method: "POST",
          url: ADVISORY_SIGNDESK_API_CALL,
          data: {
            user_id: props.sessiondata["id"],
            fp_log_id: props.sessiondata["fp_log_id"],
            file_content: b64FileString,
          },
        };
        let signDeskResponse = await fetchData(signDeskConfig);
        if (signDeskResponse["error_code"] == "100") {
          let widgetURL =
            signDeskResponse["details"]["signer_info"][0]["invitation_link"];
            setIsLoading(false)
            window.open(
              widgetURL,
              "_blank"
            );
        }
      }
    }
    catch (e){
      console.log(e)
    }
  }

  return (
    <>
    <FintooLoader isLoading={isLoading}/>
    <div>
      <BootStrapModal
        className="Modalpopup"
        show={props.show}
        onHide={()=> {
          props.onHide();
        }}
        centered
      >
       <BootStrapModal.Header closeButton>
          <h2 className="text-center w-100">Attention !!</h2>
        </BootStrapModal.Header>
        <BootStrapModal.Body>
          <div className="p-2">
            <p className="PopupContent">
              In order to access your report, please{" "}
              {!props.fpDone && (
                <>
                  accept your <a className="pointer" style={{color:"#042b62",textDecoration:"none"}} onClick={()=> proceedFp()}>FP Agreement</a>{" "}
                </>
              )}
              {!props.fpDone && !props.kycDone && "and "}
              {!props.kycDone && (
                <>
                  complete your{" "}
                  <Link
                  col
                    style={{textDecoration:"none",   color : "#042b62"}}
                    href={
                      process.env.PUBLIC_URL +
                      "/datagathering/about-you/#completekyc"
                    }
                  >
                    KYC
                  </Link>{" "}
                </>
              )}
            </p>
          </div>
        </BootStrapModal.Body>
      </BootStrapModal>
    </div>
    </>
    
  );
};

export default KYCPopup;
