import { useEffect, useState, Fragment } from "react";
import {
  fetchData,
  fetchEncryptData,
  getPublicMediaURL,
} from "../../common_utilities";
import { DMF_STOP_REASON_API_URL } from "../../constants";
import style from "./style.module.css";
import { useDispatch } from "react-redux";

const StopSipReason = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [allReasons, setAllReasons] = useState([]);
  const [selected, setSelected] = useState({});
  const [textError, setTextError] = useState("");
  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    const r = await fetchData({
      url: DMF_STOP_REASON_API_URL,
      method: "post",
    });
    console.log("r34", r.data);
    setAllReasons(r.data);
  };

  return (
    <>
      {allReasons.map((v) => (
        <Fragment key={"rsnx-" + v.id}>
          <p
            className={`${style.para} py-2 pointer ${selected?.id == v.id && style.paraselect}`}
            onClick={() => {
              setSelected(v);
            }}
          >
            <img
              src={getPublicMediaURL(
                `/static/media/icons/${
                  selected?.id == v.id ? "check_01.png" : "check_02.png"
                }`
              )}
              width={17}
            />
            &nbsp;&nbsp;&nbsp;{v.reason}
          </p>
          {selected.id == 13 && v.id == 13 && (
            <div>
              <input
                placeholder="Please specify the reason"
                type="text"
                onChange={(e) => {
                  if(e.target.value) {
                    setTextError("");
                  } else {
                    setTextError("This field is required.");
                  }
                  setSelected((prev) => ({ ...prev, text: e.target.value }));
                }}
                value={selected?.text}
                className={`${style.txt} w-100`}
              />
              {textError && <p className="error">{textError}</p>}
            </div>
          )}
        </Fragment>
      ))}
      <div className="pt-4">
        <div
          className={`${Object.keys(selected).length == 0 && "disabled"} switch-fund-btn mobile-bottom-button`}
          onClick={() => {
            console.log('s33', selected);
            if(Object.keys(selected).length == 0) {
              dispatch({ type: "RENDER_TOAST", payload: { message: 'Please select reason.', type: 'error' } });
            } else if(selected?.id == 13 && !selected?.text) {
              setTextError("This field is required.");
            } else {
              onSubmit(selected);
            }
          }}
        >
          Next
        </div>
      </div>
    </>
  );
};

export default StopSipReason;
