import { useState, useEffect } from "react";
import Footer from "../components/MainComponents/Footer";
import { ReactComponent as Logo } from "../Assets/Images/logo.svg";
import MainLayout from "../components/Layout/MainLayout";
import { apiCall, getItemLocal, getParentUserId } from "../common_utilities";
import {
  ADVISORY_ADDTOCART_API_URL,
  ADVISORY_GET_PRICINGDETAILS_API_URL,
  ADVISORY_GET_TAXDETAILS_API_URL,
  BASE_API_URL, imagePath,
  CHECK_SESSION,
  STATIC_URL,
} from "../constants";
import PricingPopup from "./PricingPopup";
// import Modal from "react-responsive-modal";
import { Modal } from "react-bootstrap";
import FintooLoader from "../components/FintooLoader";
import * as constClass from "../constants";
import commonEncode from "../commonEncode";
import { Buffer } from "buffer";
import GuestLayout from "../components/Layout/GuestLayout";
import ApplyWhiteBg from "../components/ApplyWhiteBg";
import { SegmentedControl } from "segmented-control";
import TaxPricingView from "./Pricing/views/TaxPricingView";
import { useSearchParams } from "react-router-dom";


const PricingPage = () => {
  const [tabNo, setTabNo] = useState(1);
  const [sessionData, setSessionData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [taxPricingData, setTaxPricingData] = useState([]);
  const [reqplandict, setReqPlanDict] = useState({});
  const [taxData, setTaxsData] = useState([]);
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceTab, SetServiceTab] = useState(2)

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    SetServiceTab(() => defaultService())

    const keyDownHandle = (e) => {
      e.preventDefault()
    }
    window.addEventListener("keydown", keyDownHandle);

    return (() => window.removeEventListener("keydown", keyDownHandle))
  }, [])

  useEffect(() => {
    document.body.classList.add("white-bg");
    return (() => document.body.classList.remove("white-bg"))
  }, [])

  useEffect(() => {
    if (location.href.includes("#tax_plan")) {
      setTabNo(2);
    } else {
      setTabNo(1);
    }
  }, []);
  useEffect(() => {
    getSessionData();
    getPricingDetails();
    getTaxDetails();
  }, []);

  const userid = getParentUserId();
  const getSessionData = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
    } catch {
      setSessionData([]);
    }
  };
  const getPricingDetails = async () => {
    try {
      setIsLoading(true);
      let url = ADVISORY_GET_PRICINGDETAILS_API_URL;
      let pricing_data = await apiCall(url, "", false, false);
      if (pricing_data["error_code"] == "100") {
        setIsLoading(false);
        pricing_data = pricing_data["data"]["plan_details"]["plandetails"]
        const pricingData = pricing_data.filter(data =>
          data.plan_id == 29 || data.plan_id == 31
        )

        const tax_pricing_data = pricing_data.filter(data =>
          data.plan_id == 44
        )
        setPricingData(pricingData);
        setTaxPricingData(tax_pricing_data);
      }
    } catch {
      setIsLoading(true);
      setPricingData({});
      setTaxPricingData([]);
    }
  };
  const getTaxDetails = async () => {
    try {
      setIsLoading(true);
      let url = ADVISORY_GET_TAXDETAILS_API_URL;
      let tax_data = await apiCall(url, "", false, false);
      if (tax_data["error_code"] == "100") {
        setIsLoading(false);
        setTaxsData(tax_data["data"]["tax_plan_details"]["taxplandetails"]);
      }
    } catch {
      setIsLoading(true);
      setTaxsData({});
    }
  };
  const loginRedirect = () => {
    localStorage.removeItem("userid");
    let t = window.location.href;
    if (window.location.pathname == "/") {
      t = t + "web/commondashboard";
    }
    if (t == "https://www.fintoo.in/web/pricing") {
      var redirectURL =
        window.location.origin +
        process.env.PUBLIC_URL +
        "/checkredirect?redirect=" +
        encodeURI(process.env.PUBLIC_URL + "/commondashboard/");
    }
    else {
      var redirectURL =
        window.location.origin +
        process.env.PUBLIC_URL +
        "/checkredirect?redirect=" +
        encodeURI(t);
    }

    var goTo =
      constClass.LOGIN_PAGE +
      "?src=" +
      Buffer.from(commonEncode.encrypt("dmf")).toString("base64") +
      "&redirect_uri=" +
      Buffer.from(commonEncode.encrypt(redirectURL)).toString("base64");

    window.location = goTo;
  };
  function addtocart(monthly_amount, total_amount, frq, plan_sub_cat, plan_id) {
    var amount = 0;
    if (total_amount.isquaterly == 0 && total_amount.total != "custom") {
      amount = parseInt(total_amount.total);
    } else {
      amount = total_amount.Q1;
    }
    setReqPlanDict({
      amount: amount,
      frq: frq,
      plan_sub_cat: plan_sub_cat,
      plan_id: plan_id,
    });

    if (
      sessionData &&
      sessionData.fp_log_id &&
      (plan_id == 29 || plan_id == 31) &&
      sessionData.fp_plan_sub_cat == plan_sub_cat
    ) {
      window.location = process.env.PUBLIC_URL + "/commondashboard/";
    } else if (
      sessionData &&
      sessionData.fp_log_id &&
      (plan_id == 29 || plan_id == 31) &&
      sessionData.fp_plan_sub_cat != plan_sub_cat
    ) {
      if (
        (sessionData["plan_payment_status"] == "1" ||
          sessionData["plan_payment_status"] == "3" ||
          sessionData["plan_payment_status"] == "6" ||
          sessionData["plan_payment_status"] == "7") &&
        sessionData.fp_plan_sub_cat
      ) {
        if (sessionData.fp_plan_sub_cat > plan_sub_cat) {
          {
            setOpen(true);
          }
        } else {
          expertDetails(plan_id, plan_sub_cat, amount, frq);
        }
      } else {
        expertDetails(plan_id, plan_sub_cat, amount, frq);
      }
    } else if (plan_sub_cat != 7 && !sessionData) {
      loginRedirect()
    } else {
      if (plan_id == 29 || plan_id == 31) {
        expertDetails(plan_id, plan_sub_cat, amount, frq);
      }
    }
  }

  const expertDetails = async (plan_id, plan_sub_cat, amount, frq) => {
    if (plan_id == 29) {
      let cartdatatosend = {
        user_id: sessionData && sessionData && sessionData.id,
        plan_id: plan_id,
        plan_sub_cat_id: plan_sub_cat,
        amount: amount,
        subscription_freq: frq,
      };
      setIsLoading(true);
      let url = ADVISORY_ADDTOCART_API_URL;
      let cart_data = await apiCall(url, cartdatatosend, true, false);
      if (cart_data.error_code == "100") {
        setIsLoading(false);
        window.location.href = process.env.PUBLIC_URL + "/userflow/payment/";
      }
    } else if (plan_id == 31) {
      setIsLoading(true);
      window.location = process.env.PUBLIC_URL + "/expert";
    }
  };

  const addTaxCart = async (plan_id) => {
    window.location =
      "http://www.erokda.in/tax/itr-filing.php?pid=" + "" + plan_id;
  };
  const defaultService = () => {
    const service = searchParams.get('service');
    switch (service) {
      case 'financial-planning':
        return 0
      case 'tax-planning':
        return 1
      case 'estate-planning':
        return 2
      default:
        return 0
    }
  }

  const getSearchParam = (val) => {
    switch (val) {
      case 0:
        return 'financial-planning'
      case 1:
        return 'tax-planning'
      case 2:
        return 'estate-planning'
      default:
        return 'financial-planning'
    }
  }
  return (
    <GuestLayout>
      <div className="pricingSegmentControlContainer">
        <SegmentedControl
          name="taxResident"
          className="my-segment-2"
          options={[
            {
              label: "Financial Planning",
              value: 0,
              default: defaultService() == 0
            },

            { label: "Tax Planning", value: 1, default: defaultService() == 1 },
          ]}
          setValue={(newValue) => {
            setSearchParams({ 'service': getSearchParam(newValue) })
            SetServiceTab(newValue)
          }}
        />
      </div>

      <div className="container-fluid white-bg">
        {serviceTab === 0 && <div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 w-100">
              <div className="s-head text-center mb-80">
                <h2 className="upperText">
                  AI-Based Personalized Financial Planning Solutions Trusted By
                  2,00,000+ Investors.
                </h2>
                <p
                  className="mt-2 BottomText"
                  style={{
                    color: "gray",
                  }}
                >
                  Fintoo's AI-Based comprehensive financial planning solution is a
                  perfect combination of advanced technology and the extensive
                  experience of financial planning experts. As each client is
                  unique, we develop a unique and customised plan for each of our
                  clients.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="pric-tables price-table">
                <div className="PricingBox">
                  {pricingData &&
                    pricingData.map((x) => (
                      <div
                        className={`item sm-mb50 item-active`}
                        key={x.id}
                      >
                        <div className="type Price-type text-center mb-15">
                          {x.category_name == "Assisted Advisory" ? (
                            <div style={{ fontWeight: "bold", color: "#fff" }}>
                              ASSISTED ADVISORY
                            </div>
                          ) : (
                            <div style={{ fontWeight: "bold", color: "#fff" }}>{x.category_name.toUpperCase()}</div>
                          )}
                        </div>
                        <div className="amount text-center mb-40" style={{ marginTop: "1rem" }}>
                          {x.category_name == "Financial Health Checkup" && (
                            <h4>₹ {parseInt(x.monthly_amount).toLocaleString()}</h4>
                          )}
                          {x.category_name == "Assisted Advisory" && (
                            <h4>{x.monthly_amount}</h4>
                          )}
                        </div>

                        {x.category_name == "Assisted Advisory" ? (
                          <div className="text-center custombtmText text-gray p">
                            Get a completely customised financial plan according
                            to your unique requirement
                          </div>
                        )
                          :
                          <div className="text-center custombtmText text-gray p">
                            Half Yearly
                          </div>
                        }
                        <div className="feat">
                          <ul>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.dedicated_wealth_manager == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.dedicated_wealth_manager == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Dedicated Wealth Manager
                              </span>
                            </li>
                            <li className="d-flex align-items-md-center">
                              <span>
                                {x.comprehensive_fp == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.comprehensive_fp == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Comprehensive Financial Planning
                              </span>
                            </li>
                            {/* <li className="d-flex align-items-center">
                              <span>
                                {x.tax_advisory.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.tax_advisory.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Tax Advisory
                              </span>
                            </li> */}
                            <li className="d-flex align-items-center">
                              <span>
                                {x.will_estate_planning == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.will_estate_planning == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                {" "}
                                Will & Estate Planning
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.risk_management.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.risk_management.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Risk Management
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                <img
                                  className="pe-2 checkPng"
                                  style={{ width: "16px" }}
                                  src={
                                    process.env.REACT_APP_STATIC_URL +
                                    "media/Pricing/check.svg"
                                  }
                                  alt=""
                                />
                              </span>
                              <span>
                                Review Frequency{" "}
                                <span
                                  className={`ExtraTxt ${x.category_name == "Assisted Advisory"
                                    ? "text-secondary"
                                    : "text-secondary"
                                    }`}
                                >
                                  (
                                  {x.review_frequency == "3"
                                    ? "Quarterly"
                                    : "Monthly"}
                                  )
                                </span>
                              </span>
                            </li>
                            <li className="d-flex">
                              <span>
                                {x.private_wealth_managment.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.private_wealth_managment.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Private Wealth Management{" "}
                                <span
                                  className={`ExtraTxt ${x.category_name == "Assisted Advisory"
                                    ? "text-secondary"
                                    : "text-secondary"
                                    }`}
                                >
                                 { x.private_wealth_managment.meta.web}
                                </span>
                                {/* <br /> */}

                                {/* {x.monthly_amount == 'Custom' && (<div className="Extrainfo">
                                  (Direct MF, Domestic Equity, Intl Equity,
                                  Unlisted Equity, Bonds, Debt & Alternate)
                                </div>)} */}
                              </span>
                            </li>
                            <li className="d-flex ">
                              <span>
                                {x.rebalancing_portfolio.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.rebalancing_portfolio.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Rebalancing of Portfolio
                                <span
                                  className={`ExtraTxt ${x.category_name == "Assisted Advisory"
                                    ? "text-secondary"
                                    : "text-secondary"
                                    }`}
                                >
                                  &nbsp;
                                  {x.rebalancing_portfolio.meta.web
                                    ? x.rebalancing_portfolio.meta.web
                                    : "(Advisory & Execution)"}
                                </span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.tax_filling.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.tax_filling.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>

                              <span>
                                {" "}
                                Tax Filing &nbsp;
                                <span className="ExtraTxt text-secondary">
                                  {x.tax_filling.meta.web}
                                </span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.chat_with_expert.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.chat_with_expert.data == 1 && (
                                  <img
                                    className="pe-2 checkPng"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.svg"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span>
                                Chat With Expert
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="BtnBox">
                          <button
                            onClick={() =>
                              addtocart(
                                x.monthly_amount,
                                x.amount,
                                x.payment_frequency,
                                x.id,
                                x.plan_id
                              )
                            }
                          >
                            {" "}
                            Get Started{" "}
                          </button>
                        </div>
                        <br></br>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Modal className="Modalpopup" show={open} onHide={onCloseModal} centered>
            <div className="text-center">
              <h2 className="HeaderText">Information !!</h2>
              <PricingPopup
                open={open}
                onClose={onCloseModal}
                sessionData={sessionData}
                reqplandict={reqplandict}
              />
            </div>
          </Modal>
        </div>}
        {
          serviceTab === 1 && <TaxPricingView taxPricingData={taxPricingData} />
        }

      </div>


    </GuestLayout>
  );
};
export default PricingPage;
