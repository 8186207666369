import React, { useEffect, useRef, useState } from "react";
import Styles from "./Expertfp.module.css";
import HideHeader from "../components/HideHeader";
import HideFooter from "../components/HideFooter";
import { Link } from "react-router-dom";
import FintooLoader from "../components/FintooLoader";
import commonEncode from "../commonEncode";
import {
  ADVISORY_EXPERT_NDA, CHECK_SESSION,
  ADVISORY_UPDATE_CURRENT_STEP,
  ADVISORY_UPDATE_AUTH_DATA,
  ADVISORY_EXPERT_FP_DOC,
  ADVISORY_EXPERT_FP_INFLOW_GET_RMEMAIl,
  DMF_SENDMAIL_API_URL,
  DMF_SENDSMS_API_URL,
  DMF_DELETE_FILES_API_URL,
  AUTH_URL,
  master_psw
} from "../constants";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  getUserId, loginRedirectGuest, fetchData, getFpLogId, setFplogid,
} from "../common_utilities";
import { useNavigate } from "react-router-dom";


const Expertnda = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [termsError, setTermsMsg] = useState("");
  const [isTermsChecked, setTerms] = useState(false);
  const sessionRef = useRef(null);
  const [expertNDA, setExpertNDA] = useState("");
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    getSessionData();

  }, []);

  const getSessionData = async () => {

    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      var session_data = await apiCall(url, data, true, false);

      if (session_data.error_code == "102") {
        loginRedirectGuest();
      } else {
        sessionRef.current = session_data.data;
        // console.log("session nda: ", session_data.data);

        if (session_data.data['fp_lifecycle_status'] == "0" ||
          session_data.data["fp_lifecycle_status"] == "" ||
          !session_data.data["fp_lifecycle_status"]) {
          getExpertNDAData();
        }
        else {
          window.location.href = '/web/datagathering/about-you/';
        }
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const getFullname = async () => {
    try {
      var payload = {
        url: AUTH_URL,
        method: "GET"
      };

      var auth_resp = JSON.parse(commonEncode.decrypt(await fetchData(payload)));

      // console.log("client pp:", typeof(auth_resp), auth_resp.error_code, auth_resp.error_code == "0");

      if (auth_resp.error_code == "0") {
        var auth_user_details = auth_resp.data['user_details'];

        if (auth_user_details["last_name"]) {
          setFullName(auth_user_details['first_name'] + " " + auth_user_details['last_name']);
        } else {
          setFullName(auth_user_details['first_name']);
        }
      }
    } catch (e) {
      console.log("err", e);
    }
  }

  const getExpertNDAData = async () => {
    getFullname();

    try {
      var payload = {
        data: { user_id: sessionRef.current["id"] },
        url: ADVISORY_EXPERT_NDA,
        method: "post",
      };

      var response = await fetchData(payload);

      if (response.error_code == "100") {
        setExpertNDA(response['data']);
      }
    } catch (e) {
      console.log("err", e);
    }

  }

  const handleCheckboxClick = (e) => {
    var checked = e.target.checked;

    if (checked == false) {
      setTermsMsg("Please agree to proceed");
    } else {
      setTermsMsg("");
    }
    setTerms(e.target.checked);
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if (isTermsChecked == false) {
      setTermsMsg("Please agree to proceed");
    }
    else if (isTermsChecked && termsError == '') {
      agreeOnNda();
    }

  };

  const agreeOnNda = async () => {

    try {
      var config = {
        method: "POST",
        url: ADVISORY_UPDATE_CURRENT_STEP,
        data: {
          step: 1,
          fp_log_id: sessionRef.current["fp_log_id"],
        },
      };

      let lc_response = await fetchData(config);

      if (lc_response["error_code"] == "100") {
        var fp_lifecycle_status = 1;
        sessionStorage.setItem("showNDAcompletionToast", "1");

        await updateAuthData({ fp_lifecycle_status: 1, nda_flag: "1" });
      } else {
        console.log(lc_response);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const updateAuthData = async (payload) => {

    try {
      var config = {
        method: "POST",
        url: ADVISORY_UPDATE_AUTH_DATA,
        data: payload
      };
      var authResponse = await fetchData(config);

      // If session response received
      if (authResponse) {
        var fpdata = {
          user_id: sessionRef.current["id"].toString(),
          fp_log_id: sessionRef.current['fp_log_id'].toString(),
          flag: "nda",
          emp_name: fullName
        }

        var fppayload = {
          method: "POST",
          url: ADVISORY_EXPERT_FP_DOC,
          data: fpdata
        }

        let fp_response = await fetchData(fppayload);

        if (fp_response.error_code == "100") {
          var nda_attachment = [fp_response['data']['local_path']];
          var nda_file_name = fp_response['data']['filename'];

          try {
            var rm_payload = {
              method: "GET",
              url: ADVISORY_EXPERT_FP_INFLOW_GET_RMEMAIl + "?fp_log_id=" + sessionRef.current['fp_log_id'],
            }

            let rm_response = await fetchData(rm_payload);

            var rm_email = rm_response?.data?.expert_email || '';
            var rm_name = rm_response?.data?.expert_name || '';
            var rm_mobile = rm_response?.data?.expert_mobile || '';
            var rm_designation = rm_response?.data?.expert_designation || '';

            // send email to user
            var emailDataUser = {
              "userdata": { "to": sessionRef.current['email'] },
              "subject": "Non-Disclosure Agreement From Fintoo",
              "template": "NDA_completed.html",
              "attachment": nda_attachment,
              "contextvar": {
                "encodeduserid": btoa('00' + sessionRef.current['id']),
                "fullname": fullName,
                "id": sessionRef.current["id"],
                "fp_log_id": sessionRef.current['fp_log_id'],
                "rm_email": rm_email,
                "rm_name": rm_name,
                "rm_mobile": rm_mobile,
                "rm_designation": rm_designation
              }
            }

            var user_data = {
              method: "POST",
              url: DMF_SENDMAIL_API_URL,
              data: emailDataUser
            }

            //send email to RM
            var emaildataRm = {
              "userdata": { "to": rm_email },
              "subject": " NDA confirmation from Client!",
              "template": "NDA_completed_rm.html",
              "attachment": nda_attachment,
              "contextvar": {
                "encodeduserid": btoa('00' + sessionRef.current['id']),
                "fullname": fullName,
                "client_email": sessionRef.current['email'],
                "client_mobile": sessionRef.current['mobile'],
                "id": sessionRef.current['id'],
                "fp_log_id": sessionRef.current['fp_log_id'],
                "link": "login-to-module/?" + "uKey=" + btoa(sessionRef.current['id']) + "&authtoken=" + btoa(master_psw)
              }
            };

            var rm_data = {
              method: "POST",
              url: DMF_SENDMAIL_API_URL,
              data: emaildataRm
            }

            let rm_email_resp = await fetchData(rm_data);

            if (rm_email_resp.error_code == "0") {
              // API to delete the nda documents from local
              var nda_data = { "file_name": nda_file_name, "folder_path": "/NDA/" };
              var delete_data = {
                method: "POST",
                url: DMF_DELETE_FILES_API_URL,
                data: nda_data
              }

              let nda_deleted_resp = await fetchData(delete_data);
              if (nda_deleted_resp.error_code == "100") {
                console.log("NDA local filed deleted...");
              }
            }

            //send NDA sms to client
            var smsdata = {
              "msg": "Dear " + fullName + ",\r\nThank you for accepting the NDA. Once again we would like to assure you that we completely understand the importance of data and its security is always our topmost priority. For any help or query, please feel free to get in touch with your Wealth Manager; Name: " + rm_name + "\r\nPhone: " + rm_mobile + "\r\Email: " + rm_email + "\r\nTeam Fintoo",
              "whatsapptext": "Dear " + fullName + ",\nThank you for accepting the NDA. Once again we would like to assure you that we completely understand the importance of data and its security is always our topmost priority. For any help or query, please feel free to get in touch with your Wealth Manager; Name: " + rm_name + " Phone: " + rm_mobile + " Email: " + rm_email,
              "mobile": sessionRef.current['mobile'],
              sms_api_id: "FintooNDAAccepted"
            };
            var user_sms_data = {
              method: "POST",
              url: DMF_SENDSMS_API_URL,
              data: smsdata
            }
            let sms_resp = await fetchData(user_sms_data);

            if (sms_resp.error_code == "0") {
              //send NDA sms to RM
              try {
                let fp_log_id = await getFpLogId();
                setFplogid(fp_log_id);
              } catch {
                setFplogid("");
              }
              if (rm_mobile != "") {
                var smsdata = {
                  msg:
                    "Dear " +
                    rm_name +
                    ",\r\nOur client, " +
                    fullName +
                    " has accepted the NDA. Request you to initiate the next step.\r\nTeam Fintoo",
                  mobile: rm_mobile,
                  whatsapptext:
                    "Dear " +
                    rm_name +
                    ", Our client, " +
                    fullName +
                    " has accepted the NDA. Request you to initiate the next step.",
                  sms_api_id: "FintooNDAAcceptedWM",
                };

                var rm_sms_data = {
                  method: "POST",
                  url: DMF_SENDSMS_API_URL,
                  data: smsdata,
                };

                let sms_rm_resp = await fetchData(rm_sms_data);

                if (sms_rm_resp) {
                  navigate(process.env.PUBLIC_URL + '/datagathering/about-you/');
                }
              } else {
                navigate(process.env.PUBLIC_URL + '/datagathering/about-you/');
              }
            }

          } catch (e) {
            console.log("error: ", e);
          }

        }
      }

    } catch (e) {
      console.error(e);
    }
  }

  return (

    <div className={`${Styles.ExpertNda}`}>
      <HideHeader />
      {/* <FintooLoader isLoading={isLoading} /> */}
      <div className={`${Styles.whitebox}`}>
        <div className="container-fluid">
          {/* <div className={`${Styles.backlink}`}>
            <Link to={process.env.PUBLIC_URL + "/datagathering/about-you"}>
              <div className={`${Styles.backarrow}`}>
                <img src="https://static.fintoo.in/static/userflow/img/icons/back-arrow.svg" />
              </div>
            </Link>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-6 mt-5 h-100">
                  <h3 className={`${Styles.ndacontent}`}>
                    Non Disclosure Agreement
                  </h3>
                  <p className={`${Styles.ndahighlight}`}><span>**</span> &nbsp;In order to continue with expert plan, please accept this agreement.</p>

                  <div className="h-100 mt-5">
                    <p
                      className={`${Styles.fpcontent}`}
                    >
                      <div dangerouslySetInnerHTML={{ __html: expertNDA }} />
                    </p>
                  </div>

                  <div
                    className={`checkbox-container position-relative ${Styles.expertNdaCheckbox}`} >

                    <input
                      type="checkbox"
                      name=""
                      tabIndex="1"
                      className="custom-checkbox"
                      id="terms"
                      checked={isTermsChecked}
                      onChange={handleCheckboxClick}
                    />
                    <label
                      htmlFor="terms"
                      style={{
                        paddingTop: "2px",
                        fontSize: "15px",
                      }}
                    >
                      I accept the &nbsp;
                      <Link
                        className={`${Styles.LinkTerms}`} style={{
                          fontWeight: "500",
                        }}
                        to={process.env.PUBLIC_URL + "/terms-conditions"}
                      >
                        Terms & Conditions</Link>*
                      <br></br>
                      <span className="error">{termsError}</span>

                    </label>

                    <button
                      style={{
                        width: "max-content"
                      }}
                      type={"button"}
                      className={`${Styles.Proceedbtn}`}
                      onClick={handleSubmit}
                    >
                      Proceed
                    </button>
                  </div>

                  <div></div>
                </div>

                <div className={`col-md-6 d-md-block d-none  ${Styles.ExpertNdaimg}`}>
                  <img class="w-100"
                    src={process.env.PUBLIC_URL + "/static/media/login-illustration.svg"}
                    alt="Fintoo Invest logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HideFooter />
    </div>
  );
}

export default Expertnda;
