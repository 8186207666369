import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Profile_1 from "../../../Assets/Profile_1.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Back from "../../../Assets/left-arrow.png";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import { CheckSession, getUserId, memberId } from "../../../../common_utilities";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  DMF_BASE_URL,
  DMF_GETINCOMESLABS,
  GET_OCCUPATIONS_API_URL,
} from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import * as constClass from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import FintooCheckbox from "../../../FintooCheckbox/FintooCheckbox";


function Occupation(props) {
  const user_id = memberId();
  const [validated, setValidated] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [occupation, setOccupation] = useState([]);
  const [incSlabs, setIncSlab] = useState([]);
  const dispatch = useDispatch();
  var [occupationId, setOccupationId] = useState("");
  var [incomeId, setIncomeId] = useState("");
  const allUserData = localStorage.getItem("user");
  const showBack = useSelector((state) => state.isBackVisible);
  const userData = JSON.parse(allUserData);
  const statusList = ["Married", "Unmarried"];
  var [MaStatus, SetMaStatus] = useState("");

  const onLoadInIt = async () => {
    var data = { user_id: getUserId() };
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      setUserDetails(JSON.parse(response)["data"]);
    } catch (e) {
      e.errorAlert();
    }
  };

  const defaultValues = async () => {
    if (userDetails.income_slab_id != "") {
      setIncomeId(userDetails.income_slab_id);
    } else {
      setIncomeId("");
    }
    if (userDetails.occupation != "") {
      setOccupationId(userDetails.occupation);
    } else {
      setOccupationId("");
    }
    if (userDetails.marital_status != "") {
      SetMaStatus(userDetails.marital_status);
    } else {
      SetMaStatus("");
    }
  };

  useEffect(() => {
    defaultValues();
  }, [userDetails]);

  const getIncomeSlabs = async () => {
    try {
      var config = {
        method: "post",
        url: DMF_GETINCOMESLABS,
      };

      var res = await axios(config);
      var responseInc = res.data;
      setIncSlab(responseInc.data);
    } catch (e) {
      e.errorAlert();
    }
  };

  const getOccupations = async () => {
    try {
      var config = {
        method: "get",
        url: GET_OCCUPATIONS_API_URL,
      };

      var res = await axios(config);
      var responseOcc = commonEncode.decrypt(res.data);
      setOccupation(JSON.parse(responseOcc));
    } catch (e) {
      e.errorAlert();
    }
  };

  useEffect(() => {
    localStorage.removeItem("req");
    // CheckSession();
    onLoadInIt();
    getOccupations();
    getIncomeSlabs();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const handleStatusChange = (v) => {
    if (MaStatus == v) {
      SetMaStatus("");
    } else {
      SetMaStatus(v);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    console.log('nil', occupationId, 'here', incomeId, 'hello', form.checkValidity())
    if (form.checkValidity() === true) {
      if (
        occupationId != "" &&
        incomeId != "" &&
        occupationId != "0" &&
        incomeId != "0" &&
        MaStatus != ""
      ) {
        ApiCall();
      } else {
        if (!occupationId && !incomeId && !MaStatus) {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Please select Marital Status,Occupation & Income Slab",
              type: "error",
            },
          });
        } else if (incomeId == "" || incomeId == "0") {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Please select Income Slab",
              type: "error",
            },
          });
        } else if (occupationId == "" || occupationId == "0") {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Please select Occupation",
              type: "error",
            },
          });
        } else if (MaStatus == "") {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Please select Marital Status",
              type: "error",
            },
          });
        }
      }
    }
    setValidated(true);
  };
  
  const ApiCall = async () => {
    let url = constClass.DMF_UPDATEBASICDETAILS_API_URL;
    if (typeof occupationId === "number" || typeof incomeId === "number") {
      occupationId = occupationId + "";
      incomeId = incomeId + "";
    }
    let data_sent = JSON.stringify({
      user_id: getUserId(),
      occupation: occupationId,
      income_slab_id: incomeId,
      marital_status: MaStatus
    });
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100") {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "User details updated successfully.",
          type: "success",
        },
      });
      setTimeout(() => {
        props.onNext();
      }, 3500);
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: response_obj["message"], type: "error" },
      });
    }
  };

  return (
    <>
      <ToastContainer limit={2} />
      <Row className="reverse">
        <div className="col-12 col-md-6 ProfileImg">
          <div>
            <img src={Profile_1} alt="ProfileImg" />
          </div>
        </div>
        <div className="col-12 col-md-6 RightPanel">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div>
              {showBack == true && (
                <FintooProfileBack
                  title=" "
                  onClick={() => props.onPrevious()}
                />
              )}
              <div className="fintoo-bottom-border pb-4 ">
                <h4>Marital Status</h4>
              <p>Select your current relationship status.</p>
              <Row style={{ width: "100%" }}>
                <Col className=" p-2">
                  <div className="fi-list">
                    {statusList.map((v) => (
                      <>
                        <div className="fi-list-item">
                          <FintooCheckbox
                            checked={MaStatus == v}
                            title={v}
                            onChange={() => handleStatusChange(v)}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </Col>
              </Row>
              </div>
              <div className="fintoo-bottom-border pb-4 ">
                <h4>Occupation</h4>
                <p className="mb-4">
                  Please select your current occupation from the below options.
                </p>
                <Form.Select
                  aria-label="Default select example"
                  className="shadow-none"
                  style={{
                    borderRadius: "10px",
                    height: "3rem",
                    outline: "none",
                  }}
                  required
                  value={occupationId}
                  onChange={(event) => setOccupationId(event.target.value)}
                >
                  <option value="0">Select</option>
                  {occupation.map((v) => (
                    <option
                      selected={v.occupation_id == userDetails.occupation}
                      value={v.occupation_id}
                    >
                      {v.occupation_name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="VerifyDetails pt-4">
                <h4>Income</h4>
                <div className=" fintoo-bottom-border pb-4 ">
                  <p className="mb-4">
                    Please select your income slab as per your current ITR
                    filling.
                  </p>
                  <Form.Select
                    required
                    className="shadow-none"
                    aria-label="Default select example"
                    style={{
                      borderRadius: "10px",
                      outline: "none",
                      height: "3rem",
                    }}
                    onChange={(event) => setIncomeId(event.target.value)}
                  >
                    <option value="0">Select</option>
                    {incSlabs.map((w) => (
                      <option
                        selected={
                          w.income_slab_id == userDetails.income_slab_id
                        }
                        value={w.income_slab_id}
                      >
                        {w.income_slab_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              <div className="pt-4">
                <FintooButton
                  className="d-block me-0 ms-auto"
                  type="submit"
                  title="Next"
                />
                {/* onClick={() => props.onNext()} */}
              </div>
            </div>
          </Form>
        </div>
      </Row>
    </>
  );
}

export default Occupation;
