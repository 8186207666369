import React, { useEffect, useRef, useState } from "react";
import Styles from "../report.module.css";

import { Link } from "react-router-dom";
import Select from "react-select";

import PortfolioLayout from "../../../../components/Layout/Portfolio";
import SimpleReactValidator from "simple-react-validator";
import AllCategoriesView from "./AssetCategoriesDetailsViews/AllCategoriesView";
import MutualFundReportView from "./AssetCategoriesDetailsViews/MutualFundReportView";
import StocksHoldingsReportView from "./AssetCategoriesDetailsViews/StocksHoldingsReportView";
import FixedDepositBondsReportView from "./AssetCategoriesDetailsViews/FixedDepositBondsReportView";
import AlternateReportView from "./AssetCategoriesDetailsViews/AlternateReportView";

const reportTypeOptions = [
    { value: "", label: "Select" },
    { value: "Summary", label: "Summary Report" },
    { value: "Detailed", label: "Detail Report" },
];

const AssetCategoryOptions = [
    { value: "All", label: "All" },
    { value: "MutualFund", label: "Mutual Fund" },
    { value: "StocksHoldings", label: "Stocks Holdings" },
    { value: "FixedDepositBonds", label: "Fixed Deposit | Bonds" },
    { value: "Alternate", label: "Alternate" },
];

const initialValues = {
    reportType: "",
    assetCategory: "All",
};

function PortfolioHoldingsReportDetails() {

    const [, forceUpdate] = useState();
    const [formData, setFormData] = useState(initialValues);
    const [currentReportView, setCurrentReportView] = useState(null);

    const simpleValidator = useRef(new SimpleReactValidator());

    const onDateAndSelectInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        if (simpleValidator.current.allValid() == true) {
            setCurrentReportView(formData.assetCategory)
            return true;
        }
        return false;
    };

    return (
        <PortfolioLayout>
            <div className={`${Styles.ReportDetailSection}`}>
                <div>
                    <Link
                        className="text-decoration-none"
                        to={`${process.env.PUBLIC_URL}/commondashboard/Report`}
                    >
                        <img
                            className="pointer"
                            src={
                                process.env.REACT_APP_STATIC_URL + "media/DMF/left-arrow.svg"
                            }
                            width={25}
                        />
                    </Link>
                </div>
                <div className={`row ${Styles.PortfolioReportSection}`}>
                    <div className="col-12  mt-md-5 mt-4">
                        <div className={`pb-2 ${Styles.insideTabBoxd}`}>
                            <div className="d-flex align-items-center">
                                <div>
                                    <img
                                        src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "media/DMF/Report/01_capital_gains_Loss_report.svg"
                                        }
                                        width={50}
                                    />
                                </div>
                                <div className={`pt-3  ${Styles.ReportName}`}>
                                    Portfolio Holdings Report
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div className={`${Styles.ReportDetailsOptionsContainer}`}>
                        <div >
                            <div className={`${Styles.ReportLabel}`}>Type of Report</div>
                            <div className="mt-2">
                                <Select
                                    className="box-shadow-none border-0"
                                    classNamePrefix="ReportSelect"
                                    isSearchable={false}
                                    placeholder="Select.."
                                    options={reportTypeOptions}
                                    value={reportTypeOptions.filter((v) => v.value === formData.reportType)[0] ?? ""}
                                    onChange={(e) => {
                                        onDateAndSelectInputChange("reportType", e.value);
                                    }}
                                />
                            </div>
                            {simpleValidator.current.message(
                                "reportType",
                                formData.reportType,
                                "required",
                                { messages: { required: 'This field is required' } }
                            )}
                        </div>
                        <div >
                            <div className={`${Styles.ReportLabel}`}>Asset Category</div>
                            <div className="mt-2">
                                <Select
                                    className="box-shadow-none border-0"
                                    classNamePrefix="ReportSelect"
                                    isSearchable={false}
                                    options={AssetCategoryOptions}
                                    placeholder="Select.."
                                    value={AssetCategoryOptions.filter((v) => v.value === formData.assetCategory)[0] ?? "All"}
                                    onChange={(e) => {
                                        onDateAndSelectInputChange("assetCategory", e.value);
                                    }}
                                />
                            </div>
                            {simpleValidator.current.message(
                                "assetCategory",
                                formData.assetCategory,
                                "required"
                            )}
                        </div>
                        <div>
                            <div className={`${Styles.ReportGenOption}`}>
                                <div
                                    className="d-md-block d-none"
                                    style={{
                                        height: "2.3rem",
                                    }}
                                ></div>
                                <button
                                    onClick={() => validateForm()}
                                    className={`${Styles.ReportButton}`}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${Styles.categoriesDataContainer}`}>
                    {
                        currentReportView === 'All' && <AllCategoriesView selectedCategoryName={AssetCategoryOptions.filter((v) => v.value === currentReportView)[0]?.label ?? ""} />

                    }
                    {
                        currentReportView === 'MutualFund' && <MutualFundReportView selectedCategoryName={AssetCategoryOptions.filter((v) => v.value === currentReportView)[0]?.label ?? ""} />

                    }
                    {
                        currentReportView === 'StocksHoldings' && <StocksHoldingsReportView selectedCategoryName={AssetCategoryOptions.filter((v) => v.value === currentReportView)[0]?.label ?? ""} />

                    }
                    {
                        currentReportView === 'FixedDepositBonds' && <FixedDepositBondsReportView selectedCategoryName={AssetCategoryOptions.filter((v) => v.value === currentReportView)[0]?.label ?? ""} />

                    }
                    {
                        currentReportView === 'Alternate' && <AlternateReportView selectedCategoryName={AssetCategoryOptions.filter((v) => v.value === currentReportView)[0]?.label ?? ""} />

                    }
                </div>
            </div>
        </PortfolioLayout>
    );
}

export default PortfolioHoldingsReportDetails;
