import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className={`${styles["section-sub-title"]}`}>
        Don’t Just SAVE Money, <br />
        NURTURE Wealth.
        {/* Guiding You To Invest In A Financially Independent & Stress Free Future. */}
      </h3>
    </>
  );
};
export default LeftSection;
