import React, { useEffect, useRef, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import pmc from "./commonDashboard.module.css";
import FintooLogo from "../../../Assets/Images/F_logo.png";
import Advisory from "../../../Assets/Images/CommonDashboard/01_advisory.svg";
import PlanofAction from "../../../Assets/Images/CommonDashboard/02_plan_of_action.svg";
import Dashboard from "../../../Assets/Images/CommonDashboard/Dashboard.png";
// import Transaction from "../../../Assets/Images/CommonDashboard/04_transactions.png";
import Investment from "../../../Assets/Images/CommonDashboard/02_investment.png";
import PersonalTax from "../../../Assets/Images/CommonDashboard/03_personal_tax.svg";
import Transaction from "../../../Assets/Images/CommonDashboard/04_transactions.svg";
import Man from "../../../Assets/Images/CommonDashboard/profile_side.png";
import Profile from "../../../Assets/Images/CommonDashboard/profile-picture.svg";
import Menu from "../../../Assets/Images/CommonDashboard/menu.png";
import Call from "../../../Assets/Images/CommonDashboard/phonecall.png";
import Mail from "../../../Assets/Images/CommonDashboard/Mail.png";
import Expiry from "../../../Assets/Images/CommonDashboard/evaluation.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  ADVISORY_GET_RM_DETAILS_API_URL,
  ADVISORY_RENEWPAYMENT_API_URL,
  BASE_API_URL,
  CHECK_SESSION,
  STATIC_URL,
  imagePath,
} from "../../../constants";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  getUserId,
  removeSlash,
} from "../../../common_utilities";
import commonEncode from "../../../commonEncode";
import Calendly from "../../CommonDashboard/Calendly";
import { useNavigate } from "react-router-dom";

import { HiOutlineMail } from "react-icons/hi";
import Fintootour from "../../../Pages/Fintootour";
import RenewPopupTextbox from "../../CommonDashboard/RenewPopupTextbox";
import KYCTextbox from "../../CommonDashboard/KYCTextbox";
import { Modal } from "react-bootstrap";
import RenewPopup from "../../CommonDashboard/RenewPopup";
const CommonDSidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { showPage } = props;

  // const url = window.location.pathname.split("/").pop();
  const mobileNavRef = useRef(null);
  const [rmdetails, setRmDetails] = useState({});
  const [calendlydata, setCalendlyData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [planExpiryDate, setPlanExpiryDate] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleTrigger = () => setIsOpen(!isOpen);
  const [currentLocation, setCurrentLocation] = useState("");

  const [plantype, setPlanType] = useState(0);
  const [dashboardpage, setDashboardPage] = useState(-1);
  const [email, setEmail] = useState('');
  const emailContainerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [renewpopup, setRenewPopup] = useState(0);
  const onOpenModal = () => {
    console.log("open", open)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);
  const [dgShow, setDGShow] = useState(false)
  const [fpLifecycleStatus, setFpLifecycleStatus] = useState(null);
  const [subscriptionenddate, setSubscriptionEndDate] = useState("");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [url]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    getrmdetails();
    getSessiondata();
  }, []);

  const userid = getParentUserId();
  const getrmdetails = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      if (session_data["data"]["user_details"]) {
        let calendly_detail = session_data["data"]["user_details"];
        setCalendlyData({
          name: calendly_detail["name"],
          email: calendly_detail["email"],
          mobile: calendly_detail["mobile"],
        });
      } else {
        let calendly_detail = session_data["data"];
        setCalendlyData({
          name: calendly_detail["first_name"],
          email: calendly_detail["email"],
          mobile: calendly_detail["mobile"],
        });
      }
      let rmId = "96";
      if ("rm_id" in session_data["data"]) {
        rmId = session_data["data"]["rm_id"];
      }
      let api_data = {
        user_id: userid,
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var rm_data = await apiCall(
        ADVISORY_GET_RM_DETAILS_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(rm_data));
      if (res.error_code == "100" && res.data != "") {
        var rm_details = res.data[0];
        if ("fp_plan_type" in session_data["data"] && session_data["data"]["fp_plan_type"] == 6) {
          rm_details.id = 96
          rm_details.imagePath = ""
          rm_details.emp_name = "Online"
          rm_details.username = "support@fintoo.in"
          rm_details.emp_mobile = "9699800600"
        }
        setRmDetails(rm_details);
      } else {
        setRmDetails({});
      }
    } catch { }
  };


  const getOngoingPlanDates = async () => {
    try {
      var response = await apiCall(
        BASE_API_URL +
        "restapi/userflow/getongoingplandates/?user_id=" +
        btoa(getParentUserId()).toString(),
        "",
        false,
        false
      );
      try {
        if (response["error_code"] == "100") {
          let paymentDate = response["data"]?.[0]?.["payment_datetime"]
            ? response["data"][0]["payment_datetime"]
            : "";
          let paymentStatus = parseInt(
            response["data"][0]["plan_payment_status"]
          );
          if (paymentDate && [1, 6, 7].includes(paymentStatus)) {
            setPaymentCompleted(true);
            paymentDate = new Date(paymentDate);
            // paymentDate.setMonth(paymentDate.getMonth() + 6);
            var suffixes = ["th", "st", "nd", "rd"];
            var day = paymentDate.getDate();
            var month = paymentDate.toLocaleString("default", { month: "long" });
            var year = paymentDate.getFullYear();
            var daySuffix = day % 10 === 1 && day !== 11 ? "st" : (day % 10 === 2 && day !== 12 ? "nd" : (day % 10 === 3 && day !== 13 ? "rd" : "th"));
            var formattedDate = day + daySuffix + " " + month + ", " + year;
            setPlanExpiryDate(formattedDate);
          }
        }
      } catch (err) {
        console.log(err);
      }
    } catch { }
  };
  const [currentUrl, setCurrentUrl] = useState("");
  React.useEffect(() => {
    setCurrentUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    if (document.querySelector(".plan-of-action-cls") == null) return;
    if (dashboardpage > 1 && plantype == 6) {

      document.querySelectorAll(".plan-of-action-cls").forEach((v) => {
        v.style.display = 'block';
      });
    } else {

      document.querySelectorAll(".plan-of-action-cls").forEach((v) => {
        v.style.display = 'none';
      });
    }
  }, [dashboardpage, plantype]);

  const getSessiondata = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, true);
      if ((session_data.error_code = "100" && session_data.data != "")) {
        // if (
        //   session_data.data.fp_lifecycle_status == "" ||
        //   !session_data.data.fp_lifecycle_status
        // ) {
        //   var lifecycledata = 0;
        // } else {
        //   var lifecycledata = session_data.data.fp_lifecycle_status;
        // }
        let lifecycledata = session_data.data.fp_lifecycle_status || 0;
        setFpLifecycleStatus(lifecycledata);
        // setPlanSubCat(session_data.data.fp_plan_sub_cat);
        setDashboardPage(lifecycledata);
        setPlanType(session_data.data.fp_plan_type);
        let api_data = {
          fp_log_id: session_data["data"]["fp_log_id"],
          user_id: session_data["data"]["id"],
        };
        var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
        var renew_data = await apiCall(
          ADVISORY_RENEWPAYMENT_API_URL,
          payload_data,
          false,
          false
        );
        var res = JSON.parse(commonEncode.decrypt(renew_data));
        if ((res.error_code = "100" && res.data != "")) {
          setRenewPopup(res.data["show_popup"]);
          setSubscriptionEndDate(res.data["subscription_end_date"]);
        } else {
          setRenewPopup(0);
          setSubscriptionEndDate("");
        }
        getOngoingPlanDates();

      } else {
        setDashboardPage(0);
        setRenewPopup(0);
        setPlanType(0);
        setPlanSubCat(0);
        setSubscriptionEndDate("");
      }
    } catch (e) {
      // console.log("object1",e);
    }
  };

  useEffect(() => {
    const emailContainer = emailContainerRef.current;
    if (emailContainer && email.length > 26) {
      emailContainer.classList.add('animateRMMail');
    } else if (emailContainer) {
      emailContainer.classList.remove('animateRMMail');
    }
  }, [email]);

  useEffect(() => {
    setEmail(rmdetails.username);
  }, [rmdetails.username]);

  return (
    <>
      {/* {renewpopup == 2 ? (
        <div className="RenewMsgbox">
          <RenewPopupTextbox showpopup={true} />
        </div>
      ) : (
        ""
      )} */}
      <div className={`${pmc.navbar} portfolio-navbar-sidebar`}>
        <div
          className={`${pmc.SidebarBox}`}
          ref={mobileNavRef}
        ></div>
        {/* for DG */}
        <div className={`${pmc.menuitem} `}>
          {/* <Link
            className={`text-decoration-none d-flex menu-link-182 ${
              pmc["aaa"]
            } ${
              currentUrl.toLowerCase().indexOf("dashboard") > -1 &&
              currentUrl.toLowerCase().split("/")[
                currentUrl.toLowerCase().split("/").length - 1
              ] == "dashboard"
                ? "active"
                : "Inactive"
            }`}
            to={`${process.env.PUBLIC_URL}/direct-mutual-fund/commondashboard/dashboard`}
          > */}
          <div
            onClick={() => {
              if (!(currentLocation == '/web/commondashboard' || currentLocation == '/web/commondashboard/')) {
                navigate(`${process.env.PUBLIC_URL}/commondashboard`);
              }
              dispatch({
                type: "CHANGE_COMMONDASHBOARD",
                payload: "dashboard",
              });
            }}
            className={`text-decoration-none d-flex menu-link-182 ${pmc["aaa"]
              } ${pmc.menulink} ${showPage === "dashboard" &&
                (currentLocation == '/web/commondashboard' || currentLocation == '/web/commondashboard/')
                ? pmc.activeMenu
                : "Inactive"
              }`}
          >
            <div>
              <img width={20} src={Advisory} />
            </div>
            <div className={pmc.menutext}>Dashboard</div>
          </div>

          {/* </Link> */}
        </div>

        {fpLifecycleStatus !== 0 && (
          <div className={` ${pmc.menuitem}`}>

            {renewpopup === 2 ? (<>
              <div
                className={({ isActive, isPending }) =>
                  "text-decoration-none pointer" +
                  (isPending
                    ? pmc.pendingMenu
                    : isActive
                      ? pmc.activeMenu + " sidebar-custom-color-activeMenu"
                      : pmc.InactiveMenu)
                }
                onClick={() => {
                  onOpenModal();
                }} 
              >
                <div
                  className={` text-decoration-none d-flex menu-link-182 `}
                >
                  <div>
                    <img width={20} alt="DataGathering"  src={process.env.REACT_APP_STATIC_URL + "media/DG/DataGathering.svg"} />
                  </div>
                  <div className={` ${pmc.menutext + " sidebar-custom-color"} `}>
                    Data Gathering
                  </div>
                </div>
              </div>
            </>) : <>
              <NavLink
                className={({ isActive, isPending }) =>
                  "text-decoration-none " +
                  (isPending
                    ? pmc.pendingMenu
                    : isActive
                      ? pmc.activeMenu + " sidebar-custom-color-activeMenu"
                      : pmc.InactiveMenu)
                }
                to={`${process.env.PUBLIC_URL}/datagathering/about-you`}
              >
                <div
                  className={` text-decoration-none d-flex menu-link-182 `}
                >
                  <div>
                    <img width={20} alt="DataGathering"  src={process.env.REACT_APP_STATIC_URL + "media/DG/DataGathering.svg"}/>
                  </div>
                  <div className={`${pmc.menutext + " sidebar-custom-color"} `}>
                    Data Gathering
                  </div>
                </div>
              </NavLink>
            </>
            }

          </div>
        )}
        <div id="" className={` ${pmc.menuitem} plan-of-action-cls`}>
          <div
            onClick={() => {
              // if (currentLocation.indexOf("/commondashboard") === -1) {
              navigate(`${process.env.PUBLIC_URL}/commondashboard`);
              // }
              dispatch({
                type: "CHANGE_COMMONDASHBOARD",
                payload: "planofaction",
              });
            }}
            className={` text-decoration-none d-flex menu-link-182  ${pmc.menulink} ${(currentLocation == '/web/commondashboard' || currentLocation == '/web/commondashboard/') && showPage === "planofaction" ? `${pmc.activeMenu}` : `${pmc.Inactive}`
              }`}
          >

            <div>
              <img width={20} src={PlanofAction} />
            </div>
            <div className={`${pmc.menutext} `}>Plan of Action</div>
          </div>
        </div>
        <div className={`PortfolioCoach ${pmc.menuitem} `}>
          <NavLink
            className={({ isActive, isPending }) =>
              "text-decoration-none " +
              (isActive ? `${pmc.activeMenu}` : `${pmc.Inactive}`)
            }
            to={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard/`}
          >
            <div
              // onClick={() => {
              //   dispatch({
              //     type: "CHANGE_COMMONDASHBOARD",
              //     payload: "dashboard",
              //   });
              // }}
              className={` text-decoration-none d-flex menu-link-182 `}
            >
              <div>
                <img width={20} src={PersonalTax} />
              </div>
              <div className={`${pmc.menutext} `}>Portfolio </div>
            </div>
          </NavLink>
        </div>
        <div className={`ReportItem ${pmc.menuitem}`}>
          <NavLink
            className={`text-decoration-none d-flex menu-link-182 ${pmc.menulink
              } ${showPage === "dashboard" &&
                (removeSlash(currentLocation) == "/web/commondashboard/Report" ||
                  removeSlash(currentLocation) == "/web/commondashboard/Report-details")
                ? `${pmc.activeMenu + " sidebar-custom-color-activeMenu"}`
                : `${pmc.Inactive}`
              }`}
            to={`${process.env.PUBLIC_URL}/commondashboard/Report`}
          >
            <div
              // onClick={() => {
              //   dispatch({
              //     type: "CHANGE_COMMONDASHBOARD",
              //     payload: "dashboard",
              //   });
              // }}
              className={` text-decoration-none d-flex menu-link-182 `}
            >
              <div>
                <img
                  src={Transaction}
                  width={20}
                />
              </div>
              <div
                style={{
                  paddingTop: ".1rem",
                }}
                className={`${pmc.menutext + " sidebar-custom-color"} `}
              >
                Report{" "}
              </div>
            </div>
          </NavLink>
        </div>
        <div className={`${pmc.menuitem}`}>
          <NavLink
            className={({ isActive, isPending }) =>
              "text-decoration-none " +
              (isPending ? pmc.pendingMenu : isActive ? pmc.activeMenu : pmc.InactiveMenu)
            }
            to={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard/transaction`}
          >
            <div
              // onClick={() => {
              //   dispatch({
              //     type: "CHANGE_COMMONDASHBOARD",
              //     payload: "dashboard",
              //   });
              // }}
              className={` text-decoration-none d-flex menu-link-182 `}
            >
              <div>
                <img width={20} src={Transaction} />
              </div>
              <div className={`${pmc.menutext} `}>Transaction </div>
            </div>
          </NavLink>
        </div>

        {/* <div className={`${pmc.menuitem} `}>
            <NavLink
              className={({ isActive}) =>
                "text-decoration-none " +
                (isActive ? `${pmc.activeMenu}` : `${pmc.Inactive}`)
              }
              to={`${process.env.PUBLIC_URL}/commondashboard/investment`}
            >
              <div
                // onClick={() => {
                //   dispatch({
                //     type: "CHANGE_COMMONDASHBOARD",
                //     payload: "dashboard",
                //   });
                // }}
                className={` text-decoration-none d-flex menu-link-182 `}
              >
                <div>
                  <img width={20} src={Investment} />
                </div>
                <div className={`${pmc.menutext} `}>Investment </div>
              </div>
            </NavLink>
          </div> */}

        <hr className="mt-md-3 ms-3 d-none d-md-block" />
        <div className={`d-none d-md-block mt-4 mb-4 ${pmc.RmBox}`}>
          {paymentCompleted && planExpiryDate && (
            <>
              <div>
                <span>
                  {" "}
                  <img
                    src={Expiry}

                    width={20}
                  />{" "}
                </span>
                <span className={pmc.MailTxt}>Plan Expiry Date</span>
              </div>
              <div className={pmc.Rminfo} style={{ width: "100%" }}>{planExpiryDate}</div>

            </>
          )}
        </div>
        {paymentCompleted && planExpiryDate && (
          <hr className="mt-md-3 ms-3 d-none d-md-block" />
        )}
        {rmdetails && Object.keys(rmdetails).length != 0 ? (
          <div
            style={
              {
                // display : "none"
              }
            }
            className={`${pmc.RmBox} d-none d-md-block`}
          >
            <div className={pmc.RmImg}>
              {rmdetails.imagepath != "" ? (
                <img src={process.env.REACT_APP_STATIC_URL_PYTHON + rmdetails.imagepath} />
              ) : (
                <img src={Profile} />
              )}
            </div>
            <div
              style={{
                lineHeight: "16px",
              }}
            >
              <div className={pmc.RmProfile}>
                {plantype == 6 ? (
                  ""
                ) : (
                  <>
                    {plantype == 8 && rmdetails.id !== 96
                      ? "Wealth Manager"
                      : ""}
                  </>
                )}
              </div>
              <div className={pmc.RmName}>
                {rmdetails.id !== 96 ? rmdetails.emp_name : "Online"}
              </div>
            </div>
            <hr width={100} className="mt-2 mb-2" />
            <div
              className="mb-2"
              style={{
                lineHeight: "16px",
              }}
            >
              <div>
                <span>
                  {" "}
                  <img
                    src={Mail}
                    style={{
                      opacity: ".4",
                    }}
                    width={14}
                  />{" "}
                </span>
                <span className={pmc.MailTxt}>Email</span>
              </div>
              <div ref={emailContainerRef} className={pmc.Rminfo}>{rmdetails.username}</div>
            </div>
            <div
              style={{
                lineHeight: "16px",
              }}
            >
              <div>
                <span>
                  {" "}
                  <img
                    src={Call}
                    style={{
                      opacity: ".4",
                    }}
                    width={14}
                  />{" "}
                </span>
                <span className={pmc.MailTxt}>Mobile No.</span>
              </div>
              <div className={pmc.Rminfo}>{rmdetails.emp_mobile}</div>
            </div>
            <div className={pmc.bookDemo}>
              <button onClick={() => setShow(true)}>Book appointment</button>
            </div>
          </div>
        ) : (
          <></>
        )}

      </div>

      <Calendly
        show={show}
        handleClose={() => setShow(false)}
        calendlydata={calendlydata}
        rmdetails={rmdetails}
      />

      <Modal
        className="Modalpopup"
        open={open}
        showCloseIcon={false}
        onClose={onCloseModal}
        center
      >
        <div className="text-center">
          <h2 className="HeaderText">Attention !!</h2>
          <RenewPopup
            open={open}
            onClose={onCloseModal}
            subscriptionenddate={subscriptionenddate}
          />
        </div>
      </Modal>

    </>
  );
};

export default CommonDSidebar;
