import React, { useState, useRef, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import FintooRadio2 from "../../../components/FintooRadio2";
import Switch from "react-switch";
import moment from "moment";
import Slider from "../../../components/HTML/Slider";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import SimpleReactValidator from "simple-react-validator";
import GoalsDropdown from "../../../components/GoalsDropdown/GoalDropdown";
import customStyles from "../../../components/CustomStyles";

function Alternate(props) {
  const setAssetsDetails = props.setAssetsDetails;
  const assetsDetails = props.assetsDetails;
  const familyData = props.familyData;
  const goalData = props.goalData;
  const unchangedgoaldata = props.unchangedgoaldata;
  const setDate = props.setDate;
  const cryptodata = props.cryptodata;
  const session = props.session;
  const addForm = props.addForm;
  const updateForm = props.updateForm;
  const addAssetsSubmit = props.addAssetsSubmit;
  const cancelAssetForm = props.cancelAssetForm;
  const updateAssetsSubmit = props.updateAssetsSubmit;
  const setGoalSelected = props.setGoalSelected;
  const closeModal = props.closeModal;
  const selectGoals = props.selectGoals;
  const selectedGoals = props.selectedGoals;
  const selectedGoalIdArray = props.selectedGoalIdArray;
  const selectedGoalsId = props.selectedGoalsId;
  const setPriorityArray = props.setPriorityArray;
  const selectedPriorityArray = props.selectedPriorityArray;
  const setAutoMatedGoal = props.setAutoMatedGoal;
  const isAutoMatedGoal = props.isAutoMatedGoal;
  const setGoalLink = props.setGoalLink;
  const isGoalSelected = props.isGoalSelected;
  const setSelectedGoals = props.setSelectedGoals;
  const setSelectedGoalsId = props.setSelectedGoalsId;
  const setSelectedPriorityArray = props.setSelectedPriorityArray;
  const assetEditId = props.assetEditId;

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [, setForceUpdate] = useState(0);

  const handleAlternateSubmit = async (e) => {
    e.preventDefault();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (assetsDetails.asset_isrecurring) {
      // Handle SIP form validation
      const isSipFormValid =
        simpleValidator.current.fieldValid("SIP Amount") &&
        simpleValidator.current.fieldValid("Total Invested Amount (₹)*") &&
        simpleValidator.current.fieldValid("No. Of Coins*") &&
        simpleValidator.current.fieldValid("Current Price (₹)*");

      if (isSipFormValid) {
        addAssetsSubmit(e);
        setGoalSelected(false);
        setSelectedGoals("Automated Linkage");
        setSelectedGoalsId(false);
        setSelectedPriorityArray([]);
        setAutoMatedGoal(true);

        simpleValidator.current.hideMessages();
        setForceUpdate((v) => ++v);
      }
    } else {
      // Handle regular asset form validation
      const isRegularFormValid =
        (simpleValidator.current.fieldValid("No. Of Units*") &&
          simpleValidator.current.fieldValid("Avg. Purchase Price (₹)*") &&
          simpleValidator.current.fieldValid("Current Price (₹)*")) ||
        simpleValidator.current.fieldValid("Current Market Price*");
      if (isRegularFormValid) {
        // Your logic to submit regular asset data here
        addAssetsSubmit(e);
        setGoalSelected(false);
        setSelectedGoals("Automated Linkage");
        setSelectedGoalsId(false);
        setSelectedPriorityArray([]);
        setAutoMatedGoal(true);
        simpleValidator.current.hideMessages();
        setForceUpdate((v) => ++v);
      }
    }
  };

  const handleAlternateUpdate = async (e) => {
    e.preventDefault();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (assetsDetails.asset_isrecurring) {
      // Handle SIP form validation
      const isSipFormValid =
        simpleValidator.current.fieldValid("SIP Amount") &&
        simpleValidator.current.fieldValid("Total Invested Amount (₹)*") &&
        simpleValidator.current.fieldValid("No. Of Coins*") &&
        simpleValidator.current.fieldValid("Current Price (₹)*");

      if (isSipFormValid) {
        updateAssetsSubmit(e);
        simpleValidator.current.hideMessages();
        setForceUpdate((v) => ++v);
      }
    } else {
      // Handle regular asset form validation
      const isRegularFormValid =
        (simpleValidator.current.fieldValid("No. Of Units*") &&
          simpleValidator.current.fieldValid("Avg. Purchase Price (₹)*") &&
          simpleValidator.current.fieldValid("Current Price (₹)*")) ||
        simpleValidator.current.fieldValid("Current Market Price*");
      if (isRegularFormValid) {
        // Your logic to submit regular asset data here
        updateAssetsSubmit(e);
        simpleValidator.current.hideMessages();
        setForceUpdate((v) => ++v);
      }
    }
  };

  const handleAlternateCancel = async (e) => {
    e.preventDefault();
    cancelAssetForm(e);
    simpleValidator.current.hideMessages();
    setForceUpdate((v) => ++v);
  };

  useEffect(() => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.purgeFields();

    if (assetEditId) {
      if (selectedGoals == "Automated Linkage") {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      } else {
        setAutoMatedGoal(false);
      }
    } else {
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }

    if (session && !assetEditId) {
      setGoalSelected(false);
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      props.getfpgoalsdata(session.data.fp_log_id);
    }
    setForceUpdate((v) => ++v);
  }, [assetsDetails?.asset_sub_category_id, assetsDetails?.asset_isrecurring]);

  const Input = (props) => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  return (
    <div>
      {(assetsDetails.asset_sub_category_id == 64 ||
        assetsDetails.asset_sub_category_id == 66 ||
        assetsDetails.asset_sub_category_id == 67) && (
          <form noValidate="novalidate" name="goldassetform" className="mt-2">
            <>
              <div className="row d-flex align-items-center">
                <div className="col-md-5  custom-input">
                  <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{ paddingTop: '19px' }}>
                    <input
                      type="text"
                      name="asset_name"
                      maxLength={35}
                      value={assetsDetails.asset_name}
                      onChange={(e) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_name: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        simpleValidator.current.showMessageFor("Asset Name");
                        forceUpdate(1);
                      }}
                      required
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Name of Asset*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Asset Name",
                    assetsDetails.asset_name,
                    "required|min:3|max:60",
                    {
                      messages: {
                        required: "Please enter asset name",
                        max: "Asset name must be between 3-35 characters.",
                        min: "Asset name must be between 3-35 characters.",
                      },
                    }
                  )}
                </div>
                <div className="col-md-5 pt-2 pt-md-1">
                  <div className="material mt-md-0 mt-3">
                    <Form.Label>Who is This Investment For?*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_member_id: e.value,
                          })
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center">
                {assetsDetails.asset_sub_category_id == 64 && (
                  <div className="col-md-5 mt-md-1 mt-4">
                    <div className="material">
                      <Form.Label className="mb-0">Date of Purchase</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          paddingTop: "0px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_purchase_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          setDate={(date) => {
                            setDate(date, "assetPurchaseDate");
                          }}
                          minDate={""}
                          maxDate={moment().toDate()}
                          className="pt-4"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="col-md-5  custom-input"
                  style={{
                    paddingTop:
                      assetsDetails.asset_sub_category_id == 64 ? "14px" : " ",
                  }}
                >
                  <div
                    className={`form-group mt-md-1 mt-3 ${assetsDetails.asset_amount ? "inputData" : null
                      } `}
                  >
                    <input
                      type="Number"
                      name="asset_amount"
                      className="shadow-none "
                      value={assetsDetails.asset_amount}
                      onChange={(e) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                        });
                      }}
                      required
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Current Market Price*</label>
                  </div>
                  <div
                    className="w-100"
                  // style={{ position: "absolute", top: assetsDetails.asset_sub_category_id == 64 ? "15px" : "4px" }}
                  >
                    {simpleValidator.current.message(
                      "Current Market Price*",
                      assetsDetails.asset_amount,
                      "required|numeric|min:1,num",
                      {
                        messages: {
                          required: "Please enter asset amount",
                          min: "Please enter valid asset amount",
                        },
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="row py-md-2">
                <div className="col-md-8">
                  <div className="d-md-flex mt-md-3 mt-4">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span
                      className="ms-md-4 info-hover-left-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex  ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_islinkable: v,
                          })
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-2 ">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>
                        <span
                          className="ms-md-4 ms-2 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-md-0 mt-md-2">
                      <div className="material">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div
                            className="d-flex"
                            style={{ textAlign: "left!important" }}
                          >
                            <div>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            type={"Asset"}
                            asset_maturity_date={assetsDetails?.asset_mf_end_date}
                            isGoalFilter={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                            isAssetRecurring={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleAlternateSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleAlternateCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleAlternateUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}
                        {/* <Link
                          to={process.env.PUBLIC_URL + "/datagathering/insurance"}
                        > */}
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </form>
        )}
      {(assetsDetails.asset_sub_category_id == 36 ||
        assetsDetails.asset_sub_category_id == 119 ||
        assetsDetails.asset_sub_category_id == 37) && (
          <>
            <form noValidate="novalidate" name="goldassetform" className={assetsDetails.asset_sub_category_id == 119 ? "mt-4" : "mt-2"}>
              <div className="row d-flex align-items-center">
                {assetsDetails.asset_sub_category_id == 119 ? (
                  <div className="col-md-5 pt-1">
                    <div className="material ">
                      <Form.Label>
                        {" "}
                        Start Typing To Search For Your Crypto*{" "}
                      </Form.Label>
                      {props.cryptodata && props.cryptodata.length > 0 && (
                        <Select
                          classNamePrefix="sortSelect"
                          isSearchable={true}
                          styles={customStyles}
                          options={cryptodata}
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_name: v.label,
                              asset_current_unit_price: v.value,
                            })
                          }
                          value={cryptodata.filter(
                            (v) => v.label == assetsDetails.asset_name
                          )}
                        />
                      )}
                    </div>
                    {simpleValidator.current.message(
                      "Cryptocurrency",
                      assetsDetails.asset_name
                        .replace("Cryptocurrency", "")
                        .trim(),
                      "required",
                      {
                        messages: {
                          required: "Please select a cryptocurrency",
                        },
                      }
                    )}
                  </div>
                ) : (
                  <div className="col-md-5 custom-input">
                    <div
                      className={`form-group ${assetsDetails.asset_name ? "inputData" : null
                        } `}
                      style={{ paddingTop: "19px" }}
                    >
                      <input
                        type="text"
                        name="asset_name_2"
                        maxLength={35}
                        value={assetsDetails.asset_name}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_name: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          simpleValidator.current.showMessageFor("Asset Name");
                          forceUpdate(1);
                        }}
                        required
                        autoComplete="off"
                      />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Name of Asset*</label>
                    </div>

                    {simpleValidator.current.message(
                      "Asset Name",
                      assetsDetails.asset_name,
                      "required|min:3|max:60",
                      {
                        messages: {
                          required: "Please enter asset name",
                          max: "Asset name must be between 3-35 characters.",
                          min: "Asset name must be between 3-35 characters.",
                        },
                      }
                    )}
                  </div>
                )}

                <div
                  className={`col-md-5 ${assetsDetails.asset_sub_category_id == 119
                    ? "mt-md-2"
                    : "mt-md-0"
                    }`}
                  style={{ paddingTop: assetsDetails.asset_sub_category_id === 119 ? "0px" : window.innerWidth > 1024 ? "0px" : window.innerWidth > 600 ? "15px" : "10px", }}
                >
                  <div className="material mt-md-0 mt-3" >
                    <Form.Label>Who is This Investment For?*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={customStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_member_id: e.value,
                          })
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              {assetsDetails.asset_sub_category_id != 119 && (
                <div className="row py-md-2 mt-md-0 mt-3">
                  <div className="col-md-8">
                    <div className="d-flex">
                      <Form.Label className=" ">
                        One Time Or Recurring*
                      </Form.Label>
                      <div className="d-flex ms-md-4">
                        <div>One Time</div>
                        <Switch
                          onChange={(v) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_isrecurring: v,
                              asset_purchase_amount: " ",
                            });
                          }}
                          checked={assetsDetails.asset_isrecurring}
                          className="react-switch px-2"
                          activeBoxShadow="0 0 2px 3px #424242"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                          onColor="#042b62"
                          offColor="#d8dae5"
                        />
                        <div>Recurring</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {assetsDetails.asset_isrecurring == true && (
                <>
                  <div className="row d-flex mt-md-0 mt-3 align-items-center py-md-2">
                    <div className="col-md-5 ">
                      <div className="material">
                        <Form.Label>SIP Start Date</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            paddingTop: "0px",
                          }}
                        >
                          <ReactDatePicker
                            select_date={moment(
                              assetsDetails.asset_purchase_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            setDate={(date) => {
                              setDate(date, "assetPurchaseDate");
                            }}
                            minDate={""}
                            maxDate={moment().toDate()}
                            className="pt-4"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 custom-input">
                      <div
                        className={`form-group mt-1 ${assetsDetails.asset_amount ? "inputData" : null
                          } `}
                        style={{ paddingTop: '11px' }}
                      >
                        <input
                          type="text"
                          name="asset_amount"
                          className="shadow-none"
                          value={assetsDetails.asset_amount}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_amount: e.target.value.slice(0, 12),
                            });
                          }}
                          required
                          autoComplete="off"
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">SIP Amount (₹)*</label>
                      </div>
                      {simpleValidator.current.message(
                        "SIP Amount",
                        assetsDetails.asset_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add SIP Amount ",
                            min: "Please enter valid SIP Amount ",
                          },
                        }
                      )}
                    </div>
                  </div>
                  <div className="row py-md-2">
                    <div className="col-md-5 custom-input">
                      <div
                        className={`form-group mt-1 ${assetsDetails.asset_purchase_amount ? "inputData" : null
                          } `}
                      >
                        <input
                          type="number"
                          name="asset_isrecurring"
                          value={
                            // If asset is recurring, set it to an empty string
                            assetsDetails.asset_purchase_amount // Otherwise, use the regular value
                          }
                          onChange={(e) => {
                            if (assetsDetails.asset_isrecurring) {
                              // Allow input if asset is not recurring
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }
                          }}
                          required
                          autoComplete="off"
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Total Invested Amount (₹)*</label>
                      </div>
                      {simpleValidator.current.message(
                        "Total Invested Amount (₹)*",
                        assetsDetails.asset_purchase_amount,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add Invested amount ",
                            min: "Please enter valid Invested amount ",
                          },
                        }
                      )}
                    </div>
                    <div className="col-md-5 custom-input">
                      <div
                        className={`form-group mt-1 ${assetsDetails.asset_units ? "inputData" : null
                          } `}
                      >
                        <input
                          type="Number"
                          name="asset_units"
                          value={assetsDetails.asset_units}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }}
                          required
                          autoComplete="off"
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">No. Of Coins*</label>
                      </div>
                      <div
                        className="w-100"
                      // style={{ position: "absolute", bottom: "0px" }}
                      >
                        {simpleValidator.current.message(
                          "No. Of Coins*",
                          assetsDetails.asset_units,
                          "required|numeric|min:1,num",
                          {
                            messages: {
                              required: "Please add coins ",
                              min: "Please enter valid coins ",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row py-md-2">
                    <div className="col-md-5 custom-input">
                      <div
                        className={`form-group mt-1 ${assetsDetails.asset_current_unit_price
                          ? "inputData"
                          : null
                          } `}
                      >
                        <input
                          type="Number"
                          name="asset_current_unit_price"
                          value={assetsDetails.asset_current_unit_price}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }}
                          required
                          autoComplete="off"
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="name">Current Price (₹)*</label>
                      </div>
                      {simpleValidator.current.message(
                        "Current Price (₹)*",
                        assetsDetails.asset_current_unit_price,
                        "required|numeric|min:1,num",
                        {
                          messages: {
                            required: "Please add current price ",
                            min: "Please enter valid current price ",
                          },
                        }
                      )}
                    </div>
                    <div className="col-md-5 custom-input ">
                      <div
                        className={`form-group mt-1 ${assetsDetails.totalinvestedvalue
                          ? "inputData"
                          : null
                          } `}
                      >
                        <span>
                          <input
                            type="text"
                            className="w-100"
                            name="totalinvestedvalue"
                            value={assetsDetails.totalinvestedvalue}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                totalinvestedvalue: e.target.value,
                              });
                            }}
                            readOnly
                            required
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Current value (₹)</label>
                        </span>
                        <span
                          className="info-hover-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            Auto calculated by SIP amount and current NAV.
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row py-md-2">
                    <div className="col-md-5 mt-2">
                      <div className="material">
                        <Form.Label>SIP End Date*</Form.Label>
                        <div
                          className="dt-conbx"
                          style={{
                            borderBottom: "1px solid #dadada",
                            // paddingTop: "19px",
                          }}
                        >
                          <ReactDatePicker
                            select_date={moment(
                              assetsDetails.asset_mf_end_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            setDate={(date) => {
                              setDate(date, "assetendDate");
                            }}
                            minDate={moment().toDate()}
                            maxDate={""}
                            className="pt-4"
                          />
                        </div>

                        {simpleValidator.current.message(
                          "SIP End Date",
                          assetsDetails.asset_mf_end_date,
                          "required",
                          { messages: { required: "Please add SIP end date " } }
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {(assetsDetails.asset_isrecurring == false ||
                assetsDetails.asset_sub_category_id == 119) && (
                  <>
                    <div className="row d-flex align-items-center">
                      {assetsDetails.asset_sub_category_id == 36 && (
                        <div className="col-md-5 mt-3">
                          <div className="material">
                            <Form.Label className="mb-0">
                              Date of Purchase
                            </Form.Label>
                            <div
                              className="dt-conbx"
                              style={{
                                borderBottom: "1px solid #dadada",
                                paddingTop:
                                  assetsDetails.asset_sub_category_id == 36
                                    ? "3.5px"
                                    : "0",
                              }}
                            >
                              <ReactDatePicker
                                select_date={moment(
                                  assetsDetails.asset_purchase_date,
                                  "DD/MM/YYYY"
                                ).toDate()}
                                setDate={(date) => {
                                  setDate(date, "assetPurchaseDate");
                                }}
                                minDate={""}
                                maxDate={moment().toDate()}
                                className="pt-4"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="col-md-5 mt-md-1 custom-input"
                        style={{
                          paddingTop: assetsDetails.asset_sub_category_id === 37 ? "" : window.innerWidth > 1024 ? "19px" : window.innerWidth > 600 ? "15px" : "10px",
                        }}
                      >
                        <div
                          className={`form-group mt-2 ${assetsDetails.asset_units ? "inputData" : null
                            } `}
                        >
                          <input
                            type="Number"
                            name="asset_units"
                            value={assetsDetails.asset_units}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
                              const isZeroDecimal = value === '0.0';
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_units: isZeroDecimal ? '' : value,
                              });
                            }}
                            required
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">
                            {" "}
                            {assetsDetails.asset_sub_category_id == 37
                              ? "No. Of Coins*"
                              : "No. Of Units*"}
                          </label>
                        </div>
                        <div
                          className="w-100"
                        // style={{ position: "absolute", top: assetsDetails.asset_sub_category_id == 37 ? "3px" : "15px" }}
                        >
                          {simpleValidator.current.message(
                            "No. Of Units*",
                            assetsDetails.asset_units,
                            "required|numeric|min:0.1,num",
                            {
                              messages: {
                                required: "Please add units ",
                                min: "Please enter valid units ",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-md-1 mt-3 ${assetsDetails.asset_purchase_amount ? "inputData" : null
                            } `}
                        >
                          <input
                            type="Number"
                            name="asset_purchase_amount"
                            value={
                              !assetsDetails.asset_isrecurring
                                ? assetsDetails.asset_purchase_amount
                                : " "
                            }
                            onChange={(e) => {
                              if (!assetsDetails.asset_isrecurring) {
                                // Allow input if asset is not recurring
                                setAssetsDetails({
                                  ...assetsDetails,
                                  asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                                });
                              }
                            }}
                            required
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name"> Avg. Purchase Price (₹)*</label>
                        </div>
                        {simpleValidator.current.message(
                          "Avg. Purchase Price (₹)*",
                          assetsDetails.asset_purchase_amount,
                          "required|numeric|min:1,num",
                          {
                            messages: {
                              required: "Please add purchase amount ",
                              min: "Please enter valid purchase amount ",
                            },
                          }
                        )}
                      </div>
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-md-1 mt-3 ${assetsDetails.totalpurchasevalue ? "inputData" : null
                            } `}
                        >
                          <span>
                            <input
                              type="text"
                              name="asset_units"
                              value={assetsDetails.totalpurchasevalue}
                              onChange={(e) => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  totalpurchasevalue: e.target.value,
                                });
                              }}
                              readOnly
                              autoComplete="off"
                            />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="name">Invested Amount (₹)</label>
                          </span>
                          <span
                            className="info-hover-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto Calculated by No Of Units and Avg. Buy Price
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row py-md-2">
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-1 ${assetsDetails.asset_current_unit_price
                            ? "inputData"
                            : null
                            } `}
                        >
                          <input
                            type="Number"
                            name="asset_current_unit_price"
                            value={assetsDetails.asset_current_unit_price}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            required
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Current Price (₹)*</label>
                        </div>
                        {simpleValidator.current.message(
                          "Current Price (₹)*",
                          assetsDetails.asset_current_unit_price,
                          "required|numeric|min:1,num",
                          {
                            messages: {
                              required: "Please add current price ",
                              min: "Please enter valid current price ",
                            },
                          }
                        )}
                      </div>
                      <div className="col-md-5  custom-input">
                        <div
                          className={`form-group mt-md-1 mt-3 ${assetsDetails.totalinvestedvalue
                            ? "inputData"
                            : null
                            } `}
                        >
                          <span>
                            <input
                              className="w-100"
                              type="text"
                              name="totalinvestedvalue"
                              value={assetsDetails.totalinvestedvalue}
                              onChange={(e) => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  totalinvestedvalue: e.target.value,
                                });
                              }}
                              readOnly
                              required
                              autoComplete="off"
                            />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="name">Current Value (₹)</label>
                          </span>
                          <span
                            className="info-hover-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto Calculated by No Of Units and Current Price
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {assetsDetails.asset_sub_category_id == 119 && (
                <div className="row py-md-2 mt-3">
                  <div className="col-md-5">
                    <div className="material">
                      <Form.Label>
                        Expected Return (%)* :{" "}
                        {Number(
                          assetsDetails.asset_ror == 0
                            ? 20
                            : assetsDetails.asset_ror
                        )}
                      </Form.Label>
                      <div
                        className={`${assetsDetails.asset_ror < -75 && "sl-hide-left"
                          } ${assetsDetails.asset_ror > 80 && "sl-hide-right"}`}
                      >
                        <Slider
                          min={-100}
                          max={100}
                          value={
                            assetsDetails.asset_ror == 0
                              ? 20
                              : assetsDetails.asset_ror
                          }
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_ror:
                                Math.round(
                                  (parseFloat(v) + Number.EPSILON) * 100
                                ) / 100,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row py-md-2">
                <div className="col-md-8 mt-md-2">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Goal Linkage*
                    </Form.Label>
                    <span
                      className="ms-md-4 ms-2 info-hover-left-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        Select a goal below to map this investment with a goal of
                        your choice. Otherwise, Fintoo will link it automatically
                        with your high priority goal. In case, you do not wish to
                        utilize this investment for any goal, select "NO".
                      </span>
                    </span>
                    <div className="d-flex  ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_islinkable: v,
                          })
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                        offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-2">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>
                        <span
                          className="ms-md-4 ms-2 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-md-0 mt-md-5 mt-2">
                      <div className="material">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div
                            className="d-flex"
                            style={{ textAlign: "left!important" }}
                          >
                            <div>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            type={"Asset"}
                            asset_maturity_date={assetsDetails?.asset_mf_end_date}
                            isGoalFilter={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                            isAssetRecurring={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleAlternateSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleAlternateCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleAlternateUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span className="hover-text" style={{ maxWidth: 100 }}>
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
    </div>
  );
}

export default Alternate;
