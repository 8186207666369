import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewUnlisted_Aif_EquityFormView from "./NewUnlisted_Aif_EquityFormView";

const NewUnlisted_Aif_EquityForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewUnlisted_Aif_EquityFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewUnlisted_Aif_EquityForm;
