// Common constants
export const RAZORPAY_API_URL = "https://ifsc.razorpay.com/";
// export const BASE_API_URL = 'http://127.0.0.1:8000/'; //local
// export const STATIC_URL = 'http://127.0.0.1:8000/static/';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const LOGIN_PAGE = process.env.PUBLIC_URL + "/login";
export const REGISTER_PAGE = process.env.PUBLIC_URL + "/register";
export const BLOG_URL = "https://www.fintoo.in/blog";
export const CHECK_SESSION = BASE_API_URL + "checksessionreact/";
export const AUTH_URL = BASE_API_URL + "restapi/authlogin";
export const GET_ACCESSTOKEN_API_URL = BASE_API_URL + "getaccesstoken/";
export const GET_JWTTOKEN_API_URL = BASE_API_URL + "restapi/getjwttoken/";
export const GETTRANSACTION_API_URL = BASE_API_URL + "restapi/gettransaction/";
export const SEND_SC_OTP_API_URL = BASE_API_URL + "restapi/sendotp/";
export const VERIFY_SC_OTP_API_URL = BASE_API_URL + "restapi/verifyotp/";
export const STOCKPAGE = process.env.REACT_APP_STOCKPAGE;
export const STOCKLIST_PAGE = STOCKPAGE + "stocks";
export const SUPPORT_EMAIL = 'support@fintoo.in'
export const AADHAR_GENERATE_OTP = "https://kyc-api.aadhaarkyc.io//api/v1/aadhaar-v2/generate-otp";
export const AADHAR_SUBMIT_OTP = "https://kyc-api.aadhaarkyc.io//api/v1/aadhaar-v2/submit-otp";
export const AADHAR_HEADERS = {
  "Content-Type": "application/json",
  "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MjAyODU5OSwianRpIjoiNDY1YzUzZDQtNTgyOC00NGI4LTgyMmQtMGU4NjY2NTYwZjc5IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmZpbnRvb0BzdXJlcGFzcy5pbyIsIm5iZiI6MTY2MjAyODU5OSwiZXhwIjoxOTc3Mzg4NTk5LCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsid2FsbGV0Il19fQ.XIzLSJnukwEGGznLCtNwwyrqWm6pWuwQIt9rzcqoZZ8",
};
export const CALENDLY_TOKEN = "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjExMjQ0LCJqdGkiOiJmMmM1YWIwOC01N2ZiLTQ0YzAtODNjYy1lM2QxZWZhZGY2YzMiLCJ1c2VyX3V1aWQiOiI0ODVhZTAyZC02ZGNiLTQ1MjktODdiYi01MGY2NDE3NGI4ZWYifQ.5bIIwHH3DTn1Vp7Oj6hZlLkVIbI1q7jxqFogGaGkb1g"
// TAX constants
export const TAX_URL = BASE_API_URL + 'tax/';
export const TAX_GET_TAX_PLANS_API_URL = TAX_URL + "gettaxplans";
export const TAX_GET_DOCUMENTS_API_URL = TAX_URL + "getdocuments";
export const TAX_GET_UPLOADED_DOCUMENTS_API_URL = TAX_URL + "getuploadeddocuments";
export const TAX_GET_USER_PERSONAL_DETAILS_API_URL = TAX_URL + "getuserpersonaldetails";
export const TAX_UPDATE_USER_DETAILS_API_URL = TAX_URL + "updateuserdetails";
export const TAX_ITR_UPLOAD_DOCS_API_URL = TAX_URL + "itradddocument";
export const TAX_ITR_DELETE_DOCS_API_URL = TAX_URL + "itrdeletedocument";
export const TAX_CHECK_VALID_COUPON_API_URL = TAX_URL + "checkvalidcoupon";
export const RAZORPAY_CREATE_ORDER = TAX_URL + "createorder";
export const RAZORPAY_PAYMENT_SUCCESS = TAX_URL + "itrpaymentsuccess";
export const RAZORPAY_PAYMENT_FAILURE = TAX_URL + "itrpaymentfailure";
export const GET_USER_ITR_DETAILS_API_URL = TAX_URL + "getuseritrdetails";
export const TAX_GET_USER_PAYMENT_STATUS_API_URL = TAX_URL + "getuserpaymentstatus";
export const TAX_APPROVE_COMPUTATION = TAX_URL + "approvecomputation";
export const TAX_SCHEDULE_APPOINTMENT = TAX_URL + "scheduleappointment";
export const RAZORPAY_CHECKOUT = "https://checkout.razorpay.com/v1/checkout.js";
export const PAYMENT_URL_TEST = 'https://test.payu.in/_payment';
export const PAYMENT_URL_LIVE = 'https://secure.payu.in/_payment';
export const RAZOR_PAY_KEY = "rzp_live_9ul8nvWs8peVUd";
export const RAZOR_PAY_SECRET = "85hZeZxI4LqKLdK9OIwyrzAM";
export const ASSESSMENT_YEAR = '2024-25';
// DMF constants
export const IS_DIRECT = "1";
// export const DMF_BASE_URL ='http://127.0.0.1:8000/direct-mutual-fund/'; //local
// export const DMF_URL = "http://127.0.0.1:8000/";
export const DMF_BASE_URL = process.env.REACT_APP_DMF_BASE_URL;
export const DMF_URL = process.env.REACT_APP_DMF_URL;
export const LOGIN_PAGE_MUTUAL_FUND_LIST = DMF_URL + "login?param=DMF";
export const GET_OTHER_INVESTMENTS = DMF_BASE_URL + "api/otherinvestment/getotherinvestments";
export const DELETE_OTHER_INVESTMENTS = DMF_BASE_URL + "api/otherinvestment/deleteotherinvestments"
export const DELETE_FP_USER_ASSET = process.env.REACT_APP_PYTHON_URL + "restapi/assetsdeleteApi/";
export const GET_OCCUPATIONS_API_URL = DMF_URL + "restapi/getoccuptionlist/?web=1";
export const DMF_UPDATEBASICDETAILS_API_URL = DMF_BASE_URL + "api/user/updatebasicdetails";
export const DMF_GETUSERDETAILS_API_URL = DMF_BASE_URL + "api/user/getuserdetails";
export const DMF_GETUSERAUTHKEY_API_URL = DMF_BASE_URL + "api/user/getuserauthkey";
export const DMF_GETPANSTATUS_API_URL = DMF_BASE_URL + "api/kyc/getpanstatus";
export const DMF_ADDKYC = DMF_BASE_URL + "api/kyc/addkyc";
export const DMF_GETCITIES_API_URL = DMF_BASE_URL + "api/common/getcities";
export const DMF_GETSTATES_API_URL = DMF_BASE_URL + "api/common/getstates";
export const DMF_GETBANKTYPES_API_URL = DMF_BASE_URL + "api/common/getbanktypes";
export const DMF_GETBANKDETAILS_API_URL = DMF_BASE_URL + "api/bank/getbankdetails";
export const DMF_GETUSERBANKS_API_URL = DMF_BASE_URL + "api/user/getuserbanks";
export const DMF_GETPENNYDROP_API_URL = DMF_BASE_URL + "api/bank/bankdetailspennydrop";
export const DMF_GETCODES_API_URL = DMF_BASE_URL + "api/bank/getcodes";
export const DMF_ADDBANK_API_URL = DMF_BASE_URL + "api/bank/addbank";
export const DMF_DELETEBANK_API_URL = DMF_BASE_URL + "api/bank/deletebank";
export const DMF_GETBANK_BSECODE_API_URL = DMF_BASE_URL + "api/bank/getbankbsecode";
// export const DMF_LISTMANDATE_API_URL = DMF_BASE_URL + "api/bank/getmandatelist";
export const DMF_UPLOADCANCELCHECK_API_URL = DMF_BASE_URL + "api/bank/uploadcancelledcheque";
export const DMF_GETCOUNTRIES_API_URL = DMF_BASE_URL + "api/common/getcountries";
export const DMF_DATAGATHERING_API_URL = DMF_BASE_URL + "api/user/getdatagatheringdetails";
export const DMF_CLIENT_REGISTRATION_API_URL = DMF_BASE_URL + "api/bse/clientregistration";
export const DMF_FATCA_UPLOAD_API_URL = DMF_BASE_URL + "api/bse/fatcaupload";
export const DMF_DELETENOMINEE_API_URL = DMF_BASE_URL + "api/nominee/deletenomineedetails";
export const DMF_ADDNOMINEE_API_URL = DMF_BASE_URL + "api/nominee/addnomineedetails";
export const DMF_UPDATENOMINEE_API_URL = DMF_BASE_URL + "api/nominee/updatenomineedetails";
export const DMF_GENERATE_AOF_API_URL = DMF_BASE_URL + "api/bse/generateaof";
export const DMF_NOMINEESTATUS = DMF_BASE_URL + "api/nominee/nomineestatus";
export const DMF_NOMINEERELATIONSHIP_LIST = DMF_BASE_URL +"api/nominee/getnomineerelationslist";
export const DMF_GUARDIANRELATIONSHIP_LIST = DMF_BASE_URL +"api/common/getguardianrelationslist";
//export const DMF_AOF_IMAGE_UPLOAD_API_URL = DMF_BASE_URL +"api/bse/aofimageuploadinput";
export const DMF_AOF_IMAGE_UPLOAD_API_URL = DMF_BASE_URL + "api/bse/aofimageupload";
export const DMF_UPLOADDOCUMENT_API_URL = DMF_BASE_URL + "api/document/adddocument";
export const DMF_GETINCOMESLABS = DMF_BASE_URL + "api/common/getincomeslabs";
export const DMF_NOMINEELIST_API_URL = DMF_BASE_URL + "api/nominee/getnomineedetails";
export const DMF_SENDSMS_API_URL = BASE_API_URL + "restapi/sendsmsApi/";
export const DMF_SENDWPSMS_API_URL = BASE_API_URL + "restapi/sendwpsms/";
export const DMF_SENDMAIL_API_URL = BASE_API_URL + "restapi/sendmail/";
export const DMF_DELETE_FILES_API_URL = BASE_API_URL + "/restapi/deletefiles/";
export const DMF_DETAILED_PF_API_URL = DMF_BASE_URL + "api/portfolio/getmfdetailedportfolio";

export const DMF_UPDATEBANK_API_URL = DMF_BASE_URL + "api/bank/updatebank";
export const DMF_GET_SCHEME_BY_AMC_API_URL = DMF_BASE_URL + "api/scheme/getschemesbyamc";
export const DMF_ADD_TRANSACTION_API_URL = DMF_BASE_URL + "api/transaction/addtransaction";
export const DMF_ADD_TO_SWP_STP_TRANSACTION_API_URL = DMF_BASE_URL + "api/transaction/addswitchtransaction";
export const DMF_COMPLETEDTRANSACTIONS_API_URL = DMF_BASE_URL + "api/transaction/getcompletedtransactions";
export const DMF_GET_MF_TRANSACTIONS_API_URL = DMF_BASE_URL + "api/transaction/getmftransactions"
export const DMF_ADD_TO_STPSWP_CART_API_URL = DMF_BASE_URL + "api/cart/addtostpswpcart";
export const getSumOfProperty = DMF_BASE_URL + "api/cart/addtostpswpcart";
export const DMF_SWITCH_FUND_API_URL = DMF_BASE_URL + "api/bse/switchorderentry";
export const DMF_DELETE_SWP_STP_FROM_CART = DMF_BASE_URL + "api/cart/deleteswpstpcart";
export const DMF_CART_DELETE_API_URL = DMF_BASE_URL + "api/cart/delete";
export const DMF_GETBANKUPIID_API_URL = DMF_BASE_URL + "api/bank/getbankupiid";
export const DMF_DETAILED_REPORT_URL = DMF_BASE_URL + "api/portfolio/generatedetailedreport";
export const DMF_SUMMARY_REPORT_URL = DMF_BASE_URL + "api/portfolio/generatesummaryreport";
export const DMF_PLACEORDER_API_URL = DMF_BASE_URL + "api/order/placeorder";
// export const DMF_NEW_PLACEORDER_API_URL = DMF_BASE_URL + "api/order/newplaceorder";
export const DMF_PAYMENTGATEWAYRESPONSE_API_URL = DMF_BASE_URL + "api/bse/paymentgatewayresponse";
export const DMF_CANCELORDER_API_URL = DMF_BASE_URL + "api/order/cancelorder";
export const DMF_ADD_FATCA_API_URL = DMF_BASE_URL + "api/user/addfatcadetails";
export const DMF_PLACEORDER_NEFT_API_URL = DMF_BASE_URL + "api/order/placeorderneft";
export const DMF_SUCCESSORDER_API_URL = DMF_BASE_URL + "api/order/successorder";
export const DMF_GET_PRIMARY_BANK_API_URL = DMF_BASE_URL + "api/bank/getprimarybank";
export const DMF_GET_USER_PROFILE_STATE_URL = DMF_BASE_URL + "api/common/getuserprofilestate";
export const DMF_GET_AMC_LIST = DMF_BASE_URL + "api/common/getamclist";
export const DMF_GET_DASHBOARD_DATA = DMF_BASE_URL + "api/common/getdashboarddata";
export const DMF_GET_SC_DATA_API_URL = BASE_API_URL + "restapi/getsmallcasedata/";
export const DMF_CHECK_ECAS_S3 = BASE_API_URL + "restapi/checkifs3filepresent/";
export const DMF_CHECKIFPANEXISTS_API_URL = DMF_BASE_URL + "api/common/checkifpanexists";
export const DMF_GETBANKLIST_API_URL = DMF_BASE_URL + "api/common/getbanks";
export const DMF_ADDFDBOND_API_URL = DMF_BASE_URL + "api/otherinvestment/addfdbond";
export const DMF_GET_UPCOMING_TRANSACTION_API_URL = DMF_BASE_URL + "api/transaction/getupcomingtransactions";
export const DMF_GET_PENDING_TANSACTION_API_URL = DMF_BASE_URL + "api/transaction/getpendingtransactions";
export const DMF_ADD_CITIES_API_URL = DMF_BASE_URL + "api/bank/addnewcity";
export const DMF_SOURCEMEDIUM_NAME = DMF_BASE_URL + "api/common/getsourcemediumname"
export const DMF_TXNLOGIN = DMF_BASE_URL + "api/transaction/addtxnlogin";
export const DMF_GETSOURCEMEDIUM = DMF_BASE_URL + "api/common/getsourcemediumname";
// Stocks constants
// export const STOCKS_API_URL = 'https://stg.minty.co.in/stocksapi/';
// export const STOCKS_API_URL ='https://wealthtechindia.in/stocks/';
// export const STOCKS_API_URL = 'https://www.fintoo.in/stocksapi/';
export const STOCKS_API_URL = process.env.REACT_APP_STOCKS_API_URL;
//export const STOCKS_API_URL = 'http://127.0.0.1:8000/stocksapi/';
export const LOGIN_PAGE_STOCKLIST = BASE_API_URL + "login?param=stocks";
export const GET_USERINFO_API_URL = STOCKS_API_URL + "getuserdetails/";
export const STOCK_DETAIL_API_URL = STOCKS_API_URL + "getstockdetails/";
export const GET_SESSION_API_URL = STOCKS_API_URL + "getsessiondata/";
export const STOCK_FILTER_API_URL = STOCKS_API_URL + "getfilteredstocklist/";
export const GET_HOLIDAYS = STOCKS_API_URL + "checkholiday/";
export const STOCKDATA_BYFILTER_API_URL = STOCKS_API_URL + "getdatabysector/";
export const CONNECT_WITH_BROKER_API_URL = STOCKS_API_URL + "connectwithbroker/";
export const STOCK_DATA_API_URL = STOCKS_API_URL + "getstockdata/";
export const STOCK_GAIN_LOSS_API_URL = STOCKS_API_URL + "getgainloss/";
export const STOCK_WATCHLIST_API_URL = STOCKS_API_URL + "getstockwatchlist/";
export const STOCK_52_WEEK_HIGH_LOW_API_URL = STOCKS_API_URL + "gethighlow/";
export const STOCK_DATABYPRICE_API_URL = STOCKS_API_URL + "getdatabyprice/";
export const STOCK_AUTH_URL = STOCKS_API_URL + "restapi/authlogin";
export const INSERT_WATCHLIST_API_URL = STOCKS_API_URL + "";
export const ADD_UPDATE_WATCHLIST_API_URL = STOCKS_API_URL + "addupdatewatchlist/";
export const GET_WATCHLIST_API_URL = STOCKS_API_URL + "getwishlist/";
export const GET_INDEX_DATA_API_URL = STOCKS_API_URL + "getdatabyindex/";
export const GET_TICKERS_DATA_API_URL = STOCKS_API_URL + "gettickersdata/";
export const GUEST_MESSAGE = 'User not logged in';
export const GET_PORTFOLIO_SUMMARY_URL = DMF_BASE_URL + "api/portfolio/getmfsummaryportfolio";
export const MUTUAL_FUND_DATA_API_URL = DMF_BASE_URL + "api/scheme/getschemelist";
export const GET_SCHEME_DETAILS = DMF_BASE_URL + "api/scheme/getschemedetail";
export const CATEGORY_DATA_API_URL = DMF_BASE_URL + "api/common/getcategories";
export const WISHLIST_FUND_DATA_API_URL = DMF_BASE_URL + "api/wishlist/getwishlist";
export const GET_CART_DETAILS = DMF_BASE_URL + 'api/cart/getcartfunds';
export const CART_ADD_URL = DMF_BASE_URL + 'api/cart/add';
export const CART_UPDATE_URL = DMF_BASE_URL + 'api/cart/updatecart';
export const USER_GET_DOCS = DMF_BASE_URL + 'api/user/getuserdocuments';
export const DOCS_READ_FILE = DMF_BASE_URL + 'api/document/readdocument';
export const DOCS_DELETE_FILE = DMF_BASE_URL + 'api/document/deletedocument';
export const DMF_MANDATELIST_API_URL = DMF_BASE_URL + "api/bank/getmandatelist";
export const DMF__USER_MANDATELIST_API_URL = DMF_BASE_URL + "api/bank/getusermandatelist";
export const DMF_MANDATEREGISTRATION_API_URL = DMF_BASE_URL + "api/bse/mandateregister";
export const DMF_ENACHMANDATE_REGISTRATION_API_URL = DMF_BASE_URL + "api/bse/getemandateauthurl";
export const DMF_GETEXITLOAD_API_URL = DMF_BASE_URL + "api/portfolio/getexitload";
export const DMF_NORMAL_ORDER_ENTRY_API_URL = DMF_BASE_URL + "api/bse/normalorderentry";
export const DMF_ADD_USER_NRI_DETAILS_API_URL = DMF_BASE_URL + "api/user/addusernridetails";
export const DMF_GET_KYC_DETAILS_API_URL = DMF_BASE_URL + "api/kyc/getkycdetails";
export const GET_MEMBER_LIST = DMF_BASE_URL + "api/user/getparentandmemberdetails";
export const ADD_MEMBER_API_URL = DMF_BASE_URL + "api/user/addmember";
export const ADD_MINOR_API_URL = DMF_BASE_URL + "api/user/addminordetails";
export const DMF_VALIDATE_PAN_API_URL = DMF_BASE_URL + "api/user/validatepan";
export const DMF_XSIP_ORDER_ENTRY_API_URL = DMF_BASE_URL + "api/bse/xsiporderentry";
export const DMF_ADD_TO_STPSWP_CART_NEW_API_URL = DMF_BASE_URL + "api/cart/addtostpswpcartnew";
export const DMF_START_SWP_API_URL = DMF_BASE_URL + "api/bse/swpregistration";
export const DMF_STOP_SWP_API_URL = DMF_BASE_URL + "api/bse/swpcancellation";
export const DMF_STOP_REASON_API_URL = DMF_BASE_URL + "api/bse/getstopsipreasons";
export const DMF_START_STP_API_URL = DMF_BASE_URL + "api/order/stporderregistration";
export const DMF_JOINT_DROPDOWN_API_URL = DMF_BASE_URL + "api/common/getcompleteprofiledata";
export const DMF_DOWN_API_URL = DMF_BASE_URL + "api/common/getmaintenancedetails";
export const DMF_MIN_INVESTMENT_STP_API_URL = DMF_BASE_URL + "api/order/getstpfunddetails";
export const DMF_CAPITAL_GAIN_REPORT_URL = DMF_BASE_URL + "api/portfolio/getcapitalgain";
export const DMF_EXCEL_REPORT = DMF_BASE_URL + "api/portfolio/generatesummaryreport";
export const DMF_PAR_REPORT_URL = BASE_API_URL + "restapi/report/getparreport/";
export const DMF_ADD_EDIT_REALESTATE = DMF_BASE_URL + "api/otherinvestment/addeditrealestate";
export const DMF_ADD_EDIT_GOLD = DMF_BASE_URL + "api/otherinvestment/addeditgoldassets";
export const DMF_ADD_EDIT_LIQUID = DMF_BASE_URL + "api/otherinvestment/addeditliquidassets";
export const DMF_GET_DIGILOCKER_URL = DMF_BASE_URL + "api/document/getdigilockerurl";
export const DMF_DOWNLOAD_DIGILOCKER_DOCUMENT = DMF_BASE_URL + "api/document/downloaddocuments";
export const RMF_GET_SC_DATA_API_URL = DMF_BASE_URL + "api/rmfcommon/getsmallcasedata";
export const RMF_GET_SC_CHECK_STATUS = DMF_BASE_URL + "api/rmfcommon/getuserecasstatus";

export const GET_CAPITALGAIN_DATA_API_URL = DMF_BASE_URL + "api/portfolio/getcapitalgaindata";
export const CAPITALGAIN_PYTHON_REPORT_URL = DMF_BASE_URL + "api/portfolio/getcapitalgainpython";
// Advisory Constant
export const ADVISORY_GET_BLOG_API_URL = process.env.REACT_APP_ADVISORY_GET_BLOG_API_URL;
export const ADVISORY_GET_SCORECARD_API_URL = BASE_API_URL + "restapi/report/getscorecard/";
export const ADVISORY_GET_FP_ABOUTYOUR_PLANDATA_API_URL = BASE_API_URL + "restapi/report/getfpaboutyourplandata/";
export const ADVISORY_GET_GOALS_API_URL = BASE_API_URL + "restapi/getgoalsdatabyuser/";
export const ADVISORY_CART_DATA_RENEW_API_URL = BASE_API_URL + "restapi/userflow/cartdataforenewal/";
export const ADVISORY_UPDATE_AUTH_DATA_API_URL = BASE_API_URL + "restapi/updateauthdata/";
export const ADVISORY_GET_RM_DETAILS_API_URL = BASE_API_URL + "restapi/getrmdetails/";
export const ADVISORY_GET_RM_LIST_API = BASE_API_URL + "restapi/getrmplancategory/";
export const ADVISORY_RENEWPAYMENT_API_URL = BASE_API_URL + "restapi/userflow/renewpayment/";
export const ADVISORY_GET_INSURANCE_COVER_API_URL = BASE_API_URL + "restapi/getmedicallifeinsurancebyuser/";
export const ADVISORY_GET_FAMILY_DATA_API_URL = BASE_API_URL + "restapi/getfpfamilydata/";
export const ADVISORY_NIFTY_SENSEX_DATA_API_URL = BASE_API_URL + "stocksapi/gettickersdata/";
export const ADVISORY_GET_NETWORTHLIABILITES_API_URL = BASE_API_URL + "restapi/userflow/getnetworthliabilitesbyuser/";
export const ADVISORY_GET_PLANOFACTION_API_URL = BASE_API_URL + "restapi/userflow/getplanofactiondata/";
export const ADVISORY_GET_PRICINGDETAILS_API_URL = BASE_API_URL + "restapi/getpricingdetails/";
export const ADVISORY_GET_TAXDETAILS_API_URL = BASE_API_URL + "restapi/gettaxpricingdetails/";
export const ADVISORY_ADDTOCART_API_URL = BASE_API_URL + "restapi/userflow/addtocart/";
export const ADVISORY_CALLBACKSERVICES_API_URL = BASE_API_URL + "callback-services/";
export const ADVISORY_CAPTCHA_API_URL = BASE_API_URL + "restapi/getcaptcha/";
export const ADVISORY_CONTACTUS_API_URL = BASE_API_URL + "contact/";
export const ADVISORY_INVOICE_API_URL = BASE_API_URL + "restapi/userflow/getbillingdetails/";
export const ADVISORY_ADDPROFILE_API_URL = BASE_API_URL + "restapi/userflow/addprofile/";
export const ADVISORY_REMOVEPROFILEIMG_API_URL = BASE_API_URL + "restapi/userflow/removeprofileimage/";
export const ADVISORY_GETUSERFLOWUSERDATA_API_URL = BASE_API_URL + "restapi/userflow/getuserflowuserdata/";
export const ADVISORY_GETGOALSDATA_API_URL = BASE_API_URL + "restapi/getfpgoalsdata/";
export const ADVISORY_GETDEFAULTROR_API_URL = BASE_API_URL + "restapi/getdefultrorApi/?web=1";
export const ADVISORY_ADDGOAL_API_URL = BASE_API_URL + "restapi/saveupdategoalsdata/";
export const ADVISORY_EDITGOAL_API_URL = BASE_API_URL + "restapi/getfpgoalsdata/";
export const ADVISORY_GET_US_EQUITY_API_URL = BASE_API_URL + "restapi/userflow/getUSEquity/";
export const ADVISORY_DELETEGOAL_API_URL = BASE_API_URL + "restapi/removefpgoalsdata/";
export const ADVISORY_GET_KNOWYOURRISK_API_URL = BASE_API_URL + "restapi/knowyourriskgetApi/"
export const ADVISORY_UPDATE_KNOWYOURRISK_API_URL = BASE_API_URL + "restapi/knowyourriskupdateApi/"
export const ADVISORY_STATUS_KNOWYOURRISK_API_URL = BASE_API_URL + "restapi/getknowyourstatusApi/"
export const ADVISORY_ADD_KNOWYOURRISK_API_URL = BASE_API_URL + "restapi/knowyourriskaddApi/"
export const ADVISORY_SAVE_UPDATE_FPUSER_DATA_API_URL = BASE_API_URL + "restapi/saveupdatefpuserdata/"
export const ADVISORY_GET_DATA_BY_PINCODE_API_URL = BASE_API_URL + "restapi/getdatabypincode/"
export const ADVISORY_USER_LOGIN_FLAG_UPDATE_API_URL = BASE_API_URL + "restapi/UserloginFlagUpdateApi/"
export const ADVISORY_KNOW_YOUR_RISK_UPDATE_API_URL = BASE_API_URL + "restapi/knowyourriskupdateApi/"
export const ADVISORY_KNOW_YOUR_RISK_ADD_API_URL = BASE_API_URL + "restapi/knowyourriskaddApi/"
export const ADVISORY_KNOW_YOUR_RISK_GET_API_URL = BASE_API_URL + "restapi/knowyourriskgetApi/"
export const ADVISORY_GET_KNOW_YOUR_STATUS_API_URL = BASE_API_URL + "restapi/getknowyourstatusApi/"
export const ADVISORY_GET_MEDICLAIM = BASE_API_URL + "restapi/getmediclaimansApi/"
export const ADVISORY_ADD_MEDICLAIM = BASE_API_URL + "restapi/addMedicalimansApi/"
export const ADVISORY_GETEXPENSES_API_URL = BASE_API_URL + "restapi/getexpensedetails/";
export const ADVISORY_GETLIABILITIES_API_URL = BASE_API_URL + "restapi/getuserliabilities/";
export const ADVISORY_SAVE_UPDATE_LIABILITIES_API_URL = BASE_API_URL + "restapi/saveupdateliability/";
export const ADVISORY_REMOVE_LIABILITIES_API_URL = BASE_API_URL + "restapi/removeliability/";
export const ADVISORY_GET_APPENDIXDATA_API_URL = BASE_API_URL + "restapi/report/getappendixdataApi/";
export const ADVISORY_CHECK_PROFILE_STATUS_API_URL = BASE_API_URL + "restapi/checkprofilestatusApi/"
export const ADVISORY_UPDATE_RETIREMENT_DATE_API_URL = BASE_API_URL + "restapi/updateretirementdate/"
export const ADVISORY_YOUR_PROFILE_API_URL = BASE_API_URL + "restapi/report/getrpyourprofiledata/"
export const ADVISORY_RISK_APPETITE_API_URL = BASE_API_URL + "restapi/report/getriskappetite/"
export const ADVISORY_ADD_UPDATE_INSURANCE_DATA = BASE_API_URL + "restapi/saveupdateinsurance/"
export const ADVISORY_GET_INSURANCE_API_URL = BASE_API_URL + "restapi/getuserinsurance/"
export const ADVISORY_GET_INSURANCE_TYPE = BASE_API_URL + "restapi/getinsurancetypes/"
export const ADVISORY_GET_ULIP_DATA = BASE_API_URL + "restapi/report/getulipdata/"
export const ADVISORY_REMOVE_INSURANCE_DATA = BASE_API_URL + "restapi/removeinsurance/"
export const ADVISORY_GET_DOCUMENTS_API = BASE_API_URL + "restapi/getdocuments/"
export const ADVISORY_ADD_DOCUMENT_API = BASE_API_URL + "restapi/adddocument/"
export const ADVISORY_DELETE_DOCUMENT_API = BASE_API_URL + "restapi/deletedocuments/"
export const ADVISORY_GETSUMMARYREPORT_API = BASE_API_URL + "report/downloadreport/?filename=all&action=downloadreport&merge=all&summaryreport=1&doc_type=148"
export const ADVISORY_GET_FP_GOALS_DATA = BASE_API_URL + "restapi/getfpgoalsdata/"
export const ADVISORY_GET_ASSETS_SUMMARY_API = BASE_API_URL + "restapi/report/getassetsummaryApi/"
export const ADVISORY_GET_NETWORTHFUNDFLOW_API_URL = BASE_API_URL + "restapi/report/getnetworthfundflow/"
export const ADVISORY_NETWORTHFUNDFLOW_PROJECTION_API_URL = BASE_API_URL + "restapi/report/networthProjection/"
export const ADVISORY_GET_EQUITY_DATA_API = BASE_API_URL + "restapi/report/getequitydata/"
export const ADVISORY_GET_FP_GOALS_SUMMARY_DATA = BASE_API_URL + "restapi/report/goalsummaryApi/?ordered=true"
export const ADVISORY_DEBT_INVESTMENT_API = BASE_API_URL + "restapi/report/debtInvestmentApi/"
export const ADVISORY_ALTERNATE_INVESTMENT_API = BASE_API_URL + "restapi/report/alternateInvestmentApi/"
export const ADVISORY_REALESTATE_INVESTMENT_API = BASE_API_URL + "restapi/report/realstateInvestmentApi/"
export const ADVISORY_GET_CURRENTINSURANCE_API = BASE_API_URL + "restapi/report/getcurrentinsurance/"
export const ADVISORY_GET_FP_GOALS_ASSET_MAP = BASE_API_URL + "restapi/report/getassetgoals/"
export const ADVISORY_GET_FP_GOALS_ASSET_MAP_ANALYSIS = BASE_API_URL + "restapi/report/getfinalgoalrecommnedation/"
export const ADVISORY_GET_CONTENGENCY_DATA = BASE_API_URL + "restapi/report/getreccontingencyrisk/"
export const ADVISORY_GET_INSURANCE_DATA = BASE_API_URL + "restapi/report/getcurrentinsurance/"
export const ADVISORY_GET_LIFE_INSURANCE = BASE_API_URL + "restapi/report/getlifeinsurance/"
export const ADD_INSURANCE = BASE_API_URL + "api/otherinvestment/addinsurance"
export const ADVISORY_GET_MEDICAL_INSURANCE = BASE_API_URL + "restapi/report/getMedicalinsuranceApi/"
export const ADVISORY_GET_RETIREMENT_INFO_API = BASE_API_URL + "restapi/report/getretirementinfo/"
export const ADVISORY_GET_RETIREMENT_CORPUS_API = BASE_API_URL + "restapi/report/getretirementcorpus/"
export const ADVISORY_GET_RETIREMENT_GOAL_RECOMMENDATION_API = BASE_API_URL + "restapi/report/getfinalgoalrecommnedation/"
export const ADVISORY_GET_RETIREMENT_CASHFLOW_API = BASE_API_URL + "restapi/report/getretirementcashflow/"
export const ADVISORY_GET_RECOMMENDED_RETIRE_CASHFLOW_API = BASE_API_URL + "restapi/report/getrecommendationcashflow/"
export const ADVISORY_GET_SURPLUS_DATA_API = BASE_API_URL + "restapi/report/getsurplusdata/"
export const ADVISORY_GET_RP_INOUTFLOW_DATA_API = BASE_API_URL + "restapi/report/getrpinoutflow/"
export const ADVISORY_GET_UNASSIGNED_ASSET = BASE_API_URL + "restapi/report/getecasunassignedasset/"

export const ADVISORY_FETCH_EQUIFAX_LOANS_API = BASE_API_URL + "restapi/fetchequifaxloans/"
export const ADVISORY_UPDATE_FETCHEDLOANS_API = BASE_API_URL + "restapi/updatefetchedloans/"
export const ADVISORY_UNLINK_EQUIFAXMEMBER_API = BASE_API_URL + "restapi/unlinkequifaxmember/"
export const ADVISORY_GETEQUIFAX_MEMBER_DATA_API = BASE_API_URL + "restapi/getequifaxmemberdata/"

export const ADVISORY_GET_LIABILITY_DATA = BASE_API_URL + "restapi/report/getliabilitysummaryApi/"
export const ADVISORY_GET_NETWORTH_DATA = BASE_API_URL + "restapi/report/getassetandliabilityrecommendationApi/"
export const ADVISORY_NET_SURPLUS_SHORTFALL_DATA = BASE_API_URL + "restapi/report/getcashflowsurplusshortfall/"
export const ADVISORY_GET_CASHINFLOW_DATA = BASE_API_URL + "restapi/report/getcashinflow/"
export const ADVISORY_GET_CASHOUTFLOW_DATA = BASE_API_URL + "restapi/report/getcashoutflow/"
export const ADVISORY_GET_CASHFLOW_RECOMMENDATION_DATA = BASE_API_URL + "restapi/report/getcashflowrecommendation/"
export const ADVISORY_GET_FP_ASSET_GOAL_MAP = BASE_API_URL + "restapi/report/getassetgoalmapping/"
export const ADVISORY_GET_FP_UNLINKED_ASSET = BASE_API_URL + "restapi/report/getallunlinkassets/"
export const ADVISORY_GET_ASSETS_API = BASE_API_URL + "restapi/assetsgetApi/"
export const ADVISORY_ADD_ASSETS_API = BASE_API_URL + "restapi/assetsaddApi/"
export const ADVISORY_DELETE_ASSETS_API = BASE_API_URL + "restapi/assetsdeleteApi/"
export const ADVISORY_DELETE_ECAS_ASSETS_API = BASE_API_URL + "restapi/ecasdeleteapi/"
export const ADVISORY_UPDATE_ASSETS_API = BASE_API_URL + "restapi/assetsupdateApi/"
export const ADVISORY_UPDATE_MULTIPLE_GOALS_API = BASE_API_URL + "restapi/updateMultipleGoalDMF/"
export const ADVISORY_UPDATE_MEDICALAIM = BASE_API_URL + "restapi/updateMedicalimansApi/"
export const ADVISORY_GET_MEMBER_COUNT = BASE_API_URL + "restapi/getmembercount/"
export const ADVISORY_GET_INCOME_DATA = BASE_API_URL + "restapi/getincomedata/"
export const ADVISORY_GET_CURRENT_INVESTMENT_RECOMMENDATION = BASE_API_URL + "restapi/report/getcurrentinvestmentrecommendation/"
export const ADVISORY_GET_SCHEME_DATA = BASE_API_URL + "restapi/report/getschemedata/?web=1"
export const ADVISORY_GET_CRYPTO_DATA = "https://api.buyucoin.com/market/v1.0/getLiveOtcMarketData"
export const ADVISORY_GET_EQUITY_SHARES_DATA = BASE_API_URL + "restapi/report/getsharedata/?web=1"
export const ADVISORY_SAVE_FAMILY_DATA = BASE_API_URL + "restapi/saveupdatefpfamilydata/"
export const ADVISORY_GET_FAMILY_DATA = BASE_API_URL + "restapi/getfpfamilydata/?"
export const ADVISORY_REMOVE_FAMILY_DATA = BASE_API_URL + "/restapi/removefamilydata/"
export const ADVISORY_GET_FPUSER_ASSUMPTIONS = BASE_API_URL + "restapi/report/getfpuserAssumptionsApi/"
export const ADVISORY_GET_FPUSER_INFLATION = BASE_API_URL + "restapi/report/getfpuserInflationsApi/"
export const ADVISORY_SAVE_UPDATE_FP_ASSUMPTIONS = BASE_API_URL + "restapi/report/saveupdatefpassumptionsApi/"
export const ADVISORY_SAVE_UPDATE_FP_INFLATION = BASE_API_URL + "restapi/report/saveupdatefpinflationsApi/"
export const ADVISORY_UPLOAD_ECASH_DOCUMENT = BASE_API_URL + "restapi/addecashdocument/"
export const ADVISORY_CHECK_PAN_EXISTS = BASE_API_URL + "restapi/checkpanexist/";
export const ADVISORY_CHECK_PAN_EXISTSS = BASE_API_URL + "restapi/checkpanexist/";
export const ADVISORY_CHECK_PAN_REGISTERED = BASE_API_URL + "restapi/checkpanregistered/";
export const ADVISORY_FETCH_SMALLCASE_DATA = BASE_API_URL + "restapi/fetchsmallcasedata/";
export const ADVISORY_FETCH_MEMBER_DATA = BASE_API_URL + "restapi/fetchmemberdata/";
export const ADVISORY_NSDL_CDSL_DATA = BASE_API_URL + "restapi/fetchnsdlcdsldata/";
export const ADVISORY_ADD_NSDL_CDSL_DATA = BASE_API_URL + "restapi/addnsdlcdsldata/";
export const ADVISORY_DELETE_SMALLCASE_DATA = BASE_API_URL + "restapi/deletesmallcasedata/";
export const ADVISORY_GET_VALID_EPF_MEMBERS = BASE_API_URL + "restapi/getepfvalidmembers/";
export const ADVISORY_GET_FP_USER_DATA = BASE_API_URL + "restapi/getfpuserdata/";
export const ADVISORY_GET_USERDETAILS = BASE_API_URL + "restapi/getuserdetails/";
export const ADVISORY_EXPERTFLOW_CALLBACK = BASE_API_URL + "expertflowcallback/";
export const ADVISORY_EXPERT_PAYMENT = BASE_API_URL + "expertpayment/";
export const ADVISORY_EXPERT_NDA = BASE_API_URL + "restapi/expertflow/expertnda/";
export const ADVISORY_PAYMENT_FAILURE = BASE_API_URL + "restapi/userflow/razorpayfailure/";
export const ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT = BASE_API_URL + "restapi/calculateepfmaturityamt/"
export const ADVISORY_FETCH_CONNECTED_BROKER = BASE_API_URL + "restapi/fetchconnectedbroker/"
export const ADVISORY_CONNECTED_WITH_BROKER = BASE_API_URL + "restapi/connectwithbroker/"
export const ADVISORY_FETCH_HOLDING = BASE_API_URL + "restapi/fetchholdings/"
export const ADVISORY_CHECK_PAN_EXIST_API = BASE_API_URL + "restapi/checkpanexistApi/"
export const ADVISORY_ASSIGN_ECAS_MEMBER = BASE_API_URL + "restapi/report/assignecasmember/";
export const ADVISORY_DISCONNECT_BROKER = BASE_API_URL + "restapi/disconnectbroker/"
export const ADVISORY_UNLINK_HOLDINGS = BASE_API_URL + "restapi/unlinkholdings/"
export const ADVISORY_GET_FP_LOG = BASE_API_URL + "get_fp_log/"
export const ADVISORY_GET_EPF_OTP = BASE_API_URL + "restapi/getepfotp/"
export const ADVISORY_CHECK_UAN_EXISTS = BASE_API_URL + "restapi/checkifuanexists/"
export const ADVISORY_SUBMIT_EPF_OTP = BASE_API_URL + "restapi/submitepfotp/"
export const ADVISORY_GET_EPF_PASSBOOK = BASE_API_URL + "restapi/getpassbook/"
export const ADVISORY_DELETE_EPF_HOLDINGS = BASE_API_URL + "restapi/deleteepfholdingsdata/"
export const ADVISORY_CHECK_EPF_FETCHED_TODAY = BASE_API_URL + "restapi/checkifepffetchedtoday/"
export const ADVISORY_FETCH_FP_AGREEMENT = BASE_API_URL + "restapi/expertflow/expertfp/"
export const ADVISORY_EXPERT_FP_DOC = BASE_API_URL + "restapi/expertflow/expertfpdoc/"
export const ADVISORY_EXPERT_FP_INFLOW_GET_RMEMAIl = BASE_API_URL + "restapi/expertflow/expertfinflowgetrmemail/"
export const ADVISORY_SIGNDESK_API_CALL = BASE_API_URL + "restapi/signdeskapicall/"
export const ADVISORY_SIGNDESK_API_CHECK = BASE_API_URL + "restapi/signdeskapicheck/"
export const ADVISORY_EXPERTFLOECOMMONAPI = BASE_API_URL + "expertflowcommonreactapi/"
export const ADVISORY_GET_SET_REPORT_STATUS = BASE_API_URL + "restapi/report/getsetreportpdfstatus/"
export const ADVISORY_DOWNLOAD_REPORT = BASE_API_URL + "report/downloadreport/"
export const ADVISORY_UPDATE_CURRENT_STEP = BASE_API_URL + "restapi/userflow/updatecurrentstep/"
export const ADVISORY_UPDATE_AUTH_DATA = BASE_API_URL + "restapi/updateauthdata/"
export const ADVISORY_SET_DEFAULT_ASSUMPTIONS = BASE_API_URL + "restapi/setdefaultassumptiondata/"
export const ADVISORY_ADD_BANK_DATA = BASE_API_URL + "restapi/addbankdata/"
export const ADVISORY_GET_BANK_DATA = BASE_API_URL + "restapi/getbankdata/"
export const ADVISORY_DELETE_BANK_DATA = BASE_API_URL + "restapi/deletebankdata/"
export const ADVISORY_FETCH_EPF_DATA = BASE_API_URL + "restapi/getepffetchedamount/"
export const ADVISORY_MF_GENERATE_TOKEN = BASE_API_URL + "restapi/mf-generate-token/";
export const ADVISORY_MF_ENCRYPT = BASE_API_URL + "restapi/mf-encrypt/";
export const ADVISORY_MF_DECRYPT = BASE_API_URL + "restapi/mf-decrypt/";
export const ADVISORY_MF_GENERATE_SIGNATURE = BASE_API_URL + "restapi/mf-generate-signature/";
export const ADVISORY_MF_SUBMIT_CAS_SUM_REQUEST = BASE_API_URL + "restapi/mf-submit-cas-sum-request/";
export const ADVISORY_MF_SUBMIT_CAS_DET_REQUEST = BASE_API_URL + "restapi/mf-submit-cas-det-request/";
export const ADVISORY_MF_INVESTOR_CONSENT = BASE_API_URL + "restapi/mf-investor-consent/";
export const ADVISORY_MF_GET_CAS_DOCUMENTS = BASE_API_URL + "restapi/mf-get-cas-documents/";
export const ADVISORY_MF_UPLOAD_FILES = BASE_API_URL + "restapi/mf-upload-files/";
export const ADVISORY_GET_MF_DATA = BASE_API_URL + "restapi/get-mf-data/";
export const ADVISORY_GET_ALL_CAS_DOCUMENTS = BASE_API_URL + "restapi/get-all-cas-documents/";
export const ADVISORY_FETCH_MF_CENTRAL_DATA = BASE_API_URL + "restapi/fetchmfcentraldata/";
export const ADVISORY_DELETE_MF_CENTRAL_DATA = BASE_API_URL + "restapi/deletemfcentraldata/";
export const GET_COACHMARK_STATUS = BASE_API_URL + "getCoachmarkStatus/"
export const VERIFY_GOOGLE_CAPTCHA = BASE_API_URL + "verfiyCaptchToken/"
export const ADVISORY_UPDATE_MULTI_ASSETS_API = BASE_API_URL + "restapi/multiassetsupdateApi/"
export const ADVISORY_LIABILITY_NETWORTH_DATA = BASE_API_URL + "restapi/report/getliabilityfundflow/"

// Digi Locker
export const auth_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY2MjAyODU5OSwianRpIjoiNDY1YzUzZDQtNTgyOC00NGI4LTgyMmQtMGU4NjY2NTYwZjc5IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmZpbnRvb0BzdXJlcGFzcy5pbyIsIm5iZiI6MTY2MjAyODU5OSwiZXhwIjoxOTc3Mzg4NTk5LCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsid2FsbGV0Il19fQ.XIzLSJnukwEGGznLCtNwwyrqWm6pWuwQIt9rzcqoZZ8"
export const digilocker_url = "https://kyc-api.aadhaarkyc.io/api/v1/digilocker"

export const imagePath2 = 'https://www.fintoo.in/image/?frontend=1&file=https://www.fintoo.in'

export const imagePath = window.location.hostname === "localhost"
  ? "https://www.fintoo.in/image/?frontend=1&file="
  : "/image/?frontend=1&file=";

//  Finvu Constant
export const FINVU_BASE_API_URL = "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/";
// export const FINVU_BASE_API_URL =  "https://dhanaprayoga.fiu.finfactor.in/finsense/API/V1/";
// export const CHATBOT_BASE_API_URL = "https://chatbot.wealthtech.ai/chatbot/";
export const CHATBOT_BASE_API_URL = "https://chatbot.wealthtech.ai/chatbot/";
export const GATEWAY_AUTH_NAME = "www.fintoo.in";
export const CHATBOT_BASE_API_URL_LOCAL = "http://127.0.0.1:8000/chatbot/";
export const FINTOO_BASE_API_URL = "https://www.fintoo.in/";
export const FINVU_WEBSOCKET_URL = "wss://webvwlive.finvu.in/consentapi";
// export const FINVU_WEBSOCKET_URL = "wss://webvwdev.finvu.in/consentapi";

// Live Finvu Creds
export const FINVU_USER_ID = "channel@fintoo";
export const FINVU_PASSWORD = "85a333fb49044c7e91611a0d962ff8ba";
export const FINVU_AAID = "cookiejaraalive@finvu";
export const FINVU_TEMPLATE_NAME = "BANK_STATEMENT_PERIODIC";

// export const CHATBOT_TOKEN_USERNAME = "localfrontend";
// export const CHATBOT_TOKEN_PASSWORD = "localfrontend@123";

export const CHATBOT_TOKEN_USERNAME = "prdfintoo";
export const CHATBOT_TOKEN_PASSWORD = "prdfintoo@123";

//Dollar to Rupees convertion rate
export const exchange_rate = 81.93

//master password
export const master_psw = "JH6LIA26RoSnKvQ1"