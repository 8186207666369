import React, { useEffect, useState } from 'react';
import style from '../../style.module.css';
import { indianRupeeFormat } from "../../../../common_utilities";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as toastr from "toastr";
import { FINTOO_BASE_API_URL } from '../../../../constants';

import {
    getMemberId,
    getUserId,
    removeMemberId,
    setFpUserDetailsId,
    setMemberId,
    setUserId
} from '../../../../common_utilities';



const SavingAccountInfoSection = (props) => {
    console.log('props.activeBank', props.activeBank)
    const [allAccData, setAllAccData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log("Props: ", props);
    const totalBanks = props.totalBanks;
    const totalBankBalance = props.totalBankBalance;
    const dashboardData = props.dashboardData;
    const accountNumbers = props.accountNumbers;
    const userContactNumber = props.userContactNumber;
    let user_details = "";

    const getMemberIdFn = () => {
        let isFamilySelected = Boolean(localStorage.getItem("family"));
        if (!isFamilySelected) {
            if (getMemberId()) {
                const memberId = getMemberId();
                return memberId;
            } else {
                const userId = getUserId();
                return userId;
            }
            // } else {
            //     let users = JSON.parse(commonEncode.decrypt(localStorage.getItem("member")));
            //     console.log("Users Data is here: ", users);
            //     const idsArray = users.map(item => String(item.id));
            //     console.log(idsArray);
            //     return idsArray;
        }
    };



    const FetchAccountTransactionsAPI = async (user_id, bank_accounts) => {
        var myHeaders = new Headers();
        const payload = {
            "user_id": parseInt(user_id),
            "bank_accounts": bank_accounts
        };
        myHeaders.append("Content-Type", "application/json");
        try {
            const response = await fetch(FINTOO_BASE_API_URL + "money_managment/fetch_account_trxns/", {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(payload),
            });
            if (response.ok) {
                const result = await response.json();
                // setStatementAccountsData(result.data[0]); 
                // setFlag(true);
                console.log("FetchAccountTransactionsAPI Response: ", result);
                dispatch({ type: "SET_STATEMENT_ACCOUNT_DATA", payload: result.data[0] });
                dispatch({ type: "SET_MAX_AMOUNT_LIMIT", payload: result.max_amount_limit });
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error("No transactions found");
        }
    };

    console.log('SavingAccountInfoSection', {
        totalBanks: totalBanks,
        totalBankBalance: totalBankBalance,
        dashboardData: dashboardData,
        accountNumbers: accountNumbers,
        userContactNumber, userContactNumber
    })

    const indianRupeesWithoutRupees = (balance) => {
        const currentBal = indianRupeeFormat(balance);
        const currentBalWithoutRupee = currentBal.replace('₹', '');
        return currentBalWithoutRupee;
    };

    function handleNavigationToBankOverview() {
        console.log("Going to Bank Tracking Overview Page..........");
        navigate('/web/money-management/bank-tracking-overview');
    };

    async function handleNavigationToDashboard(accountNo) {
        console.log("Going to Bank Tracking Overview Page..........");
        // await FetchAccountTransactionsAPI(user_details, accountNo);
        dispatch({ type: "SET_FETCH_TXN_DATA_ACCOUNT", payload: accountNo });
        navigate(`/web/money-management/map-transactions`);
        // window.location.href = `${process.env.PUBLIC_URL}/money-management/map-transactions`;

    };

    function handleNavigationToOverview() {
        navigate('/web/money-management/overview');
    };

    const handleClick = (accountNo) => {
        // Your click handling logic goes here
        console.log("Going to Bank Tracking Overview Page..........");
        navigate("/web/money-management/dashboard", {
            state: { accountNoList: [accountNo] },
        });
    };
    const formatBalance = (balance) => {
        if (balance >= 10000000) {
            return (balance / 10000000).toFixed(1) + 'Cr';
        } else if (balance >= 100000) {
            return (balance / 100000).toFixed(1) + 'L';
        } else if (balance >= 1000) {
            return (balance / 1000).toFixed(1) + 'K';
        } else {
            return indianRupeesWithoutRupees(balance);
        }
    };

    const handleAccountClick = (account) => {
        if (props.onAccountClick) {
            props.onAccountClick(account)
        } else {
            handleNavigationToDashboard([account.mm_account_masked_id])
        }
    }

    useEffect(() => {
        user_details = getMemberIdFn();
    });

    return (
        <div className='pt-4'>
            <div className={`GoalText ${props.headingColor ? '' : 'default-grey'}`} style={{ marginBottom: '1rem', marginLeft: '0', color: `${props.headingColor ? props.headingColor : ''}` }}>Account Balance</div>

            <div className={`autoAdvisory lifeInsurance ${style.savingAccountInfoSectionContainer}`}>
                <div className={`${style.SavingAccountInfoSectionAmountsMainContainer}`}>
                    <div className={`${style.SavingAccountInfoSectionAmountsContainer}`}>
                        <div className={`${style.SavingAccountInfoSectionTotalAmountContainer}`}>
                            <div>
                                <div className="TextLabel">
                                    Total Balance
                                </div>
                                <div className="valueLabel">
                                    &#8377;{" "}
                                    <span className="bigBalue">
                                        {formatBalance(totalBankBalance)}
                                    </span>
                                </div>
                            </div>
                            {
                                !Boolean(props.hideAddBtn) && <div className={`${style.showOverviewContainer}`} onClick={() => handleNavigationToOverview()} >{'Show Overview >'}
                                {/* <img style={{ width: '20px', cursor: 'pointer' }} src={process.env.REACT_APP_STATIC_URL + "media/MoneyManagement/rightrarrow.svg"} alt="" /> */}
                                </div>
                            }
                        </div>
                        <div className="ms-lg-4 ms-0 d-flex justify-content-end">
                            <div>
                                <div className="TextLabel">Active Accounts</div>
                                <div className="valueLabel">
                                    <span>
                                        {totalBanks.toString().padStart(2, '0')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${style.SavingAccountInfoSectionBanksListOuterContainer}`} style={{ position: 'relative' }}>
                    <div className={`${style.SavingAccountInfoSectionBanksListContainerGradElem}`}>
                    </div>
                    {dashboardData.length > 0 ? (
                        <div className={`${style.SavingAccountInfoSectionBanksListContainer}`}>
                            {dashboardData.map((account, index) => (
                                <div style={{ cursor: 'pointer' }} onClick={() => handleAccountClick(account)} className={`${style.SavingAccountInfoSectionBanksListItem} ${props.activeBank == account.mm_account_masked_id && style.SavingAccountInfoSectionBanksListItemActive}`} >
                                    <div className={`${style.SavingAccountInfoSectionBanksListItemDataContainer}`}>
                                        <div className={`${style.SavingAccountInfoSectionBanksListItemDataBankImg}`} style={{
                                            backgroundImage: `url(${process.env.REACT_APP_STATIC_URL + "media/bank_logo/" + account.mm_bank_logo})`,
                                        }} ></div>
                                        <div>
                                            <div>{account.mm_fip_name}</div>
                                            <div
                                                style={{
                                                    color: '#47494C',
                                                    fontSize: '0.875rem',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                }}
                                            >Acc no: {account.mm_account_masked_id}</div>
                                        </div>
                                    </div>
                                    <div >{'>'}</div>
                                    {/* <div style={{ cursor: 'pointer' }} onClick={handleNavigationToDashboard(account.mm_account_masked_id)}>{'>'}</div> */}
                                </div>

                            ))}
                        </div>
                    ) : (
                        <div>No accounts</div>
                    )}
                </div>

                {
                    !Boolean(props.hideAddBtn) && <div className={`${style.addAccountBtnContainer}`}>
                        <div className={`${style.btn1}`} onClick={handleNavigationToBankOverview} >
                            Add Account
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

export default SavingAccountInfoSection;
