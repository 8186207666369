import React from "react";
import { useEffect, useState } from "react";
import Styles from "./Style.module.css";
import { apiCall, getItemLocal, getUserId,getParentUserId,loginRedirectGuest, indianRupeeFormat } from "../../../common_utilities";
import { ADVISORY_INVOICE_API_URL, BASE_API_URL, CHECK_SESSION, STATIC_URL } from "../../../constants";
function Invoice() {
  const [invoicekey,setInvoiceKey] = useState('')
  const [invoice,setInvoice] = useState([])
  const userid = getParentUserId();
  useEffect(() =>{
    getBillingDetails()
  },[])

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      // loginRedirectGuest();
    }
  }, []);


  const getBillingDetails = async () => {
    try {
      let payload_data = { user_id: userid };
      let invoice_data_dict = await apiCall(ADVISORY_INVOICE_API_URL, payload_data, true, false);
      let invoice_data = {}
      if (invoice_data_dict.error_code == 100) {
        invoice_data = Object.keys(invoice_data_dict.data);
        console.log(invoice_data_dict)
        setInvoiceKey(invoice_data)
        setInvoice(invoice_data_dict.data)
        }
      }
    catch {
    }
  }

  return (
    <div>
      <div className={`col-md-12 ${Styles.InvoiceBox}`}
    
      >
        <h4>Completed Payments</h4>
        <div className={`${Styles.TableBox}`}>
          <table className={`${Styles.defaulttable}`}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Service</th>
                <th style={{ width: "20%" }}>Plan Name</th>
                {/* <th style={{ width: "20%" }}>Expert</th> */}
                <th style={{ width: "20%" }}>Amount</th>
                <th style={{ width: "20%" }}>Invoice Date</th>
                <th style={{ width: "20%" }} className="text-center">Download</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(invoicekey).length > 0 && invoicekey.map((key) => (
                <tr key={invoice[key].doc_id}>
                  <td>{invoice[key].servie_name_c}</td>
                  <td><span>{invoice[key].category_name_c}</span></td>
                  {/* <td>{invoice[key].expert_name}</td> */}
                  <td>{indianRupeeFormat(invoice[key]['data'][0].gross_amount)}</td>
                  <td>{invoice[key]['data'][0].invoice_date}</td>
                  <td className="text-center">
                    <a href={`${BASE_API_URL}restapi/userflow/downloadinvoice/${invoice[key].doc_name}`} className="download" download>
                      <img className="download-icon" alt="Download" src={STATIC_URL+"media/download.svg"}/>
                    </a>
                  </td>
                </tr>
              ))}
              {Object.keys(invoicekey).length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">No Invoices.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Invoice;