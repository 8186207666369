import styles from "./style.module.css";
// import Assumptions from "./Images/Assumptions.png";
// import FinancialGoalAdvice from "./Images/FinancialGoalAdvice.png";
// import FintooRecommends from "./Images/FintooRecommends.png";
// import Goal from "./Images/Goal.png";
// import RetirementPlanning from "./Images/RetirementPlanning.png";   
function FPReportBox() {
  return (
    <div className={`${styles.FPReport}`}>
      <div className={`${styles.FPReportBox}`}>
        <div className={`${styles.FPReportImg}`}>
        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/Assumptions.png'} alt="" />
          {/* <img src={Assumptions} /> */}
        </div>
        <div className={`ms-md-5 ${styles.FPReportBoxContent}`}>
          <p className={`${styles.FPReportBoxContentTitle}`}>Assumptions</p>
          <p className={`${styles.FPReportBoxContentText}`}>
            Helps to assume the future income, expense, and returns based on the
            average rate of inflation and past performance.
          </p>
        </div>
      </div>
      <div className={`${styles.FPReportBox}`}>
        <div className={`${styles.FPReportBoxContent}`}>
          <p className={`${styles.FPReportBoxContentTitle}`}>
            Net Worth Analysis
          </p>
          <p className={`${styles.FPReportBoxContentText}`}>
            Enables you to know your current net worth and compare it with your
            ideal net worth.
          </p>
        </div>
        <div className={`ms-md-5 ${styles.FPReportImg}`}>
        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/FinancialGoalAdvice.png'} alt="" />
          {/* <img src={FinancialGoalAdvice} /> */}
        </div>
      </div>
      <div className={`${styles.FPReportBox}`}>
        <div className={`${styles.FPReportImg}`}>
        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/FintooRecommends.png'} alt="" />
          {/* <img src={FintooRecommends} /> */}
        </div>
        <div className={`ms-md-5 ${styles.FPReportBoxContent}`}>
          <p className={`${styles.FPReportBoxContentTitle}`}>
            Fintoo Recommends
          </p>
          <p className={`${styles.FPReportBoxContentText}`}>
            Based on the difference between your current financial position and
            your ideal financial position, Fintoo recommends various ways via
            which the difference can be minimized or eliminated.
          </p>
        </div>
      </div>
      <div className={`${styles.FPReportBox}`}>
        <div className={`${styles.FPReportBoxContent}`}>
          <p className={`${styles.FPReportBoxContentTitle}`}>Goal Analysis</p>
          <p className={`${styles.FPReportBoxContentText}`}>
          Enables you to understand the potential of mapped assets to achieve your financial goals and recommends the ideal assets to achieve the goals.
          </p>
        </div>
        <div className={`ms-md-5 ${styles.FPReportImg}`}>
        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/Goal.png'} alt="" />
          {/* <img src={Goal} /> */}
        </div>
      </div>
      <div className={`${styles.FPReportBox}`}>
        <div className={`${styles.FPReportImg}`}>
        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/RetirementPlanning.png'} alt="" />
          {/* <img src={RetirementPlanning} /> */}
        </div>
        <div className={`ms-md-5 ${styles.FPReportBoxContent}`}>
          <p className={`${styles.FPReportBoxContentTitle}`}>
            Retirement Planning
          </p>
          <p className={`${styles.FPReportBoxContentText}`}>
            Along with all the financial goals that you wish to achieve, the
            customised financial plan also takes care of your retirement
            planning.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FPReportBox;
