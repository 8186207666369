import React, { useEffect, useState } from "react";
import FaqSection from "../components/HTML/IpoPage/FaqSection";
import IpoWhyTrustUsSection from "../components/HTML/IpoPage/WhyInvestIpoSection";
import IpoHeaderSection from "../components/HTML/IpoPage/IpoPageHeaderSection";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function IpoPage() {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <IpoHeaderSection />
        <IpoWhyTrustUsSection />
        {/* <IpoClientTestimonialSection /> */}
        <ClientTestimonial />
        {/* <IpoFeaturedIn /> */}

        {utmSource && tagval ? (
          <AppointmentBox
            extraParams={{ "utm_source": utmSource, "service": 98 }}
            eventCode={tagval}
            serviceName="IPO"
            eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-ipo?hide_event_type_details=1"}
            planId = "50"
          />
        ) : (
          <AppointmentBox
            eventCode={'Callback_mintyApp_113'}
            serviceName="IPO"
            eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-ipo?hide_event_type_details=1"}
            planId = "50"
          />
        )}
        {/* <AppointmentBox eventCode={'Callback_mintyApp_113'} serviceName="IPO" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-ipo?hide_event_type_details=1" /> */}


        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.IPO} onSuccess={() => SetShow(true)} /> */}
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
        <FaqSection />
      </div>
    </Fullpage>
  );
}

export default IpoPage;
