import React, { useEffect, useRef, useState } from "react";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import moment from "moment";
import Slider from "../../../components/HTML/Slider";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Cibilreport from './CIIBIL_Report/Cibil_Report.module.css';
import {
  ADVISORY_GETLIABILITIES_API_URL,
  ADVISORY_REMOVE_LIABILITIES_API_URL,
  ADVISORY_SAVE_UPDATE_LIABILITIES_API_URL,
  CHECK_SESSION,
  GET_MEMBER_LIST,
  imagePath,
  ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
  ADVISORY_UPDATE_FETCHEDLOANS_API,
  ADVISORY_UNLINK_EQUIFAXMEMBER_API
} from "../../../constants";
import {
  apiCall,
  calculateEMI,
  getItemLocal,
  getParentFpLogId,
  getParentUserId,
  getUserId,
  indianRupeeFormat,
  restApiCall,
  loginRedirectGuest
} from "../../../common_utilities";
import commonEncode from "../../../commonEncode";
import DGstyles from "../DG.module.css";
import Select from "react-select";
import { Buffer } from "buffer";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { Modal } from "react-bootstrap";
import customStyles from "../../../components/CustomStyles";
import FintooLoader from "../../../components/FintooLoader";
import Fetchloan from "./CIIBIL_Report/Fetchloan";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import FintooCheckbox from "../../../components/FintooCheckbox/FintooSubCheckbox";


const Liabilities = (props) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const removefixheader = document.querySelector('.d-block .removefixheader');
      const FixdgsubHeader = document.querySelector('.FixdgHeader');
      const FixdgmainHeader3 = document.querySelector('.DGheaderFix3');
      const scrollPosition = window.scrollY;
      if (removefixheader && FixdgsubHeader && FixdgmainHeader3) {
        const removefixheaderRect = removefixheader.getBoundingClientRect();
        const threshold2 = 70;
        if(props.tab === "tab2"){
          if (removefixheaderRect.top > 130 && scrollPosition > 50) {
            setScroll(true)
            FixdgsubHeader.classList.add("DGsubheaderFix");
            if (removefixheaderRect.top <= threshold2) {
              FixdgmainHeader3.classList.remove("DGmainHeaderFix");
            }
            else {
              FixdgmainHeader3.classList.add("DGmainHeaderFix");
            }
          } else {
            setScroll(false);
            FixdgsubHeader.classList.remove("DGsubheaderFix");
            FixdgmainHeader3.classList.remove("DGmainHeaderFix");
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, [props.tab]);
  // const customStyles = {
  //   option: (base, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...base,
  //       backgroundColor: isFocused ? "#ffff" : "#042b62",
  //       color: isFocused ? "#000" : "#fff",
  //       cursor: "pointer",
  //     };
  //   },
  //   menuList: (base) => ({
  //     ...base,
  //     height: "100px",
  //     overflowY: "scroll",
  //     scrollBehavior: "smooth",
  //     "::-webkit-scrollbar": {
  //       width: "4px",
  //       height: "0px",
  //     },
  //     "::-webkit-scrollbar-track": {
  //       background: "#fff",
  //     },
  //     "::-webkit-scrollbar-thumb": {
  //       background: "#042b62",
  //     },
  //     "::-webkit-scrollbar-thumb:hover": {
  //       background: "#555",
  //     },
  //   }),
  // };
  const liability_option = [
    {
      id: 24,
      title: "Business Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_business.svg",
    },
    {
      id: 23,
      title: "Car Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_car.svg",
    },
    {
      id: 27,
      title: "Consumer Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_consumer.svg",
    },
    {
      id: 22,
      title: "Credit Card",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_mortgage.svg",
    },
    {
      id: 21,
      title: "Housing",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_housing.svg",
    },
    {
      id: 28,
      title: "Loan From Family And Friends",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_famil_friends.svg",
    },
    {
      id: 25,
      title: "Other Property Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_other_property_loan.svg",
    },
    {
      id: 26,
      title: "Personal Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_personal.svg",
    },
    {
      id: 30,
      title: "Fetch Loan",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/fetch_loan.svg",
    },
    {
      id: 127,
      title: "Others",
      img:
        imagePath +
        "https://static.fintoo.in/static/assets/img/assets-liabilities/fetch_loan.svg",
    },
  ];

  const defaultLiabilitiesDetails = {
    liability_name: "Business Loan",
    liability_category_type: "Business Loan",
    liability_category_id: "24",
    liability_outstanding_amount: "",
    user_id: 0,
    fp_log_id: 0,
    liability_end_date: "",
    liability_member_id: 0,
    liability_asset_id: 0,
    liability_frequency: "1",
    liability_emi: "",
    current_emi: "",
    liability_emi_rate: 7,
    liability_footnote: "",
    liability_type: ""
  };

  const defaultHandleError = {
    liability_name: "",
    liability_outstanding_amount: "",
    liability_end_date: "",
    current_emi: "",
  };

  const defaultLiabilityChecks = {
    checkEMI: 0,
    notCalculated: "0",
    checkLiabilityOutstandingAmount: "0",
    checkLiabilityEndDate: "0",
    checkEMIRate: "0",
  };

  const [liabilitiesData, setLiabilitiesData] = useState(
    defaultLiabilitiesDetails
  );
  const [openModalByName, setOpenModalByName] = useState("");
  const memberData = props.familyData;
  const isFetch = props?.isFetch;
  const [liabilityCheck, setLiabilityCheck] = useState(defaultLiabilityChecks);
  const [handlerror, setHandleError] = useState(defaultHandleError);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Business Loan");
  const [session, setSession] = useState("");
  const [fplogid, setFpLogId] = useState("");
  const [equifaxData, setEquifaxData] = useState([]);

  const [liabilityData, setLiabilityData] = useState([]);
  const [liabilityId, setLiabilityId] = useState("");
  const [showview, setShowView] = useState(true);
  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [isPlan, setIsPlan] = useState(true);
  const [deletetoggleliabilities, setDeleteToggleliabilities] = useState(false);
  const [selectedliabilitiesCategories, setSelectedliabilitiesCategories] = useState([]);
  const frequencylist = {
    1: "Monthly",
    2: "Quarterly",
    3: "Half Yearly",
    4: "Yearly",
  };
  const handleClose = (type, form) => {
    if (type == "yes" && form == "liability") {
      removeLiability(liabilityId);
    } else {
      setShow(false);
    }
  };
  const handleShow = () => setShow(true);
  const userid = getParentUserId();
  const fp_log_id = getParentFpLogId();
  const cntRef = useRef(null);

  useEffect(() => {
    if (!liabilitiesData.liability_outstanding_amount) {
      setLiabilitiesData({
        ...liabilitiesData,
        current_emi: "",
      });
    }
  }, [liabilitiesData?.liability_outstanding_amount]);

  useEffect(() => {
    setLiabilitiesData({
      ...liabilitiesData,
      liability_emi: "",
    });
    let emiAmt = 0;
    let not_calculated = "1";
    if (
      liabilityCheck.checkLiabilityOutstandingAmount !==
      liabilitiesData.liability_outstanding_amount ||
      liabilityCheck.checkLiabilityEndDate !==
      liabilitiesData.liability_end_date ||
      liabilityCheck.checkEMIRate !== liabilitiesData.liability_emi_rate
    ) {
      setLiabilityCheck({
        ...liabilityCheck,
        notCalculated: "0",
      });
      not_calculated = "0";
    }
    if (
      liabilitiesData.liability_emi_rate &&
      liabilitiesData.liability_end_date &&
      liabilitiesData.liability_outstanding_amount &&
      not_calculated === "0"
    ) {
      let currentDate = new Date();
      let targetDateStr = liabilitiesData.liability_end_date;
      let [targetDay, targetMonth, targetYear] = targetDateStr.split("/");
      let targetDateFormatted = `${targetYear}-${targetMonth}-${targetDay}`;
      let targetDate = new Date(targetDateFormatted);
      let currentDay = currentDate.getDate();
      targetDay = targetDate.getDate();

      let monthsDiff =
        (targetDate.getFullYear() - currentDate.getFullYear()) * 12 +
        (targetDate.getMonth() - currentDate.getMonth());

      if (currentDay < targetDay) {
        monthsDiff++;
      }

      let principalAmount = liabilitiesData.liability_outstanding_amount;
      let interestRatePerAnnum = liabilitiesData.liability_emi_rate;
      let loanTenureInMonths = monthsDiff;

      emiAmt = calculateEMI(
        principalAmount,
        interestRatePerAnnum,
        loanTenureInMonths
      );

      // emiAmt = PMT(
      //   (liabilitiesData.liability_emi_rate * 0.01) / 12,
      //   liabilitiesData.liability_end_date,
      //   liabilitiesData.liability_outstanding_amount
      // );

      if (`${emiAmt}`.length > 9) {
        emiAmt = Math.round(emiAmt);
      }
      setLiabilitiesData({
        ...liabilitiesData,
        current_emi: emiAmt,
      });
      setLiabilityCheck({
        checkEMI: emiAmt,
      });
    }
  }, [
    liabilitiesData?.liability_emi_rate,
    liabilitiesData?.liability_end_date,
    liabilitiesData?.liability_outstanding_amount,
  ]);

  useEffect(() => {
    let currentEMI = liabilitiesData.current_emi;
    let liabilityEMI = "";
    if (liabilityCheck.checkEMI != liabilitiesData.current_emi) {
      setLiabilitiesData({
        ...liabilitiesData,
        liability_emi: currentEMI,
      });
      liabilityEMI = currentEMI;
    }
    if (liabilityEMI) {
      if (liabilityCheck.checkEMI != liabilitiesData.current_emi) {
        setLiabilitiesData({
          ...liabilitiesData,
          currentEMI: liabilityEMI,
        });
      }
    }
  }, [liabilitiesData?.current_emi]);

  useEffect(() => {
    document.body.classList.add("dg-layout");
    return () => {
      document.body.classList.remove("rp-layout");
    };
  }, []);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    }
  }, []);

  useEffect(() => {
    if (isFetch) {
      setSelectedOption("Fetch Loan");
    }

    checkSession();
  }, []);

  const handleDisableRefresh = () => {
    toastr.options.positionClass = "toast-bottom-left";
    toastr.error("Please note that you can refresh your CIBIL score after a 30-day waiting period from the last updated date.");
  };

  const onLoadInit = async (session) => {
    try {
      getEquifaxData(session);
    } catch (e) {
      console.log(e);
    }
  };

  const getEquifaxData = async (session_data) => {
    try {
      let reqdata = {
        user_id: session_data.id,
        fp_log_id: session_data.fp_log_id,
      };
      let respData = await restApiCall(
        ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
        reqdata,
        true,
        false
      );

      if (respData.error_code == "100") {
        setEquifaxData(respData.data);
        if (respData.valid_members.length > 0) {
          const all = respData.valid_members.map((v) => (
            v.relation_id == 1 ?
              {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name + " (Self)",
                value: v.id,
              } :
              {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name,
                value: v.id,
              }
          ))
          setAllMembers([...all]);
        }
        else {
          setAllMembers([]);
        }
      }
      else {
        setEquifaxData([]);
        setAllMembers([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const scrollToForm = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop - 50 });
  };

  const scrollToList = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop - 1500 });
  };

  useEffect(() => {
    if (liabilitiesData.liability_name != "") {
      setHandleError({
        ...handlerror,
        liability_name: "",
      });
    }
    if (liabilitiesData.liability_outstanding_amount != "") {
      setHandleError({
        ...handlerror,
        liability_outstanding_amount: "",
      });
    }
    if (liabilitiesData.current_emi != "") {
      setHandleError({
        ...handlerror,
        current_emi: "",
      });
    }
    if (liabilitiesData.liability_end_date != null) {
      setHandleError({
        ...handlerror,
        liability_end_date: "",
      });
    }
    if (liabilitiesData.current_emi && liabilitiesData.current_emi.toString().length >= 9) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter less than 9 digits",
      });
    }

    if (liabilitiesData.current_emi && liabilitiesData.current_emi.toString().length < 10) {
      setHandleError({
        ...handlerror,
        current_emi: "",
      });
    }
    if (liabilitiesData.current_emi && liabilitiesData.current_emi.toString() == "0") {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter EMI amount",
      });
    }
  }, [
    liabilitiesData.liability_name,
    liabilitiesData.liability_outstanding_amount,
    liabilitiesData.current_emi,
    liabilitiesData.liability_end_date,
  ]);

  const checkSession = async () => {
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let session_data = await apiCall(url, data, true, false);

    if (session_data.error_code == "100") {
      setSession(session_data["data"]);
      setFpLogId(fp_log_id);

      setIsDataLoading(true);
      setLiabilitiesData({
        ...liabilitiesData,
        liability_member_id: session_data['data']['fp_user_id']
      })
      var liability_payload = await apiCall(
        ADVISORY_GETLIABILITIES_API_URL +
        "?user_id=" +
        Buffer.from(
          commonEncode.encrypt(userid.toString())
        ).toString("base64") +
        "&fp_log_id=" +
        Buffer.from(
          commonEncode.encrypt(fp_log_id.toString())
        ).toString("base64") +
        "&web=1"
      );
      await new Promise((resolve, reject) => setTimeout(resolve, 2000));
      setIsDataLoading(false);

      var liability_res = liability_payload;
      if (liability_res["error_code"] == "100") {
        setLiabilityData(liability_res.data);
      } else {
        setLiabilityData([]);
      }

      onLoadInit(session_data["data"]);

    }
  };

  const getLiabilityList = async (fplogid) => {
    try {
      let url =
        ADVISORY_GETLIABILITIES_API_URL +
        "?user_id=" +
        Buffer.from(commonEncode.encrypt(userid.toString())).toString(
          "base64"
        ) +
        "&fp_log_id=" +
        Buffer.from(
          commonEncode.encrypt(fp_log_id.toString())
        ).toString("base64") +
        "&web=1";
      let liability_payload = await apiCall(url, "", true, false);
      var liability_res = liability_payload;
      if (liability_res["error_code"] == "100") {
        setLiabilityData(liability_res.data);
      } else {
        setLiabilityData([]);
      }
    } catch {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const handleUnlink = async (equifax_id) => {
    var selected_name = "";
    equifaxData.filter((item) => {
      if (item.id == equifax_id) {
        selected_name = item.first_name;
      }
    });

    try {
      let reqdata = {
        fp_equifax_id: equifax_id
      };
      let respData = await restApiCall(
        ADVISORY_UNLINK_EQUIFAXMEMBER_API,
        reqdata,
        true,
        false
      );

      if (respData.error_code == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(selected_name + "'s loan has been unlinked successfully.");
        getEquifaxData(session);
        getLiabilityList();
      } else {
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.success(respData.message);
        console.log(respData.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefreshLoans = async (equifax_id, member_id, selected_name) => {
    try {
      var payload = {
        "fp_equifax_id": equifax_id,
        "liability_member_id": member_id,
        "user_id": session.id,
        "fp_log_id": session.fp_log_id
      };

      let respData = await restApiCall(
        ADVISORY_UPDATE_FETCHEDLOANS_API,
        payload,
        true,
        false
      );

      if (respData.error_code == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(selected_name + "'s loan has been refreshed successfully.");
        getEquifaxData(session);
        getLiabilityList();
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong!");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateForm(false);

    let f_errors = {};

    if (liabilitiesData.liability_name == "") {
      // setHandleError({
      //   ...handlerror,
      //   liability_name: "Please enter goal name",
      // });
      f_errors["liability_name"] = "Please enter goal name";
    }

    if (liabilitiesData.liability_outstanding_amount == "") {
      // setHandleError({
      //   ...handlerror,
      //   liability_outstanding_amount: "Please enter current outstanding amount",
      // });
      f_errors["liability_outstanding_amount"] =
        "Please enter current outstanding amount";
    }
    if (liabilitiesData.current_emi == "") {
      // setHandleError({
      //   ...handlerror,
      //   current_emi: "Please enter the liability current emi",
      // });

      f_errors["current_emi"] = "Please enter EMI amount";
    } else if (liabilitiesData.current_emi <= 0) {
      // setHandleError({
      //   ...handlerror,
      //   current_emi: "Please enter EMI amount",
      // });
      f_errors["current_emi"] = "Please enter the liability current emi";
    } else if (liabilitiesData.current_emi.toString().length >= 9) {
      // setHandleError({
      //   ...handlerror,
      //   current_emi: "Please enter less than 9 digits",
      // });
      f_errors["current_emi"] = "Please enter less than 9 digits";
    } else {
      var liability_outstanding_amount = liabilitiesData.liability_outstanding_amount;
      if (parseInt(liabilitiesData.current_emi) > parseInt(liability_outstanding_amount)) {
        //  setHandleError({
        //    ...handlerror,
        //    current_emi: "Please enter EMI amount less than outstanding amount",
        //  });
        f_errors["current_emi"] = "Please enter EMI amount less than outstanding amount";
      }
    }
    if (
      liabilitiesData.liability_end_date == null ||
      liabilitiesData.liability_end_date == ""
    ) {
      // setHandleError({
      //   ...handlerror,
      //   liability_end_date: "Please select end date",
      // });
      f_errors["liability_end_date"] = "Please select end date";
    }

    if (f_errors) {
      setHandleError((previous) => ({
        ...previous,
        ...f_errors,
      }));
    }

    if (
      liabilitiesData.liability_name != "" &&
      liabilitiesData.liability_outstanding_amount != "" &&
      liabilitiesData.current_emi != "" &&
      liabilitiesData.liability_end_date != null &&
      liabilitiesData.current_emi.toString().length < 10 &&
      liabilitiesData.current_emi != 0 &&
      liabilitiesData.current_emi > 0 &&
      parseInt(liabilitiesData.current_emi) < parseInt(liabilitiesData.liability_outstanding_amount)
    ) {
      saveLiability(e);
    }
  };

  const saveLiability = async (e) => {
    try {
      let url = ADVISORY_SAVE_UPDATE_LIABILITIES_API_URL;
      var payload = liabilitiesData;
      if ("id" in liabilitiesData) {
        payload["liability_id"] = liabilitiesData.id;
      }
      // payload.current_emi = liabilitiesData.liability_emi;
      payload.user_id = getParentUserId();
      payload.fp_log_id = getParentFpLogId();
      console.log("getParentFpLogId()", getParentFpLogId());
      setLoading(true);
      var res = await apiCall(url, payload, false, false);
      if ((res.error_code = "100")) {
        getLiabilityList(fp_log_id);
        scrollToList();
        setLiabilitiesData({
          ...defaultLiabilitiesDetails,
          liability_member_id: session.fp_user_id,
        });
        setSelectedOption("Business Loan");
        setHandleError({
          ...handlerror,
          liability_name: "",
          liability_outstanding_amount: "",
          liability_end_date: "",
          current_emi: "",
        });
        var savetext = liabilitiesData.id ? " updated " : " saved ";
        var msg = liabilitiesData.liability_name
          ? " - " + liabilitiesData.liability_name
          : "";
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          "Data" +
          savetext +
          "successfully for " +
          liabilitiesData.liability_category_type +
          msg
        );
        setAddForm(true);
        setUpdateForm(false);
        setLoading(false);
      } else {
        setLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const cancelFormData = async (e) => {
    e.preventDefault();
    setAddForm(true);
    setUpdateForm(false);
    scrollToList();
    setLiabilitiesData({
      ...defaultLiabilitiesDetails,
      liability_member_id: session.fp_user_id,
    });
    setSelectedOption("Business Loan");
    setHandleError({
      ...handlerror,
      liability_name: "",
      liability_outstanding_amount: "",
      liability_end_date: "",
      current_emi: "",
    });
  };

  const updateFormData = async (e) => {
    setUpdateForm(true);
    e.preventDefault();
    if (liabilitiesData.liability_name == "") {
      setHandleError({
        ...handlerror,
        liability_name: "Please enter goal name",
      });
    }
    if (liabilitiesData.liability_outstanding_amount == "") {
      setHandleError({
        ...handlerror,
        liability_outstanding_amount: "Please enter current outstanding amount",
      });
    }
    if (liabilitiesData.current_emi == "") {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter the liability current emi",
      });
    }
    if (liabilitiesData.current_emi <= 0) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter EMI amount",
      });
    }
    if (liabilitiesData.current_emi.toString().length >= 9) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter less than 9 digits",
      });
    }
    else if (parseInt(liabilitiesData.current_emi) > parseInt(liabilitiesData.liability_outstanding_amount)) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter EMI amount less than outstanding amount",
      });
    }
    if (liabilitiesData.liability_end_date == null) {
      setHandleError({
        ...handlerror,
        liability_end_date: "Please enter liability end date",
      });
    }

    if (
      liabilitiesData.liability_name != "" &&
      liabilitiesData.liability_outstanding_amount != "" &&
      liabilitiesData.current_emi != "" &&
      liabilitiesData.liability_end_date != null &&
      liabilitiesData.current_emi.toString().length < 10 &&
      liabilitiesData.current_emi != 0 &&
      liabilitiesData.current_emi > 0 &&
      parseInt(liabilitiesData.current_emi) < parseInt(liabilitiesData.liability_outstanding_amount)
    ) {
      saveLiability();
    }
  };

  const editLiability = async (id) => {
    try {
      let liability_id = id;
      setLiabilityId(id);
      let url =
        ADVISORY_GETLIABILITIES_API_URL +
        "?liability_id= " +
        Buffer.from(commonEncode.encrypt(liability_id.toString())).toString(
          "base64"
        ) +
        "&web=1";

      var getliab_payload = await apiCall(url);

      var lib_res = getliab_payload;
      if (lib_res["error_code"] == "100") {
        if (lib_res.data.length > 0) {
          let liabData = lib_res["data"]["0"];
          if (liabData["liability_emi"]) {
            liabData["current_emi"] = liabData["liability_emi"];
            setLiabilityCheck({
              notCalculated: "1",
              checkLiabilityOutstandingAmount:
                liabData["liability_outstanding_amount"],
              checkLiabilityEndDate: liabData["liability_end_date"],
              checkEMIRate: liabData["liability_emi_rate"],
            });
          }
          setLiabilitiesData(liabData);
          scrollToForm();
          setSelectedOption(liabData["categoryname"]);
          setHandleError({
            ...handlerror,
            liability_name: "",
            liability_outstanding_amount: "",
            liability_end_date: "",
            current_emi: "",
          });
          toastr.options.positionClass = "toast-bottom-left";
          var msg = liabData["liability_name"]
            ? " - " + liabData["liability_name"]
            : " ";
          toastr.success(
            " You can now edit details for " + liabData["categoryname"] + msg
          );
        }
      } else {
        setLiabilityData([]);
        setLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const removeLiability = async (id) => {

    const equifaxIds = liabilityData
      .filter(item => item.liability_type === "equifax")
      .map(item => item.id);
    const status = equifaxIds.length > 0 ? equifaxIds.every(id => selectedliabilitiesCategories.includes(id)) : false;
    const ids = equifaxData.map(data => data.id);

    try {
      let url = ADVISORY_REMOVE_LIABILITIES_API_URL;
      var remove_payload = {
        liability_id: selectedliabilitiesCategories,
        deletedflag: status,
        equifax_unique_id: ids,
        user_id: session.id,
        fp_log_id: fp_log_id,
      };
      // setLoading(true);
      let deleteLiabilityData = await apiCall(url, remove_payload, true, false);
      if (deleteLiabilityData["error_code"] == "100") {
        setShow(false);
        // setLoading(false);
        getLiabilityList(session["user_details"]["fp_log_id"]);
        getEquifaxData(session);
        toastr.options.positionClass = "toast-bottom-left";
        var msg = liabilitiesData.liability_name
          ? " - " + liabilitiesData.liability_name
          : " ";
        // toastr.success(selectedOption + msg + " has been deleted successfully");
        toastr.success(" Data been deleted successfully");
        setLiabilitiesData({
          ...defaultLiabilitiesDetails,
          liability_member_id: session.fp_user_id,
        });
        setHandleError(defaultHandleError);
        setSelectedOption("Business Loan");
        setAddForm(true);
        setUpdateForm(false);
        scrollToList();
        setSelectedliabilitiesCategories([]);
      } else {
        // setLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleLiabilityName = (name) => {
    if (name == "") {
      setHandleError({
        ...handlerror,
        liability_name: "Please enter liability name",
      });
    } else if (name.length < 3 || name.length > 35) {
      setHandleError({
        ...handlerror,
        liability_name: "Name must be between 3-35 characters",
      });
    } else {
      setHandleError({ ...handlerror, liability_name: "" });
    }
  };

  const handleLiabilityOutstandingValue = (currentvalue) => {
    let f_error = {};
    if (currentvalue == "") {
      f_error["liability_outstanding_amount"] =
        "Please enter current outstanding amount";
    } else if (currentvalue <= 0) {
      f_error["liability_outstanding_amount"] =
        "Please enter a value greater than or equal to 1";
    } else {
      f_error["liability_outstanding_amount"] = "";
    }

    setHandleError((previous) => ({
      ...previous,
      ...f_error,
    }));
  };

  const handleCurrentEMI = (currentvalue) => {
    if (currentvalue == "") {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter EMI amount",
      });
    }
  };

  const handleLiabilityEMIValue = (currentvalue) => {
    if (currentvalue <= 0) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter EMI amount",
      });
    }
    if (currentvalue.toString().length > 9) {
      setHandleError({
        ...handlerror,
        current_emi: "Please enter less than 9 digits",
      });
    }
    if (currentvalue == null) {
      setHandleError({
        ...handlerror,
        liability_end_date: "Please enter liability end date",
      });
    }
  };

  const handleEndDate = (enddate) => {
    handlerror.liability_end_date = "Please select end date";
    if (enddate == "") {
      setHandleError({
        ...handlerror,
        liability_end_date: "Please select end date",
      });
    } else {
      setHandleError({ ...handlerror, liability_end_date: "" });
    }
  };

  const handleCategoryClick = (liab, type) => {
    if (type == "liability") {
      const { id, ...updatedLiabilitiesData } = liabilitiesData;

      setLiabilitiesData({
        ...updatedLiabilitiesData, // Spread the updated object
        liability_name: liab.title,
        liability_category_id: liab.id,
        liability_end_date: null,
        liability_outstanding_amount: "",
        liability_footnote: "",
        liability_emi: "",
        liability_member_id: session.fp_user_id,
        liability_emi_rate: 7,
        liability_type: "",
        current_emi: "",
      });
      setAddForm(true);
      setUpdateForm(false);
    }
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  function calculateTotalLiabilities() {
    if (!liabilityData) return 0;

    return liabilityData.reduce((total, liab) => {
      if (liab.liability_outstanding_amount) {
        const outstandingamount = parseFloat(liab.liability_outstanding_amount);
        return total + outstandingamount;
      }
    }, 0);
  }

  const CloseLoanModal = () => {
    setOpenModalByName(null);
  }

  const Info = styled(ReactTooltip)`
  max-width: 278px;
  padding-top: 9px;
  background: "#fff";
`;
  const InfoMessage = styled.p`
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
`;

  return (
    <div>
      <FintooLoader isLoading={loading} />
      <div className="row">
      <div className="col-md-10">
          <div >
            <div className="shimmercard br hide" id="liability-shimmer">
              <div className="wrapper">
                <div className="comment br animate w80" />
                <div className="comment br animate" />
              </div>
            </div>
          </div>
          {/* ngIf: liabilitydata.length */}
          <div style={{height : liabilityData && liabilityData.length >  0 ? "58px" : null }} className={` d-flex align-items-center top-tab-menu justify-content-between ${ liabilityData && liabilityData.length > 0 &&  isDataLoading == false ? "FixdgHeader" : null}`}>
            <div className="d-flex align-items-center">
              {
                liabilityData && liabilityData.length > 0 && (
                  <FintooCheckbox
                    checked={selectedliabilitiesCategories.length === liabilityData.length}
                    onChange={() => {
                      if (selectedliabilitiesCategories.length === liabilityData.length) {
                        setSelectedliabilitiesCategories([]);
                        setDeleteToggleliabilities(false);
                      } else {
                        const allIds = liabilityData.map(Lib => Lib.id);
                        setSelectedliabilitiesCategories(allIds);
                        setDeleteToggleliabilities(true);
                      }
                    }}
                  />
                )}
              <div className="">
                {liabilityData && liabilityData.length > 0 && (
                  <div
                    className="total-amt"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {" "}
                    Total Liability:{" "}
                    <span>
                      {indianRupeeFormat(Number(calculateTotalLiabilities()))}{" "}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div>
              {
                selectedliabilitiesCategories.length>0 &&
                liabilityData.length>0 &&
                deletetoggleliabilities == true && (
                  <span
                    onClick={() => {
                      handleShow();
                      // setInsuranceId(i.id),
                      // setInsuranceName(i.insurance_name),
                      // setinsuranceCategoryType(
                      //   i.insurance_category_name
                      // );
                    }}
                    style={{
                      marginRight: "2rem",
                      cursor: "pointer"
                    }}
                    className="opt-options-2 pointer"
                  >
                    <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                  </span>
                )
              }
            </div>
          </div>
          <div className="inner-box" style={{margin : "11px 0"}}>
            {isDataLoading && (
              <div>
                <div className=" inner-container mt-4 pt-4">
                  <div
                    className="shine w-25 mb-1"
                    style={{ height: ".7rem" }}
                  ></div>
                  <div
                    className="shine w-100"
                    style={{ height: ".7rem" }}
                  ></div>
                </div>
                <div className=" inner-container mt-4 pt-4">
                  <div
                    className="shine w-25 mb-1"
                    style={{ height: ".7rem" }}
                  ></div>
                  <div
                    className="shine w-100"
                    style={{ height: ".7rem" }}
                  ></div>
                </div>
              </div>
            )}
            <div className="" style={{ margin: "0px 0" }}>
              {isDataLoading == false &&
                liabilityData &&
                liabilityData.map((lib) => (
                  <div className="d-flex align-items-center">
                    <FintooCheckbox
                      id={lib.id}
                      checked={selectedliabilitiesCategories.includes(lib.id)}
                      title={lib.title}
                      onChange={() => {
                        setSelectedliabilitiesCategories((prevSelected) => {
                          if (prevSelected.includes(lib.id)) {
                            const updatedSelection = prevSelected.filter((id) => id !== lib.id);
                            setDeleteToggleliabilities(updatedSelection.length > 0); // Check if any checkbox is still selected
                            return updatedSelection;
                          } else {
                            setDeleteToggleliabilities(true);
                            return [...prevSelected, lib.id];
                          }
                        });
                      }}
                    />
                    <div key={lib} className="inner-container mt-4 w-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>
                          {lib.categoryname}{" "}
                          {lib.liability_name ? " - " + lib.liability_name : ""}
                          {lib.liability_type == "equifax" ?
                            " (Fetched)" : ""
                          }
                        </h4>
                        {lib.incomplete_flag &&
                          <div className="d-flex  w-100" style={{
                            justifyContent: "right"
                          }}>
                            <div className="incompleteMsg" style={{ padding: ".2rem .8rem", color: "red", background: 'rgba(255, 0, 0, 0.10)', borderRadius: 4, fontSize: ".9rem" }}> Incomplete  <span className="info-hover-box ms-2">
                              <span className="icon">
                                <img
                                  width={15}
                                  alt="More information"
                                  src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                />
                              </span>
                              <span style={{ color: "#212529" }} className="msg">
                                We've fetched this loan details from Equifax. The details received from them are incomplete, we request you to click on edit button and fill the blank fields.
                              </span>
                            </span>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-4">
                          <div className="display-style">
                            <span>
                              Value ({frequencylist[lib.liability_frequency]}):
                            </span>
                            <p
                              className="invest-show "
                              title={lib.liability_emi && `${indianRupeeFormat(lib.liability_emi)}`
                              }
                            >
                              {lib.liability_emi &&
                                indianRupeeFormat(lib.liability_emi)
                              }
                              {/* {indianRupeeFormat(lib.liability_emi)} */}
                            </p>
                          </div>
                        </div>
                         <div className="col-md-3">
                          <div className="display-style"></div>
                          {/* <div className="display-style">
                            <span>Mapped Asset:</span>
                            <p>
                              {lib.related_asset_name
                                ? lib.related_asset_name
                                : "Not added"}
                            </p>
                          </div> */}
                        </div> 
                        <div className="col-md-3">
                          <div className="display-style">
                            <span>Member:</span>
                            <p
                              style={{
                                textOverflow: "ellipsis",
                                width: "187px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textAlign: "left",
                              }}
                            >
                              {lib.membername ? lib.membername : "Not added"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="opt-options">
                            <span>
                              <BsPencilFill
                                onClick={() => {
                                  editLiability(lib.id);
                                  setUpdateForm(true);
                                  setAddForm(false);
                                }}
                              />
                            </span>

                            {/* <span
                            onClick={() => {
                              handleShow();
                              setLiabilityId(lib.id);
                            }}
                            className="opt-options-2"
                          >
                            <MdDelete />
                          </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={`removefixheader fetchloanbox ${liabilityData && liabilityData.length > 0 ? "mt-3" : null}`} >
            <img className="d-none d-md-block" style={{ width: "100%", height: "100%" }}
              onClick={() => {
                setOpenModalByName("Fecth_your_Loan")
              }}
              src={process.env.REACT_APP_STATIC_URL + "media/DG/Fecthloan.png"} />
            <img className="d-md-none d-block pointer" style={{ width: "100%", height: "100%" }}
              onClick={() => {
                setOpenModalByName("Fecth_your_Loan")
              }}
              src={process.env.REACT_APP_STATIC_URL + "media/DG/mobileFetchLoan.png"} />
          </div>
          {/* {liabilityData &&
              liabilityData.map((lib) => (
                <div key={lib} className="inner-container mt-4">
                   {
                      isDataLoading == false && (
                        <h4>
                        {lib.categoryname}{" "}
                        {lib.liability_name ? " - " + lib.liability_name : ""}
                        </h4>
                      )
                   }
                 
                  {
                      isDataLoading == false && (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="display-style">
                              <span>
                                Value ({frequencylist[lib.liability_frequency]}):
                              </span>
                              <p
                                className="invest-show "
                                title={`${indianRupeeFormat(lib.liability_emi)}`}
                              >
                                {indianRupeeFormat(lib.liability_emi)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="display-style">
                              <span>Mapped Asset:</span>
                              <p>
                                {lib.related_asset_name
                                  ? lib.related_asset_name
                                  : "Not added"}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="display-style">
                              <span>Member:</span>
                              <p
                                style={{
                                  textOverflow: "ellipsis",
                                  width: "187px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                }}
                              >
                                {lib.membername ? lib.membername : "Not added"}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="opt-options">
                              <span>
                                <BsPencilFill
                                  onClick={() => {
                                    editLiability(lib.id);
                                    setUpdateForm(true);
                                    setAddForm(false);
                                  }}
                                />
                              </span>
      
                              <span
                                onClick={() => {
                                  handleShow();
                                  setLiabilityId(lib.id);
                                }}
                                className="opt-options-2"
                              >
                                <MdDelete />
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                   }
                 
                </div>
              ))} */}


        </div>
        <div className="col-md-12 col-lg-10 mt-4">
          <div className="accordion">
            <div className="accordion-panel active" id="assetbox">
              <div className="accordion-header d-flex justify-content-between">
                <h4 className="accordion-heading">
                  <img
                    alt="Asset"
                    className="accordian-img"
                    src={
                      imagePath +
                      "https://static.fintoo.in/static/assets/img/assets-liabilities/liability_top.svg"
                    }
                  />
                  <span
                    style={{
                      fontWeight: "500",
                      paddingTop: "2em",
                    }}
                  >
                    Nature of Liabilities
                  </span>
                </h4>
                <div
                  onClick={() => setShowView(!showview)}
                  className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                >
                  {showview == true ? <>-</> : <>+</>}
                </div>
              </div>
              {showview && (
                <div className="accordion-content assets">
                  <div className="">
                    {/**/}
                    <style
                      type="text/css"
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n    #cd_upload{width: 100%;\n    flex: 100%;max-width: 100%;}\n    label[for=upload-7]\n    {border: 1px solid rgba(54, 54, 54, 0.15)!important;background: #e5edef!important;color: #042b62!important;box-shadow: none!important;text-align: center;}\n    label[for=upload-7] span{position: absolute;left: 0;right: 0;}\n    label[for=upload-7] span img{position: relative;}\n    #eqmf_connect_broker .link{\n        font-size: 14px;\n        padding: 8px 15px;\n        display: block;\n        background: #8bc53f;\n        border: solid 1px #8bc53f;\n        color: #fff;\n        border-radius: 20px;\n        width: fit-content;\n        width: -webkit-fit-content;\n        margin: 30px auto;\n        display: block;\n    }\n    #eqmf_connect_broker .link:hover{\n        background: #fff;\n        color: #8bc53f;\n    }\n",
                      }}
                    />
                    <div className="container-fluid ">
                      <div className="row">
                        <div className="col-md-10">
                          <span>
                            <label className="">
                              Category : ({selectedOption})
                            </label>
                          </span>
                          <ul className="card-list">
                            {liability_option.map((x, i) => (
                              <React.Fragment key={i}>
                                <li
                                  id={x.id}
                                  onClick={() => {
                                    setHandleError({
                                      ...defaultHandleError,
                                    });
                                    setSelectedOption(x.title);
                                    scrollToForm();
                                    handleCategoryClick(x, "liability");
                                  }}
                                  className={`li-options ${selectedOption.toLowerCase() ==
                                    x.title.toLowerCase()
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <label htmlFor="type-2">
                                    <img alt={x.title} src={x.img} />
                                    {x.title}
                                  </label>
                                </li>
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {selectedOption != "Fetch Loan" && (
                        <div className="forms-container">
                          <div id="eqmf_manual_form" style={{ display: "block" }}>
                            <div className="">
                              <div className="">
                                <div className="">
                                  <div ref={cntRef} id="formBox">
                                    <form
                                      noValidate="novalidate"
                                      name="goldassetform"
                                      id="goldregisterform"
                                    >
                                      <div className="row d-flex align-items-center">
                                        <div className="col-md-5 custom-input">
                                          <div className={`form-group  ${liabilitiesData.liability_name ? "inputData" : null} `} style={{ paddingTop: "17px" }}>
                                            <input
                                              type="text"
                                              name="liability_name"
                                              value={
                                                liabilitiesData.liability_name
                                              }
                                              className={`${liabilitiesData.liability_type === "equifax" &&
                                                liabilitiesData.incomplete_flag === false ?
                                                "disabled"
                                                : ""
                                                }`}
                                              maxLength={35}
                                              onChange={(e) => {
                                                setLiabilitiesData({
                                                  ...liabilitiesData,
                                                  liability_name:
                                                    e.target.value,
                                                });
                                                handleLiabilityName(
                                                  e.target.value
                                                );
                                              }}
                                              required
                                              autoComplete="off"
                                            />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label for="name">Name Of Liablity*</label>
                                          </div>

                                          <div className="error">
                                            {handlerror.liability_name}
                                          </div>
                                        </div>
                                        <div className="col-md-5 mt-md-0 mt-3">
                                          <div className="material">
                                            <Form.Label>
                                              {" "}
                                              Name of Holder*{" "}
                                            </Form.Label>
                                            <Select
                                              classNamePrefix="sortSelect"
                                              isSearchable={false}
                                              styles={customStyles}
                                              options={memberData}
                                              className={`${liabilitiesData.liability_type === "equifax"
                                                // && liabilitiesData.incomplete_flag === false
                                                ?
                                                "disabled"
                                                : ""
                                                }`}
                                              value={memberData.filter(
                                                (v) =>
                                                  v.value ==
                                                  liabilitiesData.liability_member_id
                                              )}
                                              onChange={(e) => {
                                                setLiabilitiesData({
                                                  ...liabilitiesData,
                                                  liability_member_id: e.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row d-flex align-items-center">
                                        <div className="col-md-5 custom-input" >
                                          <div className={`form-group ${liabilitiesData.liability_outstanding_amount ? "inputData" : null}`}
                                            style={{ paddingTop: "23px" }}
                                          >
                                            <input
                                              type="number"
                                              name="liability_outstanding_amount"
                                              min="1"
                                              className={`${liabilitiesData.liability_type === "equifax" &&
                                                liabilitiesData.incomplete_flag === false ?
                                                "disabled"
                                                : ""
                                                }`}
                                              maxLength="10"
                                              onInput={maxLengthCheck}
                                              value={
                                                liabilitiesData.liability_outstanding_amount
                                              }
                                              onChange={(e) => {
                                                const inputValue =
                                                  e.target.value;
                                                if (inputValue.length <= 11) {
                                                  setLiabilitiesData({
                                                    ...liabilitiesData,
                                                    liability_outstanding_amount:
                                                      inputValue,
                                                  });
                                                  handleLiabilityOutstandingValue(
                                                    inputValue
                                                  );
                                                }
                                              }}
                                              required
                                              autoComplete="off"
                                            />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label for="name">Current Outstanding Amount*</label>
                                          </div>
                                          <div className=" error">
                                            {
                                              handlerror.liability_outstanding_amount
                                            }
                                          </div>
                                        </div>
                                        <div className="col-md-5 mt-md-0 mt-3">
                                          <div
                                            className="dark-label"
                                            style={{
                                              paddingTop: "7px",
                                            }}
                                          >
                                            <Form.Label>End Date*</Form.Label>
                                            <div
                                              className={`dt-conbx ${liabilitiesData.liability_outstanding_amount
                                                ? "inputData"
                                                : null
                                                } ${(liabilitiesData.liability_type === "equifax" &&
                                                  liabilitiesData.incomplete_flag === false) ?
                                                  "disabled"
                                                  : ""
                                                }  `}
                                              style={{
                                                borderBottom: "1px solid #dadada",
                                              }}
                                            >
                                              <ReactDatePicker
                                                select_date={moment(
                                                  liabilitiesData.liability_end_date,
                                                  "DD/MM/YYYY"
                                                ).toDate()}
                                                setDate={(date) => {
                                                  setLiabilitiesData({
                                                    ...liabilitiesData,
                                                    liability_end_date:
                                                      moment(date).format(
                                                        "DD/MM/YYYY"
                                                      ),
                                                  });
                                                  handleEndDate();
                                                }}
                                                minDate={moment()
                                                  .add(1, "months")
                                                  .add(1, "days")
                                                  .toDate()}
                                                maxDate={""}
                                                className={`pt-4 ${(liabilitiesData.liability_type === "equifax" &&
                                                  liabilitiesData.incomplete_flag === false) ?
                                                  "disabled"
                                                  : ""
                                                  }`}
                                              />
                                            </div>
                                            <div style={{ color: "red", position: "absolute", fontSize: "12px" }}>
                                              {handlerror.liability_end_date}
                                            </div>
                                          </div>
                                          {/* <div >
                                           
                                          </div> */}
                                        </div>
                                      </div>
                                      <div className="row mt-4">
                                        <div className="col-md-5 mt-3">
                                          <div className="material ">
                                            <div className="d-flex justify-content-end">
                                              <Form.Label>
                                                EMI Interest Rate (%)* :{" "}
                                                {
                                                  liabilitiesData.liability_emi_rate
                                                }
                                              </Form.Label>
                                            </div>
                                            <div
                                              className={`${liabilitiesData.liability_emi_rate <
                                                2 && "sl-hide-left"
                                                } ${liabilitiesData.liability_emi_rate >
                                                18 && "sl-hide-right"
                                                } ${liabilitiesData.liability_type === "equifax" &&
                                                  liabilitiesData.incomplete_flag === false ?
                                                  "disabled"
                                                  : ""
                                                }`}
                                            >
                                              <Slider
                                                min={0}
                                                max={20}
                                                value={Number(
                                                  liabilitiesData.liability_emi_rate
                                                )}
                                                step={`${liabilitiesData.liability_type === "equifax" && liabilitiesData.incomplete_flag == false
                                                  ? 0.01 : 0.05}`}
                                                // step={0.05}
                                                onChange={(v) => {
                                                  setLiabilitiesData({
                                                    ...liabilitiesData,
                                                    liability_emi_rate:
                                                      Math.round(
                                                        (parseFloat(v) +
                                                          Number.EPSILON) *
                                                        100
                                                      ) / 100,
                                                  });
                                                }}
                                                handleStyle={{
                                                  borderColor: "#042b62",
                                                  backgroundColor: "#042b62",
                                                }}
                                                railStyle={{
                                                  backgroundColor: "#ade9c0",
                                                }}
                                                trackStyle={{
                                                  backgroundColor: "#ade9c0",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-5 mt-md-0 mt-3 custom-input">
                                          <div className={`form-group  ${liabilitiesData.current_emi ? "inputData" : null}`}>
                                            <span>
                                              <input
                                                type="text"
                                                name="current_emi"
                                                className={`${liabilitiesData.liability_type === "equifax" &&
                                                  liabilitiesData.incomplete_flag === false ?
                                                  "disabled"
                                                  : ""
                                                  }`}
                                                value={
                                                  liabilitiesData.current_emi ?? ""
                                                }
                                                onChange={(e) => {
                                                  setLiabilitiesData({
                                                    ...liabilitiesData,
                                                    current_emi: e.target.value,
                                                  });
                                                  handleCurrentEMI(
                                                    e.target.value
                                                  );
                                                }}
                                                required
                                                autoComplete="off"
                                              />
                                              <span class="highlight"></span>
                                              <span class="bar"></span>
                                              <label for="name">Current EMI*</label>
                                            </span>
                                            <span className="info-hover-box">
                                              <span className="icon">
                                                <img
                                                  alt="More information"
                                                  src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                                />
                                              </span>
                                              <span className="msg">
                                                Current EMI is Auto Calculated
                                                as per outstanding balance & EMI
                                                End Date. If you think EMI Auto
                                                Calculation is different, you
                                                can Edit & add your Current EMI.
                                              </span>
                                            </span>
                                          </div>
                                          <div className="error">
                                            {handlerror.current_emi}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 custom-input">
                                          <div className={`form-group mt-2 ${liabilitiesData.liability_footnote ? "inputData" : null} `}>
                                            <input
                                              type="text"
                                              name="Remarks"
                                              value={
                                                liabilitiesData.liability_footnote
                                              }
                                              onChange={(e) =>
                                                setLiabilitiesData({
                                                  ...liabilitiesData,
                                                  liability_footnote:
                                                    e.target.value,
                                                })
                                              } autoComplete="off" />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label for="name">Remarks</label>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>

                          <div className="row py-2">
                            <div className=" text-center">
                              <div>
                                <div className="btn-container">
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="previous-btn form-arrow d-flex align-items-center"
                                      onClick={() => props.setTab("tab1")}
                                    >
                                      <FaArrowLeft />
                                      <span className="hover-text">
                                        &nbsp;Previous
                                      </span>
                                    </div>

                                    {addForm && (
                                      <button
                                        onClick={(e) => handleSubmit(e)}
                                        className="default-btn gradient-btn save-btn"
                                      >
                                        Save & Add More
                                      </button>
                                    )}
                                    {updateForm && (
                                      <div>
                                        <button
                                          onClick={(e) => cancelFormData(e)}
                                          className="default-btn gradient-btn save-btn"
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          onClick={(e) => updateFormData(e)}
                                          className="default-btn gradient-btn save-btn"
                                        >
                                          Update
                                        </button>
                                      </div>
                                    )}
                                    <a
                                      href={process.env.PUBLIC_URL + "/datagathering/insurance"}
                                    >
                                      <div className="next-btn form-arrow d-flex align-items-center">
                                        <span
                                          className="hover-text"
                                          style={{ maxWidth: 100 }}
                                        >
                                          Continue&nbsp;
                                        </span>
                                        <FaArrowRight />
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedOption == "Fetch Loan" && (
                        <div className="forms-container">
                          <div
                            id="eqmf_manual_form"
                            style={{ display: "block" }}
                          >
                            <div className="">
                              <div className="">
                                <div className="">
                                  <div ref={cntRef} id="formBox">
                                    <div className="d-flex justify-content-center">
                                      <button onClick={() => {
                                        setOpenModalByName("Fecth_your_Loan")
                                      }} className={Cibilreport.buttonStyle12}>Fetch New Loan</button>
                                    </div>
                                    <div className="mt-md-4">
                                      <div className="table-responsive rTabl">
                                        <table className="bgStyleTable">
                                          <tbody>
                                            <tr>
                                              <th style={{ textAlign: "center" }}>Name of Liability</th>
                                              <th style={{ textAlign: "center" }}>Fetched From</th>
                                              <th style={{ textAlign: "center" }}>Connected on</th>
                                              <th style={{ textAlign: "center" }}>Updated on</th>
                                              <th style={{ textAlign: "center" }}>Action</th>
                                            </tr>

                                            {equifaxData.length > 0 ? (

                                              equifaxData.map((member) => (
                                                <tr>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {member.first_name + " "} {member.last_name}
                                                  </td>
                                                  <td>
                                                    {member.fetched_from}
                                                  </td>
                                                  <td>
                                                    {member.Created_datetime}
                                                  </td>
                                                  <td>
                                                    {member.Updated_datetime}
                                                  </td>
                                                  <td>
                                                    <div className="ButtonBx d-flex justify-content-center">
                                                      <button
                                                        className="Cancel mt-3"
                                                        onClick={() => {
                                                          handleUnlink(member.id)
                                                        }}
                                                      >
                                                        Unlink
                                                      </button>

                                                      {member.canRefresh == true ?
                                                        <button
                                                          className="Cancel ms-md-0 mt-3 ms-2"
                                                          style={{ backgroundColor: "none" }}
                                                          onClick={() => {
                                                            handleRefreshLoans(member.id, member.member_id, member.first_name);
                                                          }}
                                                        >
                                                          Refresh
                                                        </button>
                                                        :
                                                        <>
                                                          <button
                                                            // style={{ backgroundColor: "none" }}
                                                            // data-tip
                                                            // data-for={`loan-${member.id}`}
                                                            // data-disabled="false"
                                                            className="Cancel RefreshDisable ms-md-0 mt-3 ms-2"
                                                            style={{
                                                              userSelect: "none",
                                                              opacity: .5,
                                                              backgroundColor: "none"
                                                            }}
                                                            onClick={() => { handleDisableRefresh(); }}
                                                          >
                                                            Refresh
                                                          </button>

                                                          {/* <ReactTooltip className="Refresh-tooltip" id={`loan-${member.id}`} place="bottom" type="light">
                                                            <div>On {member.refreshDate}, you can update your loan details.</div>
                                                          </ReactTooltip> */}
                                                        </>

                                                      }


                                                    </div>
                                                  </td>
                                                </tr>

                                              )))
                                              :
                                              <>
                                                <tr>
                                                  <td
                                                    colSpan="5"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No Details Found
                                                  </td>
                                                </tr>
                                              </>
                                            }

                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>

                          <div className="row mt-md-4">
                            <div className=" text-center">
                              <div>
                                <div className="btn-container">
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="previous-btn form-arrow d-flex align-items-center"
                                      onClick={() => {
                                        ScrollToTop()
                                        props.setTab("tab1")
                                      }
                                      }
                                    >
                                      <FaArrowLeft />
                                      <span className="hover-text">
                                        &nbsp;Previous
                                      </span>
                                    </div>
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/datagathering/insurance"
                                      }
                                    >
                                      <div className="next-btn form-arrow d-flex align-items-center">
                                        <span
                                          className="hover-text"
                                          style={{ maxWidth: 100 }}
                                        >
                                          Continue&nbsp;
                                        </span>
                                        <FaArrowRight />
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className="popupmodal" centered show={show} onHide={handleClose}>
        <Modal.Header className="ModalHead">
          <div className="text-center">Delete Confirmation</div>
        </Modal.Header>
        <div className=" p-5 d-grid place-items-center align-item-center">
          <div className=" HeaderModal">
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              This will permanently erase the record and its associated
              information.
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <button
            onClick={() => {
              handleClose("yes", "liability");
            }}
            className="outline-btn m-2"
          >
            Yes
          </button>

          <button
            onClick={() => {
              handleClose("no");
            }}
            className="outline-btn m-2"
          >
            No
          </button>
        </div>
      </Modal>

      <Modal
        dialogClassName="Nsdlcsdl-modal-width"
        show={openModalByName == "Fecth_your_Loan"}
        centered
        animationDuration={0}
      >
        <Fetchloan
          Closemodal={CloseLoanModal} session={session}
          allMembers={allMembers}
          getEquifaxData={getEquifaxData}
          getLiabilityList={getLiabilityList}
          // handleFetchSuccess={handleFetchSuccess}
          is_plan={isPlan}
        />
      </Modal>
    </div>
  );
};

export default Liabilities;
