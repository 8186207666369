import { useEffect, useState } from "react";
import PortfolioCategory from "./PortfolioCategory";
import style from "./style.module.css";

const PortfolioValue = (props) => {
  const [categoryValues, setCategoryValues] = useState([]);

  const onLoadInit = async () => {
    await props;
    GetCategoryData(props.data);
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(value);

  const GetCategoryData = (data) => {
    let graph_data = [];
    Object.keys(data.graph_data).forEach((key) => {
      graph_data.push({
        title: key,
        title_percentage_value: parseFloat(
          (data.graph_data[key] / data.tcurr_value) * 100
        ).toFixed(2),
        title_invested_value: numberFormat(data.graph_data[key]),
      });
    });
    setCategoryValues(graph_data);
  };

  useEffect(() => {
    onLoadInit();
  }, []);

  return (
    <div>
      <div
      className={style.bepbox}
        style={{
          border: "1px solid #ececec",
          borderRadius: 10,
        }}
      >
        <div className={`${style.pbox}`}>
          <div className={`${style.pbox1}`}>
            <div>
              <div className={style.insidePo}>
                <p className={`mb-0 ${style.title}`}>Total Portfolio Value</p>
                <p className="mb-0">
                  <span className={style.symbol}>₹</span>
                  <span className={style.value}>
                    {props.data.tcurr_value
                      ? numberFormat(props.data.tcurr_value)
                      : 0}
                  </span>
                </p>
              </div>
            </div>
            <div className={style.diffDv}>
              <div className={style.insidePo}>
                <p className={`mb-0 ${style.title}`}>Investment Value</p>
                <p className="mb-0">
                  <span className={style.symbol}>₹</span>
                  <span className={style.value}>
                    {props.data.tinvested_value
                      ? numberFormat(props.data.tinvested_value)
                      : 0}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              style={{ width: 100 }}
              src={require("./images/pie-chart.png")}
            />
          </div>
        </div>
      </div>

      <div className="py-4">
        <div className={style.grid08}>
          {categoryValues.length > 0 ? (
            categoryValues.map((v) => <PortfolioCategory data={v} />)
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default PortfolioValue;
