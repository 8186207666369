import React, { useState } from "react";
import { makePositive, rsFilter } from "../../../common_utilities";
import { imagePath } from "../../../constants";

const RetirementCashflow = (props) => {

  const [lifetimeIncViewDetails, setLifetimeIncViewDetails] = useState(false);
  const [limitedIncViewDetails, setLimitedIncViewDetails] = useState(false);
  const [lifetimeExpViewDetails, setLifetimeExpViewDetails] = useState(false);
  const [limitedExpViewDetails, setLimitedExpViewDetails] = useState(false);
  
  const isRetirementData = props?.data?.isRetirementData
    ? props.data.isRetirementData
    : 0;
  const screenHeader = props?.data?.screenHeader ? props.data.screenHeader : "";
  const title = props?.data?.title ? props.data.title : "";
  const formula = props?.data?.formula ? props.data.formula : "";

  const yearlyLifetimeInc = props?.data?.yearlyLifetimeInc
  ? props.data.yearlyLifetimeInc
  : [];

  const yearlyLifetimeIncObj = props?.data?.yearly_lifetime_inc_obj
    ? props.data.yearly_lifetime_inc_obj 
    : [];
  
  const yearlyLimitedInc = props?.data?.yearlyLimitedInc
    ? props.data.yearlyLimitedInc
    : [];

  const limitedIncObj = props?.data?.limited_inc_obj 
    ? props.data.limited_inc_obj
    : [];

  const limitedExp = props?.data?.limited_exp 
    ? props.data.limited_exp
     : [];
  const yearlyLifetimeExp = props?.data?.yearly_lifetime_exp
    ? props.data.yearly_lifetime_exp
    : [];

  const yearlyLifetimeExpObj = props?.data?.yearly_lifetime_exp_obj
    ? props.data.yearly_lifetime_exp_obj
    : [];

  const limitedExpObj = props?.data?.limited_exp_obj
    ? props.data.limited_exp_obj
    : [];


  const openingBalance = props?.data?.opening_balance
    ? props.data.opening_balance
    : [];
  const closingBalance = props?.data?.closing_balance
    ? props.data.closing_balance
    : [];
  const retirementYear = props?.data?.retirement_year
    ? props.data.retirement_year
    : [];
  const incomeFromCorpus = props?.data?.income_from_corpus
    ? props.data.income_from_corpus
    : [];
  const remainingCorpus = props?.data?.remaining_corpus
    ? props.data.remaining_corpus
    : [];
  const appreciatedValue = props?.data?.appreciated_value
    ? props.data.appreciated_value
    : [];

  const getObjectKey = (obj) => {
      return Object.keys(obj)[0]
  }

  return (
    <>
      <div className="retirementCashflow">
        {isRetirementData != 0 ? (
          <>
            <h4 className="rTitle">
              <img 
              src={imagePath + "https://static.fintoo.in/static/assets/img/reports/icons/t_retirement_planning_cashflow.svg"}
               />
              {title}
            </h4>
            <div className="rContent">
              <p
                dangerouslySetInnerHTML={{
                  __html: screenHeader ? screenHeader : "",
                }}
              ></p>
            </div>
            <div className="table-responsive rTable">
              <table className="bgStyleTable">
                <tbody>
                  <tr>
                    <th>Year</th>
                    <th>Age</th>
                    <th>
                      Opening <br /> Balance (₹)(A)
                    </th>
                    {yearlyLifetimeInc.length > 0 ? (
                      <th>
                        Income till Life <br /> Exp ( B )(₹)
                        {yearlyLifetimeIncObj.data.income.length > 0 && lifetimeIncViewDetails === false &&
                            <>
                                <span className="expandColumns recomm_expandcolor" 
                                onClick={()=>setLifetimeIncViewDetails(true)}
                                >
                                View Details
                                </span>
                            </>
                        }

                        {lifetimeIncViewDetails === true ? (
                             <>
                             <span className="expandColumns recomm_expandcolor" 
                             onClick={()=>setLifetimeIncViewDetails(false)}
                             >
                             Close Details
                             </span>
                         </>
                        ) : null}
                      </th>

                    ) : null}

                    {/* show Life expetancy income view details column names */} 
                    {lifetimeIncViewDetails === true && yearlyLifetimeInc.length > 0 ? (
                        <>  
                          {Object.entries(yearlyLifetimeIncObj.data.income).map(([key, value]) => (
                          
                          <th>{yearlyLifetimeIncObj.data.income[key].income_name} (₹)</th>
                       
                          ))
                          }
                        </>

                        ) : null
                    }

                    {yearlyLimitedInc.length > 0 ? (
                      <th>
                        Income One time/
                        <br />
                        Limited ( C ) (₹)

                        {limitedIncObj.data.income.length > 0 && limitedIncViewDetails === false &&
                            <>
                                <span className="expandColumns recomm_expandcolor" 
                                onClick={()=>setLimitedIncViewDetails(true)}
                                >
                                View Details
                                </span>
                            </>
                        }

                        {limitedIncViewDetails === true ? (
                             <>
                             <span className="expandColumns recomm_expandcolor" 
                             onClick={()=>setLimitedIncViewDetails(false)}
                             >
                             Close Details
                             </span>
                         </>
                        ) : null}
                      </th>
                    ) : null}

                     {/* show Limited income view details column names */} 
                     {limitedIncViewDetails === true && yearlyLimitedInc.length > 0 ? (
                        <>  
                          {Object.entries(limitedIncObj.data.income).map(([key, obj]) => (    
                              <th>{obj.income_name} (₹)</th>
                          ))
                          }
                        </>
                        ) : null
                    }

                    <th>
                      Expenses till
                      <br /> Life Exp <br />( D) (₹)

                      {yearlyLifetimeExpObj.data.expense.length > 0 && lifetimeExpViewDetails === false &&
                            <>
                                <span className="expandColumns recomm_expandcolor" 
                                onClick={()=>setLifetimeExpViewDetails(true)}
                                >
                                View Details
                                </span>
                            </>
                        }

                        {lifetimeExpViewDetails === true ? (
                             <>
                             <span className="expandColumns recomm_expandcolor" 
                             onClick={()=>setLifetimeExpViewDetails(false)}
                             >
                             Close Details
                             </span>
                         </>
                        ) : null}
                    </th>

                     {/* show Limited income view details column names */} 
                     {lifetimeExpViewDetails === true && yearlyLifetimeExp.length > 0 ? (
                        <>  
                          {Object.entries(yearlyLifetimeExpObj.data.expense).map(([key, obj]) => (    
                              <th>{obj.expense_name} (₹)</th>
                          ))
                          }
                        </>
                        ) : null
                    }

                    {limitedExp.length > 0 ? (
                      <th>
                        Expenses One time/
                        <br />
                        Limited ( E ) (₹)

                        {limitedExpObj.data.expense.length > 0 && limitedExpViewDetails === false &&
                            <>
                                <span className="expandColumns recomm_expandcolor" 
                                onClick={()=>setLimitedExpViewDetails(true)}
                                >
                                View Details
                                </span>
                            </>
                        }

                        {limitedExpViewDetails === true ? (
                             <>
                             <span className="expandColumns recomm_expandcolor" 
                             onClick={()=>setLimitedExpViewDetails(false)}
                             >
                             Close Details
                             </span>
                         </>
                        ) : null}
                      </th>
                    ) : null}

                     {/* show Limited expense view details column names */} 
                     {limitedExpViewDetails === true && limitedExp.length > 0 ? (
                        <>  
                          {Object.entries(limitedExpObj.data.expense).map(([key, obj]) => (    
                              <th>{obj.expense_name} (₹)</th>
                          ))
                          }
                        </>
                        ) : null
                    }

                    <th>
                      Cash withdraw from Corpous
                      <br />
                      (₹)(F) = {formula}
                    </th>
                    <th>
                      Remaining Corpus
                      <br /> (G) (₹)= (A)-(F)
                    </th>
                    <th>
                      Appreciated value <br /> (H) (₹)
                    </th>
                    <th>
                      Closing balance <br />
                      (₹)= (G)+(H)
                    </th>
                  </tr>

                  {Object.entries(openingBalance).map(([key, value]) => (
                    <tr key={key}>
                      <td>{retirementYear[key]}</td>

                      {Object.entries(value).map(([k, obj]) => (
                        <>
                          <td>{k}</td>
                          <td>{rsFilter(obj)}</td>
                        </>
                      ))}

                      {yearlyLifetimeInc.length > 0
                        ? Object.entries(value).map(([k, obj]) => (
                            <>
                              <td>
                                {yearlyLifetimeInc[key] == undefined ? (
                                  0
                                ) : (
                                  <>{rsFilter(yearlyLifetimeInc[key][k])}</>
                                )}
                              </td>
                            </>
                          ))
                        : null}

                      {/* Show if View details in Income till Life expectancy clicked */}
                      {lifetimeIncViewDetails === true && yearlyLifetimeInc.length > 0 
                      ?   
                        Object.entries(yearlyLifetimeIncObj.data.income).map(([k, obj]) => (   
                            <>
                              <td>
                                  {yearlyLifetimeIncObj.data.income[k].income_growth[key] == undefined ? (
                                    0
                                  ): (
                                    rsFilter(yearlyLifetimeIncObj.data.income[k].income_growth[key][getObjectKey(yearlyLifetimeIncObj.data.income[k].income_growth[key])])
                                  )}

                              </td>
                            </>
                        ))
                        
                      : null}

                      {yearlyLimitedInc.length > 0
                        ? Object.entries(value).map(([k, obj]) => (
                            <>
                              <td>
                                {yearlyLimitedInc[key] == undefined ? (
                                  0
                                ) : (
                                  <>{rsFilter(yearlyLimitedInc[key][k])}</>
                                )}
                              </td>
                            </>
                          ))
                        : null}
                      
                      {/* Show if View details for Income One Time/ Limited */}
                      {limitedIncViewDetails === true && yearlyLimitedInc.length > 0 
                      ?   
                        Object.entries(limitedIncObj.data.income).map(([k, obj]) => (   
                            <>
                              <td>
                                  {limitedIncObj.data.income[k].income_growth[key] == undefined ? (
                                    0
                                  ): (
                                    rsFilter(limitedIncObj.data.income[k].income_growth[key][getObjectKey(limitedIncObj.data.income[k].income_growth[key])])
                                  )}

                              </td>
                            </>
                        ))
                        
                      : null}
                        
                      {Object.entries(value).map(([k, obj]) => (
                        <>
                          <td>
                            {yearlyLifetimeExp[key] == undefined ? (
                              0
                            ) : (
                              <>{rsFilter(yearlyLifetimeExp[key][k])}</>
                            )}
                          </td>
                        </>
                      ))}

                      {/* Show if View details for Expense till Life Exp  */}
                      {lifetimeExpViewDetails === true && yearlyLifetimeExpObj.data.expense.length > 0 
                      ?   
                        Object.entries(yearlyLifetimeExpObj.data.expense).map(([k, obj]) => (   
                            <>
                              <td>
                                  {yearlyLifetimeExpObj.data.expense[k].expense_growth[key] == undefined ? (
                                    0
                                  ): (
                                    rsFilter(yearlyLifetimeExpObj.data.expense[k].expense_growth[key][getObjectKey(yearlyLifetimeExpObj.data.expense[k].expense_growth[key])])
                                  )}

                              </td>
                            </>
                        ))
                        
                      : null}

                      {limitedExp.length > 0
                        ? Object.entries(value).map(([k, obj]) => (
                            <>
                              <td>
                                {limitedExp[key] == undefined ? (
                                  0
                                ) : (
                                  <>{rsFilter(limitedExp[key][k])}</>
                                )}
                              </td>
                            </>
                          ))
                        : null}

                      {/* Show View details for Expenses One time/ Limited */}
                      {limitedExpViewDetails === true && limitedExp.length > 0 
                      ?   
                        Object.entries(limitedExpObj.data.expense).map(([k, obj]) => (   
                            <>
                              <td>
                                  {limitedExpObj.data.expense[k].expense_growth[key] == undefined ? (
                                    0
                                  ): (
                                    rsFilter(limitedExpObj.data.expense[k].expense_growth[key][getObjectKey(limitedExpObj.data.expense[k].expense_growth[key])])
                                  )}

                              </td>
                            </>
                        ))
                        
                      : null}

                      {Object.entries(value).map(([k, obj]) =>
                        incomeFromCorpus[key] == undefined ? (
                          0
                        ) : incomeFromCorpus[key][k] < 0 ? (
                          <td style={{ color: "red" }}>
                            ({rsFilter(makePositive(incomeFromCorpus[key][k]))})
                          </td>
                        ) : (
                          <td>{rsFilter(incomeFromCorpus[key][k])}</td>
                        )
                      )}
                      {Object.entries(value).map(([k, obj]) =>
                        remainingCorpus[key] == undefined ? (
                          0
                        ) : remainingCorpus[key][k] < 0 ? (
                          <td style={{ color: "red" }}>
                            ({rsFilter(makePositive(remainingCorpus[key][k]))})
                          </td>
                        ) : (
                          <td>{rsFilter(remainingCorpus[key][k])}</td>
                        )
                      )}
                      {Object.entries(value).map(([k, obj]) =>
                        appreciatedValue[key] == undefined ? (
                          0
                        ) : appreciatedValue[key][k] < 0 ? (
                          <td style={{ color: "red" }}>
                            ({rsFilter(makePositive(appreciatedValue[key][k]))})
                          </td>
                        ) : (
                          <td>{rsFilter(appreciatedValue[key][k])}</td>
                        )
                      )}
                      {Object.entries(value).map(([k, obj]) =>
                        closingBalance[key] == undefined ? (
                          0
                        ) : closingBalance[key][k] < 0 ? (
                          <td style={{ color: "red" }}>
                            ({rsFilter(makePositive(closingBalance[key][k]))})
                          </td>
                        ) : (
                          <td>{rsFilter(closingBalance[key][k])}</td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="no-data-found text-center">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-10">
                    <img
                      src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                      alt="Data not found"
                    />
                    <p>
                      Since you missed to fill in the required information which
                      is needed here, we are not able to show you this section.
                      Kindly click on below button to provide all the necessary
                      inputs. Providing all the information as asked will ensure
                      more accurate financial planning report. Once you fill in
                      the data, same will be reflected here.
                    </p>
                    <a
                      href="/web/datagathering/income-expenses"
                      target="_blank"
                      className="link"
                    >
                      Complete Retirement expense
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div style={{ height: 50 }}></div>
      </div>
    </>
  );
}

export default RetirementCashflow;
