import React, { useEffect } from "react";
import styles from "./fhcheader.module.css";

function Fhcheader(props) {
  const redirectionToLogin = props.redirectToLogin;
  useEffect(() => {
    // startAnimation();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 50) {
      document
        .querySelector("." + styles.Fhcheader)
        .classList.add(styles.fixedheader);
    } else {
      document
        .querySelector("." + styles.Fhcheader)
        .classList.remove(styles.fixedheader);
    }
  };
  return (
    <>
      <div className={`${styles.Fhcheader}`}>
        <div>
          <img
            src={process.env.REACT_APP_STATIC_URL + "media/Header/logo.svg"}
            alt="Fintoo logo"
          />
        </div>
        <div>
          <button
            className={`${styles.subscribebtn}`}
            type="button"
            onClick={() => redirectionToLogin()}
          >
          Subscribe Now
          </button>
        </div>
      </div>
    </>
  );
}

export default Fhcheader;
