import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  apiCall,
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
  getRestApiHeaders,
  getUserId,
  loginRedirectGuest,
  restApiCall,
  setItemLocal,
} from "../../../common_utilities";
import * as constClass from "../../../constants";
import OTPInput from "otp-input-react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import FintooLoader from "../../../components/FintooLoader";
import * as toastr from "toastr";
import customStyles from "../../../components/CustomStyles";
import style from "../../style.module.css";
import { useNavigate } from "react-router-dom";

function LinkYourHoldingsDG(props) {
  const [show, setShow] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [modalType, setModalType] = useState(0);
  const [accToken, setAccToken] = useState("");
  const [restHeaders, setRestHeaders] = useState({});
  const [selectedMember, setSelectedMember] = useState({});
  const [otpInput, setOtpInput] = useState("");
  const dispatch = useDispatch();
  const interval = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ecasData, setEcasData] = useState([]);
  const [errors, setErrors] = useState({});
  const [sendDisabled, setSendDisabled] = useState(true);
  const [showlinkac, setShowLinkAc] = useState(false);
  const [ecasDeletePopup, setEcasDeletePopup] = useState(false);
  const [memberID, setMemberID] = useState("");
  const [panEditable, setPanEditable] = useState(true);
  const [isEcasRefresh, setIsEcasRefresh] = useState(false);
  const timeNewObj = useRef();
  const timeNewValue = useRef(120);
  const stopSmallCase = useRef(false);
  const [waitforSms, setWaitforSms] = useState(false);
  const [mfToken, setMfToken] = useState("");
  const [casResponse, setCasResponse] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (getUserId() == null) {
      loginRedirectGuest();
    }
    setSelectedMember({ ...selectedMember, id: props.session.data.fp_user_id });
    onLoadInit();
    document.body.classList.add("link-your-holding");
    return () => {
      document.body.classList.remove("link-your-holding");
      clearInterval(interval.current);
      clearInterval(timeNewObj.current);
      timeNewValue.current = 120;
    };
  }, []);

  const onLoadInit = async () => {
    try {
      var accTok = await getRestApiHeaders();
      if (accTok) {
        setAccToken(accTok.gatewayauthtoken);
        setRestHeaders(accTok);
      }
      if (props?.session?.data?.email.includes("yopmail.com")) {
        fetchMfCentral();
      } else {
        fetchSmallcase();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleChange();
  }, [allMembers]);

  const handleClose = () => {
    setErrors({ userPan: "", userMobile: "" })
  }

  const checkPANRegistered = async (pan) => {
    let url =
      constClass.ADVISORY_CHECK_PAN_REGISTERED +
      "?uid=" +
      btoa("00" + props.session.data.id) +
      "&pan=" +
      pan;
    let checkpan = await apiCall(url, "", false, false);
    return checkpan;
  }

  const findMobileErrors = () => {
    const newErrors = {};
    let regex = /^[6789]\d{9}$/;
    if (!selectedMember.mobile || selectedMember.mobile === "")
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (selectedMember.mobile.length !== 10)
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (!regex.test(selectedMember.mobile))
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (
      selectedMember.mobile ||
      regex.test(selectedMember.mobile) ||
      selectedMember.mobile.length == 10
    )
      newErrors.userMobile = "";
    return newErrors;
  };

  const findPANErrors = () => {
    const newErrors = {};
    let regex = /^[A-Za-z]{3}[HPhp]{1}[A-Za-z]{1}\d{4}[A-ZHPa-zhp]{1}$/;
    if (!selectedMember.pan || selectedMember.pan === "") {
      newErrors.userPan = "Please enter PAN number";
    } else if (selectedMember.pan.length !== 10) {
      newErrors.userPan = "Please enter valid PAN number";
    } else if (!regex.test(selectedMember.pan)) {
      newErrors.userPan = "Please enter valid PAN number!";
    } else if (
      selectedMember.pan ||
      regex.test(selectedMember.pan) ||
      selectedMember.pan.length == 10
    ) {
      newErrors.userPan = "";
    }
    return newErrors;
  };

  const findOtpErrors = () => {
    const newErrors = {};
    if (!otpInput || otpInput === "")
      newErrors.otpInput = "Please enter valid otp!";
    else if (otpInput.length !== 6)
      newErrors.otpInput = "Please enter valid otp!";
    return newErrors;
  };

  const checkenterpanexists = async () => {
    if (selectedMember.pan != "" && selectedMember.pan != null) {
      let url =
        constClass.ADVISORY_CHECK_PAN_EXISTSS +
        "?uid=" +
        btoa("00" + props.session.data.id) +
        "&pan=" +
        selectedMember.pan;
      let checkpan = await apiCall(url, "", false, false);
      return checkpan;
    }
  };
  const fetchSmallcase = async () => {
    try {
      let reqdata = {
        fp_user_id: props.session.data.fp_user_id,
        fp_log_id: props.session.data.fp_log_id,
      };
      let checkData = await restApiCall(
        constClass.ADVISORY_FETCH_SMALLCASE_DATA,
        reqdata,
        ""
      );
      if (checkData.error_code == "100") {
        setEcasData(checkData.data);
      } else if (checkData.error_code == "103") {
        setEcasData([]);
      }
      if (checkData.valid_members.length > 0) {
        const all = checkData.valid_members.map((v) => ({
          name: v.first_name + " " + v.last_name,
          id: v.id,
          pan: v.pan,
          mobile: v.mobile,
          label: v.first_name + " " + v.last_name,
          value: v.id,
        }));
        setAllMembers([...all]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMfCentral = async () => {
    try {
      let reqdata = {
        fp_user_id: props.session.data.fp_user_id,
        fp_log_id: props.session.data.fp_log_id,
      };
      let checkData = await restApiCall(
        constClass.ADVISORY_FETCH_MF_CENTRAL_DATA,
        reqdata,
        ""
      );
      if (checkData.error_code == "100") {
        setEcasData(checkData.data);
      }
      if (checkData.error_code == "103") {
        setEcasData([]);
      }
      if (checkData.valid_members.length > 0) {
        const all = checkData.valid_members.map((v) => ({
          name: v.first_name + " " + v.last_name,
          id: v.id,
          pan: v.pan,
          mobile: v.mobile,
          label: v.first_name + " " + v.last_name,
          value: v.id,
        }));
        setAllMembers([...all]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const generateClientRefNo = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
    const year = currentDate.getFullYear().toString().substring(2);
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    const customFormat = `minty_${day}${month}${year}_${hours}${minutes}${seconds}`;
    return customFormat;
  };

  const sendOTP = async (refresh = 0) => {
    try {
      const mobileErrors = findMobileErrors();
      const panErrors = findPANErrors();
      const panRegistred = await checkPANRegistered(selectedMember.pan);
      const panExists = await checkenterpanexists();
      setIsEcasRefresh(false)
      if (!panEditable && selectedMember.pan && panExists != true) {
        panErrors.userPan = panExists;
      }

      if (refresh == 0 && panRegistred != true) {
        panErrors.userPan = panRegistred
      }

      if (
        (Object.keys(mobileErrors).length > 0 ||
          Object.keys(panErrors).length > 0) &&
        (mobileErrors.userMobile !== "" || panErrors.userPan !== "")
      ) {
        setErrors({ ...mobileErrors, ...panErrors });
        return;
      }
      if (
        (Object.keys(mobileErrors).length > 0 ||
          Object.keys(panErrors).length > 0) && (mobileErrors.userMobile !== "" || panErrors.userPan !== "")
      ) {
        setErrors({ ...mobileErrors, ...panErrors });
        return;
      }

      let checkPan = await checkIfPanExists();
      if (checkPan != true) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(checkPan);
        return;
      }

      let jwtTok = await getJwtToken();
      if (jwtTok.error_code == "100") {
        let trxnIdData = await getTransactionId(jwtTok.data.token);
        if (trxnIdData.error_code == "100") {
          let trxnId = trxnIdData.data.data.data.transactionId;
          let sendOTP = await sendSmallcaseOTP(trxnId);
          if (sendOTP.error_code == "100") {
            clearInterval(timeNewObj.current);
            timeNewValue.current = 120;
            setItemLocal("trxnId", trxnId);
            setModalType(1);
            setDefaultTimer();
            setOtpInput("");
            startTimer();
            setErrors({});
            return;
          } else {
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error(sendOTP.message);
            return;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendMFOTP = async (refresh = 0) => {
    try {
      // debugger
      const mobileErrors = findMobileErrors();
      const panErrors = findPANErrors();
      const panRegistred = await checkPANRegistered(selectedMember.pan);
      const panExists = await checkenterpanexists();

      if (!panEditable && selectedMember.pan && panExists != true) {
        panErrors.userPan = panExists;
      }

      if (refresh == 0 && panRegistred != true) {
        panErrors.userPan = panRegistred
      }

      if (
        (Object.keys(mobileErrors).length > 0 ||
          Object.keys(panErrors).length > 0) &&
        (mobileErrors.userMobile !== "" || panErrors.userPan !== "")
      ) {
        setErrors({ ...mobileErrors, ...panErrors });
        return;
      }

      const checkPan = await checkIfPanExists();
      if (checkPan !== true) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(checkPan);
        return;
      }

      const mfCentralToken = await getMFToken();

      if (mfCentralToken.error_code === "100") {
        const mF_Token = mfCentralToken.data;
        setMfToken(mF_Token);

        const clientRefNo = generateClientRefNo();
        const param = {
          clientRefNo,
          pan: selectedMember.pan,
          mobile: selectedMember.mobile,
          email: "",
          pekrn: "",
          otherApi: "DET_SUM",
        };

        const request = { token: mF_Token, data: param };
        const encryptRequest = await mfEncrypt(request);

        if (encryptRequest?.error_code === "100") {
          const encryptResponse = encryptRequest.data;
          const signRequest = { token: mF_Token, data: encryptResponse };
          const generateSignature = await mfSignature(signRequest);

          if (generateSignature?.error_code === "100") {
            const submitRequestPayload = {
              token: mF_Token,
              data: generateSignature.data,
            };
            const sendOTPResponse = await mfSubmitCasSummaryRequest(
              submitRequestPayload
            );

            if (sendOTPResponse.error_code === "100") {
              clearInterval(timeNewObj.current);
              timeNewValue.current = 120;
              setModalType(1);
              setDefaultTimer();
              setOtpInput("");
              startTimer();
              setErrors({});
              return;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyMfCentralOTP = async () => {
    const otpErrors = findOtpErrors();
    if (Object.keys(otpErrors).length > 0) {
      setErrors(otpErrors);
      return;
    }
    if (!casResponse) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong!");
      return;
    }
    const verifyPayload = { ...casResponse, enteredOtp: otpInput };
    const encryptRequest = await mfEncrypt({
      token: mfToken,
      data: verifyPayload,
    });

    if (encryptRequest?.error_code !== "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong!");
      return;
    }

    const encryptResponse = encryptRequest.data;
    const generateSignature = await mfSignature({
      token: mfToken,
      data: encryptResponse,
    });

    if (generateSignature?.error_code !== "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong!");
      return;
    }

    const submitRequestPayload = {
      token: mfToken,
      data: generateSignature.data,
      pan: selectedMember.pan,
      mobile: selectedMember.mobile,
      fp_user_id: selectedMember.id,
    };
    const verifyOTP = await mfInvestorConsent(submitRequestPayload);

    if (verifyOTP?.error_code !== "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong!");
      return;
    }
    setModalType(2);
    setShowLinkAc(false);
    setWaitforSms(true);

    const getMfData = await getMfDocuments();

    if (getMfData?.error_code === "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.success("Holding Data has been fetched successfully");
      dispatch({
        type: "ASSETS_UPDATE",
        payload: true,
      });
      fetchMfCentral();
    }
  };

  const getMfDocuments = async () => {
    if (casResponse) {
      let request = {
        token: mfToken,
        reqId: casResponse["reqId"],
        clientRefNo: casResponse["clientRefNo"],
        pan: selectedMember.pan,
        mobile: selectedMember.mobile,
        fp_user_id: selectedMember.id,
        user_id: getParentUserId(),
        fp_log_id: props.session.data.fp_log_id,
      };

      let response = await restApiCall(
        constClass.ADVISORY_GET_ALL_CAS_DOCUMENTS,
        request,
        restHeaders
      );
      if (response.error_code == "100") {
        return response;
      } else {
        console.log("encrypt error", response);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Someting went wrong!");
      }
    }
  };

  const handleOtpChange = (e) => {
    setOtpInput(e.target.value);
  };

  const checkIfPanExists = async () => {
    let reqData = {
      pan: selectedMember.pan,
      fp_user_id: selectedMember.id,
      fp_log_id: props.session.data.fp_log_id,
    };
    let checkPan = await restApiCall(
      constClass.DMF_CHECKIFPANEXISTS_API_URL,
      reqData,
      restHeaders
    );
    if (checkPan.error_code == "100") return true;
    else if (checkPan.error_code == "101") return message;
    return "Something went wrong!";
  };

  const mfEncrypt = async (data) => {
    let reqData = data;
    let response = await restApiCall(
      constClass.ADVISORY_MF_ENCRYPT,
      reqData,
      restHeaders
    );
    if (response.error_code == "100") {
      return response;
    } else {
      console.log("encrypt error", response);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Someting went wrong!");
    }
  };

  const mfDecrypt = async (data) => {
    let reqData = data;
    let response = await restApiCall(
      constClass.ADVISORY_MF_DECRYPT,
      reqData,
      restHeaders
    );
    if (response.error_code == "100") {
      return response;
    } else {
      console.log("decrypt error", response);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Someting went wrong!");
    }
  };

  const mfSignature = async (data) => {
    let reqData = data;
    let response = await restApiCall(
      constClass.ADVISORY_MF_GENERATE_SIGNATURE,
      reqData,
      restHeaders
    );
    if (response.error_code == "100") {
      return response;
    } else {
      console.log("signature error", response);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Someting went wrong!");
    }
  };

  const mfSubmitCasSummaryRequest = async (data) => {
    let reqData = data;
    let response = await restApiCall(
      constClass.ADVISORY_MF_SUBMIT_CAS_SUM_REQUEST,
      reqData,
      restHeaders
    );
    let decryptPayload = {
      token: data["token"],
      data: response["data"]["response"],
    };
    let decryptResponse = await mfDecrypt(decryptPayload);
    if (response.error_code == "100") {
      if (decryptResponse?.error_code == "100") {
        setCasResponse(decryptResponse["data"]);
        return decryptResponse;
      } else {
        console.log("error", decryptResponse);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Someting went wrong!");
      }
    } else {
      var errMsg = decryptResponse["data"]["errors"][0]["message"];
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error(errMsg);
    }
  };

  const mfInvestorConsent = async (data) => {
    let reqData = data;
    let response = await restApiCall(
      constClass.ADVISORY_MF_INVESTOR_CONSENT,
      reqData,
      restHeaders
    );
    if (response.error_code == "100") {
      return response;
    } else {
      let decryptPayload = {
        token: data["token"],
        data: response["data"]["response"],
      };
      let decryptResponse = await mfDecrypt(decryptPayload);

      if (decryptResponse?.error_code == "100") {
        var errMsg = decryptResponse["data"]["errors"][0]["message"];
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(errMsg);
        return response;
      }
    }
  };

  const getJwtToken = async () => {
    let reqData = {
      user_id: getParentUserId(),
      fp_log_id: props.session.data.fp_log_id,
    };
    let jwtTok = await restApiCall(
      constClass.GET_JWTTOKEN_API_URL,
      reqData,
      restHeaders
    );
    if (jwtTok.error_code == "100") return jwtTok;
    return "Something went wrong!";
  };

  const getMFToken = async () => {
    let reqData = {
      user_id: getParentUserId(),
      fp_log_id: props.session.data.fp_log_id,
    };
    let mfTok = await restApiCall(
      constClass.ADVISORY_MF_GENERATE_TOKEN,
      reqData,
      restHeaders
    );
    if (mfTok.error_code == "100") {
      return mfTok;
    } else {
      console.log("mf token error", mfTok);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Someting went wrong!");
    }
  };

  const getTransactionId = async (jwtToken) => {
    let reqData = {
      token: jwtToken,
    };
    let trxnId = await restApiCall(
      constClass.GETTRANSACTION_API_URL,
      reqData,
      restHeaders
    );
    if (trxnId.error_code == "100") return trxnId;
    return "Something went wrong!";
  };

  const sendSmallcaseOTP = async (trxnId) => {
    let reqData = {
      transactionId: trxnId,
      pan: selectedMember.pan,
      phone: selectedMember.mobile,
    };
    let sendOTP = await restApiCall(
      constClass.SEND_SC_OTP_API_URL,
      reqData,
      restHeaders
    );
    return sendOTP;
  };

  const verifySmallcaseOTP = async () => {
    const otpErrors = findOtpErrors();
    if (Object.keys(otpErrors).length > 0) {
      setErrors(otpErrors);
      return;
    }

    let trxnId = getItemLocal("trxnId");
    let reqData = {
      transactionId: trxnId,
      pan: selectedMember.pan,
      phone: selectedMember.mobile,
      fp_user_id: selectedMember.id,
      otp: otpInput,
    };

    let verifyOTP = await restApiCall(
      constClass.VERIFY_SC_OTP_API_URL,
      reqData,
      restHeaders
    );

    let errMsg = "";

    if (verifyOTP.error_code == "100") {
      setModalType(2);
      setShowLinkAc(false);
      setWaitforSms(true);
      // setIsLoading(true);
      interval.current = setInterval(async () => {
        if (stopSmallCase.current === false) {
          await getSmallCaseData();
        } else {
          clearInterval(interval.current); // Stop the interval when the success condition is met
        }
      }, 10000);
      return;
    } else if (verifyOTP.error_code) {
      if (verifyOTP.data.data.errors) {
        let errResp = verifyOTP.data.data.errors
        errMsg = errResp[0];
      }
      else {
        let errResp = JSON.parse(verifyOTP.data.data.data)
        errMsg = errResp.errors[0]?.message;
      }
    }

    if (errMsg.includes("Entered OTP appears to be incorrect")) {
      setErrors({ otpInput: errMsg });
      return;
    }
    setModalType(1);
    // setDefaultTimer();
    setOtpInput("");
    setErrors({});
    toastr.options.positionClass = "toast-bottom-left";
    toastr.error(errMsg ? errMsg : "Someting went wrong!");
  };

  const getSmallCaseData = async () => {
    let reqData = {
      fp_log_id: props.session.data.fp_log_id,
      pan: selectedMember.pan,
      user_id: getParentUserId(),
      fp_user_id: selectedMember.id,
    };

    let getScData = await restApiCall(
      constClass.DMF_GET_SC_DATA_API_URL,
      reqData,
      restHeaders
    );
    if (getScData.error_code == "100") {
      stopSmallCase.current = true; // Stop further attempts
      toastr.options.positionClass = "toast-bottom-left";
      toastr.success("Holding Data has been fetched successfully");
      dispatch({
        type: "ASSETS_UPDATE",
        payload: true,
      });
      fetchSmallcase();
      // setIsLoading(false);
    } else {
      console.log("error", getScData)
      // toastr.options.positionClass = "toast-bottom-left";
      // toastr.error("Someting went wrong!");
      // setIsLoading(false);
    }
  };

  const deleteSmallCase = async (memberid) => {
    var selected_name = "";
    var selected_pan = "";
    ecasData.filter((item) => {
      if (item.id == memberid) {
        selected_name = item.first_name;
        selected_pan = item.pan;
      }
    });
    try {
      let reqdata = {
        fp_user_id: memberid,
        pan: selected_pan,
      };
      let delData = await restApiCall(
        constClass.ADVISORY_DELETE_SMALLCASE_DATA,
        reqdata,
        ""
      );
      if (delData.error_code == "100") {
        dispatch({
          type: "ASSETS_UPDATE",
          payload: true,
        });
        fetchSmallcase();
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success("Mutual Fund holdings has been deleted successfully");
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteMfCentral = async (memberid) => {
    var selected_name = "";
    var selected_pan = "";
    ecasData.filter((item) => {
      if (item.id == memberid) {
        selected_name = item.first_name;
        selected_pan = item.pan;
      }
    });
    try {
      let reqdata = {
        fp_user_id: memberid,
        pan: selected_pan,
      };
      let delData = await restApiCall(
        constClass.ADVISORY_DELETE_MF_CENTRAL_DATA,
        reqdata,
        ""
      );
      if (delData.error_code == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(selected_name + " has been deleted successfully");
        dispatch({
          type: "ASSETS_UPDATE",
          payload: true,
        });
        fetchMfCentral();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const refreshMfCentral = async (memberid) => {
    let member = ecasData.filter((v) => v.id == memberid);
    setSelectedMember({ ...member[0] });
    let current_date = moment().format("DD/MM/YYYY");
    let modified_date = moment(member[0].last_modified).format("DD/MM/YYYY");
    if (current_date == modified_date) {
      let reqData = {
        pan: member[0].pan,
        fp_user_id: memberid,
        fp_log_id: props.session.data.fp_log_id,
      };
      let checkPan = await restApiCall(
        constClass.DMF_CHECKIFPANEXISTS_API_URL,
        reqData,
        restHeaders
      );
      if (checkPan.error_code == "101") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(checkPan.message);
        return;
      }
    } else {
      if (member[0].id == selectedMember.id) {
        sendOTP(1);
      }
    }
  };

  const refreshSmallcase = async (memberid) => {
    let member_data = ecasData.filter((v) => v.id == memberid);
    const member = member_data.map((v) => ({
      name: v.first_name + " " + v.last_name,
      id: v.id,
      pan: v.pan,
      mobile: v.mobile,
      label: v.first_name + " " + v.last_name,
      value: v.id,
      last_modified: v.last_modified
    }));
    setSelectedMember({ ...member[0] });
    setIsEcasRefresh(true)
    let current_date = moment().format("DD/MM/YYYY");
    let modified_date = moment(member[0].last_modified).format("DD/MM/YYYY");
    if (current_date == modified_date) {
      setIsEcasRefresh(false)
      let reqData = {
        pan: member[0].pan,
        fp_user_id: memberid,
        fp_log_id: props.session.data.fp_log_id,
      };
      let checkPan = await restApiCall(
        constClass.DMF_CHECKIFPANEXISTS_API_URL,
        reqData,
        restHeaders
      );
      if (checkPan.error_code == "101") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(checkPan.message);
        return;
      }
    }
    //  else {
    //   if (member[0].id) {
    //     sendOTP();
    //   }
    // }
  };

  useEffect(() => {
    if (selectedMember?.mobile && selectedMember?.pan && isEcasRefresh) {
      sendOTP();
    }
  }, [selectedMember, isEcasRefresh]);

  const handleChange = async (e) => {
    try {
      if (Boolean(e) == false) {
        let member_data = allMembers;
        const member = member_data.map((v) => ({
          name: v.first_name + " " + v.last_name,
          id: v.id,
          pan: v.pan,
          mobile: v.mobile,
          label: v.first_name + " " + v.last_name,
          value: v.id,
          last_modified: v.last_modified
        }));
        setSelectedMember({ ...member[0] });
        setPanEditable(
          member[0].pan !== null && member[0].pan !== "" ? true : false
        );
        setSendDisabled(false);
      } else {
        setSelectedMember({ ...e });
        setPanEditable(e.pan !== null && e.pan !== "" ? true : false);
        setErrors({});
        setSendDisabled(false);
      }
    } catch (e) { }
  };

  useEffect(() => {
    setDefaultTimer();
  }, []);

  useEffect(() => {
    const mobileErrors = findMobileErrors();
    const panErrors = findPANErrors();
    if (
      !panEditable &&
      selectedMember.pan != "" &&
      selectedMember.pan != null
    ) {
      if (Object.keys(panErrors).length > 0) {
        setErrors((v) => ({ ...v, ...panErrors }));
      }
    }
    if (selectedMember.mobile != "" && selectedMember.mobile != null) {
      if (Object.keys(mobileErrors).length > 0) {
        setErrors((v) => ({ ...v, ...mobileErrors }));
      }
    }
  }, [selectedMember.pan, selectedMember.mobile]);

  const handleEcasDelete = async (e) => {
    if (e == "yes") {
      setEcasDeletePopup(false);
      if (props?.session?.data?.email.includes("yopmail.com")) {
        deleteMfCentral(memberID);
      } else {
        deleteSmallCase(memberID);
      }
    } else {
      setEcasDeletePopup(false);
    }
  };

  const startTimer = () => {
    timeNewObj.current = setInterval(function () {
      if (timeNewValue.current <= 0) {
        clearInterval(timeNewObj.current);
      } else {
        timeNewValue.current = timeNewValue.current - 1;
        setCount(timeNewValue.current);
      }
    }, 1000);
  };

  const setDefaultTimer = () => {
    timer.current.counter = timer.current.default;
  };

  const handleMobileChange = (e) => {
    setSelectedMember({ ...selectedMember, mobile: e.target.value });
    if (e.target.value.length == 10) {
      findMobileErrors();
    }
  };
  const handlePANChange = async (e) => {
    setSelectedMember({ ...selectedMember, pan: e.target.value });
    if (e.target.value.length == 10) {
      findPANErrors();
      const panErrors = {};
      let panRegistered = await checkPANRegistered(e.target.value);
      if (panRegistered != true) {
        panErrors.userPan = panRegistered;
        setErrors({ ...panErrors });
        return;
      }
    }
  };

  useEffect(() => {
    if (show && modalType) {
      document.getElementById("root").classList.add("blur-bg");
    } else {
      document.getElementById("root").classList.remove("blur-bg");
    }
  }, [show, modalType]);
  return (
    <>
      <FintooLoader isLoading={isLoading} />
      <div>
        <div>
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
              }}
              onClick={() => {
                setShowLinkAc(true);
                setModalType(0);
              }}
              className="linkholdings_btn pointer"
            >
              Link Account
            </div>
          </div>
          <table className="bgStyleTable text-center">
            <tbody>
              <tr>
                <th>Name</th>
                <th>PAN</th>
                <th>Mobile Number</th>
                <th>Last Updated</th>
                <th>Delete</th>
                <th>Update</th>
              </tr>
              {ecasData && ecasData.length > 0 ? (
                ecasData.map((smallcase) => (
                  <tr>
                    <td>
                      {smallcase.first_name} {smallcase.last_name}
                    </td>
                    <td>{smallcase.pan}</td>
                    <td>{smallcase.mobile}</td>
                    <td>
                      {moment(smallcase.last_modified).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      <button
                        className="default-btn"
                        style={{
                          marginRight: 0,
                          padding: 0,
                          margin: 0,
                        }}
                        type="button"
                      >
                        <img
                          alt="Delete"
                          src="https://static.fintoo.in/static/assets/img/delete.svg"
                          onClick={() => {
                            setMemberID(smallcase.id);
                            setEcasDeletePopup(true);
                          }}
                          style={{
                            width: 25,
                            height: 25,
                          }}
                        />
                      </button>
                    </td>
                    <td>
                      <button
                        style={{
                          marginRight: 0,
                          padding: 0,
                          margin: 0,
                        }}
                        className="default-btn"
                        type="button"
                      >
                        {" "}
                        {props?.session?.data?.email.includes("yopmail.com") ? (
                          <>
                            <img
                              src="https://static.fintoo.in/static/assets/img/refresh.svg"
                              style={{
                                width: 25,
                                height: 25,
                              }}
                              onClick={() => {
                                refreshMfCentral(smallcase.id);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="https://static.fintoo.in/static/assets/img/refresh.svg"
                              style={{
                                width: 25,
                                height: 25,
                              }}
                              onClick={() => {
                                refreshSmallcase(smallcase.id);
                              }}
                            />
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>No Holdings Linked.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {modalType == 0 && (
          <Modal className="popupmodal" centered show={showlinkac}>
            <Modal.Header className="ModalHead d-flex">
              <div className="text-center w-100">Link your Holdings </div>
              <div className="">
                <img
                  onClick={() => {
                    setShowLinkAc(false);
                    handleClose();
                  }}
                  className="pointer"
                  src="https://static.fintoo.in/static/assets/img/cancel_white.svg"
                  width={40}
                />
              </div>
            </Modal.Header>
            <div className=" p-4 d-grid place-items-center align-item-center">
              <div
                style={{
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                Please select the respective member from the dropdown menu to
                fetch MF holdings.
              </div>
              <div className=" HeaderModal mt-2">
                <form noValidate="novalidate" name="goldassetform">
                  <div className="row py-md-2">
                    <div className="col-12">
                      <div className="material">
                        <Form.Label>Member*</Form.Label>
                        <Select
                          classNamePrefix="sortSelect"
                          isSearchable={false}
                          styles={customStyles}
                          options={allMembers}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={allMembers.filter(
                            (v) => v.id == selectedMember.id
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-md-2 py-2">
                    <div className="col-md-6 col-12">
                      <div className="bank-label">
                        <div
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          PAN*{" "}
                        </div>
                      </div>
                      <div className="bank-info Nominee-name">
                        <div>
                          <input
                            aria-label=""
                            className="shadow-none form-control"
                            placeholder=""
                            maxlength="10"
                            style={{
                              border: 0,
                              borderBottom: "1px solid #aeaeae",
                              borderRadius: "0",
                              textTransform: "uppercase",
                              padding: "1px 0px 8px 0px",
                            }}
                            value={selectedMember.pan ?? ""}
                            onChange={(e) => handlePANChange(e)}
                            readOnly={panEditable}
                          />
                          {errors.userPan && (
                            <p className="error">{errors.userPan}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="bank-label">
                        <div
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          Mobile Number*{" "}
                        </div>
                      </div>
                      <div className="bank-info Nominee-name">
                        <div>
                          <input
                            aria-label=""
                            className="shadow-none form-control"
                            placeholder=""
                            type="number"
                            maxlength="10"
                            style={{
                              border: 0,
                              borderBottom: "1px solid #aeaeae",
                              borderRadius: "0",
                              padding: "1px 0px 8px 0px",
                            }}
                            value={selectedMember.mobile ?? ""}
                            onChange={(e) => handleMobileChange(e)}
                          />
                          {errors.userMobile && (
                            <p className="error">{errors.userMobile}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    {props?.session?.data?.email.includes("yopmail.com") ? (
                      <>
                        <div className="d-flex justify-content-center mt-4">
                          <button
                            type="button"
                            className="btn LInkOTPBTn"
                            disabled={sendDisabled}
                            onClick={() => sendMFOTP()}
                          >
                            Send MF OTP
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          type="button"
                          className="btn LInkOTPBTn"
                          disabled={sendDisabled}
                          onClick={() => sendOTP()}
                        >
                          Send OTP
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        )}
        {modalType == 1 && (
          <>
            <Modal
              style={{
                maxWidth: "400px !important",
              }}
              className="popupmodal"
              centered
              show
            >
              <Modal.Header className="ModalHead d-flex">
                <div className="text-center w-100">Please Enter OTP </div>
                <div className="">
                  <img
                    onClick={() => {
                      setModalType(2);
                    }}
                    className="pointer"
                    src="https://static.fintoo.in/static/assets/img/cancel_white.svg"
                    width={40}
                  />
                </div>
              </Modal.Header>

              <div className="p-4 d-grid place-items-center align-item-center">
                <div>
                  <div className="">
                    <div>
                      <div className="modal-whitepopup-box-item  border-top-0 text-center">
                        <p>
                          We have sent an OTP to -
                          <b>
                            {" "}
                            {selectedMember.mobile
                              .split("")
                              .map((v, i) => (i > 2 && i < 8 ? "*" : v))
                              .join("")}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className={`d-flex justify-center align-items-center`}>
                      <div className="m-auto">
                        <OTPInput
                          value={otpInput}
                          onChange={setOtpInput}
                          autoFocus
                          className="link-holdings-otp w-100"
                          style={{
                            border: "none",
                          }}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                        />
                        <div
                          style={{
                            height: "2px",
                          }}
                        ></div>
                        {errors.otpInput && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "normal",
                              padding: "9px 0px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {errors.otpInput}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text-center grey-color">
                      {count == 0 &&
                        (props?.session?.data?.email.includes("yopmail.com") ? (
                          <>
                            <p
                              style={{ fontSize: "1.2rem", color: "#042b62" }}
                              className="pointer mt-md-4 blue-color"
                              onClick={() => {
                                sendMFOTP();
                              }}
                            >
                              Resend MF OTP
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              style={{ fontSize: "1.2rem", color: "#042b62" }}
                              className="pointer mt-md-4 blue-color"
                              onClick={() => {
                                sendOTP();
                              }}
                            >
                              Resend OTP
                            </p>
                          </>
                        ))}
                      {count > 0 && (
                        <p
                          className="mt-md-4"
                          style={{
                            fontSize: "1.2rem",
                          }}
                        >
                          <strong>
                            {moment()
                              .startOf("day")
                              .seconds(count)
                              .format("mm:ss")}
                          </strong>
                        </p>
                      )}
                    </div>
                  </div>
                  <center>
                    {props?.session?.data?.email.includes("yopmail.com") ? (
                      <>
                        <div
                          type="button"
                          className="btn LInkOTPBTn"
                          onClick={() => {
                            verifyMfCentralOTP();
                          }}
                        >
                          Submit MF
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          type="button"
                          className="btn LInkOTPBTn"
                          onClick={() => {
                            verifySmallcaseOTP();
                          }}
                        >
                          Submit
                        </div>
                      </>
                    )}
                  </center>
                  <p>&nbsp;</p>
                </div>
              </div>
            </Modal>
          </>
        )}
        {modalType == 2 && (
          <>
            <Modal
              style={{
                maxWidth: "400px !important",
              }}
              className="popupmodal"
              centered
              show={waitforSms}
            >
              <div className={style["modal-cntn-www"] + ""}>
                <div>
                  <div className={`${style.linkmodaltext}`}>
                    Your external mutual Fund portfolio sync is in progress
                  </div>
                  <div className={`${style.linkmodalDestext}`}>
                    This may take 10 to 15 minutes. We will notify you once your
                    external portfolio is synced.
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    className={style["OK-btns"]}
                    setw
                    onClick={() => {
                      navigate(
                        process.env.PUBLIC_URL +
                        "/datagathering/assets-liabilities/"
                      );
                      setWaitforSms(false);
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Modal>
          </>
        )}

        <Modal className="popupmodal" centered show={ecasDeletePopup}>
          <Modal.Header className="ModalHead d-flex">
            <div className="text-center w-100">Delete Confirmation</div>
          </Modal.Header>
          <div className=" p-4 d-grid place-items-center align-item-center">
            <div
              style={{
                fontSize: "1rem",
                textAlign: "left",
              }}
            >
              All fetched mutual funds and their goal linkages will be
              permanently deleted. Are you sure you want to delete?
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button
                onClick={() => {
                  handleEcasDelete("yes");
                }}
                className="outline-btn m-2"
              >
                Yes
              </button>

              <button
                onClick={() => {
                  handleEcasDelete("no");
                }}
                className="outline-btn m-2"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default LinkYourHoldingsDG;
