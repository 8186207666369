const data = {
    title: "",
    rows: [
      {
        title: "What are mutual funds and how do they work?",
        content: "Lorem ipsum dolor sit amet, consectetur "
      },
      {
        title: "How to choose a mutual fund that suits your financial objective?",
        content:
          "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam. Und was ist, wenn der Satz länger ist ...? Geht es dann einfach weiter?"
      },
      {
        title: "How to invest in mutual funds?",
        content:
          "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
      },
      {
        title: "How to invest in mutual funds on Fintoo?",
        content: "Lorem ipsum dolor sit amet, consectetur "
      },
      {
        title: "What is the commission charged by Fintoo on Mutual funds investment?",
        content: "Ich habe auch keine Ahnung"
      }
    ]
  };
  
  export default data;
  