import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewFdBondsFormView from "./NewFdBondsFormView";

const NewFdBondsForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewFdBondsFormView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewFdBondsForm;
