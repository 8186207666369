import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import BackBtn from "../../Assets/left-arrow.png";
import Link from "../../MainComponents/Link";
import Form from "react-bootstrap/Form";
import UPIBOX from "./UPIBOX";
import CartAmt from "./CartAmt";
import MainLayout from "../../Layout/MainLayout";
import {
  DMF_BASE_URL,
  GET_CART_DETAILS,
  DMF_GETUSERBANKS_API_URL,
  DMF_GETBANKDETAILS_API_URL,
  DMF_GETBANKUPIID_API_URL,
  IS_DIRECT
} from "../../../constants";
import {
  CheckSession,
  apiCall,
  getSumOfProperty,
  setItemLocal,
  getItemLocal,
  getUserId,
  isFamilySelected,
} from "../../../common_utilities";
const AddFund = () => {};
const CloseFund = () => {};

const userid = getUserId();


export default function CartUPI() {

  const [typeName, setTypeName] = useState(0);
  const [bankDetail, setBankDetail] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [cartData, setCartData] = useState(getItemLocal("cart_data"));
  const [cartAmt, setCartAmt] = useState(getItemLocal("cart_amt"));
  const [upiId, setUpiId] = useState("");
  const [saveUpiId, setSaveUpiId] = useState(false);
  const bankid = getItemLocal("selbankid");
  const changeTypeName = (num) => {
    // setTypeName(num);
    setSelectedBankId(num);
  };

  useEffect(() => {
    // CheckSession();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  React.useEffect(function () {
    onLoadInIt();
  }, []);

  const onLoadInIt = async () => {
    try {
      if(isFamilySelected()) {
        setTimeout(()=> {
          navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/mycart");
        }, 300);
        return;
      }
      var res = await apiCall(DMF_GETBANKDETAILS_API_URL, {
        user_id: userid,
        bank_id: bankid,
        is_direct: IS_DIRECT,
      });
      setBankDetail(res.data);
      changeTypeName(res.data[0].bank_id);
      getUpiData(res.data[0].bank_id);
      if (!cartData || !cartAmt) getCartData();
    } catch (e) {
      // 
    }
  };

  const getCartData = async () => {
    var res = await apiCall(GET_CART_DETAILS, {
      user_id: userid,
      is_direct: IS_DIRECT,
    });
    let cartSum = getSumOfProperty(res.data, "cart_amount");
    setItemLocal("cart_data", res.data);
    setItemLocal("cart_amt", cartSum);
    setCartData(JSON.stringify(res.data));
    setCartAmt(JSON.stringify(cartSum));
  };

  const getUpiData = async (bank_id) => {
    try {
      var res = await apiCall(DMF_GETBANKUPIID_API_URL, {
        bank_id: String(bank_id),
      });
      // 
      if (res.error_code == "100") {
        if (res.data.save_upi_id == 'Y'){
          setSaveUpiId(true);
          setUpiId(res.data.upi_id);
          
        }
      }
    } catch (e) {
      // 
    }
  }
  // 
  return (
    <MainLayout>
      <div className="Transaction">
        <Container>
          <span></span>
          <div className="row">
            <div className="MainPanel d-flex">
              <div className="">
                <p>
                  <span className="Rupeees">
                    <Link to="/direct-mutual-fund/MyCart/">
                      <img className="BackBtn"  src={process.env.REACT_APP_STATIC_URL + "media/DMF/left-arrow.svg"} alt="BackBtn" />
                    </Link>
                  </span>
                </p>
              </div>
              <div className="text-label align-items-center text-center  w-75 ">
                <h4 style={{ textAlign: "center", fontWeight: "600" }}>
                  Select Bank
                </h4>
                <p className="PaymentText" style={{ textAlign: "center" }}>
                  Choose how you'd like to pay for your purchase
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="CartSelectSIP SelectBank f-Amt text-label">
              <div className="PaymentLayout">
                <Container>
                  <div className="payment-box">
                    <Row>
                      <div className="col-12 col-md-12 col-lg-7  ">
                        {/* <p className="col-12 col-md-12 col-lg-12 text-center ">
                          
                        </p> */}
                        <Form
                          style={{
                            maxHeight: "460px",
                            overflowY: "Scroll",
                            padding: "20px",
                            border: "1px solid #d6d6d6",
                            borderRadius: "10px",
                            scrollWidth: "thin",
                          }}
                        >
                          {bankDetail.map((v) => (
                            <UPIBOX
                              selectedType={v}
                              thisTypeName={selectedBankId}
                              upi_id={upiId}
                              save_upi_id={saveUpiId}
                            />
                          ))}
                          {/* <UPIBOX
                            selectedType={typeName}
                            thisTypeName={2}
                            onClick={() => changeTypeName(2)}
                          />
                          <UPIBOX
                            selectedType={typeName}
                            thisTypeName={3}
                            onClick={() => changeTypeName(3)}
                          />
                          <UPIBOX
                            selectedType={typeName}
                            thisTypeName={4}
                            onClick={() => changeTypeName(4)}
                          /> */}
                        </Form>
                      </div>
                      <div className="col-5 col-lg-4 DesktopView">
                        <CartAmt cartAmt={cartAmt} key={cartAmt} />
                      </div>
                    </Row>
                  </div>
                </Container>
                <br />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}
