import { useNavigate } from "react-router-dom";
import { fetchEncryptData, getUserId, loginRedirectGuest } from "../../common_utilities";
import { DMF_BASE_URL } from "../../constants";
import { useDispatch } from "react-redux";

const AddToWish = ({ scheme_slug, value = false, onToggleWishlist = null }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleWishList = async () => {
    try {
      const userid = getUserId();
      console.log('u---->', userid);
      if (userid != null) {
        var config = {
          url: DMF_BASE_URL + "api/wishlist/add",
          method: "post",
          data: { scheme_slug: scheme_slug, user_id: userid },
        };
        if (value) {
          config.url = DMF_BASE_URL + "api/wishlist/delete";
        }
        console.log("config---------->", { ...config });
        const r = await fetchEncryptData(config);
        console.log("rrr----->", r);
        if(r.error_code == 100) {
            if(onToggleWishlist) {
                onToggleWishlist(scheme_slug);
            }
            value ? RemoveAlert() : successAlert();
        }
        
      } else {
        loginRedirectGuest();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const successAlert = () => {
    dispatch({
      type: "RENDER_TOAST",
      payload: {
        message: "Added to Wishlist!",
        type: "success",
      },
    });
  };
  const RemoveAlert = () => {
    dispatch({
      type: "RENDER_TOAST",
      payload: {
        message: "Removed from Wishlist!",
        type: "success",
      },
    });
  };

  return (
    <div>
      <div className="pointer" onClick={() => toggleWishList()}>
        <img
          src={
            value
            ? process.env.REACT_APP_STATIC_URL + "media/DMF/bookmark1.svg"
            : process.env.REACT_APP_STATIC_URL + "media/DMF/bookmark.png"
          }
          style={{ width: "20px" }}
        />
      </div>
    </div>
  );
};
export default AddToWish;
