import Styles from "./style.module.css";
import React from "react";

const BannerSection = ({ scrollToForm, scrollToNextSection }) => {

    return (
        <section className={`${Styles.bannerSection}`}>
            <div className={`w-100 text-center ${Styles["land-txt"]}`}>
                <img
                    className={`${Styles.bannerSectionLogo}`}
                    src={process.env.REACT_APP_STATIC_URL + "media/wp/Fintoowhitelogo_.svg"}
                />
                <h1 className={`${Styles.SpaceReduce}`}>
                    Know the performance of your mutual<br />{" "} funds portfolio… <span style={{ color: '#35C3FF' }}>In a few seconds!</span>
                </h1>
                <p>
                    With Fintoo Mutual Fund Snippet <br />{" "}
                    Stay ahead and grow your wealth!
                </p>
                {/* <a href="#PortfolioReviewSectionId" style={{ textDecoration: 'none' }}> */}
                <div style={{
                    width: 'fit-content',
                    backgroundColor: '#042b62',
                    borderRadius: '5px',
                    padding: '1rem 4rem',
                    margin: '3rem auto 0 auto',
                    fontSize: '18px',
                    fontWeight: 500,
                    color: 'white',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
                    onClick={() => scrollToForm()}
                >
                    Explore Now
                </div>
                {/* </a> */}

                <div className="d-flex justify-content-center">
                    {/* <a href="#FeatureScroll"> */}
                    <div className={`${Styles.mouseicon}`} onClick={() => scrollToNextSection()}>
                        <span></span>
                    </div>
                    {/* </a> */}
                </div>
            </div>
        </section>
    );
};
export default BannerSection;
