import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import FormSwitch from "../CommonDashboardComponents/formSwitch";
import RecurringMaturityComponent from "./RecurringMaturityComponent";
import AddBonusComponent from "./AddBonusComponent";
import SimpleReactValidator from 'simple-react-validator';
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ADD_INSURANCE, ADVISORY_CHECK_PROFILE_STATUS_API_URL, DMF_BASE_URL, ADVISORY_GET_ULIP_DATA, GET_OTHER_INVESTMENTS, ADVISORY_ADD_UPDATE_INSURANCE_DATA, IS_DIRECT } from "../../../../constants";
import {
    getParentUserId, loginRedirectGuest, apiCall,
    fetchEncryptData,
    getItemLocal,
    getUserId,
    getFpUserDetailsId,
    getMemberId,
} from "../../../../common_utilities";
import commonEncode from "../../../../commonEncode";
import uuid from "react-uuid";
import moment from "moment";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const policyInputs = {
    default: [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_surrender_value",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "maturityBonus",
    ],
    '45': [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_surrender_value",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "maturityBonus",
    ],
    "46": [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_surrender_value",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "maturityBonus",
    ],
    "49": [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_surrender_value",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "maturityBonus",
    ],
    '50': [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_surrender_value",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "maturityBonus",
    ],
    "47": [
        "insurance_name",
        "insurance_policy_number",
        "typeOfGeneralInsurance",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_premium_topup",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
    ],
    "43": [
        "insurance_name",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_maturity_amount",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
    ],
    "44": [
        "ulip",
        "insurance_policy_number",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "insurance_policyterm",
        "recurring_insurance",
        "addBonus",
        "insurance_maturity_amount",
    ],
    '48': [
        "insurance_name",
        "insurance_policy_number",
        "mediclaimInsuranceFor",
        "insurance_premium_amount",
        "premiumPaymentFrequency",
        "insurance_sum_assured",
        "insurance_premium_topup",
        "dateOfPurchase",
        "premiumPaymentEndDate",
        "insurance_policyterm",
        "anyPreExistingDisease",
        "disease_name"
    ],
};

function isInputInPolicy(inputName, policyType) {
    // if string - direct lookup 
    // if number convert to string and check includes
    if (typeof policyType == "string") {
        let lowercasePolicyType = policyType.toLowerCase();

        if (!policyType) {
            lowercasePolicyType = "default";
        }
        return policyInputs[lowercasePolicyType].includes(inputName);
    } else if (typeof policyType == "number") {
        return policyInputs[policyType + ''].includes(inputName);
    }
}

const options_type_of_general_insurance = [
    { value: 106, label: "Travel Insurance" },
    { value: 107, label: "Motor Insurance" },
    { value: 108, label: "Marine Insurance" },
    { value: 109, label: "Home Insurance" },
    { value: 110, label: "Fire Insurance" },
    { value: 111, label: "Property insurance" },
    { value: 112, label: "Mobile insurance" },
    { value: 113, label: "Cycle insurance" },
    { value: 114, label: "Bite-size insurance" }
]

const options = [
    { value: "45", label: "Endowment" },
    { value: "47", label: "General Insurance" },
    { value: "46", label: "Guaranteed Income Plan" },
    { value: "48", label: "Mediclaim" },
    { value: "49", label: "Pension Plan" },
    { value: "43", label: "Term Plan" },
    { value: "44", label: "ULIP" },
    { value: "50", label: "Others" }
];

const options_premium_payment_frequency = [
    { value: "1", label: "Monthly" },
    { value: "2", label: "Quarterly" },
    { value: "3", label: "Half Yearly" },
    { value: "4", label: "Yearly" },
    { value: "5", label: "One Time" },
];

const initialValues = {
    insurancePolicyType: '',
    insurancePolicyName: '',
    policyNumber: '',
    premiumAmount: '',
    premiumPaymentFrequency: '',
    sumAssured: '',
    surrenderValue: '',
    dateOfPurchase: '',
    premiumPaymentEndDate: '',
    policyTermYears: '',
    isMaturityOneTimeOrRecurring: '',
    addBonus: false,
    maturityBonus: '',
    typeOfGeneralInsurance: '',
    insuranceMaturityAmount: '',
    ulip: '',
    topUp: '',
    mediclaimInsuranceFor: '',
    anyPreExistingDisease: '',
    maturityData: []

};

const defaultInsuranceData = {
    bonus_amount: 1,
    insurance_for_member: "",
    insurance_category_id: "",
    insurance_paying_frequency: '1',
    insurance_frequency: "",
    user_id: getParentUserId(),
    insurance_goal_id: "",
    insurance_rate_of_increase: "",
    insurance_name: "",
    insurance_category_name: "",
    insurance_ms_secid: "",
    insurance_premium_amount: "",
    insurance_bonus_amount: "",
    insurance_sum_assured: "",
    insurance_maturity_amount: "",
    insurance_surrender_value: "",
    insurance_payment_enddate: "",
    insurance_policy_enddate: "",
    insurance_end_date: "",
    insurance_bonus_date: "",
    insurance_recurring_amount: "",
    insurance_policyterm: "",
    insurance_footnote: "",
    insurance_premium_topup: "",
    insurance_isRecurring: false,
    insurance_islinkable: true,
    insurance_hasPredisease: false,
    disease_name: "",
    insurance_policy_number: "",
    insurance_bonus: false,
    ulip_data: {},
    insurance_id: 0,
    id: 0,
    insurance_total_memebers: 0,
    insurance_type: "",
    insurance_start_date: "",
    insurance_purchase_date: "",
    insurance_source: "2",
    recurring_insurance: [],
    recurring_bonus_amount: [{ refId: uuid(), isValid: false }]
};

const NewInsuranceFormView = () => {
    const [, forceUpdate] = useState();
    const [formData, setFormData] = useState(initialValues);

    const [maturityToggle, setMaturityToggle] = useState(false);
    const [addBonusToggle, setAddBonusToggle] = useState(false);
    const [existingDiseaseToggle, setExistingDiseaseToggle] = useState(false);
    const dispatch = useDispatch();
    const [ulipFund, setUlipFund] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [ids, setid] = useState("")
    const simpleValidator = useRef(new SimpleReactValidator());

    //new state for advisory api integration
    const [insuranceData, setInsuranceData] = useState(defaultInsuranceData);

    const onInputChange = (e, isNumeric) => {
        const name = e.target.name;
        let value = e.target.value;
        if (isNumeric && !numericRegex.test(value) && value !== '') {
            return;
        }

        // setFormData({ ...formData, [name]: value });
        setInsuranceData({ ...insuranceData, [name]: value });
    }

    const onDateAndSelectInputChange = (name, value) => {
        if (name == "insurance_category_id") {
            setInsuranceData({ ...insuranceData, insurance_category_id: value.value, insurance_name: value.label })
        } else {
            setInsuranceData({ ...insuranceData, [name]: value });
        }
        // setFormData({ ...formData, [name]: value });
    }

    const resetState = () => {
        setInsuranceData((prev) => ({ ...prev, insurance_paying_frequency: '1', insurance_frequency: "", insurance_rate_of_increase: "", insurance_premium_amount: "", insurance_bonus_amount: "", insurance_sum_assured: "", insurance_maturity_amount: "", insurance_surrender_value: "", insurance_payment_enddate: "", insurance_policy_enddate: "", insurance_end_date: "", insurance_bonus_date: "", insurance_recurring_amount: "", insurance_policyterm: "", insurance_footnote: "", insurance_premium_topup: "", insurance_isRecurring: false, insurance_islinkable: true, insurance_hasPredisease: false, disease_name: "", insurance_policy_number: "", insurance_bonus: false, ulip_data: {}, insurance_id: 0, insurance_total_memebers: 0, insurance_type: "", insurance_start_date: "", insurance_purchase_date: moment(new Date()).toDate(), insurance_source: "2", recurring_insurance: [], recurring_bonus_amount: defaultInsuranceData.recurring_bonus_amount }));
        setAddBonusToggle(false);
        setMaturityToggle(false);
        setExistingDiseaseToggle(false);
    }

    const getSelectValueData = (selectOptions, value) => {
        return selectOptions.find((data) => data.value === value);
    }

    const validateForm = () => {
        simpleValidator.current.showMessages();
        let formSuccess = true;  

        if (maturityToggle && insuranceData.recurring_insurance.some(v => v.isValid === false)) {
            formSuccess = false;
        }
    
        if (addBonusToggle) {

            if (insuranceData.recurring_bonus_amount.some(v => v.insurance_bonus_amount <= 0 || v.isValid === false)) {
                formSuccess = false;
            }
        }

        if (formSuccess && simpleValidator.current.allValid()) {
            Addinsurance();
        } else {
            forceUpdate(1);
        }
    }

    const getInsuranceTypeData = (insuranceTypeData, value, ulip_check) => {
        if (ulip_check) {
            return insuranceTypeData.find((data) => data.label === value);
        } else {
            return insuranceTypeData.find((data) => data.value === value);
        }
    }

    const getMemberAssetId = () => {
        let a = getItemLocal("member");
        let x = a.find(item => item.id == getUserId());
        let member = getItemLocal("family") ? 0 : x.fp_user_details_id
        setInsuranceData({ ...insuranceData, insurance_for_member: member })
    }

    useEffect(() => {
        if (insuranceData?.recurring_insurance.length > 0) return
        if (maturityToggle) {
            setInsuranceData(prev => ({ ...prev, recurring_insurance: [{ refId: uuid(), isValid: false }] }));
        }
    }, [maturityToggle]);


    // useEffect(() => {
    //     if (addBonusToggle) {
    //         setInsuranceData(prev => ({ ...prev, recurring_bonus_amount: [{ refId: uuid(), isValid: false }] }));
    //     }
    // }, [addBonusToggle]);

    useEffect(() => {
        // console.log(formData, "//////shree")
        if (id != undefined) {
            setid(atob(id));
            fetchInsuranceData();

        }
        // id  = useParams();

        if (getParentUserId() == null) {
            loginRedirectGuest();

        }
        else {
            ulip();
        }

    }, [, ids])

    useEffect(() => {
        // if (getItemLocal("family")) {
        //     navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=2");
        // }
        getMemberAssetId();
    }, [])

    const assign = async (data) => {
        console.log("assignData ---> ", data);
        const dataCopy = {
            bonus_amount: 1,
            insurance_for_member: getItemLocal("family") ? 0 : await getFpUserDetailsId(),
            insurance_category_id: data.insurance_type + '',
            insurance_paying_frequency: getSelectValueData(options_premium_payment_frequency, data.insurance_paying_frequency).value,
            insurance_frequency: "",
            user_id: getParentUserId(),
            insurance_goal_id: "",
            insurance_rate_of_increase: "",
            insurance_name: data.policy_name,
            insurance_category_name: "",
            insurance_ms_secid: "",
            insurance_premium_amount: data.premium_amount,
            insurance_bonus_amount: "",
            insurance_sum_assured: data.risk_coverage,
            insurance_maturity_amount: data.insurance_maturity_amount,
            insurance_surrender_value: data.insurance_surrender_value,
            insurance_payment_enddate: moment(data.maturitydate).toDate(),
            insurance_policy_enddate: "",
            insurance_end_date: "",
            insurance_start_date: moment(data.policy_start_date).toDate(),
            insurance_bonus_date: "",
            insurance_recurring_amount: "",
            insurance_policyterm: "",
            insurance_footnote: "",
            insurance_premium_topup: "",
            insurance_isRecurring: false,
            insurance_islinkable: true,
            insurance_hasPredisease: data?.disease_name?.length > 0 ? true : false,
            disease_name: data.disease_name,
            insurance_policy_number: data.policy_no,
            insurance_bonus: false,
            ulip_data: {},
            insurance_id: atob(id),
            id: atob(id),
            insurance_total_memebers: 0,
            insurance_type: "",
            insurance_purchase_date: moment(data.policy_start_date).toDate(),
            insurance_source: "2",
            recurring_insurance: JSON.parse(data.recurring_insurance),
            recurring_bonus_amount: JSON.parse(data.recurring_bonus_amount),
            insurance_type: data.type_general_insurance * 1
        }
        
        if (dataCopy.recurring_insurance.length > 0) setMaturityToggle(true);
        // if(dataCopy.bonousData.length >0) setAddBonusToggle(true)
        if (dataCopy.recurring_bonus_amount.length > 0) setAddBonusToggle(true);
        if (dataCopy?.disease_name?.length > 0) setExistingDiseaseToggle(true);
        if (dataCopy.recurring_bonus_amount.length == 0) {
            dataCopy.recurring_bonus_amount = defaultInsuranceData.recurring_bonus_amount;
        }
        setInsuranceData(dataCopy);
    };

    function getCategoryName(id) {
        const option = options.find(key => key.value === id);
        return option ? option.label : "Label not found";
    }

    const Addinsurance = async () => {

        try {
            let cat_name = getCategoryName(insuranceData.insurance_category_id);
            let diff = moment(insuranceData.insurance_payment_enddate).diff(moment(insuranceData.insurance_purchase_date), 'years').toString();

            let aw_req_data = {
                "insurance_for_member": insuranceData.insurance_for_member,
                "insurance_category_id": insuranceData.insurance_category_id,
                "insurance_paying_frequency": insuranceData.insurance_paying_frequency,
                "insurance_frequency": insuranceData.insurance_frequency,
                "user_id": getParentUserId(),
                "insurance_name": 'policy_name' in insuranceData ? insuranceData.policy_name : insuranceData.insurance_name,
                "insurance_category_name": cat_name,
                "insurance_premium_amount": insuranceData.insurance_premium_amount,
                "insurance_sum_assured": insuranceData.insurance_sum_assured,
                "insurance_maturity_amount": insuranceData.insurance_maturity_amount,
                "insurance_surrender_value": insuranceData.insurance_surrender_value,
                "insurance_payment_enddate": moment(insuranceData.insurance_payment_enddate).format('DD/MM/YYYY'),
                "insurance_start_date": moment(insuranceData.insurance_purchase_date).format('DD/MM/YYYY'),
                "insurance_policyterm": diff,
                "insurance_isRecurring": insuranceData.insurance_isRecurring,
                "insurance_islinkable": insuranceData.insurance_islinkable,
                "insurance_hasPredisease": insuranceData?.disease_name?.length > 0 ? true : false,
                "insurance_bonus": insuranceData.insurance_bonus,
                "ulip_data": insuranceData.ulip_data,
                "insurance_id": insuranceData.insurance_id,
                "id": insuranceData.id,
                "insurance_total_memebers": insuranceData.insurance_total_memebers,
                "insurance_type": insuranceData.insurance_category_id == "47" ? insuranceData.insurance_type : "",
                "insurance_purchase_date": moment(insuranceData.insurance_purchase_date).format('DD/MM/YYYY'),
                "recurring_insurance": insuranceData.recurring_insurance,
                "recurring_bonus_amount": insuranceData.recurring_bonus_amount,
                "bonus_amount": insuranceData.bonus_amount,
                "insurance_source": "2",
                "insurance_policy_number": insuranceData.insurance_policy_number,
            }

            let array_for_recurring = [];
            if (insuranceData.recurring_insurance.length > 0) {
                array_for_recurring = insuranceData.recurring_insurance.map(item => ({
                    insurance_start_date: typeof item.insurance_start_date == "object" ? moment(item.insurance_start_date).format("DD/MM/YYYY") : item.insurance_start_date,
                    insurance_end_date: typeof item.insurance_end_date == "object" ? moment(item.insurance_end_date).format("DD/MM/YYYY") : item.insurance_end_date,
                    insurance_recurring_amount: item.insurance_recurring_amount,
                    insurance_frequency: item.insurance_frequency
                }));
                aw_req_data['recurring_insurance'] = array_for_recurring;
                aw_req_data['insurance_isRecurring'] = 1;
            }

            let array_for_bonus = [];
            if (addBonusToggle) {
                if (insuranceData.recurring_bonus_amount.length > 0) {
                    array_for_bonus = insuranceData.recurring_bonus_amount.map(item => ({
                        insurance_bonus_amount: item.insurance_bonus_amount,
                        insurance_bonus_date: typeof item.insurance_bonus_date == "object" ? moment(item.insurance_bonus_date).format("DD/MM/YYYY") : item.insurance_bonus_date
                    }))
                }
                aw_req_data['recurring_bonus_amount'] = array_for_bonus;
                aw_req_data['insurance_bonus'] = true;
            }

            if (!maturityToggle && array_for_recurring.length > 0) {
                aw_req_data['recurring_insurance'] = [];
                aw_req_data['insurance_isRecurring'] = 0;
            }

            if (!addBonusToggle && array_for_bonus.length > 0) {
                aw_req_data['recurring_bonus_amount'] = [];
                aw_req_data['insurance_bonus'] = false;
            }

            if (insuranceData.insurance_category_id == "48") {
                delete aw_req_data["insurance_surrender_value"];
                delete aw_req_data["insurance_maturity_amount"];
                if (insuranceData.disease_name != "" && insuranceData.disease_name != undefined) {
                    aw_req_data["disease_name"] = insuranceData.disease_name
                }
            }

            if (insuranceData.insurance_category_id == "43") {
                delete aw_req_data["insurance_surrender_value"];
                delete aw_req_data["insurance_maturity_amount"];
                aw_req_data['insurance_maturity_amount'] = insuranceData.insurance_sum_assured != "" ? insuranceData.insurance_sum_assured : 0
            }

            if (insuranceData.insurance_category_id == "47") {
                delete aw_req_data['insurance_surrender_value'];
                delete aw_req_data['insurance_maturity_amount'];
            }

            if (insuranceData.insurance_category_id == "44") {
                aw_req_data['insurance_name'] = insuranceData.insurance_name
            }

            //     if (formData.insurancePolicyType == "ULIP") {
            //         req_data['scheme_name'] = formData.ulip
            //         req_data['maturity_amount'] = formData.insuranceMaturityAmount != "" ? formData.insuranceMaturityAmount : 0
            //         if ("maturity_bonus" in req_data) {
            //             delete req_data["maturity_bonus"];
            //         }

            //         if ("surrender_value" in req_data) {
            //             delete req_data["surrender_value"];
            //         }
            //         // if ('scheme_name' in req_data) {
            //         //     delete req_data['scheme_name']
            //         // }

            // console.log(req_data,)
            let res = await apiCall(ADVISORY_ADD_UPDATE_INSURANCE_DATA, aw_req_data, true, false);
            if (res.error_code == "100") {
                // window.location.href =
                //     process.env.PUBLIC_URL + "/direct-mutual-fund/portfolio/dashboard/";
                if (id != undefined) {
                    dispatch({
                        type: "RENDER_TOAST",
                        payload: { message: "Insurance Updated Successfully!", type: "success" },
                    });
                }
                else {
                    dispatch({
                        type: "RENDER_TOAST",
                        payload: { message: "Insurance Added Successfully!", type: "success" },
                    });
                }
                setTimeout(() => {
                    navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=2"), 2000
                })
            }

        } catch (e) {
            console.log(e, "-------->")
        }
    };

    const ulip = async () => {

        try {
            var config = {
                method: "GET",
                url: ADVISORY_GET_ULIP_DATA,
                data: ""
            };
            var ulip_fund = await axios(config);

            if (ulip_fund.data["error_code"] == "100") {
                var ulip_array = [];
                var ulip = ulip_fund["data"]["data"];
                var labelSet = new Set();
                ulip.forEach((u) => {
                    const label = u.share_name;
                    if (!labelSet.has(label)) {
                        ulip_array.push({ value: u.ms_secid, label });
                        labelSet.add(label);
                    }
                });
                setUlipFund(ulip_array);
                // setRelations(responseRel.data);
            }
        }
        catch (e) {
            console.log(e, "-------->")
        }
    };

    const fetchInsuranceData = async () => {
        try {
            if (getItemLocal("family")) {
                var new_array = [];
                var new_data = getItemLocal("member");
                new_data.forEach((element) => {
                    if (element.id !== null) {
                        new_array.push(element.id.toString());
                    }
                });
            }
            var payload = {
                url: GET_OTHER_INVESTMENTS,
                data: {
                    user_id: getParentUserId(),
                    fp_user_details_id: getItemLocal("family") ? 0 : getItemLocal("member").find(u => u.id == getUserId() * 1).fp_user_details_id,
                    inv_type: "all",
                    is_direct: IS_DIRECT,
                },
                method: "post",
            };

            var res = await fetchEncryptData(payload);
            // console.log("res", res)
            if (res.error_code == "100") {
                var insuranceId = atob(id);
                const data = res.data.insurance_data.insurance_details.filter((v) => v.insurance_id === Number(insuranceId));
                assign(data[0])
                // console.log("shree1", data[0])
            } else {
                setFormData(initialValues)
            }
        } catch (e) {
            console.error(e);
        }
    };

    simpleValidator.current.purgeFields();
    // For Select Style
    const customStyles = {
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: "#ffff",
                color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
                cursor: "pointer",
            };
        },
        menuList: (base) => ({
            ...base,
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#042b62",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
        }),
    };


    return (
        <>
            <div className="px-0 px-md-4 assetForm">
                <div
                    className="p-3"
                    style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
                >
                    <div className="d-flex">
                        <Link to={
                            process.env.PUBLIC_URL +
                            "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=2"
                        }> <img
                                style={{
                                    transform: "rotate(180deg)",
                                }}
                                width={20}
                                height={20}
                                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.svg"}
                            /></Link>
                        <h3
                            className="text-center pb-0 mb-0 ps-2"
                            style={{
                                flexGrow: 1,
                            }}
                        >
                            {id != undefined ? "Edit Your Insurance" : "Add Your Insurance"}
                            {/* Add Your Insurance */}
                        </h3>
                    </div>
                    <hr style={{ color: '#afafaf' }} />
                    <div className="row">
                        <div className="col-12 col-md-11 col-lg-8 m-auto">
                            <p className="text-center">
                                Enter Your Details To {id != undefined ? "Edit " : "Add "} Existing Insurance
                            </p>
                            <br />
                            <br />
                            <div>
                                <div className="my-md-4">
                                    <div className="">
                                        <span className="lbl-newbond">
                                            Select Insurance Type *
                                        </span>
                                        <br />

                                        <Select
                                            className={`fnto-dropdown-react ${id ? "disabled" : ""}`}
                                            classNamePrefix="sortSelect"
                                            styles={customStyles}
                                            isSearchable={false}
                                            options={options}
                                            value={getSelectValueData(options, insuranceData.insurance_category_id)}
                                            name='insurancePolicyType'
                                            onChange={(e) => {
                                                onDateAndSelectInputChange('insurance_category_id', e); resetState();
                                            }}
                                        />
                                        {simpleValidator.current.message('insurancePolicyType', insuranceData.insurance_category_id, 'required')}
                                    </div>
                                </div>

                                {/* {
                                    getFormView(formData.insurancePolicyName)
                                } */}

                                <div>
                                    <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            isInputInPolicy("insurance_name", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Insurance Policy Name *
                                                        </span>
                                                        <br />
                                                        <input
                                                            autoComplete="off"
                                                            placeholder="Enter Insurance Policy Name"
                                                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                            type="text"
                                                            maxLength={35}
                                                            name="insurance_name"
                                                            value={insuranceData.insurance_name}
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                        {simpleValidator.current.message('insurance_name', insuranceData.insurance_name, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isInputInPolicy("ulip", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            ULIP *
                                                        </span>
                                                        <br />
                                                        <Select
                                                            className="fnto-dropdown-react"
                                                            classNamePrefix="sortSelect"
                                                            styles={customStyles}
                                                            isSearchable={true}
                                                            options={ulipFund}
                                                            name="ulip"
                                                            value={getInsuranceTypeData(ulipFund, insuranceData.insurance_name, true)}
                                                            onChange={(e) => {
                                                                setInsuranceData({
                                                                    ...insuranceData,
                                                                    insurance_name: e.label,
                                                                    insurance_ms_secid: e.value,
                                                                    ulip_data: {
                                                                        share_name: e.label,
                                                                        ms_secid: e.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {/* {console.log(formData.ulip,"nil343......",formData)} */}
                                                        {simpleValidator.current.message('ulip', insuranceData.insurance_name, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">
                                                        Policy Number *
                                                    </span>
                                                    <br />
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Enter Policy Number"
                                                        className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                        type="text"
                                                        name="insurance_policy_number"
                                                        value={insuranceData.insurance_policy_number}
                                                        onChange={(e) => onInputChange(e)}
                                                    />
                                                    {simpleValidator.current.message('insurance_policy_number', insuranceData.insurance_policy_number, 'required|numeric|min:1,num')}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isInputInPolicy("typeOfGeneralInsurance", insuranceData.insurance_category_id) && <div className="col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Type Of General Insurance *
                                                        </span>
                                                        <br />

                                                        <Select
                                                            className="fnto-dropdown-react"
                                                            classNamePrefix="sortSelect"
                                                            styles={customStyles}
                                                            isSearchable={false}
                                                            options={options_type_of_general_insurance}
                                                            name="typeOfGeneralInsurance"
                                                            value={getInsuranceTypeData(options_type_of_general_insurance, insuranceData.insurance_type)}
                                                            onChange={(e) => onDateAndSelectInputChange('insurance_type', e.value)}
                                                        />
                                                        {simpleValidator.current.message('TypeOfGeneralInsurance', insuranceData.insurance_type, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* {
                                            isInputInPolicy("mediclaimInsuranceFor", formData.insurancePolicyType) && <div className="col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Mediclaim Insurance For *
                                                        </span>
                                                        <br />
                                                        <Select
                                                            className="fnto-dropdown-react"
                                                            classNamePrefix="sortSelect"
                                                             styles={customStyles}
                                                            isSearchable={false}
                                                            options={[]}
                                                            name="mediclaimInsuranceFor"
                                                            value={getInsuranceTypeData([], 'mediclaimInsuranceFor')}
                                                            onChange={(e) => onDateAndSelectInputChange('mediclaimInsuranceFor', e.label)}
                                                        />
                                                        {simpleValidator.current.message('mediclaimInsuranceFor', formData.mediclaimInsuranceFor, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">
                                                        Premium Amount *
                                                    </span>
                                                    <br />
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Enter Premium Amount"
                                                        className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                        type="text"
                                                        name="insurance_premium_amount"
                                                        maxLength={11}
                                                        value={insuranceData.insurance_premium_amount}
                                                        onChange={(e) => onInputChange(e, true)}
                                                    />
                                                    {simpleValidator.current.message('premiumAmount', insuranceData.insurance_premium_amount, 'required|numeric|min:1,num')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">
                                                        Premium Payment Frequency *
                                                    </span>
                                                    <br />
                                                    <Select
                                                        className="fnto-dropdown-react"
                                                        classNamePrefix="sortSelect"
                                                        styles={customStyles}
                                                        isSearchable={false}
                                                        options={options_premium_payment_frequency}
                                                        name="premiumPaymentFrequency"
                                                        value={getSelectValueData(options_premium_payment_frequency, insuranceData.insurance_paying_frequency)}
                                                        onChange={(e) => onDateAndSelectInputChange('insurance_paying_frequency', e.value)}
                                                    />
                                                    {simpleValidator.current.message('premiumPaymentFrequency', insuranceData.insurance_paying_frequency, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">
                                                        Sum Assured *
                                                    </span>
                                                    <br />
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Enter Sum Assured"
                                                        className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                        type="text"
                                                        maxLength={11}
                                                        name="insurance_sum_assured"
                                                        value={insuranceData.insurance_sum_assured}
                                                        onChange={(e) => onInputChange(e, true)}
                                                    />
                                                    {simpleValidator.current.message('sumAssured', insuranceData.insurance_sum_assured, 'required|numeric|min:1,num')}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isInputInPolicy("insurance_surrender_value", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Surrender Value
                                                        </span>
                                                        <br />
                                                        <input
                                                            autoComplete="off"
                                                            placeholder="Enter Surrender Value"
                                                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                            type="text"
                                                            maxLength={11}
                                                            name='insurance_surrender_value'
                                                            value={insuranceData.insurance_surrender_value}
                                                            onChange={(e) => onInputChange(e, true)}
                                                        />
                                                        {simpleValidator.current.message('insurance_surrender_value', insuranceData.insurance_surrender_value, 'numeric|min:1,num')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isInputInPolicy("insurance_premium_topup", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Top Up
                                                        </span>
                                                        <br />
                                                        <input
                                                            autoComplete="off"
                                                            placeholder="Enter Top Up"
                                                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                            type="text"
                                                            maxLength={11}
                                                            name='insurance_premium_topup'
                                                            value={insuranceData.insurance_premium_topup}
                                                            onChange={(e) => onInputChange(e, true)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isInputInPolicy("insurance_maturity_amount", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">
                                                            Insurance Maturity Amount (if any)
                                                        </span>
                                                        <br />
                                                        <input
                                                            autoComplete="off"
                                                            placeholder="Enter Insurance Maturity Amount"
                                                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                            type="text"
                                                            name='insurance_maturity_amount'
                                                            maxLength={11}
                                                            value={insuranceData.insurance_maturity_amount}
                                                            onChange={(e) => onInputChange(e, true)}
                                                        />
                                                        {simpleValidator.current.message('insuranceMaturityAmount', insuranceData.insurance_maturity_amount, 'numeric|min:1,num')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">Date of Purchase *</span>
                                                    <br />
                                                    <div className="bonds-datepicker">
                                                        <FintooDatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            autoComplete="off"
                                                            maxDate={new Date()}
                                                            selected={insuranceData.insurance_purchase_date === "" ? "" : new Date(insuranceData.insurance_purchase_date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            customClass="datePickerDMF"
                                                            name='dateOfPurchase'
                                                            onChange={(e) => onDateAndSelectInputChange('insurance_purchase_date', formatDatefun(e))}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    </div>
                                                    {simpleValidator.current.message('dateOfPurchase', insuranceData.insurance_purchase_date, 'required')}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">Premium Payment End Date *</span>
                                                    <br />
                                                    <div className="bonds-datepicker">
                                                        <FintooDatePicker
                                                            minDate={insuranceData.insurance_purchase_date ? moment(insuranceData.insurance_purchase_date).add(1, 'y').toDate() : new Date()}
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={insuranceData.insurance_payment_enddate === "" ? "" : new Date(insuranceData.insurance_payment_enddate)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            autoComplete="off"
                                                            dropdownMode="select"
                                                            customClass="datePickerDMF"
                                                            name='premiumPaymentEndDate'
                                                            onChange={(e) => onDateAndSelectInputChange('insurance_payment_enddate', formatDatefun(e))}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    </div>
                                                    {simpleValidator.current.message('premiumPaymentEndDate', insuranceData.insurance_payment_enddate, 'required')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">Policy Term (Years) *</span>
                                                    <br />
                                                    <input
                                                        placeholder=""
                                                        autoComplete="off"
                                                        className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                        type="text"
                                                        disabled={true}
                                                        maxLength={2}
                                                        name='insurance_policyterm'
                                                        value={insuranceData.insurance_payment_enddate != "" && insuranceData.insurance_purchase_date != "" ? moment(insuranceData.insurance_payment_enddate).diff(moment(insuranceData.insurance_purchase_date), 'years') : "-"}
                                                        onChange={(e) => onInputChange(e, true)}
                                                    />
                                                    {/* {simpleValidator.current.message('insurancePolicyTerm', insuranceData.insurance_policyterm, 'required')} */}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isInputInPolicy("recurring_insurance", insuranceData.insurance_category_id) &&
                                            <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">Is Maturity One Time Or Recurring ? *</span>
                                                        <br />
                                                        <div className="bonds-datepicker">
                                                            <div className="insurance-switch-container">
                                                                <span>One Time&nbsp;&nbsp;</span> <FormSwitch switchValue={maturityToggle} onSwitchToggle={() => setMaturityToggle((previous) => !previous)} /> <span>&nbsp;&nbsp;&nbsp;&nbsp;Recurring</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isInputInPolicy("anyPreExistingDisease", insuranceData.insurance_category_id) &&
                                            <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">Any Pre Existing Disease ? *</span>
                                                        <br />
                                                        <div className="bonds-datepicker">
                                                            <div className="insurance-switch-container">
                                                                <span>No&nbsp;&nbsp;</span> <FormSwitch switchValue={existingDiseaseToggle} onSwitchToggle={() => setExistingDiseaseToggle((previous) => !previous)} /> <span>&nbsp;&nbsp;&nbsp;&nbsp;Yes</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            existingDiseaseToggle && isInputInPolicy("disease_name", insuranceData.insurance_category_id) &&
                                            <div className="col-md-6 col-12">
                                                <div className="my-md-4">
                                                    <div className="">
                                                        <span className="lbl-newbond">Name Of The Disease *</span>
                                                        <br />
                                                        <input
                                                            autoComplete="off"
                                                            placeholder="Enter Name Of The Disease"
                                                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                            type="text"
                                                            maxLength={35}
                                                            name='disease_name'
                                                            value={insuranceData.disease_name}
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                        {simpleValidator.current.message('nameOfTheDisease', insuranceData.disease_name, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                                {
                                    maturityToggle && 'recurring_insurance' in insuranceData && insuranceData.recurring_insurance.length >= 0 && isInputInPolicy("recurring_insurance", insuranceData.insurance_category_id) && <RecurringMaturityComponent
                                        data={insuranceData.recurring_insurance}
                                        setToggle={() => setMaturityToggle((previous) => !previous)}
                                        onAdd={() => {
                                            setInsuranceData(prev => ({ ...prev, recurring_insurance: [...prev.recurring_insurance, { refId: uuid(), isValid: false }] }));
                                        }}
                                        onUpdate={(newData) => {
                                            // console.log('nil12887-->', newData);
                                            setInsuranceData(prev => ({ ...prev, recurring_insurance: [...newData] }));
                                        }}
                                    />
                                } 
                                <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {
                                        isInputInPolicy("addBonus", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">Add Bonus</span>
                                                    <br />
                                                    <div className="bonds-datepicker">
                                                        <div className="insurance-switch-container">
                                                            <span>No&nbsp;&nbsp;</span> <FormSwitch switchValue={addBonusToggle} onSwitchToggle={() => setAddBonusToggle((previous) => !previous)} /> <span>&nbsp;&nbsp;&nbsp;&nbsp;Yes</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        addBonusToggle && 'recurring_bonus_amount' in insuranceData && insuranceData.recurring_bonus_amount.length >= 0 && isInputInPolicy("addBonus", insuranceData.insurance_category_id) &&
                                        <AddBonusComponent
                                            bonousData={insuranceData.recurring_bonus_amount}
                                            setToggle={() => setAddBonusToggle((previous) => !previous)}

                                            // onAdd={() => {
                                            //     setFormData(prev => ({ ...prev, bonousData: [...prev.bonousData, { refId: uuid(), isValid: false }] }));
                                            // }}

                                            // setInsuranceData(prev => ({ ...prev, recurring_bonus_amount: [...prev.recurring_bonus_amount, { ...prev }] }));

                                            onAdd={() => {
                                                setInsuranceData((prev => ({ ...prev, recurring_bonus_amount: [...prev.recurring_bonus_amount, { refId: uuid(), isValid: false }] })))
                                            }}

                                            onUpdate={(newData) => {
                                                setInsuranceData(prev => ({ ...prev, recurring_bonus_amount: [...newData] }));
                                            }}
                                        />
                                    }
                                    {
                                        isInputInPolicy("insurance_maturity_amount", insuranceData.insurance_category_id) && <div className="col-md-6 col-12">
                                            <div className="my-md-4">
                                                <div className="">
                                                    <span className="lbl-newbond">Maturity Bonus</span>
                                                    <br />
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Enter Maturity Bonus"
                                                        className={` w-100 fntoo-textbox-react inputPlaceholder`}
                                                        type="text"
                                                        name='insurance_maturity_amount'
                                                        maxLength={11}
                                                        value={insuranceData.insurance_maturity_amount}
                                                        onChange={(e) => onInputChange(e, true)}
                                                    />
                                                    {simpleValidator.current.message('maturityBonus', insuranceData.insurance_maturity_amount, 'required|numeric|min:1,num')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <br />
                                <div className="my-md-4">
                                    <button
                                        type="submit"
                                        className="d-block m-auto btn btn-primary"
                                        onClick={() => validateForm()}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
export default NewInsuranceFormView;