import styles from "./style.module.css";
import SectionHeader from "../../../SectionHeader";
const StepsFinancialPlan = () => {
  return (
    <section className={`${styles.StepsInvestmentPlanSection}`}>
      <div className={`${styles.StepsInvestmentPlanHederText}`}>
        <SectionHeader
          className="text-center"
          headerText={"Steps For Financial Planning"}
        />
      </div>
      <div className={`${styles.StepsInvestmentPlanBox}`}>
        <div className={`${styles.StepsInvestmentPlanCard}`}>
          <div className={`${styles.StepsInvestmentPlanCardImg}`}>
          <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/number-one.png'} alt="" />
            {/* <img src={One} /> */}
          </div>
          <div className={`${styles.StepsInvestmentPlancontent}`}>
            <div className={`${styles.StepsInvestmentPlancontenttitle}`}>
              Getting To Know You.
            </div>
            <div className={`${styles.StepsInvestmentPlanContentText}`}>
              Investment is an extremely personal subject. Therefore, we start
              the financial planning process by getting to know you, your needs,
              your goals, your existing assets and liabilities. Moreover, we
              also focus on understanding all minute details that play an
              important factor in financial planning.
            </div>
          </div>
        </div>
        <div className={`${styles.StepsInvestmentPlanCard}`}>
          <div className={`${styles.StepsInvestmentPlancontent}`}>
            <div className={`${styles.StepsInvestmentPlancontenttitle}`}>
            Analysing And Identifying The Right Investment Avenues.
            </div>
            <div className={`${styles.StepsInvestmentPlanContentText}`}>
            Performing an in-depth analysis of all the possible investment avenues involves several technicalities. Thus, we ensure that we combine our experience, expertise and exclusively developed AI-driven Robo Advisor to identify the ones that have the potential to achieve your financial goals. Going beyond just financial planning, our analysis also gives you effective retirement planning and tax planning recommendations that give you a 360 degrees financial solution and complete peace of mind.
            </div>
          </div>
          <div className={`${styles.StepsInvestmentPlanCardImg}`}>
          <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/number-2.png'} alt="" />
            {/* <img src={Two} /> */}
          </div>
        </div>
        <div className={`${styles.StepsInvestmentPlanCard}`}>
          <div className={`${styles.StepsInvestmentPlanCardImg}`}>
          <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/FPPlan/number-3.png'} alt="" />
            {/* <img src={Three} /> */}
          </div>
          <div className={`${styles.StepsInvestmentPlancontent}`}>
            <div className={`${styles.StepsInvestmentPlancontenttitle}`}>
            Recommending The Right Plan Of Action
            </div>
            <div className={`${styles.StepsInvestmentPlanContentText}`}>
            As the action is the final step of every plan, we not only recommend the right investment avenues that will enable you to achieve your goals, but also assist you in investing in them. Moreover, we also go the extra mile and take care of the performance management activities like portfolio analysis and balancing. This ensures that the plan succeeds and you achieve your financial goals.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default StepsFinancialPlan;
