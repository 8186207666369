import { useEffect, useState, useRef } from "react";
import Footer from "../components/MainComponents/Footer";
import { Link, useNavigate } from "react-router-dom";
import HideHeader from "../components/HideHeader";
import axios from "axios";
import { setUserId,setItemLocal, getParentFpLogId, getFpLogId, setFplogid } from '../common_utilities';
import FintooLoader from '../components/FintooLoader';
import SimpleReactValidator from "simple-react-validator";
import { BASE_API_URL, REGISTER_PAGE } from "../constants";
import Form from "react-bootstrap/Form";
import { getParentUserId,getItemLocal,apiCall,loginRedirectGuest, getUserId,
  rsFilter,toPascalCase, } from "../common_utilities";
import commonEncode from "../commonEncode";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import * as constClass from "../constants";


const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailmobile, setEmailMobileNo] = useState("");
  const [emailError, setEmailErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordErrorMsg] = useState("");
  const [captchaData, setCaptchaData] = useState([]);
  const [captchaVal, setCaptchaVal] = useState("");
  const [captchaError, setcaptchaError] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [isLoginFormValid, setLoginFormValid] = useState(false);
  const [rememberMe, setRememberMe] = useState("");
  
  useEffect(() => {
    document.body.classList.add("main-layout");

    getCaptcha();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    checkIfLoggedIn();
  }, []);

  const checkIfLoggedIn = async () => {
    let url = constClass.CHECK_SESSION;

    setIsLoading(true);
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let respData = await apiCall(url, data, true, false);
    
    if (respData["error_code"] == "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.success("You are already logged in");
      window.location.href = process.env.PUBLIC_URL + "/commondashboard/";
    }
    else{
      setIsLoading(false);
    }
    // setIsLoading(false);
  };

  const getCaptcha = async () => {
    var config = {
      method: "GET",
      url: BASE_API_URL + "restapi/getcaptcha/",
    };
    var res = await axios(config);
    if (res != "") {
      try {
        captchaData.captchaImg =
          BASE_API_URL + "static/assets/img/captcha_" + res.data + ".png";
        captchaData.captcha = atob(res.data);
      } catch (error) {
        captchaData.captchaImg = res.data.captcha_url;
        captchaData.captcha = atob(res.data.captchatext);
      }
     
      // setCaptchaImg(
      //   BASE_API_URL + "static/assets/img/captcha_" + res.data + ".png"
      // );
      // captchaData.captchaVal = "";
      setCaptchaData({...captchaData});
      // setCaptcha(atob(res.data));
      setCaptchaVal("");
    }
  };

  const checkEmailMobileValid = (enteredEmailMob) => {
    if (enteredEmailMob != "") {
      var isdigit = false;
      isdigit = /^\d+$/.test(enteredEmailMob);

      if (isdigit) {
        var isValid = false;
        isValid = /^[5-9]\d{9,15}$/.test(enteredEmailMob);
        if (isValid) {
          setEmailErrorMsg("");
        } else {
          setEmailErrorMsg("Please enter valid mobile number.");
        }
      } else {
        var isValid = false;
        isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          enteredEmailMob
        );
        if (isValid) {
          setEmailErrorMsg("");
        } else {
          if (enteredEmailMob != "") {
            setEmailErrorMsg("Please enter valid Email Id.");
          } else {
            setEmailErrorMsg("");
          }
        }
      }
    } else {
      setEmailErrorMsg("Please enter your email/mobile");
    }
  };

  const checkPasswordValid = (enteredPassword) => {

    if (enteredPassword != "") {
        // var isValid = false;
        var isValid = true;
        // isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(enteredPassword);
        // isValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/.test(enteredPassword);

        // if (isValid) {
        //     setPasswordErrorMsg("");
        // } else {
        //   if (enteredPassword != "") {
        //       setPasswordErrorMsg("Please enter valid password.");
        //   } else {
        //       setPasswordErrorMsg("");
        //   }
        // }

        setPasswordErrorMsg("");

    } else {
        setPasswordErrorMsg("Please enter your password.");
    }
  };

  // function getCookie(name) {
  //   var cookieValue = null;
  //   if (document.cookie && document.cookie !== '') {
  //       var cookies = document.cookie.split(';');
  //       for (var i = 0; i < cookies.length; i++) {
  //           // var cookie = jQuery.trim(cookies[i]);
  //           var cookie = cookies[i];

  //           if (cookie.substring(0, name.length + 1) === (name + '=')) {
  //               cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
  //               break;
  //           }
  //       }
  //   }
  //   return cookieValue;
  // }

//   const CSRFToken = () => {
//     return (
//         <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
//     );
//   };
// // export default CSRFToken;

  const checkIfUserExists = async (emailmobile, password, rememberMe) => {
    setIsLoading(true);

    

    var payload = {
      react: "1",
      emailmobile: emailmobile,
      password: password,
      // rememberMe: rememberMe
    };

    var payloadData = commonEncode.encrypt(JSON.stringify(payload));

    try{
        var config = {
          method: "POST",
          // url: BASE_API_URL + "login_with_pwd/mtP8we29q7h5ZULx/",
          url: BASE_API_URL + "loginuser/",
          data: payloadData
        }

        var res = await axios(config);

        if (res) {
          var response_obj = JSON.parse(commonEncode.decrypt(res.data));

          let error_code = response_obj.error_code;

          if (error_code == "100") {
            // setIsLoading(false);
            setEmailErrorMsg("");
          

            setUserId(response_obj.user_id);
            setItemLocal("sky", response_obj.sky);
            try {
              let fp_log_id = await getFpLogId();
              setFplogid(fp_log_id);
            } catch {
              setFplogid("");
            }

            
            let redirectUri = process.env.PUBLIC_URL + "/commondashboard/";

            setItemLocal("logged_in", 1);
            setItemLocal("family", 1);
            

            window.location.href = redirectUri;
            return;
          } else {

            // setLoginFormValid(false);
            setIsLoading(false);
            toastr.options.positionClass = "toast-bottom-left";
            // toastr.error("Please check the entered credentials");
            toastr.error(response_obj.message);
            return;
            // setEmailErrorMsg("Please check the entered email/mobile.");
          }
        }

    }catch (e){
    }


  };

  const handleRememberMe = (rememberMe) => {
      if (rememberMe){
        setRememberMe("yes")
      }
      
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    var isFormValid = simpleValidator.current.allValid();

    if (
      isFormValid &&
      emailError == "" &&
      passwordError == "" &&
      captchaVal != "" &&
      emailmobile != "" &&
      password != "" &&
      captchaError == ""
    ) {
      
      checkIfUserExists(emailmobile, password, rememberMe);

      setcaptchaError("");
    } else {
      if (captchaVal == "") {

       
        setcaptchaError("Please enter captcha.");
      }
      if (emailmobile == "") {
        setEmailErrorMsg("Enter email id/mobile number.");
      }
      if (password == "") {
        setPasswordErrorMsg("Please enter password.");
      }
    }
  };
   
  const checkCaptcha = (enteredcaptcha) => {
    if (enteredcaptcha !== "") {
      setcaptchaError("");
      if (enteredcaptcha != captchaData.captcha) {
        setcaptchaError("Please enter valid captcha");
      }
    } else {
      setcaptchaError("Please enter captcha");
    }
  };

  return (
      <div>
          <FintooLoader isLoading={isLoading} />
          <HideHeader />

          <div className="login-header">
          {/* <div
            className="back-arrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src="https://static.fintoo.in/static/userflow/img/icons/back-arrow.svg"
              alt="Back Arrow"
            />
          </div> */}

              <a target="_self" href="/" className="logo">
                <img
                  src="https://static.fintoo.in/static/userflow/img/logo.svg"
                  alt="Fintoo logo"
                />
              </a>
          </div>

          <section className="login-section">
            <div className="container-fluid" style={{ paddingTop: "50px" }}>
              <div className="row align-items-center ">
                <div className="col-md-7">
                  <div className="login-block">
                    <h2 className="page-header text-center">Welcome!!</h2>

                  
                    <div>
                        <Form noValidate onSubmit={handleSubmit}>
                       
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <div className="material input">
                              <input
                                  type="text"
                                  tabIndex="1"
                                  placeholder="Email/Mobile Number*"
                                  name="emailmobile"
                                  id="emailmobile"
                                  autoComplete="off"
                                  value={emailmobile}
                                  className="default-input"
                                  onChange={(e) => {
                                    setEmailMobileNo(e.target.value);
                                    checkEmailMobileValid(e.target.value);
                                  }}
                                  onBlur={() => {
                                    simpleValidator.current.showMessageFor(
                                      "emailmobile"
                                    );
                                    forceUpdate(1);
                                  }}
                                />
                                <p className="error">{emailError}</p>

                            {/* <input
                              type="text"
                              tabindex="1"
                              placeholder="Email/Mobile Number*"
                              name="emailmobile"
                              id="emailmobile"
                              value=""
                              className="default-input"
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <div className="material input">
                            <input
                              type="password"
                              tabindex="1"
                              placeholder="Password*"
                              name="password"
                              id="password"
                              autoComplete="off"
                              value={password}
                              className="default-input"
                              onChange={(e) => {
                                setPassword(e.target.value);
                                checkPasswordValid(e.target.value);
                              }}
                              onBlur={() => {
                                simpleValidator.current.showMessageFor(
                                  "password"
                                );
                                forceUpdate(1);
                              }}
                            />
                            <p className="error">{passwordError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4"></div>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div id="captcha_block">
                            {/* <img
                              src={require("../Assets/Images/main/captcha.png")}
                              style={{ float: "left" }}
                              draggable="false"
                            /> */}
                            <img
                              src={captchaData.captchaImg}
                              style={{ float: "left" }}
                              draggable="false"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div id="captcha_block">
                            <img
                              onClick={getCaptcha}
                              className="refresh_captcha"
                              src={require('../Assets/Images/main/refresh_captcha.png')}
                              draggable="false"
                              style={{ width: "15%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <div className="material input">
                              <input
                                  type="text"
                                  tabIndex="1"
                                  placeholder="Captcha*"
                                  value={captchaVal}
                                  className="default-input"
                                  onChange={(e) => {
                                    // captchaData.captchaVal = e.target.value;
                                    // setCaptchaData({...captchaData});
                                    setCaptchaVal(e.target.value);
                                    checkCaptcha(e.target.value);
                                  }}
                                  onBlur={() => {
                                    simpleValidator.current.showMessageFor(
                                      "captchaVal"
                                    );
                                    forceUpdate(1);
                                  }}
                              />
                              {/* <input
                                type="text"
                                tabindex="1"
                                placeholder="Captcha*"
                                name="emailmobile"
                                id="emailmobile"
                                value=""
                                className="default-input"
                              /> */}
                                <p className="error">{captchaError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center text-left">
                        <div className="col-md-6">
                          <div
                            className="checkbox-container position-relative"
                            style={{ margin: "10px 0px" }}
                          >
                            <input
                              type="checkbox"
                              name="rememberMe"
                              className="custom-checkbox"
                              id="rememberMe"
                              onChange={(e) => {
                               
                                handleRememberMe(e.target.checked);
                              }}
                            />
                            <label for="remember-me" className="checkbox-label">
                              Remember Me
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center text-center">
                        <div class="col">
                          <div class="btn-container">
                            <input
                              type="submit"
                              name="login"
                              value="Login"
                              class="default-btn d-block"
                              data-loading-text="Loading..."
                            />
                            <Link to={`${process.env.PUBLIC_URL}/login`}
                              // href="/login-with-otp/"
                              target="_self"
                              class="blue-link"
                            >
                              Login with OTP
                            </Link>
                          </div>
                        </div>
                      </div>
                      <p className="text-center">
                        Not a member yet?{" "}
                        <a href={REGISTER_PAGE} target="_self" class="blue-link">
                          Register Now
                        </a>
                      </p>
                      </Form>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-7 login-illustration h100"></div> */}

                <div className="col-md-5 login-illustration h-100">
                </div>
              </div>
            </div>
          </section>

      </div>
  );
};
export default Login;
