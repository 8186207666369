import CommonDashboardLayout from "../../../../components/Layout/Commomdashboard";
import NewGovtSchemesView from "./NewGovtSchemesView";

const NewGovtSchemesForm = () => {

  return (
    <>
      <CommonDashboardLayout>
        <NewGovtSchemesView />
      </CommonDashboardLayout>
    </>
  );
};
export default NewGovtSchemesForm;
