import HideFooter from "../../HideFooter";
import HideHeader from "../../HideHeader";
import Sidebar from "./Sidebar";
const DatagatherLayout = (props) => {
  return (
    <div className="reports ">
      <HideHeader />
      <Sidebar />
      {props.children}
      <HideFooter />
    </div>
  );
};
export default DatagatherLayout;
