import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className={`${styles["section-sub-title"]}`}>
      Invest With 
      <br />
      0% Commission… 100% Confidence.
      </h3>
      {/* <p className={`${styles.sectionsubText}`}>
      Personal stock advisory and expert assistance for investors across all 
investment budgets, experience and requirements.

      </p> */}
    </>
  );
};
export default LeftSection;
